/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CUSTOMER_COMPANY_ACTIVITY, SET_CUSTOMER_COMPANY_ACTIVITY} from '../actions';
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetCustomerCompanyActivity = function* () {
  yield takeEvery(GET_CUSTOMER_COMPANY_ACTIVITY, workerGetCustomerCompanyActivity);
}

function* workerGetCustomerCompanyActivity({ customerParam }) {
  try {

    const uri = apiUrl+'chopchop/customer/customer_company_activity_history?'+customerParam;
    const result = yield call(Axios.get, uri);
	  var resultArr = [];
	  resultArr.push(result.data);
    yield put({ type: SET_CUSTOMER_COMPANY_ACTIVITY, value: resultArr });
  } 
  catch {
    console.log('Get Promotion list Failed');
  }
} 


