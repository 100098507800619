/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cookie from "react-cookies";
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import PhoneInput from "react-phone-input-2";
import "../../common/css/countryflag.css";

import { appId, apiUrl } from "../Helpers/Config";
import { stripslashes, hideLoader, showAlert } from "../Helpers/SettingHelper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";

import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";

var Parser = require("html-react-parser");

var qs = require("qs");


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET_GLOBAL_SETTINGS, GET_LOYLATITY_OUTLET_DETAILS} from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class OutletCustomerlogin extends Component {
  constructor(props) {


    let pathname = props.location.pathname;
    let uriparms_one, uriparms_two = "";
    pathname = pathname.split('/');
    uriparms_one = pathname[1];
    uriparms_two = pathname[2];


    reactLocalStorage.set("outlet_slug_name", uriparms_one);

    const outlet_slug_name =
    reactLocalStorage.get("outlet_slug_name") !== "" &&
    typeof reactLocalStorage.get("outlet_slug_name") !== undefined &&
    typeof reactLocalStorage.get("outlet_slug_name") !== "undefined"
      ? reactLocalStorage.get("outlet_slug_name")
      : "";

    super(props);

    this.state = { customermobile_number: "", 
                   country : "",
                  customermobile_number_error: false,
                   outlet_slug_name: outlet_slug_name, 
                   customer_otp: "", 
                   invalid_customer_error: "",
                   openOtpinput:false,
                   code_digit1: "",
                   code_digit2: "",
                   code_digit3: "",
                   code_digit4: "",
                   code_digit5: "",
                   code_digit6: "",
                   invalid_otp_error: "",
                   country_code: "",
                   loyaltyoutlets: [],

                  customer_phone: "",
                  error_phonenumber: false,
                  logindata: [],
                  phonenumbersuccess: true,
                  customer_password: "",
                  customer_password_error: false,
                  customer_invalid_password: "",
                  passwordEnterInput:false,
                 };


    if (reactLocalStorage.get("UserId") !== undefined) {
        props.history.push("/");
    }


  const login_type = "Customer";
  this.props.getCompanyDetails(login_type);


  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

   if (this.state.loyaltyoutlets !== nextProps.loyaltyoutlets) {
      var tampStArr = nextProps.loyaltyoutlets;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          loyaltyoutlets: nextProps.loyaltyoutlets,
        },function(){
          reactLocalStorage.set("app_id", tampStArr[0].result_set.outlet_app_id);
          this.props.getSettings();
        });
      }

    }  

  }

  sateValChange = (field, value) => {};



  handleTextChange_getStarted(event) {

    var name = event.target.name;
    var value = event.target.value;

    this.setState({ [name]: value });
  }

  customerMobileSubmit(){
    var customer_phone = this.state.customer_phone.replace(this.state.country, "");

    if(customer_phone !=="" && customer_phone.length >= 8){

      var postObject = {
        //app_id: appId,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
      };
      //this.props.getCustomerLoginData(qs.stringify(postObject));
      axios.post(apiUrl + "chopchop/customer/customer_home_login", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {

         reactLocalStorage.set("UserFname", res.data.result_set.customer_last_name !== ""
              ? res.data.result_set.customer_last_name
              : "");
         reactLocalStorage.set("UserLname", res.data.result_set.customer_last_name !== ""
              ? res.data.result_set.customer_last_name
              : "");

          this.setState({error_phonenumber: false, passwordEnterInputPopup: true, customermobile_number: '65'+customer_phone});
          this.doUsernamePopup(res.data);
        }else{

          $.magnificPopup.open({
            items: {
              src: "#otp-popup",
            },
            type: "inline",
          });
          this.setState({ invalid_customer_number: res.data.message, customermobile_number: '65'+customer_phone });
        }
      });

    }else {

      this.setState({error_phonenumber: true});

    }
  }

  handlePwdTextChange(event) {

  var value = event.target.value;

   if (event.target.name === "customer_password") {
    if (value !== "") {
      this.setState({ customer_password_error: false });
    } else {
      this.setState({ customer_password_error: true });
    }
    this.setState({ customer_password: value });
  } 

 }

 customerPasswordSubmit(){
     var customer_phone = this.state.customer_phone.replace(this.state.country, "");
     var customer_password = this.state.customer_password;

    if(customer_password !==""){

      var qs = require("qs");
      var postObject = {
        //app_id: appId,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
        cust_password: this.state.customer_password,
      };
      axios.post(apiUrl + "chopchop/customer/customer_home_login", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {
          
          var mobileno = "",
        cust_birthdate = "";
      if (
        typeof res.data.result_set.customer_phone === "undefined" ||
        res.data.result_set.customer_phone === "null" ||
        res.data.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = res.data.result_set.customer_phone;
      }

      if (
        typeof res.data.result_set.customer_birthdate !== "undefined" &&
        res.data.result_set.customer_birthdate !== "null" &&
        res.data.result_set.customer_birthdate !== null &&
        res.data.result_set.customer_birthdate !== "" &&
        res.data.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = res.data.result_set.customer_birthdate;
      }

      reactLocalStorage.set("login_type", "customer");
      reactLocalStorage.set("LoyaltyUserId", res.data.result_set.customer_id);
      reactLocalStorage.set("UserBirthdate", cust_birthdate);
      reactLocalStorage.set("UserMobile", mobileno);
      reactLocalStorage.set("UserEmail", res.data.result_set.customer_email);
      reactLocalStorage.set("UserFname", res.data.result_set.customer_first_name !== ""
          ? res.data.result_set.customer_first_name
          : "");
      reactLocalStorage.set("UserLname", res.data.result_set.customer_last_name !== ""
          ? res.data.result_set.customer_last_name
          : "");

      reactLocalStorage.set("app_id", res.data.result_set.customer_app_id);

      this.setState({customer_password_error: false, customer_invalid_password: false, customermobile_number: '65'+res.data.result_set.customer_phone});
      this.props.history.push("/customer/dashboard");
        }else{
          showAlert("Error",res.data.message);
          window.$.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.setState({ customermobile_number: '65'+customer_phone });
        }
      });
      this.setState({customer_password_error: false, customer_invalid_password:false});

    }else {

      this.setState({customer_password_error: true});

    }

  }

  mobileNumber(type){
    
    reactLocalStorage.set("outlet_customer_mobile_number", this.state.customermobile_number);

    var customermobile_number = this.state.customermobile_number.replace(this.state.country, "");
    var customer_otp = this.state.customer_otp;

    if(customermobile_number !== "" && customermobile_number !== null && type == "otp"){

      this.setState({customermobile_number_error:false});

      reactLocalStorage.set("outlet_customer_mobile_number", customermobile_number);

      var postObject = {
        outlet_slug_name: this.state.outlet_slug_name,
        verify_type: "register",
        customer_phone: customermobile_number,
      }

       axios.post(apiUrl + "chopchop/customer/send_customer_otp", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {
     
            this.setState({ customermobile_number_error:false, openOtpinput: true, invalid_customer_error: ""});
          
        } else if (res.data.status === "error" && res.data[0].isMobilenoAvbl === "yes")  {

          this.setState({ phonenumbersuccess: false, customermobile_number_error: false, passwordEnterInput: true, customer_phone:customermobile_number});
        } else {

          this.setState({ invalid_customer_error: res.data[0].message, openOtpinput: false});
        }

      });

    }else{

      this.setState({customermobile_number_error:true});
    }


     if(type == "verification" && customermobile_number !==""){

      this.setState({customermobile_number_error:false, invalid_customer_error : ""});


       if (
        this.state.code_digit1 !== "" ||
        this.state.code_digit2 !== "" ||
        this.state.code_digit3 !== "" ||
        this.state.code_digit4 !== "" ||
        this.state.code_digit5 !== "" ||
        this.state.code_digit6 !== ""
      ) {

     var enterOTP =
          this.state.code_digit1 +
          this.state.code_digit2 +
          this.state.code_digit3 +
          this.state.code_digit4 +
          this.state.code_digit5 +
          this.state.code_digit6;


      var postObject = {
        verify_type: "register_loyality",
        //app_id: appId,
        customer_phone: customermobile_number,
        customer_otp_val: enterOTP,
      };

       axios.post(apiUrl + "chopchop/customer/customer_otp_verification", qs.stringify(postObject)).then((res) => {

        if(res.data.status === "ok") {

            this.setState({ invalid_otp_error:''});

            this.props.history.push("/outletcustomerregister");
          
        } else {

          this.setState({ invalid_otp_error: res.data.message});
        }
    });

    }

   }

  }



  handleVerificationCode(first, last) {

    var name = last.target.name;
    var value = last.target.value;
    var element_number = parseInt(first) + 1;

    this.setState({ [name]: last.target.value}, function () {
        if(element_number <= 6){
        var element_id = "code_digit" + element_number;
        document.getElementById(element_id).focus();
       }
    });

  }

 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="customer_mainlogin">
        <div className="container">
          <div className="login_main_div">
            <h1>Welcome to <span>Loyalty Reward System</span></h1>
            <h6>Login and register an account to find all your best deals here.  </h6>        
            <div className="login_form">
            {this.state.phonenumbersuccess === true && (
              <form>

                 <label>Enter your mobile no</label>

                  <PhoneInput country={"sg"}
                        value={this.state.customermobile_number}
                        countryCodeEditable={false}
                        onChange={(customermobile_number, country) =>
                        this.setState({
                            customermobile_number,
                            country: country.dialCode,
                   }) }/>


                {/*<label class="control-label"> Your Mobile Number</label>
                <input type="tel" name="customermobile_number" placeholder="Enter your mobile number" maxLength="8" value={this.state.customermobile_number} onChange={this.handleTextChange_getStarted.bind(this)} onKeyPress={(e) => this.validateIntval(e)} />*/}


                {this.state.customermobile_number_error === true && (
                  <span className="error">This field is required</span>
                )}

                {this.state.invalid_customer_error !== '' && (
                  <span className="error">{this.state.invalid_customer_error}</span>
                )}
                </form>
                )}
                {this.state.passwordEnterInput === true && (

                 <form>
                  
                 <div className="custm_password">
                  <input type="password" className="form-control" id="customer_password" name="customer_password" placeholder="Enter your password" value={this.state.customer_password} onChange={this.handlePwdTextChange.bind(this)}/>
                
                 {this.state.customer_password_error === true && (
                    <span className="error">Please fill in your password.</span>
                  )}    
                
                  {this.state.customer_invalid_password === true && (
                    <span className="error">Sorry, that password isn’t right.</span>
                  )}    
                </div>
                
                {/*<div className="banner-btn">
                  <button type="button" className="button continue_btn" onClick={this.customerPasswordSubmit.bind(this)}>Continue</button>
                </div> 
                <a onClick={this.forget_password.bind(this)} className="forgt_link">Forgot Password?</a>*/}
                </form> 
                )}

                  <form>
                  {this.state.openOtpinput === true && (
                  <div className="verification-code-div">
                  <div className="verification-code">
                    <div className="prompt">Please enter verification code <br/>we sent to your mobile no.</div>
                    <div className="prompt-box verification-code-row">
                      <input type="number" inputMode="numeric" className="input-box-small" id="code_digit1" name="code_digit1" maxLength="1" onChange={this.handleVerificationCode.bind(this, '1')} 
                      value={this.state.code_digit1} pattern="[0-9]*" />
                      <input type="number" inputMode="numeric" className="input-box-small" id="code_digit2" name="code_digit2" maxLength="1" onChange={this.handleVerificationCode.bind(this, '2')}
                        value={this.state.code_digit2} pattern="[0-9]*"/>
                      <input type="number" inputMode="numeric" className="input-box-small" id="code_digit3" name="code_digit3" maxLength="1" onChange={this.handleVerificationCode.bind(this, '3')}
                      value={this.state.code_digit3} pattern="[0-9]*"/>
                      <input type="number" inputMode="numeric" className="input-box-small" id="code_digit4" name="code_digit4" maxLength="1" onChange={this.handleVerificationCode.bind(this, '4')}
                      value={this.state.code_digit4} pattern="[0-9]*" />
                      <input type="number" inputMode="numeric" className="input-box-small" id="code_digit5" name="code_digit5" maxLength="1" onChange={this.handleVerificationCode.bind(this, '5')}
                      value={this.state.code_digit5} pattern="[0-9]*" />
                      <input type="number" inputMode="numeric" className="input-box-small" id="code_digit6" name="code_digit6" maxLength="1" onChange={this.handleVerificationCode.bind(this, '6')}
                      value={this.state.code_digit6} pattern="[0-9]*" />
                    </div>
                  </div>
                </div>
                )}

               {this.state.invalid_otp_error !== '' && (
                  <span className="error">{this.state.invalid_otp_error}</span>
               )}

              </form>

            </div>
            {this.state.openOtpinput === false && this.state.passwordEnterInput === false &&  (
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.mobileNumber.bind(this, 'otp')}>NEXT</button>
            </div>
            )} 

              {(this.state.openOtpinput === true && this.state.passwordEnterInput === false) && (
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.mobileNumber.bind(this, "verification")}>NEXT</button>
            </div>
            )}

              {this.state.passwordEnterInput === true && (
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.customerPasswordSubmit.bind(this)}>NEXT</button>
            </div>
            )}

          </div>
        </div>
       </div>
        <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    loyaltyoutlets: state.loyaltyoutlets,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
     getCompanyDetails: (login_type) => {
      dispatch({ type: GET_LOYLATITY_OUTLET_DETAILS, login_type});
    },
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

OutletCustomerlogin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OutletCustomerlogin));
