/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import cookie from "react-cookies";
import {reactLocalStorage} from 'reactjs-localstorage';
import PhoneInput from "react-phone-input-2";
import "../../../common/css/countryflag.css";

import { appId, apiUrl} from "../../Helpers/Config";

import { GET_CUSTOMER_LOGINDATA} from "../../../actions";

import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
} from "../../Helpers/SettingHelper";
import bannerimage from "../../../common/banner/banner.jpg";


var Parser = require("html-react-parser");
var qs = require("qs");

class HomeBanner extends Component {

  constructor(props) {


    let pathname = props.location.pathname;
    let uriparms_one, uriparms_two = "";
    pathname = pathname.split('/');
    uriparms_one = pathname[1];
    uriparms_two = pathname[2];

 
    const outlet_slug_name = reactLocalStorage.get("outlet_slug_name") !== "" &&
    typeof reactLocalStorage.get("outlet_slug_name") !== undefined &&
    typeof reactLocalStorage.get("outlet_slug_name") !== "undefined"
      ? reactLocalStorage.get("outlet_slug_name")
      : "";

    super(props);
    this.state = {
      customer_phone: "",
      country_code: "",
      error_phonenumber: false,
      logindata: [],
      phonenumbersuccess: true,
      outlet_slug_name: outlet_slug_name,
      customer_password: "",
      customer_password_error: false,
      customer_invalid_password: "",
      passwordEnterInput:false,
    }
    
  }

  closepopup(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(PropsDt) {

    if (PropsDt.logindata !== this.props.logindata) {
      if(PropsDt.logindata[0] !== "" && PropsDt.logindata[0] !== undefined){
      this.doPageHide(PropsDt.logindata[0]);
      }
    }

  }

  doPageHide(fbloginData){

    if (fbloginData.status === "ok" ) {

      if(this.state.phonenumbersuccess === true){

        this.setState({passwordEnterInput: true, phonenumbersuccess:false, customer_invalid_password: false});

        this.doUsername(fbloginData);

      }else if(this.state.passwordEnterInput === true && this.state.passwordEnterInput === true){

        this.doLogin(fbloginData);
      }

    }else if(fbloginData.status === "error"){

     if(this.state.phonenumbersuccess === true){

      var customermobile_number = this.state.customer_phone;
      customermobile_number =  customermobile_number.slice(2);

      var postObject = {
        verify_type: "register_loyality",
        customer_phone: customermobile_number, 
      }

      axios.post(apiUrl + "chopchop/customer/send_customer_otp", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {
          this.props.history.push("/customernumber/"+ this.state.customer_phone);
        }
      });
    }else{

      this.setState({customer_invalid_password: true});

    }

   }

  }

  doUsername(fbloginData){

   if (fbloginData.status === "ok") {

      reactLocalStorage.set("UserFname", fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "");
      reactLocalStorage.set("UserLname", fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "");

    }

  }


  /* for login and facebook login*/
  doLogin(fbloginData) {

    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }

      reactLocalStorage.set("app_id", fbloginData.result_set.customer_app_id);
      reactLocalStorage.set("login_type", "customer");
      reactLocalStorage.set("LoyaltyUserId", fbloginData.result_set.customer_id);
      reactLocalStorage.set("UserBirthdate", cust_birthdate);
      reactLocalStorage.set("UserMobile", mobileno);
      reactLocalStorage.set("UserEmail", fbloginData.result_set.customer_email);
      reactLocalStorage.set("UserFname", fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "");
      reactLocalStorage.set("UserLname",fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "");

      this.props.history.push("/customer/dashboard");

    } else {

      showAlert("Error", fbloginData.message);
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }



  customerNumberSubmit(){

    var customer_phone = this.state.customer_phone.replace(this.state.country, "");

    if(customer_phone !=="" && customer_phone.length >= 8){

      var qs = require("qs");
      var postObject = {
        //app_id: appId,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
      };
      this.props.getCustomerLoginData(qs.stringify(postObject));
      this.setState({error_phonenumber: false});

    }else {

      this.setState({error_phonenumber: true});

    }

  }

  customerPasswordSubmit(){

     var customer_password = this.state.customer_password;

    if(customer_password !==""){

      var qs = require("qs");
      var postObject = {
        //app_id: appId,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
        cust_password: this.state.customer_password,
      };
      this.props.getCustomerLoginData(qs.stringify(postObject));
      this.setState({customer_password_error: false, customer_invalid_password:false});

    }else {

      this.setState({customer_password_error: true});

    }

  }


 handleTextChange(event) {

  var value = event.target.value;

   if (event.target.name === "customer_password") {
    if (value !== "") {
      this.setState({ customer_password_error: false, customer_invalid_password:false});
    } else {
      this.setState({ customer_password_error: true });
    }
    this.setState({ customer_password: value });
  } 

 }


  forgot_password() {
    $.magnificPopup.open({
      items: {
        src: "#forgot-password-popup",
      },
      type: "inline",
    });
  }


  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    return ( <div className="home-banner">

                 {/* <img src={bannerimage} alt="Banner" />*/}

                  <div className="container">
                  <div className="homebanner-content">
                    {this.state.passwordEnterInput === false && (<h1>enjoy</h1>)}

                   {this.state.passwordEnterInput === false && (<h3>rewards, promotions & vouchers</h3>)}

                    <div className="homebanner-content-inner">

                    {this.state.passwordEnterInput === true && (
                    <h4>Welcome back, {reactLocalStorage.get("UserFname") + " " + reactLocalStorage.get("UserLname")+ "!"}</h4>
                     )}

                    {this.state.phonenumbersuccess === true ?
                    <div className="form-label">Enter Your Mobile No</div> :  
                    <div className="form-label">Enter Your Password</div>}

                      {this.state.phonenumbersuccess === true && (
                      <form>
                       <PhoneInput
                        country={"sg"}
                        value={this.state.country_code+ this.state.customer_phone}
                        countryCodeEditable={false}
                        onChange={(customer_phone, country) =>
                          this.setState({
                              customer_phone,
                              country: country.dialCode,
                            }) }/>


                        {this.state.error_phonenumber === true && (
                           <span className="error banner_mbl_error">Please enter the fields</span>
                         )}

                        <div className="banner-btn">
                          <button type="button" className="button delivery_submit_cls" onClick={this.customerNumberSubmit.bind(this)}>NEXT</button>
                        </div>  
                     
                        </form>
                          )}

                         {this.state.passwordEnterInput === true && (

                         <form>
                          
                         <div className="control-group custm_password">
                          <input type="password" className="form-control" id="customer_password" name="customer_password" value={this.state.customer_password} onChange={this.handleTextChange.bind(this)}/>
                        
                         {this.state.customer_password_error === true && (
                            <span className="error">Please fill in your password.</span>
                          )}    
                        
                          {this.state.customer_invalid_password === true && (
                            <span className="error">Sorry,that password isn’t right.</span>
                          )}   

                        </div>

                        <div className="banner-btn">
                          <button type="button" className="button continue_btn" onClick={this.customerPasswordSubmit.bind(this)}>Continue</button>
                        </div> 
                        
                        </form> 
                        )}

                       {this.state.passwordEnterInput === true && (

                        <div className="creat_link_main">
                          <p className="forgt_link" onClick={this.forgot_password.bind(this)}>Forgot Password?</p>
                        </div>

                        )}

                        {this.state.error_phonenumber === true && (
                           <span className="error home-phone">Please enter the fields</span>
                         )}
                         
                      </div>
                    </div>
                  </div>
           </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    logindata: state.customerlogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerLoginData: (formPayload) => {
      dispatch({ type: GET_CUSTOMER_LOGINDATA, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(HomeBanner));
