/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter, Route } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId, apiUrl} from "../Helpers/Config";
import update from "immutability-helper";

import { reactLocalStorage } from "reactjs-localstorage";

import Moment from "moment";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";
import TierBenifits from "./TierBenifits";

import { connect } from "react-redux";
import cookie from "react-cookies";

import { GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_LOYLATITY_CUSTOMER_DETAIL, GET_GLOBAL_SETTINGS} from "../../actions";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import awardorderImg from "../../common/images/award_gray.svg";

import logoImg from "../../common/images/logo.png";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";
import aboutImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";
import awardImg from "../../common/images/award_red.svg";


var Parser = require("html-react-parser");

class CompanyDashboard extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      outlet_id: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
      currentPage: 1,
      displayPerPage: 4,
      customercompanyactivitylist: [],
      vistCount: 0,      
      reward_ponits: 0,
      loyaltycustomerdetail: [],
      globalsettings: [],
      customerdetail: [],
      status: "",
      status1: "",
      pointsEarned: [],
      pointsRedeemed: [],
      client_slug:  this.props.match.params.client_slug !== "" &&
        typeof this.props.match.params.client_slug !== undefined &&
        typeof this.props.match.params.client_slug !== "undefined"
          ? this.props.match.params.client_slug
          : "",
      showearnitems:10,
      showredeemitems:10,
    };

    if(reactLocalStorage.get("LoyaltyUserId") == "" || reactLocalStorage.get("LoyaltyUserId") == undefined) {
      props.history.push("/");
    }

  }

  handleClick(event) {
    this.setState({currentPage: Number(event.target.id)});
  }


  componentDidMount() {

   $("#dvLoading").fadeIn();

    const login_type = "customer";
    this.props.getGlobalSettings(login_type);

    var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + reactLocalStorage.get("LoyaltyUserId");
    this.props.getCustomerDetailLoyalty(params);

  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

      if (nextProps.loyaltycustomerdetail !== this.props.loyaltycustomerdetail) {
       if(Object.keys(nextProps.loyaltycustomerdetail).length > 0 && nextProps.loyaltycustomerdetail !==undefined){
          if (nextProps.loyaltycustomerdetail[0].status === "ok") {
           $("#dvLoading").fadeOut(2000);
           this.setState({loyaltycustomerdetail: nextProps.loyaltycustomerdetail[0].result_set, },function(){
                        this.getCustomerDeatils(); });
                }
             }
         }

        if (this.state.globalSettings !== nextProps.settingsArr) {
          var tampStArr = nextProps.settingsArr;
          if (Object.keys(tampStArr).length > 0) {
            this.setState({
              globalSettings: nextProps.settingsArr,
            });
          }

        }

      if (nextProps.customercompanyactivitylist !== this.props.customercompanyactivitylist) {

        if(Object.keys(nextProps.customercompanyactivitylist).length > 0 &&  nextProps.customercompanyactivitylist !==undefined){

          if (nextProps.customercompanyactivitylist[0].status === "ok") {

           this.setState({customercompanyactivitylist: nextProps.customercompanyactivitylist[0].result_set,
                         vistCount:nextProps.customercompanyactivitylist[0].total_vist },function(){
                        $("#dvLoading").fadeOut();

                        });

                    }

                }
           }

     }

  /* for load more button -start */

  loadrewardearn() {
    var pageNext = this.state.showearnitems + 10;
    this.setState({ showearnitems: pageNext }, function () {
      this.loadereward();
    });
  }
  loadrewardredeem() {
    var pagepNext = this.state.showredeemitems + 10;
    this.setState({ showredeemitems: pagepNext }, function () {
      this.loadrreward();
    });
  }

  loadereward() {

    var customerDetails = this.state.loyaltycustomerdetail;

    $(".load_more_data").append('<b class="gloading_img"></b>');

      var rewardListEarned = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&limit=" +this.state.showearnitems;
      axios.get(apiUrl + "chopchop/loyalty_points/rewards_earnedlist?" + rewardListEarned).then((res) => {
        
              if (res.data.status === "ok") {
              this.setState({
                pointsEarned: res.data.result_set, status: "ok"}, function(){
                this.loadRewardsRedeemed();

           });

          if ( this.state.showearnitems >  res.data.common.total_records ) {
                $(".load_more_data").show();
              } else {
                $(".load_more_data").hide();
              }
            } else {
              $(".load_more_data").hide();
            }

       });

  }


  loadrreward() {

    var customerDetails = this.state.loyaltycustomerdetail;

    $(".load_more_data1").append('<b class="gloading_img"></b>');
    
      var rewardListParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + 
                           customerDetails.customer_loyalty_id + "&limit=" +this.state.showredeemitems;

      axios.get(apiUrl + "chopchop/loyalty_points/rewards_redeemedlist?" + rewardListParam).then((res) => {
              if (res.data.status === "ok") {

              this.setState({
                pointsRedeemed: res.data.result_set, status1: "ok"}, function(){
                this.loadRewardsEarned(); });

            if ( this.state.showredeemitems > res.data.common.total_records
              ) {
                $(".load_more_data1").show();
              } else {
                $(".load_more_data1").hide();
              }
            } else {
              $(".load_more_data1").hide();
            }

       });
  }

  getCustomerDeatils(){

    var customerDetails = this.state.loyaltycustomerdetail;

      if(Object.keys(customerDetails).length > 0 &&  customerDetails !==undefined){

      reactLocalStorage.set('UserId', customerDetails.customer_loyalty_id);

      var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + customerDetails.customer_loyalty_id;

       axios.get(apiUrl + "chopchop/customer/customerdetail?" + params).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                customerdetail:  res.data.result_set,
              });

            }
       });

      const inputKeys = ["reward", "overall_orders"];
      var customerParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&act_arr=" + inputKeys;
      axios.get(apiUrl + "chopchop/reports/activity_counts?" + customerParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                reward_ponits:  res.data.result_set.reward_ponits
              });

            }

       });


      var rewardListEarned = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&limit=" +this.state.showearnitems;
      axios.get(apiUrl + "chopchop/loyalty_points/rewards_earnedlist?" + rewardListEarned).then((res) => {
              if (res.data.status === "ok") {
           this.setState({
                pointsEarned: res.data.result_set, status: "ok"}, function(){
                this.loadRewardsRedeemed();

           });

             if ( this.state.showearnitems >  res.data.common.total_records ) {
                  $(".load_more_data").hide();
                } else {
                  $(".load_more_data").show();
                }
              } else {
                $(".load_more_data").hide();
              }
       });

     var rewardListParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + 
                           customerDetails.customer_loyalty_id + "&limit=" +this.state.showredeemitems;
      axios.get(apiUrl + "chopchop/loyalty_points/rewards_redeemedlist?" + rewardListParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                pointsRedeemed: res.data.result_set, status1: "ok"}, function(){
                this.loadRewardsEarned(); });

               if ( this.state.showredeemitems > res.data.common.total_records
              ) {
                $(".load_more_data1").hide();
              } else {
                $(".load_more_data1").show();
              }
            } else {
              $(".load_more_data1").hide();
            }
       });

      }
  }

  loadRewardsEarned(){
   if (this.state.status === "ok" && this.state.pointsEarned !=="" && Object.keys(this.state.pointsEarned).length > 0) {
    return  this.state.pointsEarned.map((points, index) => (<div className="reward_main">
        <ul>
          <li className="reward_orderdiv">
            <img src={awardorderImg} />
           <div className="reward-mbl-para">
            <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
            <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
              <h5>{points.loyalty_pointorders_orderlocalno}</h5>
            </div>
          </li>
          <li className="reward_datediv">
            <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
            <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
          </li>

          <li className="reward_pointsdiv">
            <p>Points Earned</p>
            <h5>{points.lh_credit_points}</h5>
          </li>
        </ul>
      </div> ));
   }else{

    return <div className="no-recrds-found">When you use our services. You can track and redeem everything here.</div>;
   }
 
  }

  loadRewardsRedeemed(){
   if (this.state.status1 === "ok" && this.state.pointsRedeemed !=="" && Object.keys(this.state.pointsRedeemed).length > 0) {
    return  this.state.pointsRedeemed.map((points, index) => (<div className="reward_main">
        <ul>
          <li className="reward_orderdiv">
            <img src={awardorderImg} />
           <div className="reward-mbl-para">
             <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
             <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
              <h5>{points.loyalty_pointorders_orderlocalno}</h5>
            </div>
          </li>

          <li className="reward_datediv">
            <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
            <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
          </li>

          <li className="reward_pointsdiv">
            <p>Points Redeemed</p>
            <h5>{points.lh_debit_points}</h5>
          </li>
        </ul>
      </div> ));
   }else{

    return <div className="no-recrds-found">When you use our services. You can track and redeem everything here.</div>;
   }

  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }

  openCompanyDetails(company_name){

    var company_name = company_name;

    if(company_name !==""){

      this.props.history.push("/customer/"+company_name);
    }

  }

  backOpen(){

     this.props.history.push("/customer/dashboard");

  }


  sateValChange = (field, value) => {};
  render() {

    let f = "";
    let customer_membership_type_display = "";
    let customer_unique_id = "";
    let calc_kakis_perc = 0;
    let calc_kakis_perc_display = "";
    let client_membership_type = "";
    let customer_membership_type = "";
    let customer_membership_tier_benefits = "";
    let membership_spent_msg = "";


    var settingsArr = this.props.globalsettings;
    var client_logo = "";
    var client_name = "";
    var client_slug = "";

     if (settingsArr !=="" &&  settingsArr.length > 0 && settingsArr !== "undefined" && settingsArr !==null) {
         if (settingsArr[0].status ==="ok") {
        client_membership_type = settingsArr[0].result_set.client_membership_type;
        client_name = settingsArr[0].result_set.client_name;
        client_logo = settingsArr[0].result_set.client_logo;
        client_slug = settingsArr[0].result_set.client_slug;
      }
    }

   
     if (this.state.customerdetail !=="" && this.state.customerdetail !==undefined && Object.keys(this.state.customerdetail).length > 0) {

      customer_membership_type = this.state.customerdetail.customer_membership_type;
      customer_unique_id = this.state.customerdetail.customer_unique_id;
      customer_membership_tier_benefits = this.state.customerdetail.customer_membership_tier_benefits;
      membership_spent_msg = this.state.customerdetail.membership_spent_msg; 

      let membership_max_amount = this.state.customerdetail.membership_max_amount;
      let membership_spent_amount = this.state.customerdetail.membership_spent_amount;
    
     if ( customer_membership_type !== "" && customer_membership_type !== "Normal") {
        customer_membership_type_display = customer_membership_type === "Normal" ? "Bronze" : customer_membership_type;
      }else{
        customer_membership_type_display = "Bronze";
      }

      if (parseInt(membership_max_amount)) {
          calc_kakis_perc = (membership_spent_amount / membership_max_amount) * 100;
      }

      if (customer_membership_type === "Normal" && membership_max_amount) {
        calc_kakis_perc_display = "SPENT $500 more to become Silver Member";
      }else{
        if(customer_membership_type === "Normal"){
          calc_kakis_perc_display = "$500 more to become Silver Member";
        }else if(customer_membership_type === "Silver"){
          calc_kakis_perc_display =  "more to become Gold Member";
        }else if(customer_membership_type === "Gold"){
         calc_kakis_perc_display = "Gold Member";
        }
           
      }

    }

    var settingsMyAcc = {
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 400,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
 
   

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
      
      <section className="myacc_details"></section>
      <div className="container myacc_Dmain customer_reward_main customer_reward_block">
      <div className="myacc_info multitier">{/* multitier start*/}
        <div className="myacc_info_lhs">
          <div className="welcome-title">
            <img className={client_logo !=="" ? "cmpy_logo" : "cmpy_logo normal_cmpy_log"} src={client_logo !=="" ? client_logo :  logoImg} />
          </div>
          <p className="myacc_linkdesc">Welcome to ChopChop Rewards, you can check your loyalty and all the ongoing promotions here</p>
          <button className="button cmpy_btn" onClick={this.backOpen.bind(this)}>
            <a title="Back">back</a>
          </button>
        </div>

        <div className="myacc_info_rhs promotions_info_mem myacc_info_rhs_multi">
          <ul>
            <li className="multi__rhs_li-left">
              <div className="myacc_lhs_multitier">
                <div className="welcome-title">
                  <h1><div><span>Hi ! </span>{reactLocalStorage.get('UserFname') !=="" ? reactLocalStorage.get('UserFname'): ""} {" "}
                    {reactLocalStorage.get('UserLname') !=="" ? reactLocalStorage.get('UserLname'): ""} <img src={hiHndImage} /></div></h1> 
                </div>
              </div>
              <div class="multitier_member_div">
               <h3>{customer_membership_type_display}</h3>
               <p className="member-help-block">{membership_spent_msg}</p>
              </div>
            </li>
            <li className="multi__rhs_li-right">
              <p>cash back point balance</p>
              <h3>{(this.state.reward_ponits || 0).toFixed(2)}</h3>
            </li>
          </ul>
     
          <div className="member-prog-bar">
            <div className="member-progress-2">
              <div className="member-bar-2 member-bar-width-2" style={{width: calc_kakis_perc + "%"}}></div>
            </div>
           <p className="member-mobile-block"> {membership_spent_msg}</p>
          </div>
           
          <div className="multitier_button_rsh">
            <div className="myacc_linkpara">
              <a href="/logout" title="SignOut">SignOut</a>
            </div>
            <div className="destop_acc_dbutton">
              <TierBenifits memDisplayType={customer_membership_type_display} />
            </div>
          </div>
        </div>
        <div className="mobile_acc_dbutton">
          <TierBenifits memDisplayType={customer_membership_type_display} />
        </div>
      </div>{/* multitier End*/}
   
    <div className="singletier_menumain">
        <ul>

          <li className="active">
           <Link to={"/customer/rewards"}>
              <img className="normal_simg" src={awardorderImg} />
              <img className="hover_simg" src={awardorderhoverImg} />
              <span>my rewards</span>
            </Link>
          </li>

          <li>
            <Link to={"/customer/promotions"}>
              <img className="normal_simg" src={celabrateImg} />
              <img className="hover_simg" src={celabratehoverImg} />
              <span>all promo</span>
            </Link>
          </li>

          <li>
            <Link to={"/customer/vouchers"}>
              <img className="normal_simg" src={percentageImg} />
              <img className="hover_simg" src={percentagehoverImg} />
              <span>e vouchers</span>
            </Link>
          </li>

          <li>
             <Link to={"/customer/aboutus/"+client_slug}>
              <img className="normal_simg" src={aboutImg} />
              <img className="hover_simg" src={abouthoverImg} />
              <span>about us</span>
            </Link>
          </li>

        </ul>
      </div>

      <div className="mobile_mnu_block">
        <Slider {...settingsMyAcc}>


            <div className="single_block active">
              <Link to={"/customer/rewards"}>
                <img className="normal_simg" src={awardorderImg} />
                <img className="hover_simg" src={awardorderhoverImg} />
                <span>my rewards</span>
              </Link>
            </div>

            <div className="single_block">
              <Link to={"/customer/promotions"}>
                <img className="normal_simg" src={celabrateImg} />
                <img className="hover_simg" src={celabratehoverImg} />
                <span>all promo</span>
              </Link>
            </div>

            <div className="single_block">
               <Link to={"/customer/vouchers/"}>
                <img className="normal_simg" src={percentageImg} />
                <img className="hover_simg" src={percentagehoverImg} />
                <span>e vouchers</span>
              </Link>
            </div>

            <div className="single_block">
               <Link to={"/customer/aboutus/"+this.state.client_slug}>
                <img className="normal_simg" src={aboutImg} />
                <img className="hover_simg" src={abouthoverImg} />
                <span>about us</span>
              </Link>
            </div>
        </Slider>
      </div>

        <div className="tab_sec main_tabsec_inner destop-reward-tab">
          <div className="myacc_filter">
            <div className="tab_sec filter_tabsec">
              <ul className="nav nav-tabs text-center destop-reward-ul">
                <li className="active">
                  <a
                    data-toggle="tab"
                    href="#tab-id-inn5"
                    aria-expanded="true"
                  >
                    <span>earned</span>
                  </a>
                </li>
                <li className="">
                  <a
                    data-toggle="tab"
                    href="#tab-id-inn6"
                    aria-expanded="false"
                  >
                    <span>redeemed</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="tab-id-inn5" className="tab-pane fade active in">
                  <div className="tab_mobrow filter_tabin">
                    <div className="order-delivery">
                      <div className="ord-body">
                        <div className="cur-order-body reward-list-body">
                          <div className="myacc_order_details">
                            {this.loadRewardsEarned()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="load_more_div reward_load_more">
                    <button
                      className="load_more_data"
                      style={{ display: "none" }}
                       onClick={this.loadrewardearn.bind(this)}
                    >
                      Load More
                    </button>
                  </div>
                </div>
                <div id="tab-id-inn6" className="tab-pane fade">
                  <div className="tab_mobrow filter_tabin">
                    <div className="cur-order-body reward-list-body">
                      <div className="myacc_order_details">
                        {this.loadRewardsRedeemed()}

                      </div>
                    </div>
                  </div>
                  <div className="load_more_div reward_load_more">
                    <button
                      onClick={this.loadrewardredeem.bind(this)}
                      className="load_more_data1"
                      style={{ display: "none" }}>
                      Load More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {

  return {
    globalsettings: state.settings,
    activitycount: state.activitycount,
    customercompanyactivitylist: state.customercompanyactivity,
    loyaltycustomerdetail: state.loyaltycustomerdetail
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
     getCustomerDetailLoyalty: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    }, 
    getCustomerCompanyActivity: (customerParam) => {
      dispatch({ type: GET_CUSTOMER_COMPANY_ACTIVITY, customerParam });
    },
     getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    }, 
    getGlobalSettings: (login_type) => {
     dispatch({ type: GET_GLOBAL_SETTINGS, login_type });
    },
    };
};

CompanyDashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard)
);
