import { SET_CUSTOMER_LOGINDATA } from '../actions';

const customerlogin = (state = [], action) => {
  switch (action.type) {
	case SET_CUSTOMER_LOGINDATA:
      return [...action.value];  
    default: return state;
  }
}

export default customerlogin;
