import React, { Component } from "react";
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";

class Logout extends Component {

  componentWillMount() {

    var login_type = reactLocalStorage.get("login_type");

    if(login_type !=="" && login_type !== undefined && login_type === "customer"){

      reactLocalStorage.clear();

      cookie.remove("UserId", { path: "/" });
      cookie.remove("UserFname", { path: "/" });
      cookie.remove("UserLname", { path: "/" });
      cookie.remove("UserMobile", { path: "/" });
      cookie.remove("UserEmail", { path: "/" });

      cookie.remove("orderPaymentMode", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });

      /* Delivery avilablity */
      cookie.remove("DeliveryDate", { path: "/" });
      cookie.remove("DeliveryTime", { path: "/" });
      cookie.remove("unitNoOne", { path: "/" });
      cookie.remove("unitNoTwo", { path: "/" });

      cookie.remove("promotion_id", { path: "/" });
      cookie.remove("promotion_applied", { path: "/" });
      cookie.remove("promotion_code", { path: "/" });
      cookie.remove("promotion_delivery_charge_applied", { path: "/" });
      cookie.remove("promotion_amount", { path: "/" });
      cookie.remove("promotion_category", { path: "/" });
      cookie.remove("prmo_type", { path: "/" });
      
      cookie.remove("orderDateTime", { path: "/" });
      cookie.remove("UserBirthdate", { path: "/" });
      cookie.remove("deliveryTime", { path: "/" });

      cookie.remove("orderIdTxt", { path: "/" });
      cookie.remove("paymentIdTxt", { path: "/" });
      cookie.remove("merchant_id", { path: "/" });
      cookie.remove("UserDefaultPostalCode", { path: "/" });
      cookie.remove("UserDefaultUnitTwo", { path: "/" });
      cookie.remove("UserDefaultUnitOne", { path: "/" });
      cookie.remove("UserDefaultAddress", { path: "/" });
      cookie.remove("birthdate", { path: "/" });
      cookie.remove("app_id", { path: "/" });
      cookie.remove("LoyaltyUserId", { path: "/" });
      cookie.remove("login_type", { path: "/" });
      sessionStorage.removeItem('UserId');
      sessionStorage.removeItem('app_id');
      this.props.history.push("/");

    }else{

      reactLocalStorage.clear();

      cookie.remove("UserId", { path: "/" });
      cookie.remove("UserFname", { path: "/" });
      cookie.remove("UserLname", { path: "/" });
      cookie.remove("UserMobile", { path: "/" });
      cookie.remove("UserEmail", { path: "/" });

      cookie.remove("orderPaymentMode", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });

      /* Delivery avilablity */
      cookie.remove("DeliveryDate", { path: "/" });
      cookie.remove("DeliveryTime", { path: "/" });
      cookie.remove("unitNoOne", { path: "/" });
      cookie.remove("unitNoTwo", { path: "/" });

      cookie.remove("promotion_id", { path: "/" });
      cookie.remove("promotion_applied", { path: "/" });
      cookie.remove("promotion_code", { path: "/" });
      cookie.remove("promotion_delivery_charge_applied", { path: "/" });
      cookie.remove("promotion_amount", { path: "/" });
      cookie.remove("promotion_category", { path: "/" });
      cookie.remove("prmo_type", { path: "/" });
      cookie.remove("orderDateTime", { path: "/" });
      cookie.remove("UserBirthdate", { path: "/" });
      cookie.remove("deliveryTime", { path: "/" });
      cookie.remove("orderIdTxt", { path: "/" });
      cookie.remove("paymentIdTxt", { path: "/" });
      cookie.remove("merchant_id", { path: "/" });
      cookie.remove("UserDefaultPostalCode", { path: "/" });
      cookie.remove("UserDefaultUnitTwo", { path: "/" });
      cookie.remove("UserDefaultUnitOne", { path: "/" });
      cookie.remove("UserDefaultAddress", { path: "/" });
      cookie.remove("app_id", { path: "/" });
      cookie.remove("LoyaltyUserId", { path: "/" });
      cookie.remove("login_type", { path: "/" });
      sessionStorage.removeItem('UserId');
      sessionStorage.removeItem('app_id');
      localStorage.removeItem('merchant_app_id');
      localStorage.removeItem('merchant_outlet_name');
      this.props.history.push("/");

    }
    
  }

  render() {
    return <h1 className="loading-text">Logging out...</h1>;
  }
}

export default Logout;
