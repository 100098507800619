/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter, Route } from "react-router-dom";
import { 
  getReferenceID,
  stripslashes,
  showPriceValue,
  getCalculatedAmount_rewards,
  showAlert,
  showCustomAlert,
  showPrices,
  showPoint, showLoader, hideLoader, getPromoCkValue } from "../Helpers/SettingHelper";
import { appId, apiUrl } from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from 'moment';
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';
import { GET_GLOBAL_SETTINGS, GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL, GET_PROMOTIONLIST } from "../../actions";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/celabrate_red.png";
import productoneImg from "../../common/images/promofour.png";
import productwoImg from "../../common/images/promotwo.png";
import producthreeImg from "../../common/images/promofour.png";
import productfourImg from "../../common/images/promothree.png";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import awardorderImg from "../../common/images/award_gray.svg";
import phoneImg from "../../common/images/phone.svg";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";

var Parser = require("html-react-parser");
var qs = require("qs");

class Mypromotions extends Component {
  constructor(props) {
    super(props);

    var getPromoCkVal = getPromoCkValue();

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      promotionlist: [],
      loyaltycustomerdetail: [],
      customerdetail: [],
      img_source:"",
      outlet_id: "",
      promoCodeDesc: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
      promo_code_val: '',
      promotion_applied: '',
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: 0,
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],
    };

   if (cookie.load("CustomerUserId") == "" || cookie.load("CustomerUserId") == undefined) {
      props.history.push("/merchantlogin");
    }

    const login_type = "Merchant";
    this.props.getGlobalSettings(login_type);
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      subject: formPayload.type,
      customer_message: formPayload.message,
    };

    this.props.getContactData(qs.stringify(postObject));
  };

  componentDidMount() {
    $("#dvLoading").fadeIn();

    $("html, body").animate({ scrollTop: 0 }, 800);

    const inputKeys = ["reward", "overall_orders"];

    let login_type = "Merchant";

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var customer_user_id = cookie.load("CustomerUserId");

    this.checkOutAuthentication();
    var calculatedAmount = getCalculatedAmount_rewards(
      this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val
    );
    
    this.props.getPromotionList(customer_user_id, login_type);
  }

  checkOutAuthentication(){

    var calculatedAmount = getCalculatedAmount_rewards(
    this.state.subtotal_value, this.state.total_amount_value,this.state.reward_point_val
    );  
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    
    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }

    }

    if (nextProps.customerdetail !== this.props.customerdetail) {

      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(1000);
        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",

             },
        });
      }
    }

    if (nextProps.promotionlist !== this.props.promotionlist) {
      var promotionlistarr = [],
          promotionlistImage ="",
          promotionCount="";
      if (nextProps.promotionlist[0].status === "ok") {
          promotionlistImage = nextProps.promotionlist[0].common;
        if (
          nextProps.promotionlist[0].status &&
          nextProps.promotionlist[0].result_set
        ) {
          promotionlistarr = nextProps.promotionlist[0].result_set.my_promo;
          promotionCount = Object.keys(nextProps.promotionlist[0].result_set.my_promo).length;
        }
      }
      this.setState({promotionlist: promotionlistarr, img_source:promotionlistImage, promocode_count:promotionCount});
    }

  }

  applyPointsAndPromo(type) {

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;
    var { history } = this.props;
    if (type === "points") {
      if (parseFloat(reedemPointVal) === 0 || reedemPointVal === "") {
        showAlert("Error", "Sorry!. Invalid Redeem Point.");
      }
    } else if (type === "promoCode") {
      if (promoCodeVal === "") {
        return false;
      }
    }

      showLoader("applypromo_cls", "class");
      
      var subtotal_value = 0;
      
      if(cookie.load("reward_checkout") === 'yes' ){
        subtotal_value = cookie.load("subtotal_value");
        var postObject = {
          app_id: cookie.load("merchant_app_id"),
          reference_id: cookie.load("CustomerUserId"),
          promo_code: this.state.promo_code_val,
          cart_amount: subtotal_value,
          outlet_id: cookie.load("merchant_outlet_id"),
        };
        
        axios
          .post(
            apiUrl + "chopchop/promotion/apply_promotion",
            qs.stringify(postObject)
          )
          .then((res) => {
            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              var IsDelivery =
                pointDet.promotion_delivery_charge_applied == "Yes"
                  ? "Yes"
                  : "No";
              promotionApplied = "Yes";
              promotionType = "promoCode";
              promotionAmount = pointDet.promotion_amount;
              promotionSource = promoCodeVal;
              promoIsDelivery = IsDelivery;
              //reedemPointVal = "";
              //usedPoints = 0;
              $.magnificPopup.close();
              showCustomAlert("success", "Promotion applied successfully");
            } else {
              var msgTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Sorry! Did not applied promo code";
              if (promotionType === "promoCode") {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
              }
              $.magnificPopup.close();
              showCustomAlert("error", msgTxt);
            }

            hideLoader("applypromo_cls", "class");
            cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
            cookie.save("promotionApplied", promotionApplied, { path: "/" });
            cookie.save("promotionType", promotionType, { path: "/" });
            cookie.save("promotionAmount", promotionAmount, { path: "/" });
            cookie.save("promotionSource", promotionSource, { path: "/" });
            cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });

            this.setState({
              promo_code_val: promoCodeVal,
              promotion_applied: promotionApplied,
              promotion_type: promotionType,
              promotion_amount: promotionAmount,
              promotion_source: promotionSource,
              promoIs_delivery: promoIsDelivery,
            },function() {
            this.redirectCheckout("reward")
            });
          });
      }else if(cookie.load("voucher_checkout") === 'yes' ){

        subtotal_value = cookie.load("cart_subtotal_value");
        var postObject = {
          app_id: cookie.load("merchant_app_id"),
          reference_id: cookie.load("CustomerUserId"),
          promo_code: this.state.promo_code_val,
          cart_amount: subtotal_value,
          outlet_id: cookie.load("merchant_outlet_id"),
        };
        
        axios
          .post(
            apiUrl + "chopchop/promotion/apply_promotion",
            qs.stringify(postObject)
          )
          .then((res) => {
            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              var IsDelivery =
                pointDet.promotion_delivery_charge_applied == "Yes"
                  ? "Yes"
                  : "No";
              promotionApplied = "Yes";
              promotionType = "promoCode";
              promotionAmount = pointDet.promotion_amount;
              promotionSource = promoCodeVal;
              promoIsDelivery = IsDelivery;
              //reedemPointVal = "";
              //usedPoints = 0;
              $.magnificPopup.close();
              showCustomAlert("success", "Promotion applied successfully");
            } else {
              var msgTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Sorry! Did not applied promo code";
              if (promotionType === "promoCode") {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
              }
              $.magnificPopup.close();
              showCustomAlert("error", msgTxt);
            }
            hideLoader("applypromo_cls", "class");
            cookie.save("voucher_promoCodeVal", promoCodeVal, { path: "/" });
            cookie.save("voucher_promotionApplied", promotionApplied, { path: "/" });
            cookie.save("voucher_promotionType", promotionType, { path: "/" });
            cookie.save("voucher_promotionAmount", promotionAmount, { path: "/" });
            cookie.save("voucher_promotionSource", promotionSource, { path: "/" });
            cookie.save("voucher_promoIsDelivery", promoIsDelivery, { path: "/" });

            this.setState({
              reward_point_val: reedemPointVal,
              promo_code_val: promoCodeVal,
              promotion_applied: promotionApplied,
              promotion_type: promotionType,
              promotion_amount: promotionAmount,
              promotion_source: promotionSource,
              promoIs_delivery: promoIsDelivery,
              used_reward_point: usedPoints,
            },function(){
            this.redirectCheckout("voucher")
            });
          });
      }else{
        $.magnificPopup.close();
        showCustomAlert("error", "Cart amount is mandatory");
      }
  }

  redirectCheckout(type){
    if(type === 'voucher'){
      this.props.history.push('/merchant/vouchercheckout');
    }else{
      this.props.history.push('/merchant/checkout');
    }

  }

  getCustomerDeatils(){

    var customerDetails = this.state.loyaltycustomerdetail;


      if(Object.keys(customerDetails).length > 0 &&  customerDetails !==undefined){

      cookie.save("UserId", customerDetails.customer_loyalty_id, { path: "/" });

      var params = "app_id="+ cookie.load("merchant_app_id") + "&status=A&refrence=" + customerDetails.customer_loyalty_id;


       axios.get(apiUrl + "chopchop/customer/customerdetail?" + params).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                customerdetail:  res.data.result_set,
              });

            }
       });

      const inputKeys = ["reward", "overall_orders"];
      var customerParam = "app_id="+ cookie.load("merchant_app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&act_arr=" + inputKeys;
      axios.get(apiUrl + "chopchop/reports/activity_counts?" + customerParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                reward_ponits:  res.data.result_set.reward_ponits
              });

            }

       });


      var rewardListEarned = "app_id="+ cookie.load("merchant_app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&limit=" +this.state.showearnitems;
      axios.get(apiUrl + "chopchop/loyalty_points/rewards_earnedlist?" + rewardListEarned).then((res) => {
              if (res.data.status === "ok") {
           this.setState({
                pointsEarned: res.data.result_set, status: "ok"}, function(){
                this.loadRewardsRedeemed();

           });

             if ( this.state.showearnitems >  res.data.common.total_records ) {
                  $(".load_more_data").hide();
                } else {
                  $(".load_more_data").show();
                }
              } else {
                $(".load_more_data").hide();
              }
       });

     var rewardListParam = "app_id="+ cookie.load("merchant_app_id") +"&status=A&customer_id=" + 
                           customerDetails.customer_loyalty_id + "&limit=" +this.state.showredeemitems;
      axios.get(apiUrl + "chopchop/loyalty_points/rewards_redeemedlist?" + rewardListParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                pointsRedeemed: res.data.result_set, status1: "ok"}, function(){
                this.loadRewardsEarned(); });

               if ( this.state.showredeemitems > res.data.common.total_records
              ) {
                $(".load_more_data1").hide();
              } else {
                $(".load_more_data1").show();
              }
            } else {
              $(".load_more_data1").hide();
            }
       });

      }
  }

  displayDesktopPromo(){  
    var promtoion_list = "";  
    if(Object.keys(this.state.promotionlist).length > 0){  
      return this.state.promotionlist.map((item, index) => (  
        <ul className="promotion-list-ul" key={index}>  
            <li className="promo_product_li"> 
          <div className="promo_product_imgdiv">  
            <img src={(item.promotion_image !=''&&item.promotion_image !=null)?this.state.img_source.promo_image_source+item.promotion_image:productwoImg} /> 
            <div className="inner_img_cotnt"> 
              <div className="inner_img_lhs"> 
                <ul>  
                  <li>  
                    valid till {moment(item.promotion_end_date).format("DD/MM/YYYY")} 
                  </li> 
                  <li>  
                    <h5>{item.promotion_title}</h5>   
                  </li> 
                </ul> 
              </div>  
              <div className="inner_img_rhs"> 
                <p><sup>$</sup> {item.promotion_percentage} off</p> 
              </div>  
            </div>  
          </div>  
          <div className="myacc_product_contntdiv"> 
            <div className="promo_button">  
              <a onClick={this.openVoucherDetail.bind(this, item)} className="button" title="Refer Now">view more</a> 
            </div>  
          </div>  
       </li></ul> 
    )); 
    }else{ 
    return <ul>No Promotions Found.</ul>; 
    }  
  } 
  displayMobilePromo(){ 
  if(Object.keys(this.state.promotionlist).length > 0){ 
   return this.state.promotionlist.map((item, index) => ( 
     <ul><li className="promo_product_li" key={index}>  
        <div className="promo_product_imgdiv">  
          <img src={(item.promotion_image !=''&&item.promotion_image !=null)?this.state.img_source.promo_image_source+item.promotion_image:productwoImg} /> 
          <div className="inner_img_cotnt"> 
            <div className="inner_img_lhs"> 
              <ul>  
                <li>  
                  valid till {moment(item.promotion_end_date).format("DD/MM/YYYY")} 
                </li> 
                <li>  
                  <h5>{item.promotion_title}</h5>   
                </li> 
              </ul> 
            </div>  
            <div className="inner_img_rhs"> 
              <p><sup>$</sup> {item.promotion_percentage} off</p> 
            </div>  
          </div>  
        </div>  
        <div className="myacc_product_contntdiv"> 
          <div className="promo_button">  
            <a onClick={this.openVoucherDetail.bind(this,item)} className="button" title="Refer Now">view more</a>  
          </div>  
        </div>  
     </li> </ul>  
       ));  
     }else{ 
      return <ul><li>No Promotions Found.</li></ul>;  
     }  
  }

  openVoucherDetail(promoList){
    this.setState({promoCodeDesc : promoList.promo_desc, promo_code_val : promoList.promo_code})
   $.magnificPopup.open({
      items: {
        src: ".promo_details_popup",
      },
      type: "inline",
    });

  }

  logoutClientDetails(){
  
    cookie.remove("CustomerUserId", { path: "/" });
    cookie.remove("CustomerUserFname", { path: "/" });
    cookie.remove("CustomerUserLname", { path: "/" });
    cookie.remove("CustomerUserMobile", { path: "/" });
    cookie.remove("CustomerUserEmail", { path: "/" });

    var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';

    this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
  }

  sateValChange = (field, value) => {};
  render() {

    return (
    <div className="contactusmain-top-div">
      <Header sateValChange={this.sateValChange} />
      {/*<section className="myacc_details"></section>*/}
      {/*<div className="container myacc_Dmain customer_promotion_main">*/}
        <div className="myacc_main_div">
          {/*<div className="merchent-mobile_outlet">
            <div className="merchent-mobile_innername">
              <p>bugis outlet</p>
              <div className="merchent-mobile-inbutton">
                <a href="#" className="button">switch</a>
              </div>
            </div>
          </div>*/}
          <div className="merchent_serving">
            {/*<ul className="merchent_servingul">                        
              <li className="merchent_dropdown">
                <a className="hordertype_btn" title="Serving">Serving</a>
                  <ul className="serving_submenu-inner">
                   <li> 
                    <a className="merchant_menu" title="Transaction History"><span>Transaction History</span></a>
                  </li>
                  <li> 
                    <a className="merchant_menu" title="Logout"><span>Logout</span></a>
                  </li>
                 </ul>
              </li>
            </ul>*/}
            {cookie.load("CustomerUserId") && (
             <li className="hservingtype hservingnext">
                <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
            </li>)}
          </div>
          <Merchantdashboard />

          <div className="container merchant-promotion-container">
            <div className="promotion_content_main merchant-promotion-destop">{/* Desktop view Start */}
             <ul className="promotion-merheading">
               <li className="myacc_locat_div">
                 <img src={locationImg} />
                 <h5>All Promo</h5>
               </li>
               <li><h6>Promo Codes</h6></li>
             </ul>
            <div id="receipt-popup" className="white-popup mfp-hide promo_details_popup">
              <div className="promo-popup-inner">
                <div className="promo-popup-image">
                </div>
                <div className="promo-pop-content">
                  <h4>Promo Code Description</h4>
                  <p>{Parser(this.state.promoCodeDesc)}</p>
                  <button
                      className="button"
                      onClick={this.applyPointsAndPromo.bind(
                        this
                      )}
                    >
                      Redeem Now
                    </button>
                </div>
              </div>
            </div>

          {this.displayDesktopPromo()}
          </div>{/* Destop view End */}
          
          {/* Mobile view Start */}
          <div className="myacc_product_main promotion_product_main mobile_myacc_Dmain merchant-promotion-mobile">

            <div className="myacc_content_main promotion_content_main promotion_innert_headind">
           <ul>
             <li>
                <h6>Promo Codes</h6>
             </li>
           </ul>
          </div>
             {this.displayDesktopPromo()}
          </div>{/* Mobile view End */}
        </div>
      </div>
       <Footer />
       <div id="dvLoading"></div>
  </div>
    );
  }
    
  }

const mapStateToProps = (state) => {

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    customercompanyactivitylist: state.customercompanyactivity,
    settingsArr: globalSettings,
    activitycount: state.activitycount,
    customerdetail: state.customerdetail,
    promotionlist: state.promotionlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: (login_type) => {
     dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
    },
    getPromotionList: (customerParam, login_type) => {
    dispatch({ type: GET_PROMOTIONLIST, customerParam, login_type });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Mypromotions)
);
