/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { stripslashes, showAlert, getCalculatedAmount_rewards, getPromoCkValue, shorten, showPriceValue, showLoader, hideLoader, showCustomAlert, removePromoCkValue, removeVoucherPromoCkValue } from "../Helpers/SettingHelper";
import { appId, apiUrl, apiUrlV2 } from "../Helpers/Config";
import update from "immutability-helper";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";
import moment from 'moment';
import axios from "axios";

import { connect } from "react-redux";
import cookie from "react-cookies";

import { GET_GLOBAL_SETTINGS, GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL, GET_MENU_NAVIGATION, GET_PROMOTIONLIST, GET_PRODUCT_LIST, GET_CART_DETAIL } from "../../actions";

import productoneImg from "../../common/images/promofour.png";
import heartImg from "../../common/images/heart-black.svg";
import phoneImg from "../../common/images/phone.svg";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";
import proVouchar from "../../common/images/pro-voucher.png";
import cashVouchar from "../../common/images/cash-voucher.png";
import BigproVouchar from "../../common/images/big-pro-vouchar.png";
import BigcashVouchar from "../../common/images/big-cash-vouchar.png";

var Parser = require("html-react-parser");
var qs = require("qs");

var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

class Merchantvoucher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subtotal_value: 0,
      total_amount_value: '',
      omisePayNowResponse: [],
      globalSettings: [],
      qty: 0,
      voucherProductDetail: [],
      voucherIndexFlag: 0,
      selectedNavigation: "",
      selectedSlugType: "",
      catSlug: '',
      subcatSlug: '',
      productSubcatlist: [],
      productDetailList: [],
      promodetails: [],
      myVouchers: [],
      usedVouchers: [],
      productimagePath: '', 
      currentDate: '',
      status: '',
      outletid: cookie.load("merchant_outlet_id"),
      customer_user_id: cookie.load("CustomerUserId")
    };

     if (cookie.load("CustomerUserId") == "" || cookie.load("CustomerUserId") == undefined) {
      props.history.push("/");
    }

  }

  componentDidMount() {

    $("#dvLoading").fadeIn();

    $("html, body").animate({ scrollTop: 0 }, 800);

    const inputKeys = ["reward", "overall_orders"];
    const login_type = "Merchant";

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    this.setState({
      currentDate: today,
    });

    this.checkOutAuthentication();
    var calculatedAmount = getCalculatedAmount_rewards(
      this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val
    );
    var params = "&status=A&refrence=" + this.state.customer_user_id;
    var catSlug = cookie.load("merchant_outlet_slug_name"),subcatSlug = cookie.load("merchant_outlet_slug_name");
    this.props.getCustomerDetail(params, login_type);
    this.props.getMenuNavigationList(this.state.outletid, login_type);
    this.getVoucherList(app_id,this.state.customer_user_id);
  }

  checkOutAuthentication(){

    var calculatedAmount = getCalculatedAmount_rewards(
    this.state.subtotal_value, this.state.total_amount_value,this.state.reward_point_val
    );  
  }

  getVoucherList(app_id,customer_user_id){
    axios
      .get(
        apiUrl +
          "promotion_api_v2/vouchers?app_id=" +
          cookie.load("merchant_app_id") +
          "&customer_id=" +customer_user_id
      )
      .then((vouchers) => {
        this.setState({ voucherStatus: "Ready" });
        /* set promotion content */
        if (vouchers.data.status === "ok") {
          this.setState({
            myVouchers: vouchers.data.result_set.voucher_list,
            usedVouchers: vouchers.data.result_set.used_vouchers,
            productimagePath: vouchers.data.common.image_source,
            status: "ok",
          });
        }
      });
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    let selectedNavigation = nextProps.selectedNavigation;
    let slugType = nextProps.selectedSlugType;
  
    var login_type = "Merchant";
    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }

    }

    if (selectedNavigation !== this.state.selectedNavigation) {

      var catSlug = "";
      var subcatSlug = "";
      if (selectedNavigation !== "") {
        catSlug = selectedNavigation;
        subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        catSlug = (catSlug !='')?catSlug:subcatSlug;
        subcatSlug = (subcatSlug !='')?subcatSlug:catSlug;
        this.props.getProductList(catSlug, subcatSlug, login_type);
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedSlugType: slugType,
        catSlug: catSlug,
        subcatSlug: subcatSlug,
      });
    }

    /* for receipt promotion */
    if (nextProps.promotionreceipt !== this.props.promotionreceipt) {
      if (nextProps.promotionreceipt[0].status === "ok") {
        this.setState({
          promodetails: nextProps.promotionreceipt[0].result_set,
        });

        $("#dvLoading").fadeOut(2000);

        $.magnificPopup.open({
          items: {
            src: ".receipt_popup",
          },
          type: "inline",
        });
      } else {
        this.setState({ promodetails: [] });
      }
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {

      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(4000);
        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",

             },
        });


      }
    }
    
    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          this.productsubcatlist();
        }
      );
    }

   }

  productsubcatlist() {
    var subcatlistArr = this.state.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      $("#no_product_error").hide();
      //Object.keys(nextProps.promotionlist[0].result_set.my_promo).length
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (<React.Fragment key={categoryIndex}>
                {this.loadVouchers(
                  categories.products,
                  categories.pro_cate_slug,
                  categories.pro_subcate_slug
                )}
              </React.Fragment>
          );
        }
      });
  
      this.setState({ productDetails: productDetails}, function () {
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function () {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
              $(this).find(".sub_cate_title").show();
              $(this).find(".sub_cate_description").show();
            } else {
              $(this).find(".sub_cate_title").hide();
              $(this).find(".sub_cate_description").hide();
            }
          });
          if (totalProducts > 0) {
            $("#no_product_error").hide();
          } else {
            $("#no_product_error").show();
          }
        }
        if (this.state.scrollProduct !== "") {
          if ($("#prolistproIndex-" + this.state.scrollProduct).length > 0) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#prolistproIndex-" + this.state.scrollProduct).offset()
                    .top - 100,
              },
              500
            );
            this.setState({ scrollProduct: "" });
          }
        }
      });
      return productDetails;
    } else {
      return "";
    }
  }

  openVoucherDetail(productDetail){
   this.setState({productDetailList : productDetail});
   $.magnificPopup.open({
      items: {
        src: "#voucher-popup",
      },
      type: "inline",
    });

  }

  loadProductDate(date) {
    var product_date = new Date(date).toString().split(" ");
    return product_date[2] + "-" + product_date[1] + "-" + product_date[3];
  }

  loadVouchers(list, pro_cate_slug, pro_subcate_slug){
    var imageSource = this.props.productCommon.product_image_source;
    var tagimagesource = this.props.productCommon.tag_image_source;
    var productgalleryimagesource =
      this.props.productCommon.product_gallery_image_source;
    var totalProduct = 0;

    if (Object.keys(list).length > 0) {

    return(<div className="myacc_product_main mercnt-voucher-maindiv">
      
      <h6>
        Product Vouchers
      </h6>
      {list.map((productDetail, index) => {
        if(productDetail.product_voucher === 'f'){
        return(<ul className="voucher_ul">
         <li className="voucher-li">
            <div className="voucher_imgdiv">
              <img src={productDetail.product_thumbnail !== "" ? (imageSource + "/" + productDetail.product_thumbnail) : productoneImg} />
              <div className="inner_img_cotnt">
                <div className="inner_img_lhs merchent_lhs">
                  <ul>
                    <li>
                      valid till {moment(productDetail.product_voucher_expiry_date).format("DD/MM/YYYY")}
                    </li>

                    <li>
                      <h5>
                        {productDetail.product_alias !== "" ? stripslashes(productDetail.product_alias):stripslashes(productDetail.product_name)}
                      </h5> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="myacc_product_contntdiv">
              <div className="promo_button">
                <a onClick={this.openVoucherDetail.bind(this,productDetail)} className="button" title="Refer Now">view more</a>
              </div>
            </div>
         </li>
       </ul>);
        }
      })}
      <div className="promotion_voacher_main">
      <div className="voacher_heading">
        <h5>E vouchers</h5>
      </div>
      {list.map((productDetail, index) => {
        return(<React.Fragment key={index}>
        {productDetail.product_voucher === 'c' && 
          <ul>
           <li className="promo_product_li">
              <div className="promo_product_imgdiv">
                <img src={productDetail.product_thumbnail !== "" ? (imageSource + "/" + productDetail.product_thumbnail) : productoneImg} />
                <div className="inner_img_cotnt">
                  <div className="inner_img_lhs merchent_lhs">
                    <ul>
                      <li>
                        valid till {moment(productDetail.product_voucher_expiry_date).format("DD/MM/YYYY")}
                      </li>

                      <li>
                        <h5>
                          {productDetail.product_alias !== "" ? stripslashes(productDetail.product_alias):stripslashes(productDetail.product_name)}
                        </h5> 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="myacc_product_contntdiv">
                <div className="promo_button">
                  <a onClick={this.openVoucherDetail.bind(this,productDetail)} className="button" title="Refer Now">view more</a>
                </div>
              </div>
           </li>

         </ul>}
         </React.Fragment>)
        })}
      </div>
    </div>);
    }else{
      <p>No Vouchers Found</p>
    }
  }

  loadMyVouchers() {
    var myVouchers =
      this.state.myVouchers !== undefined ? this.state.myVouchers : Array();
      
    if (Object.keys(myVouchers).length > 0) {
      return this.state.myVouchers.map((vouch, index) => (
        <li className="promo-li" key={index}>
            <div className="promo-image">
              {vouch.product_thumbnail !== "" &&
              vouch.product_thumbnail !== null ? (
                <img
                  src={this.state.productimagePath + vouch.product_thumbnail}
                />
              ) : (
                <img
                  src={vouch.product_voucher == "c" ? cashVouchar : proVouchar}
                />
              )}

              <div className="inner_img_cotnt">
                <div className="inner_img_lhs merchent_lhs">
                  <ul>
                    {/* <li>
                      valid till {moment(productDetail.product_voucher_expiry_date).format("DD/MM/YYYY")}
                    </li>

                    <li>
                      <h5>
                        {productDetail.product_alias !== "" ? stripslashes(productDetail.product_alias):stripslashes(productDetail.product_name)}
                      </h5> 
                    </li> */}

                    <li><span>Expiry {this.loadProductDate(vouch.expiry_date)}</span></li>
                    <li>
                      <h4 className="promo-heading">
                        <span> {vouch.item_name}</span>
                      </h4>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="promo-info-div">
              {/* <div className="promo-top-div">
                <h4 className="promo-heading">
                  <span> {vouch.item_name}</span>
                </h4>
                <span>Expiry {this.loadProductDate(vouch.expiry_date)}</span>
                <p className="vouchar-txt">{Parser(vouch.product_short_description)}</p>
              </div> */}
              <div className="promation_btn" id={"promo-codediv-" + index}>
               {/* <p>Available: {vouch.order_item_voucher_balance_qty}</p> */}
                {this.state.currentDate > vouch.expiry_date ? (
                  <a
                    className="button promation_btn-one voacher_btn-one"
                    href="javascript:void(0);"
                  >
                    Expired{" "}
                  </a>
                ) : (
                  <a
                    className="button merbtn-redeem"
                    onClick={this.promoPopup.bind(this, vouch)}
                  >
                    View & Redeem
                  </a>
                )}
              </div>
            </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  
  /* Vouchers used list*/
  loadMyUsedVouchers() {
    var myVouchers =
      this.state.usedVouchers !== undefined ? this.state.usedVouchers : Array();

    if (Object.keys(myVouchers).length > 0) {
      return this.state.promoused.map((vouch, index) => (
        <li className="promo-li" key={index}>
            <div className="promo-image">
              {vouch.product_thumbnail !== "" &&
              vouch.product_thumbnail !== null ? (
                <img
                  src={this.state.productimagePath + vouch.product_thumbnail}
                />
              ) : (
                <img
                  src={vouch.product_voucher == "c" ? cashVouchar : proVouchar}
                />
              )}
            </div>
            <div className="promo-info-div">
              <div className="promo-top-div">
                <h4 className="promo-heading">
                  <span> {vouch.item_name}</span>
                </h4>
                <span>Expiry {this.loadProductDate(vouch.expiry_date)}</span>
                <p className="vouchar-txt">{Parser(vouch.product_short_description)}</p>
              </div>
              <div className="promation_btn voucher_expiry" id={"promo-codediv-" + index}>
                <a
                  className="button promation_btn-one"
                >
                  Expired{" "}
                </a>
              </div>
            </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  promoPopup(promoData) {
    var promoImage =
      promoData.product_thumbnail !== "" && promoData.product_thumbnail !== null
        ? promoData.product_thumbnail
        : proVouchar;
    this.setState(
      { promodetails: promoData, promoImage: promoImage },
      function () {
        this.trgPromoPopup();
      }.bind(this)
    );
  }

  trgPromoPopup() {
    $.magnificPopup.open({
      items: {
        src: ".receipt_popup",
      },
      type: "inline",
    });
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $(".proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $(".proqty_input").val(proqtyInput);
  }

  /* add to cart */
  addToCartVoucherMe(voucherProductDetail) {
    
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("CustomerUserId") === "undefined" ? "" : cookie.load("CustomerUserId");
    var pro_qty = $('.proqty_input').val();

    var TotalPrice = voucherProductDetail.product_price * pro_qty;
    var ImagePath = voucherProductDetail.product_thumbnail;
    if (ImagePath !== "") {
      var postImagePath =
        this.state.productimagePath + voucherProductDetail.product_thumbnail;
    } else {
      postImagePath = "";
    }

    var postObject = {};
    postObject = {
      app_id: cookie.load('merchant_app_id'),
      product_id: voucherProductDetail.product_id,
      product_qty: pro_qty,
      product_name: voucherProductDetail.product_name,
      product_sku: voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: voucherProductDetail.product_price,
      product_type: 5,
      product_image: postImagePath,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      voucher_gift_name:
        typeof cookie.load("CustomerUserFname") === "undefined"
          ? ""
          : cookie.load("CustomerUserFname"),
      voucher_gift_mobile:
        typeof cookie.load("CustomerUserMobile") === "undefined"
          ? ""
          : cookie.load("CustomerUserMobile"),
      voucher_gift_email:
        typeof cookie.load("CustomerUserEmail") === "undefined"
          ? ""
          : cookie.load("CustomerUserEmail"),
      voucher_gift_message: "",
      voucher_for: "Me",
      product_voucher_expiry_date: voucherProductDetail.product_voucher_expiry_date !== "" ? this.state.voucherProductDetail.product_voucher_expiry_date : "",
    };

    axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      
      if (res.data.status === "ok") {
        $.magnificPopup.close();
        const login_type = "Merchant";
        this.props.getCartDetail(login_type);
        removeVoucherPromoCkValue();
        cookie.remove("subtotal_value", {path:'/'});
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          voucherProductDetail: res.data.contents,
          voucher_product_qty: "",
        },function() {
          this.updateCartAmount();
        }
        );
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  updateCartAmount(){
    cookie.save("cart_subtotal_value", this.state.voucherProductDetail.cart_details.cart_sub_total, {path:'/'});
    cookie.save("voucher_checkout", 'yes', {path:'/'});
    cookie.save("reward_checkout", 'no', {path:'/'});
  }

  addToCart(voucher) {

    if (voucher !== "") {
      if (voucher.product_voucher == "f") {
        var modifier = [];
        var combo = [];
        var postObject = {};
        var price = parseFloat("0.00");
        var ImagePath = voucher.product_thumbnail;
        if (ImagePath !== "") {
          var postImagePath =
            this.state.productimagePath + voucher.product_thumbnail;
        } else {
          postImagePath = "";
        }

        var qty_voucher = $(".proqty_input").val();
        var login_type = "Merchant";
        var postObject = {
          app_id: cookie.load('merchant_app_id'),
          product_id: voucher.item_product_id,
          product_name: voucher.item_name,
          product_sku: voucher.item_sku,
          product_image: postImagePath,
          product_unit_price: price,
          product_qty: qty_voucher,
          product_total_price: price,
          modifiers: modifier,
          menu_set_component: combo,
          customer_id: cookie.load("CustomerUserId"),
          order_item_id: voucher.order_item_id,
          order_outlet_id: voucher.order_outlet_id,
        };

        axios
          .post(apiUrl + "cart/is_voucher_insert", qs.stringify(postObject))
          .then((res) => {
            this.setState({ status: "ok" });
            if (res.data.status === "ok") {
              this.setState({ redirectToCart: true, cartTriggerFlg: "yes" });
              $(".mfp-close").trigger("click");
              // this.sateValChange("cartflg", "yes");
              showCustomAlert(
                "success",
                "Great choice! Voucher added to your cart."
              );
              /*showCartLst();*/
              this.handleShowAlertFunct(
                "success",
                "Great choice! Voucher added to your cart."
              );
              this.props.getCartDetail(login_type);
            } else if (res.data.status === "error") {
              $(".mfp-close").trigger("click");
              this.handleShowAlertFunct("Error", res.data.message);
            }
          });
      }

      if (voucher.product_voucher == "c") {
        var modifier = [];
        var combo = [];
        var postObject = {};
        var price = parseFloat("0.00");
        var qty_voucher = $(".proqty_input").val();
        postObject = {
          app_id: cookie.load('merchant_app_id'),
          product_qty: qty_voucher,
          product_voucher_points: voucher.product_voucher_points,
          customer_id: cookie.load("CustomerUserId"),
          order_item_id: voucher.order_item_id,
        };

        axios
          .post(
            apiUrl + "chopchop/loyalty_points/add_voucher_redeem",
            qs.stringify(postObject)
          )
          .then((res) => {
            this.setState({ status: "ok" });
            if (res.data.status === "ok") {
              //push("/rewards");
              showCustomAlert("success", "Cash Voucher Points Credited.");
              /*showCartLst();*/
              this.handleShowAlertFunct(
                "success",
                "Cash Voucher Points Credited."
              );
              this.getVoucherList(app_id,this.state.customer_user_id);
              
            } else if (res.data.status === "error") {
              this.handleShowAlertFunct("Error", res.data.message);
            }
          });
      }
    }
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  stripHTML(myString) {
    let repStr = myString || '';
    return repStr.replace( /(<([^>]+)>)/ig, '');
  }

  logoutClientDetails(){
  
  cookie.remove("CustomerUserId", { path: "/" });
  cookie.remove("CustomerUserFname", { path: "/" });
  cookie.remove("CustomerUserLname", { path: "/" });
  cookie.remove("CustomerUserMobile", { path: "/" });
  cookie.remove("CustomerUserEmail", { path: "/" });

  var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';
  this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
 }


  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
  };
  
  render() {

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />

        <div className="myacc_main_div">

          {/*<div className="merchent-mobile_outlet">
            <div className="merchent-mobile_innername">
              <p>bugis outlet</p>
              <div className="merchent-mobile-inbutton">
                <a href="#" className="button">switch</a>
              </div>
            </div>
          </div>*/}

          <div className="merchent_serving">
  
            {cookie.load("CustomerUserId") && (
             <li className="hservingtype hservingnext">
                <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
            </li>)}

          </div>

          <Merchantdashboard />

          <div className="container myacc_Dmain merchant_dash_contain">
            <div className="myacc_content_main mobile_tab_div merchant-voucher-tab">
              <ul className="mer-mobileul">
                <li className="active">
                  <a data-toggle="tab"
                      href="#tab-id-inn3"
                      aria-expanded="true">
                      <span>E vouchers</span>
                  </a>
                </li>
                <li className="">
                  <a data-toggle="tab"
                      href="#tab-id-inn4"
                      aria-expanded="true">
                      <span>My E Vouchers</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                  <div
                    id="tab-id-inn3"
                    className="tab-pane fade active in"
                  >
                    <h4 className="tab_mobtrigger inner_tab_border active">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="order-delivery">
                        <div className="ord-body">
                          <div className="cur-order-body reward-list-body">
                            
                              {this.state.productDetails}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data"
                        style={{ display: "none" }}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                  <div id="tab-id-inn4" className="tab-pane fade">
                    <h4 className="tab_mobtrigger inner_tab_border">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="cur-order-body reward-list-body">
                        <div className="myacc_product_main voucher-maindiv">
                        <h6>My E Voucher</h6>
                        <ul className="voucher_ul">
                          {this.loadMyVouchers(this,"vouchers")}
                        </ul>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data1"
                        style={{ display: "none" }}
                  
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
            </div>

            <div className="merchant-voacher-destop">
              <div className="tab_sec filter_tabsec">
                <ul className="nav nav-tabs text-center">
                  <li className="active">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn5"
                      aria-expanded="true"
                    >
                      <span>E Vouchers</span>
                    </a>
                  </li>
                  <li className="">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn6"
                      aria-expanded="false"
                    >
                      <span>My E Vouchers</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    id="tab-id-inn5"
                    className="tab-pane fade active in"
                  >
                    <h4 className="tab_mobtrigger inner_tab_border active">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="order-delivery">
                        <div className="ord-body">
                          <div className="cur-order-body reward-list-body">
                            
                              {this.state.productDetails}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data"
                        style={{ display: "none" }}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                  <div id="tab-id-inn6" className="tab-pane fade">
                    <h4 className="tab_mobtrigger inner_tab_border">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="cur-order-body reward-list-body">
                        <div className="myacc_product_main voucher-maindiv">
                        <h6>My E Vouchers</h6>
                        <ul className="voucher_ul">
                          {this.loadMyVouchers(this,"vouchers")}
                        </ul>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data1"
                        style={{ display: "none" }}
                  
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="voucher-popup" className="white-popup mfp-hide receiptcart_popup voucher_details_popup">
          <div className="voucher-popup">
            <div className="voucher-popup-image">
              <img src={this.state.productDetailList.product_thumbnail !== "" ? (this.props.productCommon.product_image_source + "/" + this.state.productDetailList.product_thumbnail) : productoneImg} />
            </div>
            <div className="voucher-popup-box">
              <div className="voucher-popup-content">
                <h5>Valid till {moment(this.state.productDetailList.product_voucher_expiry_date).format("DD/MM/YYYY")}</h5>
                <h4>
                {this.state.productDetailList.product_alias !== "" ? stripslashes(this.state.productDetailList.product_alias) : stripslashes(this.state.productDetailList.product_name)}
          </h4>
                <p>{this.state.productDetailList.product_short_description !== "" ? this.stripHTML(this.state.productDetailList.product_short_description) : ''}
                </p>
              </div>
              <div className="V-productpopup">
                <p><sup>$</sup><span id="compoprice_final">
                  {this.state.productDetailList.product_price !== "" ? this.state.productDetailList.product_price : ''}
                </span></p>
                <div className="V-productamount">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.productDetailList.product_primary_id,
                        "decr"
                      )}
                    >-</span>
                    <input type="text" name="qty" className="proqty_input" value="1" readOnly />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.productDetailList.product_primary_id,
                        "incr"
                      )}
                    >+</span>
                  </div>
                </div>
              </div>
              <div className="voucher-popup-button">
                <button className="button" onClick={this.addToCartVoucherMe.bind(
                  this,
                  this.state.productDetailList
                )}> Add </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide receipt_popup"
        >
          <div className="receipt_popup-main">
            <div className="receipt_popup-image">
              {this.state.promodetails.product_thumbnail !== "" &&
              this.state.promodetails.product_thumbnail !== null ? (
                <img
                  src={
                    this.state.productimagePath +
                    this.state.promodetails.product_thumbnail
                  }
                />
              ) : (
                <img
                  src={
                    this.state.promodetails.product_voucher == "c"
                      ? BigcashVouchar
                      : BigproVouchar
                  }
                />
              )}
            </div>
            <div className="voucher-popup-box">
              <div className="voucher-popup-content">
              <h3 className="">{this.state.promodetails.item_name}</h3>
              <span className="expiry-date">
                {this.loadProductDate(this.state.promodetails.expiry_date)}
              </span>

              <h4>
                Available :{" "}
                {this.state.promodetails.order_item_voucher_balance_qty}
              </h4>
              </div>
              {this.state.promodetails.product_short_description !== "" &&
                this.state.promodetails.product_short_description !== null && (
                  <p>
                    {Parser(
                      stripslashes(
                        this.state.promodetails.product_short_description + ""
                      )
                    )}
                  </p>
                )}

              <div className="V-productpopup">
                <p className="sel-quality">Select Quantity</p>
                <div className="V-productamount">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.promodetails.promotion_id,
                        "decr",
                        this.state.promodetails.order_item_voucher_balance_qty
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.promodetails.promotion_id,
                        "incr",
                        this.state.promodetails.order_item_voucher_balance_qty
                      )}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
              <div className="voucher-popup-button">
                <a
                  className="button"
                  onClick={this.addToCart.bind(this, this.state.promodetails)}
                >
                  Redeem Now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="dvLoading"></div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var globalSettings = Array();
  var tempArr = Array();
  var tempProdArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;

    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }
    
  if (Object.keys(state.product).length > 0) {
    var tempProdArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;

    if (Object.keys(tempProdArr).length > 0) {
      if (tempProdArr[0].status === "ok") {
        navigateRst = tempProdArr[0].result_set;
        navigateCmn = tempProdArr[0].common;
        if (tempProdArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempProdArr[0].result_set[0].category_name;
          menu_slug = tempProdArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempProdArr[0].result_set[0].subcategory_name;
          menu_slug = tempProdArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  return {
    settingsArr: globalSettings,
    customerdetail: state.customerdetail,
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    promotionreceipt: state.promotionreceipt,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: (login_type) => {
      dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
    },
    getCustomerDetail: (params, login_type) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params, login_type });
    },
    getMenuNavigationList: (outletID,login_type) => {
      dispatch({ type: GET_MENU_NAVIGATION, outletID, login_type });
    },
    getProductList: (catslug, subcatslug, login_type, outletid, sortByField, sortByValue) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, login_type, outletid, sortByField, sortByValue });
    },
    getPromotionReceipt: (params) => {
      dispatch({ type: GET_PROMOTIONRECEIPT, params });
    },
    getCartDetail: (login_type) => {
      dispatch({ type: GET_CART_DETAIL, login_type });
    },
  };
};

Merchantvoucher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchantvoucher)
);
