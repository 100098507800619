/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cookie from "react-cookies";
import axios from "axios";

import { stripslashes, hideLoader,showAlert } from "../Helpers/SettingHelper";
import {  apiUrl } from "../Helpers/Config";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";

var Parser = require("html-react-parser");

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_GLOBAL_SETTINGS, GET_LOYLATITY_OUTLET_DETAILS } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

var qs = require("qs");


class Merchantadminlogin extends Component {
  constructor(props) {
    super(props);
    
  var outlet_slug_name =  cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : ''; 

      console.log(cookie.load("merchant_outlet_slug_name"), "df");

    this.state = {

      admin_user_name: "",
      admin_user_name_error: "",
      admin_user_password: "",
      admin_user_password_error: "",
      admin_user_invalid_password: "",
      outlet_slug_name: outlet_slug_name, 
      loyaltyoutlets: [],
    };
    
  }

  componentDidMount() {
    const login_type = "Merchant";
    this.props.getCompanyDetails(login_type);
  }

  componentWillReceiveProps(nextProps) {

    if (this.state.loyaltyoutlets !== nextProps.loyaltyoutlets) {
      var tampStArr = nextProps.loyaltyoutlets;

      if (Object.keys(tampStArr).length > 0 ) {
        if(nextProps.loyaltyoutlets[0].status === "ok"){
        this.setState({
          loyaltyoutlets: nextProps.loyaltyoutlets,
        },function(){
          cookie.save("merchant_app_id", tampStArr[0].result_set.outlet_app_id, {path:'/'});
          cookie.save("merchant_outlet_id", tampStArr[0].result_set.outlet_id, {path:'/'});
          this.props.getSettings();
        });
      }
     }
     
    } 
    
  }

  handleTextChange(event) {

  var value = event.target.value;

   if (event.target.name === "admin_user_name") {
    if (value !== "") {
      this.setState({ admin_user_name_error: false });
    } else {
      this.setState({ admin_user_name_error: true });
    }
    this.setState({ admin_user_name: value });
  } 

   if (event.target.name === "admin_user_password") {
    if (value !== "") {
      this.setState({ admin_user_password_error: false });
    } else {
      this.setState({ admin_user_password_error: true });
    }
    this.setState({ admin_user_password: value });
  } 

 }

 loginMerchant(){

   var errorFlg = "n";

  if(this.state.admin_user_name === "") {
    errorFlg = "y";
    this.setState({ admin_user_name_error: true });
    return false;
  }else{
    this.setState({ admin_user_name_error: false });
  }


  if(this.state.admin_user_password === "") {
    errorFlg = "y";
    this.setState({ admin_user_password_error: true });
    return false;
  }else{
    this.setState({ admin_user_password_error: false });
  }


  if(this.state.outlet_slug_name === "") {
    errorFlg = "y";
    this.handleShowAlertFun("Error", "Outlet id missing");
    return false;
  }


  if(errorFlg === "n"){
      
      let app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var postObject = {
        app_id: app_id, 
        username: this.state.admin_user_name,
        password: this.state.admin_user_password,
        merchant_outlet_slug_name: this.state.outlet_slug_name,
      };

      axios.post(apiUrl + "chopchop/customer/admin_merchant_login", qs.stringify(postObject)).then((res) => {

        if (res.data.status === "ok") {
          
          cookie.save("merchant_id", res.data.result_set.camp_company_admin_id, { path: "/" });
          cookie.save("merchant_app_id", res.data.result_set.camp_company_app_id, { path: "/" });
 
          var redirect_url = "/" + this.state.outlet_slug_name + "/merchant";

           this.handleShowAlertFun("success", res.data.message);
           this.props.getSettings();

          this.props.history.push(redirect_url);
          //location.reload();


        }else{

          this.handleShowAlertFun("Error", res.data.message);

        }

      });

    }
 }



  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }



  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
         <div className="merchent_adminMdiv">
        <div className="container">
          <div className="login_main_div merchent_main_div merchent_admin_div">
          <h4>Merchant Login</h4>
            <div className="login_form">
              <form>
                <div className="login-field">
                  <input type="text" name="admin_user_name" placeholder="Username" onChange={this.handleTextChange.bind(this)}
                  value={this.state.admin_user_name}
                  />
                </div>
                {this.state.admin_user_name_error === true && (
                    <span className="error">This field is required.</span>
                 )}                    
                <div className="login-field pasw-filed">
                  <input type="password" name="admin_user_password" placeholder="Password" onChange={this.handleTextChange.bind(this)}
                  value={this.state.admin_user_password}
                   />
                </div>

               {this.state.admin_user_password_error === true && (
                    <span className="error">This field is required.</span>
                 )} 

                {this.state.admin_user_invalid_password !=="" && (
                    <span className="error">{this.state.admin_user_invalid_password}</span>
                 )} 
              </form>
            </div>
            <div className="login-btn  merchent_btn">
              <button type="button" className="button" onClick={this.loginMerchant.bind(this)}>Go</button>
            </div>
          </div>
        </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalsettings: state.settings,
    loyaltyoutlets: state.loyaltyoutlets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (login_type) => {
      dispatch({ type: GET_LOYLATITY_OUTLET_DETAILS, login_type});
    },
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

Merchantadminlogin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Merchantadminlogin));
