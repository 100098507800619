/* eslint-disable */
import React, { Component } from "react";
import validator from "validator";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import { Link } from "react-router-dom";
import {
  stripslashes,
  addressFormat,
  showAlert,
  callImage,
  showCustomAlert,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";

import { GET_MEMBERSHIP_BENIFITS } from "../../actions";

import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
var Parser = require("html-react-parser");

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import awardImg from "../../common/images/award_red.svg";
import awardorderImg from "../../common/images/award_gray.svg";
import awardorderhoverImg from "../../common/images/award_blue.svg";

class TierBenifits extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
        tierBenifitsList : [],
      };
    
    }

  componentDidMount() {
    var login_type = "customer";
    this.props.getMembershipBenifits(login_type);
  }

  componentWillReceiveProps(nextProps) {

    var tierBenifitsVal = [];
    if (this.state.tierBenifitsList !== nextProps.tierBenifits) {
      tierBenifitsVal = nextProps.tierBenifits;
    }
      this.setState({
        tierBenifitsList: tierBenifitsVal,
      });
    }

    viewBronzeTier(){
      var tierbenifits = this.props.tierBenifits;
      if(Object.keys(tierbenifits).length > 0){
        var BronzeList = Object.keys(tierbenifits).length > 0 && tierbenifits.map((item,index) => {
        if(item.name === "Normal"){
          return(<React.Fragment key={index}>
          <div className="tier-dbody-heading">
            <p>YOUR CURRENT TIER</p>
            <h6>{item.membership_display_name} Tier Benefits</h6>
          </div>

          <div className="tier-dbody-content">
            <div className="reward_main">
              <ul className="tier-content-ul">
                <li className="reward_orderdiv">
                    <img src={awardorderImg} />
                    <div className="reward-mbl-para">
                      <h5>Minimun Spend To Reach The Tier : {item.membership_min_spend}</h5>
                    </div>
                  </li>
                </ul>

                <ul className="tier-content-ul">
                  <li className="reward_orderdiv">
                    <img src={awardorderImg} />
                    <div className="reward-mbl-para">
                      <h5>Loyalty Percentage : {item.membership_cashback_percentage}</h5>
                    </div>
                  </li>
                </ul>

                <ul className="tier-content-ul">
                  <li className="reward_orderdiv">
                    <img src={awardorderImg} />
                    <div className="reward-mbl-para">
                      <h5>Loyalty Points Will Expire After {item.membership_cashback_expirydays} Days</h5>
                    </div>
                  </li>
                </ul>

                <div className="tier-content-para">
                  <p>{item.membership_display_name} members can receive special birthday promotions and they can use this promo code on their birthday month</p>
                </div>
            </div>
          </div>
          </React.Fragment>);
        }
        });
      }else{
        return <p>No Bronze Tier Benefits Found</p>;
      }
      return BronzeList;
    }

    viewSilverTier(){
      var silvertierbenifits = this.props.tierBenifits;
      if(Object.keys(silvertierbenifits).length > 0){
        return Object.keys(silvertierbenifits).length > 0 && silvertierbenifits.map((item,index) => {
          if(item.name === "Silver"){
            return <React.Fragment key={index}>
              <div className="tier-dbody-heading">
                <p>YOUR CURRENT TIER</p>
                <h6>{item.membership_display_name} Tier Benefits</h6>
              </div>

              <div className="tier-dbody-content">
                <div className="reward_main">
                  <ul className="tier-content-ul">
                    <li className="reward_orderdiv">
                        <img src={awardorderImg} />
                        <div className="reward-mbl-para">
                          <h5>Minimun Spend To Reach The Tier : {item.membership_min_spend}</h5>
                        </div>
                      </li>
                    </ul>

                    <ul className="tier-content-ul">
                      <li className="reward_orderdiv">
                        <img src={awardorderImg} />
                        <div className="reward-mbl-para">
                          <h5>Loyalty Percentage : {item.membership_cashback_percentage}</h5>
                        </div>
                      </li>
                    </ul>

                    <ul className="tier-content-ul">
                      <li className="reward_orderdiv">
                        <img src={awardorderImg} />
                        <div className="reward-mbl-para">
                          <h5>Loyalty Points Will Expire After {item.membership_cashback_expirydays} Days</h5>
                        </div>
                      </li>
                    </ul>

                    <div className="tier-content-para">
                      <p>{item.membership_display_name} members can receive special birthday promotions and they can use this promo code on their birthday month</p>
                    </div>
                </div>
              </div>
            </React.Fragment>
          }
        });
      }else{
        return <p>No Silver Tier Benefits Found</p>;
      }
    }

    viewGoldTier(){
      var goldtierbenifits = this.props.tierBenifits;
      if(Object.keys(goldtierbenifits).length > 0){
        return Object.keys(goldtierbenifits).length > 0 &&  goldtierbenifits.map((item,index) => {
          if(item.name === "Gold"){
            return <React.Fragment key={index}>
              <div className="tier-dbody-heading">
                <p>YOUR CURRENT TIER</p>
                <h6>{item.membership_display_name} Tier Benefits</h6>
              </div>

              <div className="tier-dbody-content">
                <div className="reward_main">
                  <ul className="tier-content-ul">
                    <li className="reward_orderdiv">
                        <img src={awardorderImg} />
                        <div className="reward-mbl-para">
                          <h5>Minimun Spend To Reach The Tier : {item.membership_min_spend}</h5>
                        </div>
                      </li>
                    </ul>

                    <ul className="tier-content-ul">
                      <li className="reward_orderdiv">
                        <img src={awardorderImg} />
                        <div className="reward-mbl-para">
                          <h5>Loyalty Percentage : {item.membership_cashback_percentage}</h5>
                        </div>
                      </li>
                    </ul>

                    <ul className="tier-content-ul">
                      <li className="reward_orderdiv">
                        <img src={awardorderImg} />
                        <div className="reward-mbl-para">
                          <h5>Loyalty Points Will Expire After {item.membership_cashback_expirydays} Days</h5>
                        </div>
                      </li>
                    </ul>

                    <div className="tier-content-para">
                      <p>{item.membership_display_name} members can receive special birthday promotions and they can use this promo code on their birthday month</p>
                    </div>
                </div>
              </div>
            </React.Fragment>
          }
        });
      }else{
        return <p>No Gold Tier Benefits Found</p>;
      }
    }

  render() {
    var showDisplayType =
      typeof this.props.memDisplayType !== "undefined"
        ? this.props.memDisplayType
        : "Bronze";
        
    return (
      <React.Fragment>

        <a href="#tier-benfits-popup" className="button open-popup-link" title="view tier benefits">view tier benefits</a>

        <div id="tier-benfits-popup" className="white-popup mfp-hide tier-benfits-popup">
          <div className="tier-benfits-main">
            <div className="tier-benfits-lhs">
              <div className="tier-benfits-header">
                <h3>Tier Benefits</h3>
              </div>
            </div>
            <ul className="nav nav-tabs text-center">
              <li className="active">
                <a
                  data-toggle="tab"
                  href="#tab-id-tier1"
                  aria-expanded="true"
                >
                  <span>Bronze</span>
                </a>
              </li>
              <li className="">
                <a
                  data-toggle="tab"
                  href="#tab-id-tier2"
                  aria-expanded="false"
                >
                  <span>Silver</span>
                </a>
              </li>
              <li className="">
                <a
                  data-toggle="tab"
                  href="#tab-id-tier3"
                  aria-expanded="false"
                >
                  <span>Gold</span>
                </a>
              </li>
            </ul>
            <div className="tab-content">

              <div id="tab-id-tier1" className={"tier-dbody tab-pane fade in "+(showDisplayType === 'Bronze'?'active':'')}>
                {this.viewBronzeTier()}
              </div>

              <div id="tab-id-tier2" className={"tier-dbody tab-pane fade "+(showDisplayType === 'Silver'?'active':'')}>
                {this.viewSilverTier()}
              </div>

              <div id="tab-id-tier3" className={"tier-dbody tab-pane fade " +(showDisplayType === 'Gold'?'active':'')}>
                {this.viewGoldTier()}
              </div>

              {/* <div className="tier-benfits-rhs">
                <div className="popup-header textcenter">
                  <h4>
                  {customer_membership_tier_benefits !== "" ? Parser(customer_membership_tier_benefits) : ""}
                  </h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  
  var tierBenifitsArr = Array();
  if (Object.keys(state.tierbenifit).length > 0) {
    if (state.tierbenifit[0].status === "ok") {
      tierBenifitsArr = state.tierbenifit[0].result_set;
    }
  }

  return {
    tierBenifits: tierBenifitsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMembershipBenifits: (login_type) => {
      dispatch({ type: GET_MEMBERSHIP_BENIFITS, login_type });
    }
  };
};

TierBenifits.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TierBenifits)
);
