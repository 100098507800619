import {reactLocalStorage} from 'reactjs-localstorage';

/* Live */

var app_id = "";

if(app_id !=="" && reactLocalStorage.get("app_id") !=="" &&  reactLocalStorage.get("app_id") !== null){
 app_id =  reactLocalStorage.get("app_id") !== "" && reactLocalStorage.get("app_id") !== undefined &&  reactLocalStorage.get("app_id") !== null
      ? reactLocalStorage.get("app_id")
      : '';
}

export const appId = app_id;

export const apiUrl = "https://loyalty.chopchoprewards.com/portal/api/";
export const apiUrlV2 = "https://loyalty.chopchoprewards.com/portal/apiv2/";
export const apiUrlPro = "https://loyalty.chopchoprewards.com/portal/ninjapro/";
export const apiUrlCtrng = "https://loyalty.chopchoprewards.com/portal/cateringv2/";
export const apiUrlNotify = "https://loyalty.chopchoprewards.com/portal/Pushorder/";
export const timThumpUrl = "https://loyalty.chopchoprewards.com/portal/timthumb.php?src=";
export const mediaUrl = "https://loyalty.chopchoprewards.com/portal/media/dev_team/";
export const blogImageUrl = "https://loyalty.chopchoprewards.com/portal/media/dev_team/blog/";
export const tagImageUrl = "https://loyalty.chopchoprewards.com/portal/media/dev_team/tag/";
export const proFolderUrl = "https://loyalty.chopchoprewards.com/portal/media/dev_team/products/";
export const outletUrl = "https://loyalty.chopchoprewards.com/portal/media/dev_team/outlet/";
export const baseUrl = "https://loyalty.chopchoprewards.com/";

//export const baseUrl = "http://localhost:3000/";

export const stripeImage = "/img/stripe_logo.png";
export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Loyalty System";
export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "mrbiryani";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const companyName = "Loyalty System";