/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";
import cookie from "react-cookies";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import Loginsccess from "../../common/images/sucess.png";

import leftarrowImg from "../../common/images/arrow-left.svg";

var Parser = require("html-react-parser");

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_GLOBAL_SETTINGS, } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = { pagedata: [], };
    
    const login_type = "customer";
    this.props.getSettings(login_type);

  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    
  }

  sateValChange = (field, value) => {};

  openDashboard(event){

    event.preventDefault();
    this.props.history.push("/customer/dashboard");

  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="customer_Mlogsucess">
          <div className="container">
            <div className="login_main_div">
              <div className="login_form logsucess">
                <div className="sucess_imagediv">
                  <img src={Loginsccess} />
                </div>
                <h3>congratz!</h3>
                <h6>You're ready to go!</h6>
              </div>
              <div className="login-btn">
                <button type="button" className="button delivery_submit_cls" onClick={this.openDashboard.bind(this)}>CONTINUE</button>
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => {


  return {
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: (login_type) => {
      dispatch({ type: GET_GLOBAL_SETTINGS,login_type});
    },

  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
