/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, withRouter, Route } from "react-router-dom";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  getCalculatedAmount_rewards,
  showAlert,
  showCustomAlert,
  showPrices,
  showPoint, showLoader, hideLoader, getPromoCkValue } from "../Helpers/SettingHelper";

import { appId, apiUrl, stripeImage, companyName} from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";

import { connect } from "react-redux";
import cookie from "react-cookies";
import Moment from "moment";

import { GET_GLOBAL_SETTINGS, GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CORDERDETAIL, GET_CUSTOMER_DETAIL, GET_PROMOTIONLIST } from "../../actions";

import locationImg from "../../common/images/celabrate_red.png";
import productoneImg from "../../common/images/promofour.png";
import productwoImg from "../../common/images/promotwo.png";
import producthreeImg from "../../common/images/promofour.png";
import productfourImg from "../../common/images/promothree.png";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import phoneImg from "../../common/images/phone.svg";
import awardabtImg from "../../common/images/award_white.svg";
import paymentImg from "../../common/images/pay_store.png";
import paymentstoreImg from "../../common/images/paynow.png";
import percentageImg from "../../common/images/percentage.svg";
import backImg from "../../common/images/back.png";
import arrowdownImg from "../../common/images/arrow-down.png";
import logoImg from "../../common/images/logo.png";

import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";


var Parser = require("html-react-parser");
var qs = require("qs");
var startTimePay;
var base64 = require("base-64");

class Merchanttransactionhistory extends Component {
  constructor(props) {
    super(props);

    cookie.remove("reedemPointVal", { path: "/" });

    var getPromoCkVal = getPromoCkValue();
    
    this.state = {
      loyalty_amount_value : '',
      subtotal_value: 0,
      total_amount_value: '',
      omisePayNowResponse: [],
      globalSettings: [],
      paymentmodevalue: 'payCash',
      reward_ponits: 0,
      used_reward_point: getPromoCkVal["usedPoints"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
      corderdetail: [],
      showitems: 10,
      showpitems: 10,
      totalcount: '',
    };

     if (cookie.load("CustomerUserId") == "" || cookie.load("CustomerUserId") == undefined) {
      props.history.push("/merchantlogin");
    }

    this.props.getGlobalSettings();


  }
 
  componentDidMount() {

    $("#dvLoading").fadeIn();

    $("html, body").animate({ scrollTop: 0 }, 800);

    const login_type = "Merchant";
    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

    var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined &&  cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';
    var customer_user_id = cookie.load("CustomerUserId");

    var deliveryparams = "&app_id="+ app_id + "&customer_id=" + customer_user_id + "&history_type=customer" + "&limit=" +  this.state.showitems;
    this.props.getCorderDetail(deliveryparams);


    this.checkOutAuthentication();
    var calculatedAmount = getCalculatedAmount_rewards(
      this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val
    );

  }

  checkOutAuthentication(){

    var calculatedAmount = getCalculatedAmount_rewards(
    this.state.subtotal_value, this.state.total_amount_value,this.state.reward_point_val
    );  
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }

    }


    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({reward_ponits: nextProps.activitycount[0].result_set.reward_ponits});
        }
      }
    } else {
      this.setState({ reward_ponits: 0 });
    }

   if (nextProps.customercompanyactivitylist !== this.props.customercompanyactivitylist) {
    if(Object.keys(nextProps.customercompanyactivitylist).length > 0 && nextProps.customercompanyactivitylist !==undefined){
      if (nextProps.customercompanyactivitylist[0].status === "ok") {

       this.setState({customercompanyactivitylist: nextProps.customercompanyactivitylist[0].result_set,
        vistCount:nextProps.customercompanyactivitylist[0].total_vist, last_vist_date: nextProps.customercompanyactivitylist[0].last_vist_date},function(){
        
        $("#dvLoading").fadeOut();

      });

     }

    }
    
    }


    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {

      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {

        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].result_set) {
          this.setState({
            totalcount: nextProps.corderdetail[0].result_set
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].result_set
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

  }


  handleTextChange(event) {

    if (event.target.name === "loyalty_amount_value") {
      this.setState({ loyalty_amount_value: event.target.value });
      var loyalty_amount_value = event.target.value !== "" ? parseFloat(event.target.value) : 0;
      var subtotal_value = loyalty_amount_value.toFixed(2);
      this.setState({ subtotal_value: subtotal_value });
     }else if (event.target.name === "reward_points_amount") {
      this.setState({ reward_points_amount: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "recipient_name") {
      this.setState({ recipient_name: event.target.value });
    } else if (event.target.name === "recipient_contact_no") {
      this.setState({ recipient_contact_no: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    } else if (event.target.name === "wallet_amount") {
      this.setState({ corporate_customer_wallet_input: event.target.value });
    }

  }


  choosePayment(paymentMode) {
    if (paymentMode === "payCash") {
      this.setState({ paymentmodevalue: "payCash" });
    } else if (paymentMode === "OmisePayNow") {
      this.setState({ paymentmodevalue: "OmisePayNow" });
    }
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    this.createOmiseSource();
  }

  payCash(event){
  event.preventDefault();
  this.postOrder(1);

  }

  /* Omise PayNow Start */
  createOmiseSource() {
    if (
      this.state.globalSettings.omise_paynow_public !== "" &&
      typeof this.state.globalSettings.omise_paynow_public !== undefined &&
      typeof this.state.globalSettings.omise_paynow_public !== "undefined"
    ) {
      var payNowID = base64.decode(
        this.state.globalSettings.omise_paynow_public
      );
      Omise.setPublicKey(payNowID);

      /*var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var cartDetailsArr = this.state.cartDetails;*/

      var calculatedAmount = getCalculatedAmount_rewards(
        this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val);

      showLoader("omisepaynow_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

paynowCapture(sourceID, amount) {
    if (sourceID !== "") {


 var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined &&  cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: app_id,
        source_id: sourceID,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: cookie.load("merchant_id"),
        amount: amount,
      };
      axios
        .post(apiUrl + "paymentv1/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          console.log(res,"res");
          hideLoader("omisepaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            console.log(res.data.status, "res.data.status");
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowPamentStatus(startTimePay);
                    }
                    currentThis.omisePaynowTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
  
     var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';


      statusObj = {
        app_id: app_id,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: cookie.load("merchant_id"),
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(apiUrl + "paymentv1/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(2);
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }
  /* Omise PayNow End */

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="button btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }

  postOrder(paymentMode){

   var calculatedAmount = getCalculatedAmount_rewards(
      this.state.subtotal_value, this.state.total_amount_value, this.state.reward_points_amount
    );
   var discount_applied = "No";


   var postObject = {};

    var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined &&  cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

   var outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined &&  cookie.load("merchant_outlet_slug_name") !== null ? cookie.load("merchant_outlet_slug_name") : '';

    postObject = {
      app_id: app_id,
      sub_total: parseFloat(calculatedAmount["SubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      customer_id: cookie.load("CustomerUserId"),
      customer_fname: cookie.load("CustomerUserFname"),
      customer_mobile_no: cookie.load("CustomerUserMobile"),
      customer_email: cookie.load("CustomerUserEmail"),
      payment_mode: paymentMode,
      outlet_slug_name: outlet_slug_name,
      order_source: "Web",
      discount_applied : discount_applied,
      redeem_point : parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2),
      reward_point_status: "Yes",

    };

    if(this.state.reward_point_val > 0) {
      postObject["redeem_applied"] = "Yes";
      postObject["redeem_amount"] = this.state.reward_point_val;
    }

    axios
      .post(
        apiUrl + "chopchop/loyalty_points/loyaltypointorders",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {

          this.handleShowAlertFun("success", res.data.message);
          this.setState({ loyalty_amount_value : '', subtotal_value: 0,  total_amount_value: '', reward_points_amount: "", used_reward_point: '', reward_point_val: ""});
          var localOrderNo = res.data.common.local_order_no;
          this.showSuccessPage(localOrderNo,res.data.common.order_primary_id);
          cookie.remove("reedemPointVal", { path: "/" });
         // location.reload();
        }

      });
  }


 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }


  showSuccessPage(localOrderNo, orderPrimaryId) {
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
     const { history } = this.props;
     history.push("/merchant/dashboard");
  }



toggleChange = (e) => {

  this.setState({
    [e.target.name]: e.target.value,
  });

  if(e.target.value === true){

      var reedemPointVal = this.state.reward_points_amount;
      var usedPoints = this.state.used_reward_point;
      var subTotal = this.state.subtotal_value;
      
      var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined &&  cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var postObject = {
          app_id: app_id,
          reference_id: cookie.load("CustomerUserId"),
          redeem_point: reedemPointVal,
          cart_amount: subTotal,
        };

        axios.post(apiUrl + "chopchop/loyalty_points/apply_loyalityv1", qs.stringify(postObject))
          .then((res) => {

            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              usedPoints = reedemPointVal;
              cookie.save("reedemPointVal", usedPoints, { path: "/" });
              showCustomAlert("success", "Rewards Points applied successfully");

            } else {
              var pointDet = '';
              reedemPointVal = 0;
              usedPoints = 0;
              cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
              showCustomAlert("error", "Sorry! Did not applied Rewards Points");
            }

              cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
              cookie.save("usedPoints", usedPoints, { path: "/" });

              this.setState({
                reward_point_val: reedemPointVal,
                used_reward_point: usedPoints,
              });

              var calculatedAmount = getCalculatedAmount_rewards(
                 this.state.subtotal_value, this.state.total_amount_value,reedemPointVal);  


          });
    }else{


      var reedemPointVal = this.state.reward_points_amount;
      var usedPoints = this.state.used_reward_point;
      var subTotal = this.state.subtotal_value;
      
      if(reedemPointVal > 0){

      cookie.remove("reedemPointVal", { path: "/" });
      cookie.remove("usedPoints",  { path: "/" });
      

      this.setState({ reward_point_val: 0, used_reward_point: 0, reward_points_amount: 0});

        var calculatedAmount = getCalculatedAmount_rewards( this.state.subtotal_value, this.state.total_amount_value,reedemPointVal);  

      }

    }
  }

  transactionHistory = (dataProp) => {

    var dataProp = dataProp !== undefined ? dataProp : Array();

    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item, index) => {
         return ( <div className="order-details-main" key={index}>
            <div className="order-details-lhs">
              <div className="order-details-image">
                <img src={awardImg} />
                <p>rewards</p>
              </div>
              <div className="order-details-content">
                <ul className="order-details-left">
                  <li className="order-title">{item.loyalty_pointorders_orderlocalno}</li>
                  <li className="order-date">{Moment(item.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> {" "} | {" "}</span>{Moment(item.loyalty_pointorders_created_on).format("h:mm A")}</li>
                  <li className="order-name">{cookie.load("CustomerUserFname") + " " + cookie.load("CustomerUserLname")} <span>|</span> {cookie.load("CustomerUserMobile")}</li>
                  {item.items.length>0 && item.items.map((item1, index1) => (
                    <li className="voucher-name">{item1.item_name}<span>|</span> {item1.item_qty} </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="order-details-rhs">
              <ul className={((item.loyalty_pointorders_redeemed_points === null && item.lh_credit_points === null)?'single-order':'') +" order-details-right" }>
                <li className="order-title">Total spent: {item.loyalty_pointorders_subtotal_amount}</li>

                {item.loyalty_pointorders_redeemed_points > 0 && (
                <li className="order-redeemed">
                  points redeemed - {item.loyalty_pointorders_redeemed_points}<span><img src={awardImg} /></span>
                </li>)}

                {/* <li className="promo_discount">
                  (code: 20off) promo discount - 20.00<span><img src={celabrateImg} /></span>
                </li> */}

                {item.lh_credit_points > 0 && (
                <li className="order-earned">
                  points earned + {item.lh_credit_points}<span><img src={awardImg} /></span>
                </li> )}

              </ul>
            </div>
          </div>)

      });
    }else{

      return <div className="no-recrds-found">No records found.</div>;
    }
  }


  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }


    loadcitems() {

    $(".load_more_data").append('<b class="gloading_img"></b>');

    var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined &&  cookie.load("merchant_app_id") !== null
    ? cookie.load("merchant_app_id") : '';

    var deliveryparams = "&app_id="+ app_id + "&customer_id=" + cookie.load("CustomerUserId") + "&limit=" +  this.state.showitems;
    this.props.getCorderDetail(deliveryparams);

  }

  logoutClientDetails(){
  
  cookie.remove("CustomerUserId", { path: "/" });
  cookie.remove("CustomerUserFname", { path: "/" });
  cookie.remove("CustomerUserLname", { path: "/" });
  cookie.remove("CustomerUserMobile", { path: "/" });
  cookie.remove("CustomerUserEmail", { path: "/" });

  var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined &&  cookie.load("merchant_outlet_slug_name") !== null
    ? cookie.load("merchant_outlet_slug_name") : ''; 
    
  this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
 }

  sateValChange = (field, value) => {};
  render() {

   var calculatedAmount = getCalculatedAmount_rewards(this.state.subtotal_value, 
    this.state.total_amount_value, this.state.reward_point_val);

   var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
   var rewardPointAmount = parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2);

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
      
      {/*<section className="myacc_details"></section>
      <div className="container myacc_Dmain merchant_dash_contain">
       <div className="myacc_main_div">*/}
        {/*<div className="merchent-mobile_outlet">
          <div className="merchent-mobile_innername">
            <p>bugis outlet</p>
            <div className="merchent-mobile-inbutton">
              <a href="#" className="button">switch</a>
            </div>
          </div>
        </div>*/}

        <div className="merchent_serving">

          {cookie.load("CustomerUserId") && (
           <li className="hservingtype hservingnext">
              <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
          </li>)}
          
        </div>

        <Merchantdashboard />

      <div className="merchant-vistmain">
      </div>

      <div className="container myacc_Dmain merchant_dash_contain">
      <div className="order-history-main">{/* Transition History Start*/}  
        <div className="order-history-inner">
          <ul className="order-history-header">
            <li>daily history</li>
            <li>
              <a href="#">view all</a>
            </li>
          </ul>

          {this.transactionHistory(this.state.corderdetail)}

         <div className="load_more_div">
            <button className="load_more_data" style={{ display: "none" }} onClick={this.loadcurrentItems.bind(this)}> Load More </button>
        </div>


        </div>
      </div>{/* Transition History End */}




    </div>{/*Container - End*/} 

        {/*payment omise Pay Now confirm popup - End*/}   
        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
 
   var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    customercompanyactivitylist: state.customercompanyactivity,
     settingsArr: globalSettings,
     activitycount: state.activitycount,
     corderdetail: state.corderdetail,
     customerdetail: state.customerdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getGlobalSettings: () => {
       dispatch({ type: GET_GLOBAL_SETTINGS });
      },
      getCorderDetail: (deliveryparams) => {
        dispatch({ type: GET_CORDERDETAIL, deliveryparams });
      }
  };
};


Merchanttransactionhistory.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchanttransactionhistory)
);
