/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter, Route } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId } from "../Helpers/Config";
import update from "immutability-helper";
import Moment from "moment";
import { connect } from "react-redux";
import cookie from "react-cookies";

import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";

import {GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL, GET_GLOBAL_SETTINGS, GET_PROMOTIONLIST } from "../../actions";

import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import phoneImg from "../../common/images/phone.svg";
import awardabtImg from "../../common/images/award_white.svg";
import percentageImg from "../../common/images/percentage.svg";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";


var Parser = require("html-react-parser");

class Dashboardmulti extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customercompanyactivitylist: [],
      vistCount: 0,   
      last_vist_date: "", 
      reward_ponits: 0,
      outlet_image_url:'',
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
      },
    };

     if (cookie.load("CustomerUserId") == "" || cookie.load("CustomerUserId") == undefined) {
      props.history.push("/merchantlogin");
    }

   
  }

  componentDidMount() {

    $("#dvLoading").fadeIn();
    
    cookie.remove("ChkOrderid", { path: "/" });
    
    $("html, body").animate({ scrollTop: 0 }, 800);
   
    const inputKeys = ["reward", "overall_orders"];
    const login_type = "Merchant";

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined &&  cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var customer_user_id = cookie.load("CustomerUserId");
    var customerParam = "app_id="+ app_id + "&customer_id=" + customer_user_id + "&login_type=Merchant";
    this.props.getCustomerCompanyActivity(customerParam);
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    
   if (nextProps.customercompanyactivitylist !== this.props.customercompanyactivitylist) {

    console.log(nextProps.customercompanyactivitylist, "nextProps.customercompanyactivitylist");

    if(Object.keys(nextProps.customercompanyactivitylist).length > 0 && nextProps.customercompanyactivitylist !==undefined){

      if (nextProps.customercompanyactivitylist[0].status === "ok") {

       this.setState({customercompanyactivitylist: nextProps.customercompanyactivitylist[0].result_set, vistCount:nextProps.customercompanyactivitylist[0].total_vist, last_vist_date: nextProps.customercompanyactivitylist[0].last_vist_date, outlet_image_url: nextProps.customercompanyactivitylist[0].outlet_image_url },function(){

        this.recentVisitlist();
        
        $("#dvLoading").fadeOut();

         });

        }

       }
     }
  }

 recentVisitlist(){

    var recentList = this.state.customercompanyactivitylist;
  
    if (Array.isArray(recentList) && recentList.length > 0) {
    return recentList.map((item, index)=> (
         <li className="myacc_product_li" key={index}>
            <div className="myacc_product_imgdiv">
              <img src={item.outlet_image !== '' ? this.state.outlet_image_url+item.outlet_image : productoneImg} />
              <div className="inner_img_cotnt">
                {item.availability !== undefined || item.availability !== null || item.availability !== ''  && 
                <ul className="myacc_product_option">
                 {item.availability.map((avail, availIndex)=>(
                  <li key={availIndex}>
                    <a>{avail.availability_name}</a>
                  </li>
                  ))}
                </ul>
                }
                <ul className="myacc_product_wish">
                {/*<li>
                    <a href="#"><img src={heartImg} /></a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="myacc_product_contntdiv">
              <h5>{item.activity_company_outlet_name}</h5>
              <ul>
                <li>
                  <img src={produclockImg} />
                  Last visited : {Moment(item.outlet_last_vist_date).format("DD MMM YYYY")}
                </li>

                <li>

                  {/*<p>
                    <img src={producelabrateImg} />
                    Promotions : 3
                  </p>*/}

                  {/*<p>
                    <img src={productawardImg} />
                    Loyalty : 3
                  </p>*/}
                  
                </li>
              </ul>
            </div>
         </li>));
    }
 }




  showMembershipDesc(memberShpType) {
      if(memberShpType === 'Gold') {
        return(<p>12% Cashback for every online order.</p>);
      } else if(memberShpType === 'Silver') {
        return(<p>8% Cashback for every online order.</p>);
      } else {
        return(<p>5% Cashback for every online order.</p>);   
      }
  }

openCheckout(){
  this.props.history.push("/merchant/checkout");
}

  logoutClientDetails(){
  
  cookie.remove("CustomerUserId", { path: "/" });
  cookie.remove("CustomerUserFname", { path: "/" });
  cookie.remove("CustomerUserLname", { path: "/" });
  cookie.remove("CustomerUserMobile", { path: "/" });
  cookie.remove("CustomerUserEmail", { path: "/" });

  var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
    ? cookie.load("merchant_outlet_slug_name") : ''; 
  this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
 }

  sateValChange = (field, value) => {};
  render() {

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
        
        <div className="merchent_serving">
          {/*<ul className="merchent_servingul">                        
            <li className="merchent_dropdown">
              <a className="hordertype_btn" title="Serving">Serving</a>
                <ul className="serving_submenu-inner">
                 <li> 
                  <Link to={"/merchant/transactionhistory"} className="merchant_menu" title="Transaction History"><span>Transaction History</span></Link>
                </li>
                <li> 
                  <a href="/merchantlogout" className="merchant_menu" title="Logout"><span>Logout</span></a>
                </li>
               </ul>
            </li>
          </ul>*/}
          
          {cookie.load("CustomerUserId") && (
          <li className="hservingtype hservingnext">
              <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
          </li>)}       
        </div>


        <Merchantdashboard />

          <div className="container myacc_Dmain merchant_dash_contain">
            <div className="myacc_main_div">

              {/*<div className="merchent-mobile_outlet">
                <div className="merchent-mobile_innername">
                  <p>bugis outlet</p>
                  <div className="merchent-mobile-inbutton">
                    <a href="#" className="button">switch</a>
                  </div>
                </div>
              </div>*/}

              {/*<div className="myacc_info merchent_dbord">
                <div className="myacc_info_lhs merchent_info_lhs merchent-mobile_dbord">
                  <div className="welcome-title">
                    <h4>{cookie.load('CustomerUserFname') !=="" ? cookie.load('CustomerUserFname'): ""}
                    {" "}{cookie.load('CustomerUserLname') !=="" ? cookie.load('CustomerUserLname'): ""}</h4>
                    <div className="merchent_title">
                      <a href={"tel:"+ cookie.load('CustomerUserMobile')}><img src={phoneImg} />{cookie.load('CustomerUserMobile') !=="" ? cookie.load('CustomerUserMobile'): ""}</a>
                    </div>
                    <p>visits: {this.state.vistCount}<span>last visit : {Moment(this.state.last_vist_date).format("DD MMM YYYY")}</span> </p>
                      <p className="myacc_linkpara">
                        <a href="/merchantlogout" title="Close">close</a>
                      </p>
                  </div>
                </div>

                <div className=" merchent_info_lhs merchent-mobile_dbordblock">
                  <div className="welcome-title">
                    <h4>{cookie.load('CustomerUserFname') !=="" ? cookie.load('CustomerUserFname'): ""}
                    {cookie.load('CustomerUserLname') !=="" ? cookie.load('CustomerUserLname'): ""}</h4>
                    <div className="merchent_title">
                      <a href="tel:9482736"><img src={phoneImg} />{cookie.load('CustomerUserMobile') !=="" ? cookie.load('CustomerUserMobile'): ""}</a>
                    </div>
                  </div>
                </div>

                <div className="myacc_info_rhs promotions_info_mem merchent_info_rhs merchent-mobile_rsh">
                  <ul className="merchent_info_ul">
                    <li>
                    {(client_membership_type === 1 ? customer_membership_type_display : "")}
                    </li>
                    {(client_membership_type === 1 ? calc_kakis_perc_display: "")}
                  </ul>
                  
                  {client_membership_type === 1 && (
                  <div className="member-prog-bar">
                    <div className="member-progress-2">
                      <div className="member-bar-2 member-bar-width-2" style={{width: calc_kakis_perc + "%"}}></div>
                    </div>
                  </div>)}

                  <div className="merchebt_Rpoint">
                    <ul className="merchebt_Rpointul">
                      <li onClick={this.openCheckout.bind(this)} >
                        <p><img src={awardabtImg} /></p>
                        <h2>{parseFloat(this.state.reward_ponits || 0)}</h2>
                      </li>

                      <li className="Rpoint_list">
                        <p><img src={percentageImg} /></p>
                        <h2>3</h2>
                      </li>

                    </ul>

                    <p className="merchebt_Rpoint_para">
                        Click On Reward Points Or E Voucher For Redeem
                      </p>
                  </div>
                </div>
              </div>/*}{/*  Merchent_dbord END */}

              {/*<div className="merchant-vistmain">
                <p className="merchant-vistlist">
                  visits: {this.state.vistCount}
                </p>
                <p className="merchant-vistdate">last visit : <span> {Moment(this.state.last_vist_date).format("DD MMM YYYY")}</span>
                </p>
              </div>*/}

              <div className="myacc_Dmain mobile_myacc_Dmain mobile_merchantdiv">
                <div className="myacc_product_main merchant_mobiledash_main">
                <ul className="merchant_mobiledash_uldiv">
                  {this.recentVisitlist()}
                </ul>
              </div>
              </div>


              <div className="myacc_content_main merchant_destopdash_main">
               <ul className="merchant_destopdash_ul">
                 <li className="myacc_locat_div">
                   <img src={locationImg} />
                   <h5>Recent visits of customer</h5>
                 </li>
               </ul>
              </div>
              <div className="myacc_product_main merchant_destopdash_main">
                <ul>
                  {this.recentVisitlist()}
                </ul>
              </div>
            </div>
          </div>{/*  CONTAINER END */}
      
      <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }


  return {
  customercompanyactivitylist: state.customercompanyactivity,
  settingsArr: globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerCompanyActivity: (customerParam) => {
      dispatch({ type: GET_CUSTOMER_COMPANY_ACTIVITY, customerParam });
    },
  };
};

Dashboardmulti.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboardmulti)
);
