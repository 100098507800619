/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ACTIVITYCOUNT, SET_ACTIVITYCOUNT } from '../actions';
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';
import {reactLocalStorage} from 'reactjs-localstorage';

export const watchGetActivityCount = function* () {
  yield takeEvery(GET_ACTIVITYCOUNT, workerGetActivityCount);
}

function* workerGetActivityCount({ getObject, login_type }) {
  try {

    var app_id = "";
    var customerParam = "";

    if(login_type == "Customer"){
      app_id = reactLocalStorage.get("app_id");
      customerParam  = "&status=A&customer_id=" + reactLocalStorage.get('UserId');
    }else{

      app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id")
      : '';
      
     customerParam = "&status=A&customer_id=" + cookie.load('CustomerUserId');

    }

	var actArrParam = "&act_arr=" + getObject;     	
  const uri = apiUrl+'chopchop/reports/activity_counts?app_id='+ app_id  + customerParam;
  const result = yield call(Axios.get, uri);

	var resultArr = [];
		resultArr.push(result.data);
    yield put({ type: SET_ACTIVITYCOUNT, value: resultArr });
  } 
  catch {
    console.log('Activitycount failed');
  }
} 

