import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PICKUP_OUTLETS, SET_PICKUP_OUTLETS} from '../actions';
import { apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
import {reactLocalStorage} from 'reactjs-localstorage';


export const watchGetPickupOutlets = function* () {
  yield takeEvery(GET_PICKUP_OUTLETS, workerGetPickupOutlets);
}

function* workerGetPickupOutlets() {
  try {

  var app_id  =  reactLocalStorage.get("app_id") !== "" && reactLocalStorage.get("app_id") !== undefined && reactLocalStorage.get("app_id") !== null
      ? reactLocalStorage.get("app_id")
      : "";    

  const uri = apiUrl+'outlets/pickup_outlets?app_id='+app_id;
  const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_PICKUP_OUTLETS, value: resultArr });
  } 
  catch {
    console.log('Get Pickup Outlets Failed');
  }
}