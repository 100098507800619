/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_GLOBAL_SETTINGS, SET_GLOBAL_SETTINGS } from "../actions";
import {
  apiUrl,
  deliveryId,
  pickupId,
} from "../components/Helpers/Config";
import cookie from "react-cookies";
import Axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

export const watchGetSettings = function* () {
  yield takeEvery(GET_GLOBAL_SETTINGS, workerGetSettings);
};

function* workerGetSettings(login_type) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") === ""
        ? ""
        : cookie.load("defaultAvilablityId");
    var orderPostalCode =
      cookie.load("orderPostalCode") === undefined ||
      cookie.load("orderPostalCode") === ""
        ? ""
        : cookie.load("orderPostalCode");
        
    var orderOutletId = (cookie.load('orderOutletId') === undefined || cookie.load("orderOutletId") === '' )?'':cookie.load("orderOutletId");

    var posCdParm =
      orderPostalCode !== "" ? "&postal_code=" + orderPostalCode : "";

    var avltyParm =
      availabilityId !== "" ? "&availability=" + availabilityId : "";

     var outletParm = (orderOutletId !== '') ? "&outlet_id=" + orderOutletId : '';

     var app_id = "";

    if(login_type.login_type === "customer"){

      app_id = reactLocalStorage.get("app_id") !== "" &&  typeof reactLocalStorage.get("app_id") !== undefined &&  typeof reactLocalStorage.get("app_id") !== "undefined"  ? reactLocalStorage.get("app_id") : reactLocalStorage.get("app_id");

    }else{

      app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id")
      : '';

    }

    var appId = app_id;

    const uri =
      apiUrl +
      "settings/get_common_settings?app_id=" +
      appId;
    const result = yield call(Axios.get, uri);

    if (result.data.status === "ok") {
      var resultSet = result.data.result_set;
      if (availabilityId === "") {
        availabilityId = !("ninja_pro_default_availability_id" in resultSet)
          ? ""
          : resultSet.ninja_pro_default_availability_id;
      }
      var availability = !("availability" in resultSet)
        ? Array()
        : resultSet.availability;
      if (Object.keys(availability).length > 0) {
        var availabilityLen = availability.length;
        for (var i = 0; i < availabilityLen; i++) {
          if (availability[i].availability_id === deliveryId) {
            cookie.save("deliveryOption", "Yes", {
              path: "/",
            });
          }
          if (availability[i].availability_id === pickupId) {
          }
        }
      }
    }
    availabilityId = availabilityId !== "" ? availabilityId : deliveryId;
    /* cookie.save("defaultAvilablityId", availabilityId, {
      path: "/",
    }); */
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_GLOBAL_SETTINGS, value: resultArr });
  } catch {
    console.log("Get Settings Failed");
  }
}
