/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, withRouter, Route } from "react-router-dom";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  getCalculatedAmount_rewards,
  showAlert,
  showCustomAlert,
  showPrices,
  showPoint, showLoader, hideLoader, getPromoCkValue } from "../Helpers/SettingHelper";

import { appId, apiUrl, stripeImage, companyName} from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";

import { connect } from "react-redux";
import cookie from "react-cookies";
import Moment from "moment";

import { GET_GLOBAL_SETTINGS, GET_CORDERDETAIL, GET_CUSTOMER_DETAIL, GET_PROMOTIONLIST } from "../../actions";

import locationImg from "../../common/images/celabrate_red.png";
import productoneImg from "../../common/images/promofour.png";
import productwoImg from "../../common/images/promotwo.png";
import producthreeImg from "../../common/images/promofour.png";
import productfourImg from "../../common/images/promothree.png";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import phoneImg from "../../common/images/phone.svg";
import awardabtImg from "../../common/images/award_white.svg";
import paymentImg from "../../common/images/pay_store.png";
import paymentstoreImg from "../../common/images/paynow.png";
import percentageImg from "../../common/images/percentage.svg";
import backImg from "../../common/images/back.png";
import arrowdownImg from "../../common/images/arrow-down.png";
import logoImg from "../../common/images/logo.png";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";


var Parser = require("html-react-parser");
var qs = require("qs");
var startTimePay;
var base64 = require("base-64");

class Merchantclienttransactionhistory extends Component {
  constructor(props) {
    super(props);

    cookie.remove("reedemPointVal", { path: "/" });

    var getPromoCkVal = getPromoCkValue();
    
    this.state = {
      loyalty_amount_value : '',
      subtotal_value: 0,
      total_amount_value: '',
      reward_points_amount: '',
      customercompanyactivitylist: [],
      recentVisitlistData: [],
      vistCount: 0,
      last_vist_date: "",
      omisePayNowResponse: [],
      globalSettings: [],
      paymentmodevalue: 'payCash',
      reward_ponits: 0,
      used_reward_point: getPromoCkVal["usedPoints"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
      corderdetail: [],
      showitems: 10,
      showpitems: 10,
      totalcount: '',
      noRecordsFound:false,
    };

     if (cookie.load("merchant_id") === "" || cookie.load("merchant_id") === undefined || cookie.load("merchant_id") === null) {
      props.history.push("/merchantlogin");
    }

    this.props.getGlobalSettings();


  }
 
  componentDidMount() {

    $("#dvLoading").fadeIn();
    var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    var newdate = year + "-" + month + "-" + day;
    var customer_user_id = cookie.load("CustomerUserId");
    var deliveryparams = "&app_id="+ app_id + "&order_date=" + newdate + "&history_type=company" + "&limit=" +  this.state.showitems;
    var params = "&status=A&refrence=" + customer_user_id;
    this.props.getCorderDetail(deliveryparams);
  }



  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }

    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {

      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {

        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].result_set) {
          this.setState({
            totalcount: nextProps.corderdetail[0].result_set,
          });
          this.setState({noRecordsFound: false});
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].result_set
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
        $("#dvLoading").fadeOut(2000);
        this.setState({noRecordsFound: true});
      }
    }

    }


  handleTextChange(event) {

    if (event.target.name === "loyalty_amount_value") {
      this.setState({ loyalty_amount_value: event.target.value });
      var loyalty_amount_value = event.target.value !== "" ? parseFloat(event.target.value) : 0;
      var subtotal_value = loyalty_amount_value.toFixed(2);
      this.setState({ subtotal_value: subtotal_value });
     }else if (event.target.name === "reward_points_amount") {
      this.setState({ reward_points_amount: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "recipient_name") {
      this.setState({ recipient_name: event.target.value });
    } else if (event.target.name === "recipient_contact_no") {
      this.setState({ recipient_contact_no: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    } else if (event.target.name === "wallet_amount") {
      this.setState({ corporate_customer_wallet_input: event.target.value });
    }

  }




 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }


  showSuccessPage(localOrderNo, orderPrimaryId) {
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
     const { history } = this.props;
     history.push("/merchant/dashboard");
  }



  transactionHistory = (dataProp) => {

    var dataProp = dataProp !== undefined ? dataProp : Array();

    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
         return ( <div className="order-details-main">
            <div className="order-details-lhs">
              <div className="order-details-image">
                <img src={awardImg} />
                <p>rewards</p>
              </div>
              <div className="order-details-content">
                <ul className="order-details-left">
                  <li className="order-title">{item.loyalty_pointorders_orderlocalno}</li>
                  <li className="order-date">{Moment(item.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> {" "} | {" "}</span>{Moment(item.loyalty_pointorders_created_on).format("h:mm A")}</li>
                  <li className="order-name">{item.customer_first_name + " " + item.customer_last_name} <span>|</span> {item.customer_phone}</li>
                </ul>
              </div>
            </div>

            <div className="order-details-rhs">
              <ul className="order-details-right">
                <li className="order-title">Total spent: {item.loyalty_pointorders_subtotal_amount}</li>

                {item.loyalty_pointorders_redeemed_points > 0 && (
                <li className="order-redeemed">
                  points redeemed - {item.loyalty_pointorders_redeemed_points}<span><img src={awardImg} /></span>
                </li>)}

                {/* <li className="promo_discount">
                  (code: 20off) promo discount - 20.00<span><img src={celabrateImg} /></span>
                </li> */}

                {item.lh_credit_points > 0 && (
                <li className="order-earned">
                  points earned + {item.lh_credit_points}<span><img src={awardImg} /></span>
                </li> )}
                
              </ul>
            </div>
          </div>)

      });
    }
  }


  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }


    loadcitems() {

    $(".load_more_data").append('<b class="gloading_img"></b>');

    var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
    ? cookie.load("merchant_app_id") : '';

    var deliveryparams = "&app_id="+ app_id + "&customer_id=" + cookie.load("CustomerUserId") + "&limit=" +  this.state.showitems;
    this.props.getCorderDetail(deliveryparams);

  }

  logoutClientDetails(){
  
    cookie.remove("CustomerUserId", { path: "/" });
    cookie.remove("CustomerUserFname", { path: "/" });
    cookie.remove("CustomerUserLname", { path: "/" });
    cookie.remove("CustomerUserMobile", { path: "/" });
    cookie.remove("CustomerUserEmail", { path: "/" });

    var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';


    this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
  }

  sateValChange = (field, value) => {};
  render() {

    var calculatedAmount = getCalculatedAmount_rewards(this.state.subtotal_value, 
    this.state.total_amount_value, this.state.reward_point_val,this.state.promotion_amount);

    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    var rewardPointAmount = parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2);
    var promoCodeAmount = parseFloat(calculatedAmount["promotionTotalAmount"]).toFixed(2);

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
        
        {/*<section className="myacc_details"></section>*/}
        <div className="merchent_serving">
           {cookie.load("CustomerUserId") && (
           <li className="hservingtype hservingnext">
              <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
          </li>)}         
              
        </div>

        <div className="container myacc_Dmain merchant_dash_contain">
          {/*<div className="myacc_main_div">
            <div className="merchent_serving"></div>
            <div className="myacc_info merchent_dbord">
              <div className="myacc_info_lhs merchent_info_lhs merchent-mobile_dbord"></div>
            </div>
          </div>*/} 
          <div className="order-history-main">{/* Transition History Start*/}  
            <div className="order-history-inner">
              <ul className="order-history-header">
                <li>daily history</li>
                <li>
                  <a href="#">view all</a>
                </li>
              </ul>

              {this.transactionHistory(this.state.corderdetail)}

              <div className="load_more_div">
                <button className="load_more_data" style={{ display: "none" }} onClick={this.loadcurrentItems.bind(this)}> Load More </button>
              </div>
            </div>
        
            {this.state.noRecordsFound === true &&(

               <div className="no-recrds-found">No records found.</div>
            )}
                
          </div>{/* Transition History End */}  

        </div>{/* Container - End*/} 

        {/*payment omise Pay Now confirm popup - End*/}   
        <Footer />

        <div id="dvLoading"></div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
 
   var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
     settingsArr: globalSettings,
     corderdetail: state.corderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getGlobalSettings: () => {
        dispatch({ type: GET_GLOBAL_SETTINGS });
      },
      getCorderDetail: (deliveryparams) => {
        dispatch({ type: GET_CORDERDETAIL, deliveryparams });
      }   
  };
};


Merchantclienttransactionhistory.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchantclienttransactionhistory)
);
