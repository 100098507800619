/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import CurrencyInput from 'react-currency-input';

import { Link, withRouter, Route } from "react-router-dom";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  getCalculatedAmount_rewards,
  showAlert,
  showCustomAlert,
  showPrices,
  showPoint, showLoader, hideLoader, getPromoCkValue, removePromoCkValue, getVoucherPromoCkValue, removeVoucherPromoCkValue } from "../Helpers/SettingHelper";

import { appId, apiUrl, stripeImage, companyName} from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";

import { connect } from "react-redux";
import cookie from "react-cookies";
import { format } from 'date-fns';

import { 
  GET_GLOBAL_SETTINGS, 
  GET_CUSTOMER_COMPANY_ACTIVITY, 
  GET_ACTIVITYCOUNT, 
  GET_CUSTOMER_DETAIL, 
  GET_PROMOTIONLIST,
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_LOYLATITY_CUSTOMER_DETAIL
} from "../../actions";

import locationImg from "../../common/images/celabrate_red.png";
import productoneImg from "../../common/images/promofour.png";
import productwoImg from "../../common/images/promotwo.png";
import producthreeImg from "../../common/images/promofour.png";
import productfourImg from "../../common/images/promothree.png";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import phoneImg from "../../common/images/phone.svg";
import awardabtImg from "../../common/images/award_white.svg";
import paymentImg from "../../common/images/pay_store.png";
import paymentstoreImg from "../../common/images/paynow.png";
import percentageImg from "../../common/images/percentage.svg";
import backImg from "../../common/images/back.png";
import arrowdownImg from "../../common/images/arrow-down.png";
import logoImg from "../../common/images/logo.png";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";
import crossImg from "../../common/images/cross1.png";

var Parser = require("html-react-parser");
var qs = require("qs");
var startTimePay;
var base64 = require("base-64");

class Merchantvouchercheckout extends Component {
  constructor(props) {
    super(props);

    //cookie.remove("voucher_reedemPointVal", { path: "/" });

    var getVocuherPromoCkVal = getVoucherPromoCkValue();
    
    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        postal: "",
        address_line1: "",
        address_line2: "",
        birthdate: "",
        gender: "",
        unitnumber1: "",
        unitnumber2: "",
      },
      voucherSubtotal_value: 0,
      total_amount_value: '',
      reward_points_amount: getVocuherPromoCkVal["voucher_reedemPointVal"],
      customercompanyactivitylist: [],
      promotionListArr: [],
      recentVisitlistData: [],
      vistCount: 0,
      last_vist_date: "",
      omisePayNowResponse: [],
      globalSettings: [],
      paymentmodevalue: 'payCash',
      reward_ponits: 0,
      promotionPercentage: 0,
      error_promocode: false,
      calc_kakis_perc_display: '',
      customer_membership_type: '',
      promo_code_val: getVocuherPromoCkVal["voucher_promoCodeVal"],
      promotion_applied: getVocuherPromoCkVal["voucher_promotionApplied"],
      promotion_type: getVocuherPromoCkVal["voucher_promotionType"],
      promotion_amount: getVocuherPromoCkVal["voucher_promotionAmount"],
      promotion_source: getVocuherPromoCkVal["voucher_promotionSource"],
      promoIs_delivery: getVocuherPromoCkVal["voucher_promoIsDelivery"],
      used_reward_point: getVocuherPromoCkVal["voucher_usedPoints"],
      reward_point_val: getVocuherPromoCkVal["voucher_reedemPointVal"],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      cartTotalItmCount: 0,
      product_lead_time: 0,
      cart_qty : 0,
    };

     if (cookie.load("CustomerUserId") == "" || cookie.load("CustomerUserId") == undefined) {
      props.history.push("/merchantlogin");
    }

    const login_type = "Merchant";
    this.props.getGlobalSettings(login_type);
    this.props.getCartDetail(login_type);
    $.magnificPopup.close();
  }
 
  componentDidMount() {

    $("#dvLoading").fadeIn();

    $("html, body").animate({ scrollTop: 0 }, 800);

    const inputKeys = ["reward", "overall_orders"];
    const login_type = "Merchant";
    this.props.getActivityCount(JSON.stringify(inputKeys), login_type);

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var customer_user_id = cookie.load("CustomerUserId");
    var customerParam = "app_id="+ app_id + "&customer_id=" + customer_user_id + "&login_type=merchant";
    this.props.getCustomerCompanyActivity(customerParam);

    this.checkOutAuthentication();
    var calculatedAmount = getCalculatedAmount_rewards(
      this.state.voucherSubtotal_value, this.state.total_amount_value, this.state.reward_point_val,this.state.promotion_amount
    );
    var params = "&status=A&refrence=" + customer_user_id;
    this.props.getCustomerDetail(params, login_type);
  }

  checkOutAuthentication(){

    var calculatedAmount = getCalculatedAmount_rewards(
    this.state.voucherSubtotal_value, this.state.total_amount_value,this.state.reward_point_val,this.state.promotion_amount
    );  
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    
    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }
    }

    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          $("#dvLoading").fadeOut(500);
          this.setState({reward_ponits: nextProps.activitycount[0].result_set.reward_ponits});
        }
      }
    } else {
      this.setState({ reward_ponits: 0 });
    }

   if (nextProps.customercompanyactivitylist !== this.props.customercompanyactivitylist) {
    if(Object.keys(nextProps.customercompanyactivitylist).length > 0 && nextProps.customercompanyactivitylist !==undefined){
      if (nextProps.customercompanyactivitylist[0].status === "ok") {

       this.setState({customercompanyactivitylist: nextProps.customercompanyactivitylist[0].result_set,
        vistCount:nextProps.customercompanyactivitylist[0].total_vist, last_vist_date: nextProps.customercompanyactivitylist[0].last_vist_date},function(){
        
        $("#dvLoading").fadeOut();

      });

     }

    }
    
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {

      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(4000);
        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",
            address_line1:
              nextProps.customerdetail[0].result_set.customer_address_line1 !== null
                ? nextProps.customerdetail[0].result_set.customer_address_line1
                : "",
            address_line2:
              nextProps.customerdetail[0].result_set.customer_address_line2 !== null
                ? nextProps.customerdetail[0].result_set.customer_address_line2
                : "",
            birthdate:
              nextProps.customerdetail[0].result_set.customer_birthdate !== null
                ? nextProps.customerdetail[0].result_set.customer_birthdate
                : "",
            gender:
              nextProps.customerdetail[0].result_set.customer_gender !== null
                ? nextProps.customerdetail[0].result_set.customer_gender
                : "",
            unitnumber1:
              nextProps.customerdetail[0].result_set.customer_billing_unitno1 !== null
                ? nextProps.customerdetail[0].result_set.customer_billing_unitno1
                : "",
            unitnumber2:
              nextProps.customerdetail[0].result_set.customer_billing_unitno2 !== null
                ? nextProps.customerdetail[0].result_set.customer_billing_unitno2
                : "",
            },
        });
      }
    }

    if (this.state.overAllcart !== nextProps.overAllcart) {
      this.setState({
        overAllcart: nextProps.overAllcart,
        cartItems: nextProps.cartItems,
        cartStatus: nextProps.cartStatus,
        cartTotalItmCount: nextProps.cartTotalItmCount,
        product_lead_time: nextProps.product_lead_time,
      });
    }

    if(this.state.cartDetails !== nextProps.cartDetails){
      console.log(nextProps.cartDetails,"length");
      removePromoCkValue();
      cookie.remove('subtotal_value', {path:'/'}); 
      cookie.remove('reedemPointVal', {path:'/'});
      cookie.save('cart_subtotal_value', nextProps.cartDetails.cart_sub_total, {path:'/'});
      cookie.save("reward_checkout", 'no', {path:'/'});
      let voucherCheckout = (Object.keys(nextProps.cartDetails).length>0)?cookie.save("voucher_checkout", 'yes', {path:'/'}):cookie.save("voucher_checkout", 'no', {path:'/'});
      this.setState({cartDetails: nextProps.cartDetails, cart_qty: nextProps.cartDetails.cart_total_items, voucherSubtotal_value: nextProps.cartDetails.cart_sub_total});
    }

    if (this.state.updateCartResponse !== nextProps.updateCartResponse) {
      if (Object.keys(nextProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: nextProps.updateCartResponse },
          function () {
            var Response = headerProps.updateCartResponse;

            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }

    var customer_membership_type = "",
        customer_membership_type_display = "",
        customer_unique_id = "",
        calc_kakis_perc = 0,
        calc_kakis_perc_display = "",
        client_membership_type = "",
        membership_spent_msg = "",
        membership_message_amount = "";

    if (nextProps.settingsArr !== "" && nextProps.settingsArr !==undefined && Object.keys(nextProps.settingsArr).length > 0) {
      client_membership_type = nextProps.settingsArr.client_membership_type;
    }

    if (nextProps.customerdetail !=="" && nextProps.customerdetail !==undefined && Object.keys(nextProps.customerdetail).length > 0) {

      customer_membership_type = nextProps.customerdetail[0].result_set
        .customer_membership_displayname;

      customer_unique_id = nextProps.customerdetail[0].result_set
        .customer_unique_id;

      let membership_max_amount = nextProps.customerdetail[0].result_set
        .membership_max_amount;
      let membership_spent_amount = nextProps.customerdetail[0].result_set
        .membership_spent_amount;
        membership_spent_msg = nextProps.customerdetail[0].result_set
        .membership_spent_msg;

        membership_message_amount = membership_max_amount - membership_spent_amount;

     if ( customer_membership_type !== "" && customer_membership_type !== "Normal") {
        customer_membership_type_display = (
         <h5>{customer_membership_type === "Normal" ? "Bronze" : customer_membership_type}</h5>  
        );
      }else{
        customer_membership_type_display = (          
        <h5>Bronze</h5>
        );
      }

      if (parseInt(membership_max_amount)) {
          calc_kakis_perc = (membership_spent_amount / membership_max_amount) * 100;
      }
    
      if(customer_membership_type === "Normal" || customer_membership_type === "Bronze"){
        calc_kakis_perc_display = <p>{membership_message_amount} more points to become Silver Member</p>
      }else if(customer_membership_type === "Silver"){
        calc_kakis_perc_display = <p>{membership_message_amount} more points to become Gold Member</p>
      }else if(customer_membership_type === "Gold"){
        calc_kakis_perc_display = <p>Gold Member</p>
      }

    }
    this.setState({ customer_membership_type : customer_membership_type, calc_kakis_perc_display: calc_kakis_perc_display });
  }

  loadCartList() {
    $("#dvLoading").fadeOut();
    var cartItemsArr = this.state.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="col-sm-cls cart_left">
            {/*<div className="cart_img">
              {product.cart_item_product_image !== "" ? (
                <img
                  src={product.cart_item_product_image}
                  alt={stripslashes(product.cart_item_product_name)}
                />
              ) : (
                <img
                  src={noimage}
                  alt={stripslashes(product.cart_item_product_name)}
                />
              )}
            </div>*/}
            <div className="cart_img">
              <b>{product.cart_item_qty} X</b>
            </div>
            <div className="cart_info text-left">
              <h4>{stripslashes(product.cart_item_product_name)}</h4>
              <h4>
                {product.cart_item_voucher_id !== "" &&
                product.cart_item_voucher_id != null
                  ? "Discount Applied"
                  : ""}{" "}
              </h4>

              {product.cart_item_type === "Gift" ? (
                <div>
                  <p>
                    {" "}
                    Name:
                    {product.cart_item_product_voucher_gift_name !== "" &&
                    product.cart_item_product_voucher_gift_name != null
                      ? " " + product.cart_item_product_voucher_gift_name
                      : ""}
                  </p>

                  <p>
                    {" "}
                    Mobile No:
                    {product.cart_item_product_voucher_gift_mobile !== "" &&
                    product.cart_item_product_voucher_gift_mobile != null
                      ? " " + product.cart_item_product_voucher_gift_mobile
                      : ""}
                  </p>

                  <p>
                    {" "}
                    Email:
                    {product.cart_item_product_voucher_gift_email !== "" &&
                    product.cart_item_product_voucher_gift_email != null
                      ? " " + product.cart_item_product_voucher_gift_email
                      : ""}
                  </p>

                  <p>
                    {" "}
                    Message:{" "}
                    {product.cart_item_product_voucher_gift_message !== "" &&
                    product.cart_item_product_voucher_gift_message != null
                      ? " " + product.cart_item_product_voucher_gift_message
                      : ""}{" "}
                  </p>
                </div>
              ) : (
                ""
              )}

              {this.loadModifierItems(
                product.cart_item_type,
                product.modifiers,
                product.set_menu_component
              )}

              {product.cart_item_special_notes !== "" && (
                <p className="help-block">
                  {stripslashes(product.cart_item_special_notes)}
                </p>
              )}

              {parseFloat(product.cart_item_promotion_discount) > 0 && (
                <span class="member-discount-desc">
                  $ {product.cart_item_promotion_discount}{" "}
                  {product.cart_item_promotion_type} discount Applied
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-cls cart_right text-right">
            <div className="cart_price">
              <p>
                $
                {product.cart_item_total_price}
              </p>
            </div>

            {/*{product.cart_item_voucher_product_free != 1 ? (
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.updateCartQty.bind(this, product, "decr")}
                >
                  -
                </span>
                <input type="text" value={product.cart_item_qty} readOnly />
                <span
                  className="qty_plus"
                  onClick={this.updateCartQty.bind(this, product, "incr")}
                >
                  +
                </span>
              </div>
            ) : (
              <div className="qty_bx free_product">
                <span className="qty_minus">-</span>
                <input type="text" value={product.cart_item_qty} readOnly />
                <span className="qty_plus">+</span>
              </div>
            )}*/}
          </div>

          <a
            href="/"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          >
            
          </a>
        </div>
      ));
    }
  }

  updateCartQty(itemArr, type) {
    console.log(itemArr,"itemArr");
    console.log(type,"type");
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = (itemArr.cart_voucher_order_item_id !== null) ? itemArr.cart_voucher_order_item_id : 0;
    var login_type = "Merchant";

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId, login_type);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId,
          login_type
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
        login_type
      );
    }
    this.removePointsAndPromo("frmFunct");
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    var login_type = "Merchant";
    cookie.remove("cart_subtotal_value", {path:'/'});
    cookie.remove("voucher_reedemPointVal", {path:'/'});
    this.props.deleteCartDetail(cartItemId,login_type);
    this.removePointsAndPromo();
    this.setState({reward_points_amount:'',reward_point_val:''})
  }

  clearCartItm(event) {
    event.preventDefault();
    let login_type = "Merchant";
    showLoader("cart_body", "class");
    this.props.destroyCartDetail(login_type);
  }

  loadCartOverallData() {
    var cartDetailsArr = this.state.cartDetails;

    if (Object.keys(cartDetailsArr).length > 0) {
      $("#dvLoading").fadeOut(1000);

      var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var calculatedAmount = getCalculatedAmount(
        this.state.globalSettings,
        this.props.zonedetails,
        cartDetailsArr,
        promoTionArr
      );
      var promotionType = this.state.promotion_type;

      return (
        <div className="checkout-right-body-section">
          <div className="cart_table">
            <div className="cart_body">
              <div className="overall-parent">
                <div className="order-details-with-clear">
                  <div className="product_orders_top">
                    <h5>Your Items</h5>

                    <div className="product_modify_cart">
                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title="CLEAR CART"
                      >
                        Clear All
                      </a>
                    </div>
                  </div>

                  <div className="mobile-cart">
                    <h5>Your Items</h5>
                    <div className="product_modify_cart">
                      <a
                        href="/menu"
                        className="hclear_cart modify_item"
                        title="ADD"
                      >
                        {" "}
                        <img src={mofifyimage} alt="Add Items" /> Add Items{" "}
                      </a>

                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title="CLEAR CART"
                      >
                        <img src={mofifydeleteimage} alt="Clear Cart" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="product-details-parent">
                  {this.loadCartList()}
                </div>
              </div>
            </div>

              {Object.keys(this.props.addonproductlist).length > 0  &&  Object.keys(this.props.addonproductlist[0].subcategorie).length > 0 && (
                <div className="check_pg">
                  <div className="container">
                    <div className="check_pg_inner">
                      <div className="chk-title text-center">
                        <h4>Would you like to add following items ?</h4>
                      </div>
                      <OwlCarousel options={settingsourclient}>
                        {this.addonProductListing()}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              )}


            <div className="cart_footer">
              <div className="cart_row">
                <p className="text-uppercase">SUBTOTAL</p>
                <span>
                  {currencySybmol}
                  {calculatedAmount["cartSubTotalAmount"]}
                </span>
              </div>
              {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">Delivery</p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["deliveryCharge"]).toFixed(2)}
                  </span>
                </div>
              )}
              {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                <div className="cart_row">
                  <p className="text-uppercase">Additional Delivery</p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["additionalDelivery"]).toFixed(
                      2
                    )}
                  </span>
                </div>
              )}
              {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                <div className="cart_row promo-cart-row">
                  <p className="text-uppercase">
                    {promotionType === "points" ? "Discount " : "Promo Code"}
                  </p>
                  <span>
                    (-{currencySybmol}
                    {parseFloat(calculatedAmount["promotionAmount"]).toFixed(2)}
                    )
                  </span>
                  <a
                    href="/"
                    onClick={this.removePointsAndPromo.bind(this, "fromclk")}
                    className="cart_remove"
                  >
                  </a>
                </div>
              )}
              {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                <div className="cart_row gst-row">
                  <p className="text-uppercase">
                    GST ({calculatedAmount["orderDisplayGst"]} %)
                  </p>
                  <span>
                    {currencySybmol}
                    {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(2)}
                  </span>
                </div>
              )}

              {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                <div className="cart_row gst-row">
                  <p className="text-uppercase">Voucher Discount Amount</p>
                  <span>
                    {currencySybmol}
                    {parseFloat(
                      calculatedAmount["voucherDiscountAmount"]
                    ).toFixed(2)}
                  </span>
                </div>
              )}

              <div className="cart_row grant-total-cls">
                <p className="text-uppercase">Total</p>

                <span>
                  <sup>{currencySybmol}</sup>
                  {calculatedAmount["grandTotalAmount"]}
                </span>

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div class="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type}{" "}
                    {currencySybmol}
                    {cartDetailsArr.cart_special_discount} Applied
                  </div>
                )}
              </div>


            </div>
              <div className="checkout_bar">
                {cookie.load("defaultAvilablityId") === deliveryId &&
                this.loadDeliveryPercentageBar()}
              </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " ( + " +$ + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><b>" +
            comboName +
            ":</b> </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " ( + " +$ + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  handleTextChange(event) {

    if (event.target.name === "reward_points_amount") {
      this.setState({ reward_points_amount: event.target.value });
    } else if (event.target.name === "promocode_points_amount") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "recipient_name") {
      this.setState({ recipient_name: event.target.value });
    } else if (event.target.name === "recipient_contact_no") {
      this.setState({ recipient_contact_no: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    } else if (event.target.name === "wallet_amount") {
      this.setState({ corporate_customer_wallet_input: event.target.value });
    }

  }


  choosePayment(paymentMode) {
    if (paymentMode === "payCash") {
      this.setState({ paymentmodevalue: "payCash" });
    } else if (paymentMode === "OmisePayNow") {
      this.setState({ paymentmodevalue: "OmisePayNow" });
    } else if (paymentMode === "OmisePayNowChain") {
      this.setState({ paymentmodevalue: "OmisePayNowChain" });
    }
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    if (payGetWayType === "OmisePayNow") {
      this.createOmiseSource();
    } else if (payGetWayType === "OmisePayNowChain") {
      this.createOmiseChainSource();
    }
  }

  payCash(event){
  event.preventDefault();
  this.postOrder(1);
  }

  /* Omise PayNow Start */
  createOmiseSource() {
    if (
      this.state.globalSettings.omise_paynow_public !== "" &&
      typeof this.state.globalSettings.omise_paynow_public !== undefined &&
      typeof this.state.globalSettings.omise_paynow_public !== "undefined"
    ) {
      var payNowID = base64.decode(
        this.state.globalSettings.omise_paynow_public
      );
      Omise.setPublicKey(payNowID);

      /*var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var cartDetailsArr = this.state.cartDetails;*/

      var calculatedAmount = getCalculatedAmount_rewards(
        this.state.voucherSubtotal_value, this.state.total_amount_value, this.state.reward_point_val, this.state.promotion_amount);

      showLoader("omisepaynow_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

paynowCapture(sourceID, amount) {
    if (sourceID !== "") {


    var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: app_id,
        source_id: sourceID,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: cookie.load("merchant_outlet_id"),
        amount: amount,
        
      };
      axios
        .post(apiUrl + "paymentv1/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          console.log(res,"res");
          hideLoader("omisepaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            console.log(res.data.status, "res.data.status");
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowPamentStatus(startTimePay);
                    }
                    currentThis.omisePaynowTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
  
    var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null 
      ? cookie.load("merchant_outlet_id") :  "";


      statusObj = {
        app_id: app_id,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: outlet_id,
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(apiUrl + "paymentv1/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(2);
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }
  /* Omise PayNow End */

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="button btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }


  /* Omise PayNow Chain Start */
  createOmiseChainSource() {

    if (
      this.state.globalSettings.client_enable_omise_chain_paynow !== "" &&
      this.state.globalSettings.client_enable_omise_chain_paynow == "1" &&
      typeof this.state.globalSettings.client_enable_omise_chain_paynow !==
        undefined &&
      typeof this.state.globalSettings.client_enable_omise_chain_paynow !==
        "undefined"
    ) {
      var payNowID = "";

      if (this.state.globalSettings.client_omise_chain_mode === "0") {
        payNowID = this.state.globalSettings.omise_main_acc.omise_public_test;
      } else {
        payNowID = this.state.globalSettings.omise_main_acc.omise_public_live;
      }
      Omise.setPublicKey(payNowID);

      var calculatedAmount = getCalculatedAmount_rewards(
        this.state.voucherSubtotal_value, this.state.total_amount_value, this.state.reward_point_val, this.state.promotion_amount);

      showLoader("omisepaynowchain_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowChainCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

paynowChainCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};

      var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null 
      ? cookie.load("merchant_outlet_id") :  "";

      var currentThis = this;
      omisePostObject = {
        app_id: app_id,
        source_id: sourceID,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: outlet_id,
        amount: amount,
      };
      axios
        .post(apiUrl + "omisepay/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          hideLoader("omisepaynowchain_btn", "Idtext");
          if (res.data.status === "ok") {
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowChainTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowChainPamentStatus(
                        startTimePay
                      );
                    }
                    currentThis.omisePaynowChainTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowChainTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowChainPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};

      var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null 
      ? cookie.load("merchant_outlet_id") :  "";

      statusObj = {
        app_id: app_id,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: outlet_id,
        capture_id: this.state.omisePayNowResponse.captureId,
      };

      axios
        .post(apiUrl + "omisepay/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(3);
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }

  removePointsAndPromo() {

    removeVoucherPromoCkValue();

    this.setState({
      //reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      //used_reward_point: 0
    });
  }

  postOrder(paymentMode){

   var calculatedAmount = getCalculatedAmount_rewards(
      this.state.voucherSubtotal_value, this.state.total_amount_value, this.state.reward_point_val, this.state.promotion_amount
    );
   var discount_applied = "No";


   var postObject = {};

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

   var outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';

   var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null 
      ? cookie.load("merchant_outlet_id") :  "";

    var enterd_redeem_point = this.state.reward_points_amount;

    if(enterd_redeem_point > 0){

      enterd_redeem_point =  parseFloat(this.state.reward_points_amount).toFixed(2);

    }else{

      enterd_redeem_point =  "0.00";
    }

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    
    var promocode_points_amount = (this.state.promotion_amount>0)?parseFloat(this.state.promotion_amount).toFixed(2):"0.00"
    var finalcartItems = this.state.cartItems;
    var cartDetailsArr = this.state.cartDetails;

    var products = [];
    var row = this.state.cartItems.map(
      function (item, i) {
        var modifierdata = [];
        var comboData = [];
        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_actual_unit_price: item.cart_item_actual_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          condiments: "",
          modifiers: modifierdata,
          bakers_modifiers: "",
          menu_set_components: comboData,
          baby_pack: "",
          product_special_notes: item.cart_item_special_notes,
          voucher_gift_name: item.cart_item_product_voucher_gift_name,
          voucher_gift_email: item.cart_item_product_voucher_gift_email,
          voucher_gift_mobile: item.cart_item_product_voucher_gift_mobile,
          voucher_gift_message: item.cart_item_product_voucher_gift_message,
          order_item_id:item.cart_voucher_order_item_id != "0"? item.cart_voucher_order_item_id : "",
          voucher_free_product: item.cart_item_voucher_product_free,
          order_voucher_id: item.cart_item_voucher_id,
        });

        return products;
      }.bind(this)
    );

    var orderDate = "";
    var seletedOrdDate = new Date();
    if (seletedOrdDate !== "") {
      var formatedDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdDate.getHours();
      var OrderMunts = seletedOrdDate.getMinutes();
      var OrderSecnd = seletedOrdDate.getSeconds();

      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);

      orderDate = formatedDate + " " + orderTime;
    }

    var redeem_order = '';

    var order_grand_total = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);

    if(order_grand_total > 0){

      redeem_order = paymentMode;

    }else{

      redeem_order = 12;
      
    }

     postObject = {
      /* cart details */
      app_id: app_id,
      sub_total: parseFloat(calculatedAmount["SubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      //order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      outlet_slug_name: outlet_slug_name,
      outlet_id: outlet_id,
      discount_applied : discount_applied,
      redeem_point_amount : parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2),
      redeem_point : parseFloat(this.state.reward_points_amount).toFixed(2),
      reward_point_status: "Yes",

      /* For Advanced Slot */
      /*order_is_timeslot: order_is_timeslot,
      order_pickup_time_slot_from: ordSlotStrTm,
      order_pickup_time_slot_to: ordSlotEndTm,

      order_remarks: this.state.order_specil_note,*/
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: cookie.load("CustomerUserId"),
      customer_fname: cookie.load("CustomerUserFname"),
      customer_lname: cookie.load("CustomerUserLname"),
      customer_mobile_no: cookie.load("CustomerUserMobile"),
      customer_email: cookie.load("CustomerUserEmail"),
      customer_address_line1: this.state.fields.address_line1,
      customer_address_line2: this.state.fields.address_line2,
      customer_postal_code: "",
      customer_birthdate: this.state.fields.birthdate,
      customer_birthdate_update: this.state.fields.birthdate,
      customer_unit_no1: this.state.fields.unitnumber1,
      customer_unit_no2: this.state.fields.unitnumber2,
      customer_gender: this.state.fields.gender,

      /*billing_address_line1: BillingAddress,
      billing_postal_code: BillingPostalCode,
      billing_unit_no1: BillingUnitNo1,
      billing_unit_no2: BillingUnitNo2,
      latitude:(cookie.load("latitude")!=="" && typeof cookie.load("latitude")!==undefined && typeof cookie.load("latitude")!=="undefined")?cookie.load("latitude"):'',
      longitude:(cookie.load("longitude")!=="" && typeof cookie.load("longitude")!==undefined && typeof cookie.load("longitude")!=="undefined")?cookie.load("longitude"):'',
      */

      /* Payment */
      payment_mode: redeem_order 
      /*payment_reference: stripeReference,
      stripe_envir: "test",
      payment_getway_details:
        payGetWayType === "OmisePayNow" || payGetWayType === "OmisePayNowChain"
          ? { payment_type: "Omise PayNow", payment_status: "Success" }
          : "",
      order_payment_retrieved:
        (payGetWayType === "OmisePayNow" ||
          payGetWayType === "OmisePayNowChain") &&
        captureID !== ""
          ? "Yes"
          : "No",
      omise_log_id:
        (payGetWayType === "OmisePayNow" ||
          payGetWayType === "OmisePayNowChain") &&
        captureID !== ""
          ? this.state.omise_log_id
          : "",
      order_capture_token: captureID,
      order_payment_getway_type: "",
      order_payment_getway_status: "Yes",*/

      /* additional paras */
      /*delivery_charge: parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
        2
      ),
      additional_delivery: parseFloat(
        calculatedAmount["additionalDelivery"]
      ).toFixed(2),
      tax_charge: calculatedAmount["orderDisplayGst"],
      order_tax_calculate_amount: parseFloat(
        calculatedAmount["orderGstAmount"]
      ).toFixed(2),

      /* discount */
      /*order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",*/
    };

    if(this.state.reward_point_val > 0) {
      postObject["redeem_applied"] = "Yes";
      postObject["redeem_amount"] = this.state.reward_point_val;
    }

    /*  promo code object - start */
    if (parseFloat(promotionAmount) > 0) {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);
        postObject["promotion_applied"] = promotionApplied;
        postObject["promo_code"] = cookie.load("voucher_promoCodeVal");
        postObject["cart_quantity"] = cartDetailsArr.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["promotion_amount"] = parseFloat(calculatedAmount["promotionTotalAmount"]).toFixed(2);
        postObject["discount_type"] = "coupon";
    }else{
      postObject["discount_type"] = "redeem";
    }

    /*if (payGetWayType === "Ocbc") {
      if (validation === "Yes") {
        postObject["order_ocbc_reference_id"] = this.makeid(10);
      } else {
        postObject["order_ocbc_reference_id"] = cookie.load("ocbcreferenceID");
      }
      cookie.save("ocbcreferenceID", postObject["order_ocbc_reference_id"], { path: "/" });
    }

    if (payGetWayType === "Gcash" || payGetWayType === "paymaya" ) {
      if (validation === "Yes") {
        postObject["order_xenditEwallet_reference_id"] = this.makeid(10);
      } else {
        postObject["order_xenditEwallet_reference_id"] = cookie.load("xenditewalletReferenceId");
      }
      cookie.save("xenditewalletReferenceId", postObject["order_xenditEwallet_reference_id"], { path: "/" });
    }*/

    axios
      .post(
        apiUrl + "chopchop/loyalty_points/loyaltypointorders",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var login_type = "Merchant";
          this.props.destroyCartDetail(login_type);
          this.handleShowAlertFun("success", res.data.message);
          this.setState({ subtotal_value: 0,  total_amount_value: '', reward_points_amount: "", used_reward_point: '', reward_point_val: ""});
          var localOrderNo = res.data.common.local_order_no;
          this.showSuccessPage(localOrderNo,res.data.common.order_primary_id);
          cookie.remove("voucher_reedemPointVal", { path: "/" });
          cookie.remove("cart_subtotal_value", { path: "/" });
          cookie.save("reward_checkout", 'no', { path: "/" });
          cookie.save("voucher_checkout", 'no', { path: "/" });
          removeVoucherPromoCkValue();
         // location.reload();
        }else{
          showCustomAlert("error", res.data.message);
        }

      });
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }


  showSuccessPage(localOrderNo, orderPrimaryId) {
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    setTimeout(function () {
      $.magnificPopup.close();
      history.push("/merchant/thankyou/" + localOrderNo);
    }, 450);

  }



toggleChange(value) {

    var reedemPointVal = this.state.reward_points_amount;
    var promoCodeVal = this.state.promoCodeVal;
    var usedPoints = "";
    var subTotal = this.state.cartDetails.cart_sub_total;
    
    var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

   if (reedemPointVal === "") {
      showCustomAlert("Error", "Please Enter Redeem Points");
      return false;
    }

   if(value == "rewardPoint"){

    var postObject = {
        app_id: app_id,
        reference_id: cookie.load("CustomerUserId"),
        redeem_point: reedemPointVal,
        cart_amount: this.state.cartDetails.cart_sub_total,
      };

      axios.post(apiUrl + "chopchop/loyalty_points/apply_loyalityv1", qs.stringify(postObject))
        .then((res) => {

          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            usedPoints = res.data.result_set.points_amount;
            ///reedemPointVal;
            cookie.save("voucher_reedemPointVal", usedPoints, { path: "/" });
            cookie.save("usedPoints", usedPoints, { path: "/" });
            showCustomAlert("success", "Rewards Points applied successfully");

            this.setState({
              reward_point_val: usedPoints,
              used_reward_point: usedPoints,
            });

            var calculatedAmount = getCalculatedAmount_rewards(
               this.state.voucherSubtotal_value, this.state.total_amount_value,usedPoints); 

          } else {
            var pointDet = '';
            reedemPointVal = 0;
            usedPoints = 0;
            cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
            cookie.save("usedPoints", usedPoints, { path: "/" });
            showCustomAlert("error", res.data.message);

            this.setState({
              reward_point_val: reedemPointVal,
              used_reward_point: usedPoints,
              reward_points_amount: 0
            });

            var calculatedAmount = getCalculatedAmount_rewards(
               this.state.voucherSubtotal_value, this.state.total_amount_value,reedemPointVal); 
          }
        });
      }else{
        cookie.remove("voucher_reedemPointVal", { path: "/" });
        cookie.remove("usedPoints", { path: "/" });
        this.setState({
          reward_point_val: 0,
          used_reward_point: 0,
          reward_points_amount: ''
        });
      }
  }


  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;

    if (type === "points") {
      if (parseFloat(reedemPointVal) === 0 || reedemPointVal === "") {
        showAlert("Error", "Sorry!. Invalid Redeem Point.");
      }
    } else if (type === "promoCode") {
      if (promoCodeVal === "") {
        showCustomAlert("Error", "Please Apply Promocode ");
        return false;
      }
    }

    if (type === "points") {
      if (reedemPointVal > 0) {
        showLoader("applypoints_cls", "class");
        var postObject = {
          app_id: appId,
          reference_id: cookie.load("UserId"),
          redeem_point: reedemPointVal,
          cart_amount: this.state.cartDetails.cart_sub_total
        };

        axios
          .post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              promotionApplied = "Yes";
              promotionType = "points";
              promotionAmount = pointDet.points_amount;
              promotionSource = reedemPointVal;
              promoIsDelivery = "";
              promoCodeVal = "";
              usedPoints = reedemPointVal;
              showCustomAlert("success", "Rewards Points applied successfully");
            } else {
              if (promotionType === "points") {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
                usedPoints = 0;
              }
              showCustomAlert("error", "Sorry! Did not applied Rewards Points");
            }

            hideLoader("applypoints_cls", "class");
            cookie.save("voucher_reedemPointVal", reedemPointVal, { path: "/" });
            cookie.save("voucher_promoCodeVal", promoCodeVal, { path: "/" });
            cookie.save("voucher_promotionApplied", promotionApplied, { path: "/" });
            cookie.save("voucher_promotionType", promotionType, { path: "/" });
            cookie.save("voucher_promotionAmount", promotionAmount, { path: "/" });
            cookie.save("voucher_promotionSource", promotionSource, { path: "/" });
            cookie.save("voucher_promoIsDelivery", promoIsDelivery, { path: "/" });
            cookie.save("voucher_usedPoints", usedPoints, { path: "/" });

            this.setState({
              reward_point_val: reedemPointVal,
              promo_code_val: promoCodeVal,
              promotion_applied: promotionApplied,
              promotion_type: promotionType,
              promotion_amount: promotionAmount,
              promotion_source: promotionSource,
              promoIs_delivery: promoIsDelivery,
              used_reward_point: usedPoints,
            });
          });
      }
    } else if (type === "promoCode") {
      showLoader("applypromo_cls", "class");

      var postObject = {
        app_id: cookie.load("merchant_app_id"),
        reference_id: cookie.load("CustomerUserId"),
        promo_code: this.state.promo_code_val,
        cart_amount: this.state.cartDetails.cart_sub_total,
        outlet_id: cookie.load("merchant_outlet_id"),
      };

      axios
        .post(
          apiUrl + "chopchop/promotion/apply_promotion",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            //reedemPointVal = "";
            //usedPoints = 0;
            showCustomAlert("success", "Promotion applied successfully");
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Did not applied promo code";
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            showCustomAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");
          //cookie.save("voucher_reedemPointVal", reedemPointVal, { path: "/" });
          cookie.save("voucher_promoCodeVal", promoCodeVal, { path: "/" });
          cookie.save("voucher_promotionApplied", promotionApplied, { path: "/" });
          cookie.save("voucher_promotionType", promotionType, { path: "/" });
          cookie.save("voucher_promotionAmount", promotionAmount, { path: "/" });
          cookie.save("voucher_promotionSource", promotionSource, { path: "/" });
          cookie.save("voucher_promoIsDelivery", promoIsDelivery, { path: "/" });
          //cookie.save("voucher_usedPoints", usedPoints, { path: "/" });

          this.setState({
            //reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            //used_reward_point: usedPoints,
          });
        });
    }
  }

  logoutClientDetails(){
  
    cookie.remove("CustomerUserId", { path: "/" });
    cookie.remove("CustomerUserFname", { path: "/" });
    cookie.remove("CustomerUserLname", { path: "/" });
    cookie.remove("CustomerUserMobile", { path: "/" });
    cookie.remove("CustomerUserEmail", { path: "/" });

    var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';


    this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
  }

  sateValChange = (field, value) => {};
  render() {
    var voucherSubtotal_value = this.state.cartDetails.cart_sub_total;
    var calculatedAmount = getCalculatedAmount_rewards(voucherSubtotal_value, 
    this.state.total_amount_value, this.state.reward_point_val,this.state.promotion_amount);
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    var rewardPointAmount = parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2);
    var promoCodeAmount = parseFloat(calculatedAmount["promotionTotalAmount"]).toFixed(2);

    return (
      <div className="contactusmain-top-div">

        <Header sateValChange={this.sateValChange} />
        
        <div className="merchent_serving">

           {cookie.load("CustomerUserId") && (
           <li className="hservingtype hservingnext">
              <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
          </li>)}         
              
        </div>

        <Merchantdashboard />
        
      <div className="container myacc_Dmain merchant_dash_contain">
      <div className="merchant-vistmain"></div>
      
      <div className="merchent-reward-main merchant-reward-whole">
        <h2>Checkout</h2>
        <div className="merchent-reward-inner">
          <div className="merchent-reward-lhs">
            <p className="amt-pay-para">Your Items</p>
            <div className="merchent-details">
              {/*<div className="col-sm-cls cart_left">
                  <div className="cart_img">
                    <img src="https://ccpl.ninjaos.com/media/dev_team/products/main-image/02203b4461588d6f17ca61b513d568e9.jpg" alt="" />
                    <b>1 X</b>
                  </div>
                  <div className="cart_info text-left">
                     <div className="cart_extrainfo">
                        <p>Buy 5 Chicken Wing & Get 1 Free</p>
                     </div>
                  </div>
               </div>
               <div className="col-sm-cls cart_right text-right">
                  <div className="cart_price">
                     <p>$390.00</p>
                  </div>
              </div>*/}
              {this.loadCartList()}
            </div>
            <ul>
              <li>
                <p>redeem points</p>
                <div className="redeem_points">
                  <input type="text" name="reward_points_amount" onChange={this.handleTextChange.bind(this)} value={this.state.reward_points_amount}  onKeyPress={(e) => this.validateIntval(e)}
                disabled={voucherSubtotal_value > 0 ? "": "disabled" }/>
                
                  {this.state.reward_point_val > 0 ? (
                    <button
                      className="button rdm_submit"
                      onClick={this.toggleChange.bind(
                        this,
                        "removePoint"
                      )}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="button rdm_submit"
                      onClick={this.toggleChange.bind(
                        this,
                        "rewardPoint"
                      )}
                      disabled={voucherSubtotal_value > 0 ? "": "disabled" }
                    >
                      Apply Now
                    </button>
                  )}


                </div>
              </li>

              <li>
                <p>redeem promo code</p>
                <div className="redeem_points">
                  <input type="text" name="promocode_points_amount" onChange={this.handleTextChange.bind(this)} value={this.state.promo_code_val} 
                 disabled={voucherSubtotal_value > 0 ? "": "disabled" }/>
                 {this.state.promotion_applied === "Yes" &&
                  this.state.promotion_type === "promoCode" ? (
                    <button
                      className="button rdm_submit"
                      onClick={this.removePointsAndPromo.bind(
                        this
                      )}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="button rdm_submit"
                      onClick={this.applyPointsAndPromo.bind(
                        this,
                        "promoCode"
                      )}
                      disabled={voucherSubtotal_value > 0 ? "": "disabled" }
                    >
                      Apply Now
                    </button>
                  )}
                </div>
              </li>
            </ul>
          </div>

          <div className="merchent-reward-rhs">
            
            <div className="cart_footer">
              <div className="cart_row">
                <p className="text-uppercase">SUBTOTAL</p>
                {voucherSubtotal_value> 0 ?
                <span>${voucherSubtotal_value}</span>
                : <span>$0.00</span>}
              </div>
              {rewardPointAmount > 0 && (
                <div className="cart_row">
                <p className="text-uppercase">points</p>
                <span>(- ${rewardPointAmount})</span>
              </div>
              )}
              {promoCodeAmount > 0 && (
              <div className="cart_row gst-row">
                <p className="text-uppercase">promo code</p>
                <span>(-${promoCodeAmount})</span>
              </div>
              )}
              <div className="cart_row grant-total-cls">
                <p className="text-uppercase">pay</p>
                <span>${payAmount}</span>
              </div>
            </div>

            <div className="chk-payment-col">
               <form>
                  <ul className="merchent-ul">
                     {payAmount > 0 && (
                     <li className={"merchent-bdiv "+((this.state.paymentmodevalue == "payCash") ? 'active' : '')} onClick={this.choosePayment.bind(
                                    this,
                                    "payCash"
                                  )}>
                          <a>
                          <div className="radio_con">
                          <img src={paymentImg} />
                        </div>
                        </a>
                     </li>)}

                     
                     {/*<li className={"merchent-bdiv "+((this.state.paymentmodevalue == "OmisePayNowChain") ? 'active' : '')} onClick={this.choosePayment.bind(
                                    this,
                                    "OmisePayNowChain"
                                  )}>
                      <a >
                          <div className="radio_con">
                          <img src={paymentstoreImg} />
                        </div>
                        </a>
                     </li>*/}
                 
                  </ul>

                  {( payAmount > 0 || this.props.cartTotalItmCount > 0) && this.state.paymentmodevalue == "payCash" && (
                  <div className="merchent-paybtn">
                    <a className="button" title="Confirm" onClick={this.payCash.bind(this)}>Confirm</a>
                  </div>
                  )}

                 {payAmount > 0 && this.state.paymentmodevalue == "OmisePayNowChain" && (
                  <div className="merchent-paybtn">
                    <a className="button" title="Confirm" onClick={this.orderPayValidaion.bind(this,"OmisePayNowChain")}>Confirm</a>
                  </div>
                  )}

               </form>
            </div>

          </div>

        </div>
      </div>

      {/*</div>*/}

    </div>{/* Container End */}
  
        {/*Payment omise Pay Now confirm popup Start*/}

      <div id="pay-omisepaynowconf-popup"
          className="white-popup mfp-hide popup_sec pay-omisepaynowconf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <div className="omise-pay-title">
                    <h2>{companyName}</h2>
                  </div>
                  <div className="omise-pay-img">
                    <img className="makisan-logo-img" src={logoImg} />
                  </div>
                </div>
                {Object.keys(this.state.omisePayNowResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_inner">
                        {this.state.omisePayNowResponse.download_uri !== "" && (
                        <div className="Qr_imgdiv">
                          <img
                            src={this.state.omisePayNowResponse.download_uri}
                            id="paynow-qr-image"
                          />
                          <span className="omise-Qrheding"> Scan the QR code to pay.</span>
                        </div>
                        )}
                        <h3 className="omise-paynow-amount-to-paid">
                          <span>
                            <sup>$</sup>{this.state.omisepaynowAmount.toFixed("2")}
                          </span>
                        </h3>
                        <p className="paynow-seconds">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>&nbsp;{this.state.omisePaynowTime} sec</span>
                        </p>
                      </div>


                      <div className="paynow-notes">
                        <h4>Instructions:</h4>
                        <ul>
                          <li>
                            <img src={arrowdownImg} />
                            Use your bank app to scan QR Code and pay.
                          </li>
                          <li>
                            <img src={arrowdownImg} />
                            You will be paying to Omise Payment SG (Authorised
                            payment processor) for
                          </li>
                          <li>
                            <img src={arrowdownImg} />
                            Once you make the payment, payment status of the
                            order will reflect as 'paid', please do not make
                            multiple payments with the same QR Code.
                          </li>
                          <li>
                            <img src={arrowdownImg} />
                            Page will automatically refresh after your
                            successful payment.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}   
        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
 
  var globalSettings = Array();
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  var product_lead_time = 0;

  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      product_lead_time = resultSetArr.product_lead_time;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }


  return {
    customercompanyactivitylist: state.customercompanyactivity,
    settingsArr: globalSettings,
    activitycount: state.activitycount,
    customerdetail: state.customerdetail,
    promotionlist: state.promotionlist,

    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    updateCartResponse: updateCartResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  
      getGlobalSettings: (login_type) => {
       dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
      },
      getActivityCount: (getObject, login_type) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject, login_type});
      }, 
      getCustomerCompanyActivity: (customerParam) => {
      dispatch({ type: GET_CUSTOMER_COMPANY_ACTIVITY, customerParam });
      },
      getCartDetail: (login_type) => {
      dispatch({ type: GET_CART_DETAIL, login_type });
      },
      updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId,login_type) => {
        dispatch({
          type: UPDATE_CART_DETAIL,
          productId,
          cartItemId,
          cartQty,
          orderVoucherId,
          login_type
        });
      },
      deleteCartDetail: (cartItemId, login_type) => {
        dispatch({ type: DELETE_CART_DETAIL, cartItemId, login_type });
      },
      destroyCartDetail: (login_type) => {
        dispatch({ type: DESTROY_CART_DETAIL, login_type });
      },
      getCustomerDetail: (params, login_type) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params, login_type });
      },
      getPromotionList: (customerParam, login_type) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam, login_type });
      },
  };
};


Merchantvouchercheckout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchantvouchercheckout)
);
