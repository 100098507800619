import { SET_MEMBERSHIP_BENIFITS } from '../actions';

const tierbenifit = (state = [], action) => {
  switch (action.type) {
    case SET_MEMBERSHIP_BENIFITS:
      return [...action.value];
    default: return state;
  }
}

export default tierbenifit;