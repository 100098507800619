/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cookie from "react-cookies";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import PhoneInput from "react-phone-input-2";
import "../../common/css/countryflag.css";

import { appId, apiUrl } from "../Helpers/Config";
import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";

import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";

var Parser = require("html-react-parser");
var qs = require("qs");


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET_GLOBAL_SETTINGS, } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class Customerlogin extends Component {
  constructor(props) {

    if (cookie.load("UserId") !== undefined) {
        props.history.push("/");
    }


    let pathname = props.location.pathname;
    let uriparms_one, uriparms_two = "";
    pathname = pathname.split('/');
    uriparms_one = pathname[1];
    uriparms_two = pathname[2];

    ///cookie.save("outlet_slug_name", uriparms_one, { path: "/" });
    const outlet_slug_name =
    reactLocalStorage.get("outlet_slug_name") !== "" &&
    typeof reactLocalStorage.get("outlet_slug_name") !== undefined &&
    typeof reactLocalStorage.get("outlet_slug_name") !== "undefined"
      ? reactLocalStorage.get("outlet_slug_name")
      : "";

    super(props);

    this.state = { customermobile_number: uriparms_two,
                   country : "",
                   customermobile_number_error: false,
                   outlet_slug_name: outlet_slug_name, 
                   customer_otp: "", 
                   invalid_customer_error: "",
                   openOtpinput:true,
                   code_digit1: "",
                   code_digit2: "",
                   code_digit3: "",
                   code_digit4: "",
                   code_digit5: "",
                   code_digit6: "",
                   invalid_otp_error: "",
                   country_code: "",


                 };

    const login_type = "customer";
    this.props.getSettings(login_type);

  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    

  }
  sateValChange = (field, value) => {};



  handleTextChange_getStarted(event) {

    var name = event.target.name;
    var value = event.target.value;

    this.setState({ [name]: value });
  }

  mobileNumber(type){

    var customermobile_number = this.state.customermobile_number;
    customermobile_number =  customermobile_number.slice(2);
    var customer_otp = this.state.customer_otp;

    if(type == "verification" && customermobile_number !==""){
       if (
        this.state.code_digit1 !== "" ||
        this.state.code_digit2 !== "" ||
        this.state.code_digit3 !== "" ||
        this.state.code_digit4 !== "" ||
        this.state.code_digit5 !== "" ||
        this.state.code_digit6 !== ""
      ) {
        var enterOTP =
            this.state.code_digit1 +
            this.state.code_digit2 +
            this.state.code_digit3 +
            this.state.code_digit4 +
            this.state.code_digit5 +
            this.state.code_digit6;

        var postObject = {
          verify_type: "register_loyality",
          //app_id: appId,
          customer_phone: customermobile_number,
          customer_otp_val: enterOTP,
        };
        axios.post(apiUrl + "chopchop/customer/customer_otp_verification", qs.stringify(postObject)).then((res) => {
          if(res.data.status === "ok") {
              //cookie.save("register_customer_mobile_number", this.state.customermobile_number);
              reactLocalStorage.set("register_customer_mobile_number", this.state.customermobile_number);
              this.setState({ invalid_otp_error:''});
              this.props.history.push("/customerregister");
          } else {
            this.setState({ invalid_otp_error: res.data.message});
          }
        });
      }
    }
  }

   handleVerificationCode(first, last) {

    var name = last.target.name;
    var value = last.target.value;

    if(value !== ''){

    var element_number = parseInt(first) + 1;


    if(first == 2 && $("#code_digit1").val() ==""){
      return false;
    }


    if(first == 3 && $("#code_digit2").val() ==""){
      return false;
    }

    if(first == 4 && $("#code_digit3").val() ==""){
      return false;
    }

    if(first == 5 && $("#code_digit4").val() ==""){
      return false;
    }


    if(first == 6 && $("#code_digit5").val() ==""){
      return false;
    }



    this.setState({ [name]: last.target.value}, function () {
    
    if(element_number <= 6){
    var element_id = "code_digit" + element_number;
    document.getElementById(element_id).focus();
     }
   });

  }else{
    this.setState({ [name]: last.target.value});
  }

  }

 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="customer_mainlogin">
        <div className="container">
          <div className="login_main_div">
           <h1>Welcome to <span>Loyalty Reward System</span></h1>
            <h6>Login and register an account to find all your best deals here.  </h6>
            <div className="login_form">
              <form>

               {this.state.openOtpinput === true && (

                <label>Phone number verification</label>

                )}

                  {/*<PhoneInput
                    country={"sg"}
                    value={this.state.customermobile_number}
                    countryCodeEditable={false}
                    onChange={(customermobile_number, country) =>
                    this.setState({
                        customermobile_number,
                    }) }
                    disabled
                  />



                <label class="control-label"> Your Mobile Number</label>
                <input type="tel" name="customermobile_number" placeholder="Enter your mobile number" maxLength="8" value={this.state.customermobile_number} onChange={this.handleTextChange_getStarted.bind(this)} onKeyPress={(e) => this.validateIntval(e)} />*/}


                {this.state.customermobile_number_error === true && (
                  <span className="error">This field is required</span>
                )}

                {this.state.invalid_customer_error !== '' && (
                  <span className="error">{this.state.invalid_customer_error}</span>
                )}

                {this.state.openOtpinput === true && (
                  <>
                  <PhoneInput
                    country={"sg"}
                    value={this.state.customermobile_number}
                    countryCodeEditable={false}
                    onChange={(customermobile_number, country) =>
                    this.setState({
                        customermobile_number,
                    }) }
                    disabled
                  />

                  <div className="verification-code-div">
                    <div className="verification-code">
                      <div className="prompt">Please enter verification code <br/>we sent to your mobile no.</div>
                      <div className="prompt-box verification-code-row">
                        <input type="number" className="input-box-small" id= "code_digit1" name="code_digit1" maxLength="1" onChange={this.handleVerificationCode.bind(this, '1')} 
                        value={this.state.code_digit1} pattern="[0-9]*" inputmode="numeric" />
                        <input type="number" className="input-box-small" id= "code_digit2" name="code_digit2" maxLength="1" onChange={this.handleVerificationCode.bind(this, '2')}
                          value={this.state.code_digit2} pattern="[0-9]*" inputmode="numeric" />
                        <input type="number" className="input-box-small" id= "code_digit3" name="code_digit3" maxLength="1" onChange={this.handleVerificationCode.bind(this, '3')}
                        value={this.state.code_digit3} pattern="[0-9]*" inputmode="numeric" />
                        <input type="number" className="input-box-small" id= "code_digit4" name="code_digit4" maxLength="1" onChange={this.handleVerificationCode.bind(this, '4')}
                        value={this.state.code_digit4} pattern="[0-9]*" inputmode="numeric" />
                        <input type="number" className="input-box-small" id= "code_digit5" name="code_digit5" maxLength="1" onChange={this.handleVerificationCode.bind(this, '5')}
                        value={this.state.code_digit5} pattern="[0-9]*" inputmode="numeric" />
                        <input type="number" className="input-box-small" id= "code_digit6" name="code_digit6" maxLength="1" onChange={this.handleVerificationCode.bind(this, '6')}
                        value={this.state.code_digit6} pattern="[0-9]*" inputmode="numeric" />
                      </div>
                    </div>
                  </div>
                  </>
                )}

               {this.state.invalid_otp_error !== '' && (
                  <span className="error">{this.state.invalid_otp_error}</span>
               )}

              </form>

            </div>
            {/*{this.state.openOtpinput === false && (
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.mobileNumber.bind(this, 'otp')}>NEXT</button>
            </div>
            )}*/}

              {this.state.openOtpinput === true && (
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.mobileNumber.bind(this, "verification")}>NEXT</button>
            </div>
            )}

          </div>
        </div>
       </div>
        <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: (login_type) => {
      dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
    },

  };
};

Customerlogin.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customerlogin));
