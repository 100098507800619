
/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MENU_NAVIGATION,
  SET_MENU_NAVIGATION,
  GET_PRODUCT_LIST,
  SET_PRODUCT,
} from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import {reactLocalStorage} from 'reactjs-localstorage';

export const watchGetMenuNavigation = function* () {
  yield takeEvery(GET_MENU_NAVIGATION, workerGetMenuNavigation);
};

export const watchGetProducts = function* () {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProducts);
};

function* workerGetMenuNavigation({login_type}) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("merchant_outlet_id") === undefined ||
      cookie.load("merchant_outlet_id") === "" ||
      cookie.load("merchant_outlet_id") === "0" ||
      cookie.load("merchant_outlet_id") === "null"
        ? ""
        : cookie.load("merchant_outlet_id");

     var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id")
      : '';
     

    const uri =
      apiUrlV2 +
      "products/getMenuNavigation?app_id=" +
      app_id +
      /*"&availability=" +
      availabilityId +*/
      "&outletId=" +
      orderOutletId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    if (result.data.status == "ok") {
      cookie.save("firstNavigation", result.data.result_set[0].pro_cate_slug, {
        path: "/",
      });
    }
    yield put({ type: SET_MENU_NAVIGATION, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}

function* workerGetProducts({ catslug, subcatslug, outletid, login_type }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId = cookie.load("merchant_outlet_id") === undefined || cookie.load("merchant_outlet_id") == "" ? "" : cookie.load("merchant_outlet_id");

    var app_id = "";

    if(login_type === "Merchant"){
      
     app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id")
      : '';

    }else{

      app_id = reactLocalStorage.get("app_id") !== "" &&
        typeof reactLocalStorage.get("app_id") !== undefined &&
        typeof reactLocalStorage.get("app_id") !== "undefined"
          ? reactLocalStorage.get("app_id")
          : "";
    }

    const uri =
      apiUrlV2 +
      "products/getAllProducts?app_id=" +
      app_id +
      "&category_slug=" +
      catslug +
      "&subcate_slug=" +
      subcatslug +
      "&outletId=" +
      orderOutletId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}
