/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { mobile } = props.fields;

  return {
    fields: ["mobile"],

    validations: {
      mobile: [[isEmpty, mobile]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }


  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgEmail, errMsgPin;

    if ($validation.mobile.error.reason !== undefined) {
      errMsgEmail = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Mobile Number</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.mobile}
              onKeyPress={(e) => this.validateIntval(e)}
              maxLength="8"
              {...$field("mobile", (e) => onChange("mobile", e.target.value))}
            />
            {errMsgEmail}
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="button btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
