/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import CurrencyInput from 'react-currency-input';
import {reactLocalStorage} from 'reactjs-localstorage';

import { Link, withRouter, Route } from "react-router-dom";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  getCalculatedAmount_rewards,
  showAlert,
  showCustomAlert,
  showPrices,
  showPoint, showLoader, hideLoader, getPromoCkValue, removePromoCkValue, removeVoucherPromoCkValue } from "../Helpers/SettingHelper";

import { appId, apiUrl, stripeImage, companyName} from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";
import Merchantdashboard from "./Merchantdtopheader";

import { connect } from "react-redux";
import cookie from "react-cookies";
import Moment from "moment";

import { GET_GLOBAL_SETTINGS, GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL, GET_PROMOTIONLIST } from "../../actions";

import locationImg from "../../common/images/celabrate_red.png";
import productoneImg from "../../common/images/promofour.png";
import productwoImg from "../../common/images/promotwo.png";
import producthreeImg from "../../common/images/promofour.png";
import productfourImg from "../../common/images/promothree.png";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import phoneImg from "../../common/images/phone.svg";
import awardabtImg from "../../common/images/award_white.svg";
import paymentImg from "../../common/images/pay_store.png";
import paymentstoreImg from "../../common/images/paynow.png";
import percentageImg from "../../common/images/percentage.svg";
import backImg from "../../common/images/back.png";
import arrowdownImg from "../../common/images/arrow-down.png";
import logoImg from "../../common/images/logo.png";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";

var Parser = require("html-react-parser");
var qs = require("qs");
var startTimePay;
var base64 = require("base-64");

class Merchantrewards extends Component {
  constructor(props) {
    super(props);

    //cookie.remove("reedemPointVal", { path: "/" });

    var getPromoCkVal = getPromoCkValue();
    
    this.state = {
      loyalty_amount_value : cookie.load("subtotal_value"),
      subtotal_value: cookie.load("subtotal_value"),
      total_amount_value: '',
      reward_points_amount: getPromoCkVal["reedemPointVal"],
      customercompanyactivitylist: [],
      promotionListArr: [],
      recentVisitlistData: [],
      vistCount: 0,
      last_vist_date: "",
      omisePayNowResponse: [],
      globalSettings: [],
      paymentmodevalue: 'payCash',
      reward_ponits: 0,
      promotionPercentage: 0,
      error_promocode: false,
      calc_kakis_perc_display: '',
      customer_membership_type: '',
      promo_code_val: getPromoCkVal["promoCodeVal"],
      promotion_applied: getPromoCkVal["promotionApplied"],
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: getPromoCkVal["promotionAmount"],
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],
      used_reward_point: getPromoCkVal["usedPoints"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
    };

     if (cookie.load("CustomerUserId") == "" || cookie.load("CustomerUserId") == undefined) {
      props.history.push("/merchantlogin");
    }

    const login_type = "Merchant";
    this.props.getGlobalSettings(login_type);
    $.magnificPopup.close();

  }
 
  componentDidMount() {

    $("#dvLoading").fadeIn();

    $("html, body").animate({ scrollTop: 0 }, 800);

    const inputKeys = ["reward", "overall_orders"];
    const login_type = "Merchant";

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var customer_user_id = cookie.load("CustomerUserId");

    this.checkOutAuthentication();
    var calculatedAmount = getCalculatedAmount_rewards(
      this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val,this.state.promotion_amount
    );
    var params = "&status=A&refrence=" + customer_user_id;
    this.props.getCustomerDetail(params, login_type);
  }

  checkOutAuthentication(){

    var calculatedAmount = getCalculatedAmount_rewards(
    this.state.subtotal_value, this.state.total_amount_value,this.state.reward_point_val,this.state.promotion_amount
    );  
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }
    }

   if (nextProps.customercompanyactivitylist !== this.props.customercompanyactivitylist) {
    if(Object.keys(nextProps.customercompanyactivitylist).length > 0 && nextProps.customercompanyactivitylist !==undefined){
      if (nextProps.customercompanyactivitylist[0].status === "ok") {

       this.setState({customercompanyactivitylist: nextProps.customercompanyactivitylist[0].result_set,
        vistCount:nextProps.customercompanyactivitylist[0].total_vist, last_vist_date: nextProps.customercompanyactivitylist[0].last_vist_date},function(){
        
        $("#dvLoading").fadeOut();

      });

     }

    }
    
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {

      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(4000);
        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",
             },
        });
      }
    }

   }


  handleTextChange(event) {

    if (event.target.name === "loyalty_amount_value") {
      // this.setState({ loyalty_amount_value: event.target.value });
      // var loyalty_amount_value = event.target.value !== "" ? parseFloat(event.target.value) : 0;
      // var subtotal_value = loyalty_amount_value.toFixed(2);
      // this.setState({ subtotal_value: subtotal_value });
     }else if (event.target.name === "reward_points_amount") {
      this.setState({ reward_points_amount: event.target.value });
    } else if (event.target.name === "promocode_points_amount") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_unit_no1") {
      this.setState({ billunitnumber1: event.target.value });
    } else if (event.target.name === "bill_unit_no2") {
      this.setState({ billunitnumber2: event.target.value });
    } else if (event.target.name === "recipient_name") {
      this.setState({ recipient_name: event.target.value });
    } else if (event.target.name === "recipient_contact_no") {
      this.setState({ recipient_contact_no: event.target.value });
    } else if (event.target.name === "gift_message") {
      this.setState({ gift_message: event.target.value });
    } else if (event.target.name === "wallet_amount") {
      this.setState({ corporate_customer_wallet_input: event.target.value });
    }

  }


  choosePayment(paymentMode) {
    if (paymentMode === "payCash") {
      this.setState({ paymentmodevalue: "payCash" });
    } else if (paymentMode === "OmisePayNow") {
      this.setState({ paymentmodevalue: "OmisePayNow" });
    } else if (paymentMode === "OmisePayNowChain") {
      this.setState({ paymentmodevalue: "OmisePayNowChain" });
    }
  }

  orderPayValidaion(payGetWayType, event) {
    event.preventDefault();
    if (payGetWayType === "OmisePayNow") {
      this.createOmiseSource();
    } else if (payGetWayType === "OmisePayNowChain") {
      this.createOmiseChainSource();
    }
  }

  payCash(event){
  event.preventDefault();
  this.postOrder(1);

  }

  /* Omise PayNow Start */
  createOmiseSource() {
    if (
      this.state.globalSettings.omise_paynow_public !== "" &&
      typeof this.state.globalSettings.omise_paynow_public !== undefined &&
      typeof this.state.globalSettings.omise_paynow_public !== "undefined"
    ) {
      var payNowID = base64.decode(
        this.state.globalSettings.omise_paynow_public
      );
      Omise.setPublicKey(payNowID);

      /*var promoTionArr = Array();
      promoTionArr["promotionApplied"] = this.state.promotion_applied;
      promoTionArr["promotionAmount"] = this.state.promotion_amount;
      promoTionArr["promoIsDelivery"] = this.state.promoIs_delivery;
      var cartDetailsArr = this.state.cartDetails;*/

      var calculatedAmount = getCalculatedAmount_rewards(
        this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val, this.state.promotion_amount);

      showLoader("omisepaynow_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

paynowCapture(sourceID, amount) {
    if (sourceID !== "") {


     var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var omisePostObject = {};
      var currentThis = this;
      omisePostObject = {
        app_id: app_id,
        source_id: sourceID,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: cookie.load("merchant_id"),
        amount: amount,
        
      };
      axios
        .post(apiUrl + "paymentv1/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          console.log(res,"res");
          hideLoader("omisepaynow_btn", "Idtext");
          if (res.data.status === "ok") {
            console.log(res.data.status, "res.data.status");
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowPamentStatus(startTimePay);
                    }
                    currentThis.omisePaynowTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};
  
     var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

     var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null
       ? cookie.load("merchant_outlet_id") : "";


      statusObj = {
        app_id: app_id,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: outlet_id,
        capture_id: this.state.omisePayNowResponse.captureId,
      };
      axios
        .post(apiUrl + "paymentv1/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(2);
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }
  /* Omise PayNow End */

  paymentFail(header, message) {
    $(".warining_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec warining_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:void(0);" onClick="' +
        this.pageReload() +
        '"class="button btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>'
    );
  }


  /* Omise PayNow Chain Start */
  createOmiseChainSource() {

    if (
      this.state.globalSettings.client_enable_omise_chain_paynow !== "" &&
      this.state.globalSettings.client_enable_omise_chain_paynow == "1" &&
      typeof this.state.globalSettings.client_enable_omise_chain_paynow !==
        undefined &&
      typeof this.state.globalSettings.client_enable_omise_chain_paynow !==
        "undefined"
    ) {
      var payNowID = "";

      if (this.state.globalSettings.client_omise_chain_mode === "0") {
        payNowID = this.state.globalSettings.omise_main_acc.omise_public_test;
      } else {
        payNowID = this.state.globalSettings.omise_main_acc.omise_public_live;
      }
      Omise.setPublicKey(payNowID);

      var calculatedAmount = getCalculatedAmount_rewards(
        this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val, this.state.promotion_amount);

      showLoader("omisepaynowchain_btn", "Idtext");
      var payAmount =
        parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2) * 100;
      var currentThis = this;
      Omise.createSource(
        "paynow",
        {
          amount: parseInt(payAmount),
          currency: "SGD",
        },
        function (statusCode, response) {
          if (response.id !== "" && response.id !== null) {
            currentThis.paynowChainCapture(response.id, response.amount);
          } else {
            hideLoader("omisepaynow_btn", "Idtext");
          }
        }
      );
    }
  }

paynowChainCapture(sourceID, amount) {
    if (sourceID !== "") {
      var omisePostObject = {};

      var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null
       ? cookie.load("merchant_outlet_id") : "";

      var currentThis = this;
      omisePostObject = {
        app_id: app_id,
        source_id: sourceID,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: outlet_id,
        amount: amount,
      };
      axios
        .post(apiUrl + "omisepay/paynowCapture", qs.stringify(omisePostObject))
        .then((res) => {
          hideLoader("omisepaynowchain_btn", "Idtext");
          if (res.data.status === "ok") {
            this.setState(
              {
                omisePayNowResponse: res.data.result_set,
                omisepaynowAmount: amount / 100,
                omisePaynowTime: 600,
              },
              function () {
                $.magnificPopup.open({
                  items: {
                    src: "#pay-omisepaynowconf-popup",
                  },
                  type: "inline",
                  midClick: true,
                  closeOnBgClick: false,
                  callbacks: {
                    close: function () {
                      clearInterval(startTimePay);
                    },
                  },
                });
                this.omisePaynowChainTimeReduce();
                startTimePay = setInterval(function () {
                  var modTime = currentThis.state.omisePaynowTime % 3;
                  if (currentThis.state.omisePaynowTime > 1) {
                    if (modTime === 0) {
                      currentThis.checkOmisePayNowChainPamentStatus(
                        startTimePay
                      );
                    }
                    currentThis.omisePaynowChainTimeReduce();
                  } else {
                    clearInterval(startTimePay);
                    $.magnificPopup.close();
                    currentThis.paymentFail(
                      "Error",
                      "Error code: 1002 Oops! Unable to processing your order. Please try again."
                    );
                    $.magnificPopup.open({
                      items: {
                        src: ".warining_popup",
                      },
                      type: "inline",
                    });
                  }
                }, 1000);
              }
            );
          } else {
            var erromessage =
              res.data.message !== "" &&
              typeof res.data.message !== undefined &&
              typeof res.data.message !== "undefined"
                ? res.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  omisePaynowChainTimeReduce() {
    var omisePaynowTime = parseInt(this.state.omisePaynowTime) - 1;
    this.setState({ omisePaynowTime: omisePaynowTime });
  }

  checkOmisePayNowChainPamentStatus(startTimePay) {
    if (Object.keys(this.state.omisePayNowResponse).length > 0) {
      var statusObj = {};

      var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

      var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null
       ? cookie.load("merchant_outlet_id") : "";

      statusObj = {
        app_id: app_id,
        customer_id: cookie.load("CustomerUserId"),
        outlet_id: outlet_id,
        capture_id: this.state.omisePayNowResponse.captureId,
      };

      axios
        .post(apiUrl + "omisepay/checkPaynowStatus", qs.stringify(statusObj))
        .then((statusRes) => {
          if (statusRes.data.status === "ok") {
            if (statusRes.data.result_set.omise_paynow_status) {
              clearInterval(startTimePay);
              var captureID = statusRes.data.result_set.charge_id;

              this.setState(
                {
                  validateimage: tickImage,
                  payNowCaptureId: captureID,
                  omise_log_id: statusRes.data.result_set.omiseLogId,
                },
                function () {
                  this.postOrder(3);
                }.bind(this)
              );
            } else {
            }
          } else {
            clearInterval(startTimePay);
            var erromessage =
              statusRes.data.message !== "" &&
              typeof statusRes.data.message !== undefined &&
              typeof statusRes.data.message !== "undefined"
                ? statusRes.data.message
                : "Error code: 1001 Oops! Unable to processing your order. Please try again.";
            this.paymentFail("Error", erromessage);
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: ".warining_popup",
              },
              type: "inline",
            });
            return false;
          }
        });
    }
  }

  removePointsAndPromo() {

    removePromoCkValue();

    this.setState({
      //reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      //used_reward_point: 0
    });
  }

  postOrder(paymentMode){

   var calculatedAmount = getCalculatedAmount_rewards(
      this.state.subtotal_value, this.state.total_amount_value, this.state.reward_point_val, this.state.promotion_amount
    );
   var discount_applied = "No";


   var postObject = {};

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

   var outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';

   var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null 
      ? cookie.load("merchant_outlet_id") :  "";

    var enterd_redeem_point = this.state.reward_points_amount;

    if(enterd_redeem_point > 0){

      enterd_redeem_point =  parseFloat(this.state.reward_points_amount).toFixed(2);

    }else{

      enterd_redeem_point =  "0.00";
    }
    
    var promocode_points_amount = (this.state.promotion_amount>0)?parseFloat(this.state.promotion_amount).toFixed(2):"0.00";

    postObject = {
      app_id: app_id,
      sub_total: parseFloat(calculatedAmount["SubTotalAmount"]).toFixed(2),
      grand_total: parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2),
      customer_id: cookie.load("CustomerUserId"),
      customer_fname: cookie.load("CustomerUserFname"),
      customer_mobile_no: cookie.load("CustomerUserMobile"),
      customer_email: cookie.load("CustomerUserEmail"),
      payment_mode: paymentMode,
      outlet_slug_name: outlet_slug_name,
      outlet_id: outlet_id,
      order_source: "Web",
      discount_applied : discount_applied,
      redeem_point_amount : parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2),
      redeem_point : parseFloat(this.state.reward_points_amount).toFixed(2),
      reward_point_status: "Yes"
    };

    if(this.state.reward_point_val > 0) {
      postObject["redeem_applied"] = "Yes";
      postObject["redeem_amount"] = this.state.reward_point_val;
    }
    if(this.state.promotion_amount > 0) {
      postObject["promotion_applied"] = cookie.load("promotionApplied");
      postObject["promo_code"] = cookie.load("promoCodeVal");
      postObject["promotion_amount"] = parseFloat(calculatedAmount["promotionTotalAmount"]).toFixed(2);
      postObject["discount_type"] = "coupon";
    }else{
      postObject["discount_type"] = "redeem";
    }

    postObject["order_type"] = "reward_order";

    axios
      .post(
        apiUrl + "chopchop/loyalty_points/loyaltypointorders",
        qs.stringify(postObject)
      )
      .then((res) => {
        if (res.data.status === "ok") {

          this.handleShowAlertFun("success", res.data.message);
          this.setState({ loyalty_amount_value : '', subtotal_value: 0,  total_amount_value: '', reward_points_amount: "", used_reward_point: '', reward_point_val: ""});
          var localOrderNo = res.data.common.local_order_no;
          this.showSuccessPage(localOrderNo,res.data.common.order_primary_id);
          cookie.remove("reedemPointVal", { path: "/" });
          cookie.remove("subtotal_value", { path: "/" });
          cookie.save("reward_checkout", 'no', { path: "/" });
          cookie.save("voucher_checkout", 'no', { path: "/" });
          removePromoCkValue();
         // location.reload();
        }

      });
  }


 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }


  showSuccessPage(localOrderNo, orderPrimaryId) {
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    setTimeout(function () {
      $.magnificPopup.close();
      history.push("/merchant/thankyou/" + localOrderNo);
    }, 450);

  }



toggleChange(value) {
    console.log(value,"value");
    var reedemPointVal = this.state.reward_points_amount;
    var promoCodeVal = this.state.promoCodeVal;
    var usedPoints = "";
    var subTotal = this.state.subtotal_value;
    
   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

   if(reedemPointVal === 0 || reedemPointVal === ''){
      showCustomAlert("Error", "Please Enter Redeem Points");
      return false;
    }

   if(value == "rewardPoint"){

    var postObject = {
        app_id: app_id,
        reference_id: cookie.load("CustomerUserId"),
        redeem_point: reedemPointVal,
        cart_amount: subTotal,
      };

      axios.post(apiUrl + "chopchop/loyalty_points/apply_loyalityv1", qs.stringify(postObject))
        .then((res) => {

          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            usedPoints = res.data.result_set.points_amount;
            cookie.save("reedemPointVal", usedPoints, { path: "/" });
            cookie.save("usedPoints", usedPoints, { path: "/" });
            showCustomAlert("success", "Rewards Points applied successfully");

            this.setState({
              reward_point_val: usedPoints,
              used_reward_point: usedPoints,
            });

            var calculatedAmount = getCalculatedAmount_rewards(
               this.state.subtotal_value, this.state.total_amount_value,usedPoints); 

          } else {
            var pointDet = '';
            reedemPointVal = 0;
            usedPoints = 0;
            cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
            cookie.save("usedPoints", usedPoints, { path: "/" });
            showCustomAlert("error", res.data.message);

            this.setState({
              reward_point_val: reedemPointVal,
              used_reward_point: usedPoints,
              reward_points_amount: 0
            });

            var calculatedAmount = getCalculatedAmount_rewards(
               this.state.subtotal_value, this.state.total_amount_value,reedemPointVal); 
          }
        });
      }else{
        cookie.remove("reedemPointVal", { path: "/" });
        cookie.remove("usedPoints", { path: "/" });
        this.setState({
          reward_point_val: 0,
          used_reward_point: 0,
          reward_points_amount: ''
        });
      }
  }


  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;

    var outlet_id = cookie.load("merchant_outlet_id") !== "" &&  cookie.load("merchant_outlet_id") !== undefined && cookie.load("merchant_outlet_id") !== null
       ? cookie.load("merchant_outlet_id") : "";

    if (type === "points") {
      if (parseFloat(reedemPointVal) === 0 || reedemPointVal === "") {
        showAlert("Error", "Sorry!. Invalid Redeem Point.");
      }
    } else if (type === "promoCode") {
      if (promoCodeVal === "") {
        showCustomAlert("Error", "Please Apply Promocode");
        return false;
      }
    }

    if (type === "points") {
      if (reedemPointVal > 0) {
        showLoader("applypoints_cls", "class");
        var postObject = {
          app_id: appId,
          reference_id: cookie.load("UserId"),
          redeem_point: reedemPointVal,
          cart_amount: subTotal,
        };

        axios
          .post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "success") {
              var pointDet = res.data.result_set;
              promotionApplied = "Yes";
              promotionType = "points";
              promotionAmount = pointDet.points_amount;
              promotionSource = reedemPointVal;
              promoIsDelivery = "";
              promoCodeVal = "";
              usedPoints = reedemPointVal;
              showCustomAlert("success", "Rewards Points applied successfully");
            } else {
              if (promotionType === "points") {
                promotionApplied = "";
                promotionType = "";
                promotionAmount = "";
                promotionSource = "";
                promoIsDelivery = "";
                usedPoints = 0;
              }
              showCustomAlert("error", "Sorry! Did not applied Rewards Points");
            }

            hideLoader("applypoints_cls", "class");
            cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
            cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
            cookie.save("promotionApplied", promotionApplied, { path: "/" });
            cookie.save("promotionType", promotionType, { path: "/" });
            cookie.save("promotionAmount", promotionAmount, { path: "/" });
            cookie.save("promotionSource", promotionSource, { path: "/" });
            cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });
            cookie.save("usedPoints", usedPoints, { path: "/" });

            this.setState({
              reward_point_val: reedemPointVal,
              promo_code_val: promoCodeVal,
              promotion_applied: promotionApplied,
              promotion_type: promotionType,
              promotion_amount: promotionAmount,
              promotion_source: promotionSource,
              promoIs_delivery: promoIsDelivery,
              used_reward_point: usedPoints,
            });
          });
      }
    } else if (type === "promoCode") {
      showLoader("applypromo_cls", "class");

      var postObject = {
        app_id: cookie.load("merchant_app_id"),
        reference_id: cookie.load("CustomerUserId"),
        promo_code: this.state.promo_code_val,
        cart_amount: this.state.subtotal_value,
        outlet_id: outlet_id,
      };

      axios
        .post(
          apiUrl + "chopchop/promotion/apply_promotion",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            //reedemPointVal = "";
            //usedPoints = 0;
            showCustomAlert("success", "Promotion applied successfully");
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Did not applied promo code";
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            showCustomAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");
          cookie.save("reedemPointVal", reedemPointVal, { path: "/" });
          cookie.save("promoCodeVal", promoCodeVal, { path: "/" });
          cookie.save("promotionApplied", promotionApplied, { path: "/" });
          cookie.save("promotionType", promotionType, { path: "/" });
          cookie.save("promotionAmount", promotionAmount, { path: "/" });
          cookie.save("promotionSource", promotionSource, { path: "/" });
          cookie.save("promoIsDelivery", promoIsDelivery, { path: "/" });
          cookie.save("usedPoints", usedPoints, { path: "/" });

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
          });
        });
    }
  }

  logoutClientDetails(){
  
    cookie.remove("CustomerUserId", { path: "/" });
    cookie.remove("CustomerUserFname", { path: "/" });
    cookie.remove("CustomerUserLname", { path: "/" });
    cookie.remove("CustomerUserMobile", { path: "/" });
    cookie.remove("CustomerUserEmail", { path: "/" });

    var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : '';


    this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
  }

 
  handleChange = (event, maskedvalue, floatvalue) => {
    removePromoCkValue();
    removeVoucherPromoCkValue();
    cookie.remove('subtotal_value', {path:'/'});
    cookie.remove("reedemPointVal", { path: "/" });
    cookie.remove('cart_subtotal_value', {path:'/'});
    let checkoutVal = (floatvalue > 0) ? cookie.save('reward_checkout', 'yes', {path : '/'}): cookie.remove('reward_checkout', 'no', {path : '/'});
    cookie.save('subtotal_value', floatvalue.toFixed(2), {path : '/'});
    cookie.save('voucher_checkout', 'no', {path : '/'});
    this.setState({loyalty_amount_value: floatvalue, subtotal_value: floatvalue.toFixed(2), promo_code_val:'', promotion_applied: '', promotion_amount: '', reedemPointVal: '', reward_point_val: '', reward_points_amount: ''});
  }

  handlePromocodeChange(e){
    if(e.target.value === true){
      var currentdate = new Date();
      var datetime = currentdate.getFullYear() + "-"
                  + (((currentdate.getMonth()+1) < 10)?("0"+(currentdate.getMonth()+1)) : (currentdate.getMonth()+1))  + "-" 
                  + ((currentdate.getDate() < 10)?"0"+currentdate.getDate():currentdate.getDate()) + " "  
                  + ((currentdate.getHours() < 10)?"0"+currentdate.getHours():currentdate.getHours()) + ":"  
                  + ((currentdate.getMinutes() < 10)?"0"+currentdate.getMinutes():currentdate.getMinutes()) + ":" 
                  + currentdate.getSeconds();

      {Object.keys(this.state.promotionListArr).length > 0 && this.state.promotionListArr.my_promo.map(item => {
          if(item.promo_code === this.state.promoCodeVal && item.promotion_end_date >= datetime){
            this.setState({promotionPercentage : item.promotion_percentage, error_promocode : false });
          }else{
            this.setState({promotionPercentage : 0, error_promocode : true });
          }
        })
      }
    }else{
      this.setState({promotionPercentage : 0 });
    }
  }


  sateValChange = (field, value) => {};
  render() {
    
    var calculatedAmount = getCalculatedAmount_rewards(this.state.subtotal_value, 
    this.state.total_amount_value, this.state.reward_point_val,this.state.promotion_amount);
    var payAmount = parseFloat(calculatedAmount["grandTotalAmount"]).toFixed(2);
    var rewardPointAmount = parseFloat(calculatedAmount["RewardTotalAmount"]).toFixed(2);
    var promoCodeAmount = parseFloat(calculatedAmount["promotionTotalAmount"]).toFixed(2);

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
      
      {/*<section className="myacc_details"></section>*/}
       {/*<div className="myacc_main_div">*/}

        {/*<div className="merchent-mobile_outlet">
          <div className="merchent-mobile_innername">
            <p>bugis outlet</p>
            <div className="merchent-mobile-inbutton">
              <a href="#" className="button">switch</a>
            </div>
          </div>
        </div>*/}

        <div className="merchent_serving">
          {/*<ul className="merchent_servingul">                        
            <li className="merchent_dropdown">
              <a className="hordertype_btn" title="Serving">Serving</a>
                <ul className="serving_submenu-inner">
                 <li> 
                  <Link to={"/merchant/transactionhistory"} className="merchant_menu" title="Transaction History"><span>Transaction History</span></Link>
                </li>
                <li> 
                  <a href="/merchantlogout" className="merchant_menu" title="Logout"><span>Logout</span></a>
                </li>
               </ul>
            </li>
          </ul>*/}

           {cookie.load("CustomerUserId") && (
           <li className="hservingtype hservingnext">
              <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
          </li>)}         
              
        </div>

        <Merchantdashboard />
        
      <div className="container myacc_Dmain merchant_dash_contain">
      <div className="merchant-vistmain">
      </div>
      
      <div className="merchent-reward-main merchant-reward-whole">
        <h2>Checkout</h2>
        <div className="merchent-reward-inner">
          <div className="merchent-reward-lhs">
            <ul>
             <li>
              <p>enter amount</p>
              <CurrencyInput value={this.state.loyalty_amount_value} precision="2" decimalSeparator="." onChangeEvent={this.handleChange}/>
              </li>
              
              <li>
                <p>redeem points</p>
                <div className="redeem_points">
                  <input type="text" name="reward_points_amount" onChange={this.handleTextChange.bind(this)} value={this.state.reward_points_amount}  onKeyPress={(e) => this.validateIntval(e)}
                disabled={this.state.loyalty_amount_value > 0 ? "": "disabled" }/>
                
                  {this.state.reward_point_val > 0 ? (
                    <button
                      className="button rdm_submit"
                      onClick={this.toggleChange.bind(
                        this,
                        "removePoint"
                      )}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="button rdm_submit"
                      onClick={this.toggleChange.bind(
                        this,
                        "rewardPoint"
                      )}
                      disabled={this.state.loyalty_amount_value > 0 ? "": "disabled" }
                    >
                      Apply Now
                    </button>
                  )}


                </div>
              </li>

              <li>
                <p>redeem promo code</p>
                <div className="redeem_points">
                  <input type="text" name="promocode_points_amount" onChange={this.handleTextChange.bind(this)} value={this.state.promo_code_val} 
                 disabled={this.state.loyalty_amount_value > 0 ? "": "disabled" }/>
                 {this.state.promotion_applied === "Yes" &&
                  this.state.promotion_type === "promoCode" ? (
                    <button
                      className="button rdm_submit"
                      onClick={this.removePointsAndPromo.bind(
                        this
                      )}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      className="button rdm_submit"
                      onClick={this.applyPointsAndPromo.bind(
                        this,
                        "promoCode"
                      )}
                      disabled={this.state.loyalty_amount_value > 0 ? "": "disabled" }
                    >
                      Apply Now
                    </button>
                  )}
                </div>
              </li>
            </ul>
          </div>

          <div className="merchent-reward-rhs">
            
            <div className="cart_footer">
              <div className="cart_row">
                <p className="text-uppercase">SUBTOTAL</p>
                {this.state.subtotal_value> 0 ?
                <span>${this.state.subtotal_value}</span>
                : <span>$0.00</span>}
              </div>
              {rewardPointAmount > 0 && (
                <div className="cart_row">
                <p className="text-uppercase">points</p>
                <span>(- ${rewardPointAmount})</span>
              </div>
              )}
              {promoCodeAmount > 0 && (
              <div className="cart_row gst-row">
                <p className="text-uppercase">promo code</p>
                <span>(-${promoCodeAmount})</span>
              </div>
              )}
              <div className="cart_row grant-total-cls">
                <p className="text-uppercase">pay</p>
                <span>${payAmount}</span>
              </div>
            </div>

            <div className="chk-payment-col">
               <form>
                  <ul className="merchent-ul">
                     <li className={"merchent-bdiv "+((this.state.paymentmodevalue == "payCash") ? 'active' : '')} onClick={this.choosePayment.bind(
                                    this,
                                    "payCash"
                                  )}>
                        <a >
                          <div className="radio_con">
                          <img src={paymentImg} />
                        </div>
                        </a>
                     </li>

                     {/*<li className={"merchent-bdiv "+((this.state.paymentmodevalue == "OmisePayNow") ? 'active' : '')} onClick={this.choosePayment.bind(
                                    this,
                                    "OmisePayNow"
                                  )}>
                      <a >
                          <div className="radio_con">
                          <img src={paymentstoreImg} />
                        </div>
                        </a>
                     </li>*/}

                     {/*<li className={"merchent-bdiv "+((this.state.paymentmodevalue == "OmisePayNowChain") ? 'active' : '')} onClick={this.choosePayment.bind(
                                    this,
                                    "OmisePayNowChain"
                                  )}>
                      <a >
                          <div className="radio_con">
                          <img src={paymentstoreImg} />
                        </div>
                        </a>
                     </li>*/}

                  </ul>

                  {payAmount > 0 && this.state.paymentmodevalue == "payCash" && (
                  <div className="merchent-paybtn">
                    <a className="button" title="Confirm" onClick={this.payCash.bind(this)}>Confirm</a>
                  </div>
                  )}

                 {payAmount > 0 && this.state.paymentmodevalue == "OmisePayNow" && (
                  {/*<div className="merchent-paybtn">
                    <a className="button" title="Confirm" onClick={this.orderPayValidaion.bind(this,"OmisePayNow")}>Confirm</a>
                  </div>*/}
                  )}

                 {payAmount > 0 && this.state.paymentmodevalue == "OmisePayNowChain" && (
                  <div className="merchent-paybtn">
                    <a className="button" title="Confirm" onClick={this.orderPayValidaion.bind(this,"OmisePayNowChain")}>Confirm</a>
                  </div>
                  )}

               </form>
            </div>

          </div>

        </div>
      </div>

      {/*</div>*/}

    </div>{/* Container End */}
  
        {/*Payment omise Pay Now confirm popup Start*/}

      <div id="pay-omisepaynowconf-popup"
          className="white-popup mfp-hide popup_sec pay-omisepaynowconf-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="omise_pay_header">
                  <div className="omise-pay-title">
                    <h2>{companyName}</h2>
                  </div>
                  <div className="omise-pay-img">
                    <img className="makisan-logo-img" src={logoImg} />
                  </div>
                </div>
                {Object.keys(this.state.omisePayNowResponse).length > 0 && (
                  <div className="omise_pay_body">
                    <div className="omisepop_in">
                      <div className="omisepop_inner">
                        {this.state.omisePayNowResponse.download_uri !== "" && (
                        <div className="Qr_imgdiv">
                          <img
                            src={this.state.omisePayNowResponse.download_uri}
                            id="paynow-qr-image"
                          />
                          <span className="omise-Qrheding"> Scan the QR code to pay.</span>
                        </div>
                        )}
                        <h3 className="omise-paynow-amount-to-paid">
                          <span>
                            <sup>$</sup>{this.state.omisepaynowAmount.toFixed("2")}
                          </span>
                        </h3>
                        <p className="paynow-seconds">
                          <i className="fa fa-clock-o" aria-hidden="true"></i>
                          <span>&nbsp;{this.state.omisePaynowTime} sec</span>
                        </p>
                      </div>


                      <div className="paynow-notes">
                        <h4>Instructions:</h4>
                        <ul>
                          <li>
                            <img src={arrowdownImg} />
                            Use your bank app to scan QR Code and pay.
                          </li>
                          <li>
                            <img src={arrowdownImg} />
                            You will be paying to Omise Payment SG (Authorised
                            payment processor) for
                          </li>
                          <li>
                            <img src={arrowdownImg} />
                            Once you make the payment, payment status of the
                            order will reflect as 'paid', please do not make
                            multiple payments with the same QR Code.
                          </li>
                          <li>
                            <img src={arrowdownImg} />
                            Page will automatically refresh after your
                            successful payment.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*payment omise Pay Now confirm popup - End*/}   
        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
 
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }


  return {
    customercompanyactivitylist: state.customercompanyactivity,
     settingsArr: globalSettings,
     customerdetail: state.customerdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  
      getGlobalSettings: (login_type) => {
       dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
      },
      getCustomerDetail: (params, login_type) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params, login_type });
      }
  };
};


Merchantrewards.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchantrewards)
);
