import { takeEvery, call, put } from 'redux-saga/effects';
import {GET_LOYLATITY_OUTLET_DETAILS, SET_LOYLATITY_OUTLET_DETAILS } from '../actions';
import { apiUrl} from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";

export const watchLoyaltyGetAllOutlets = function* () {
  yield takeEvery(GET_LOYLATITY_OUTLET_DETAILS, workerLoyaltyGetAllOutlets);
}

function* workerLoyaltyGetAllOutlets({ availability, login_type }) {
  try {

   var outlet_slug_name = "";

   if (login_type === "Customer" && reactLocalStorage.get("outlet_slug_name") !== "" && typeof reactLocalStorage.get("outlet_slug_name") !== "undefined") {
      
       outlet_slug_name = reactLocalStorage.get("outlet_slug_name");

    }else{
      
       outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" && cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name")
      : ''; 

    }

  const uri = apiUrl+'chopchop/companydetails/getloyality_outlet?outlet_slug='+outlet_slug_name
  const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_LOYLATITY_OUTLET_DETAILS, value: resultArr });
  } 
  catch {
    console.log('Get All Outlets Failed');
  }
} 
