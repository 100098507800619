/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, withRouter, Route } from "react-router-dom";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId, apiUrl  } from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import Moment from "moment";
import validator from "validator";
import {reactLocalStorage} from 'reactjs-localstorage';
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import cookie from "react-cookies";

import { GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_LOYLATITY_CUSTOMER_DETAIL } from "../../actions";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";

import logoImg from "../../common/images/logo.png";
import awardorderImg from "../../common/images/award_gray.svg";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";
import aboutImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";
import noproductImg from "../../common/banner/noproduct.png";

var Parser = require("html-react-parser");

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      outlet_id: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
      currentPage: 1,
      displayPerPage: 4,
      customercompanyactivitylist: [],
      vistCount: 0,      
      reward_ponits: 0,
      loyaltycustomerdetail: [],
    };

     if (reactLocalStorage.get("LoyaltyUserId") == "" || reactLocalStorage.get("LoyaltyUserId") == undefined) {
      props.history.push("/");
    }

  }

  handleClick(event) {
    this.setState({currentPage: Number(event.target.id)});
  }


  componentDidMount() {

    $("#dvLoading").fadeIn();

    // var customerParam = "app_id="+ appId + "&customer_id=" + cookie.load("LoyaltyUserId") + "&login_type=customer"; 
    // this.props.getCustomerCompanyActivity(customerParam);

     this.getCustomerCompanyActivity();

  }

  handleShowAlertFun(header, msg) {

    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });

  }

  getCustomerCompanyActivity(){

      var params = "app_id="+ reactLocalStorage.get("app_id") + "&customer_id=" + reactLocalStorage.get("LoyaltyUserId") + "&login_type=customer";

       axios.get(apiUrl + "chopchop/customer/customer_company_histroy?" + params).then((res) => {

              if (res.data.status === "ok") {
              this.setState({
                customercompanyactivitylist:  res.data.result_set,
              });

            }else{

              $("#dvLoading").fadeOut(2000);
            }
       });

  }

  componentWillReceiveProps(nextProps) {

    $("#dvLoading").fadeOut(2000);

    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          $("#dvLoading").fadeOut(2000);
          this.setState({reward_ponits: nextProps.activitycount[0].result_set.reward_ponits});
        }
      }
    } else {
      this.setState({ reward_ponits: 0 });
      $("#dvLoading").fadeOut(2000);
    }

  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }

  openCompanyDetails(details){
    var company_name = details;
    if(company_name !==""){
      reactLocalStorage.remove("app_id");
      reactLocalStorage.set("app_id", details.client_app_id);
      var push_url = "/customer/profile/"+details.client_slug;
      //"/customer/profile/"+details.client_slug;
      this.props.history.push(push_url);
    }

  }


  sateValChange = (field, value) => {};
  render() {

    const { currentPage, displayPerPage } = this.state;
    const indexOfLast = currentPage * displayPerPage;
    const indexOfFirst = indexOfLast - displayPerPage;
    const currentDatas = this.state.customercompanyactivitylist.slice(indexOfFirst, indexOfLast);

    if (Object.keys(currentDatas).length > 0) {

       var display_recentlist = currentDatas.map((item, index)=>(
       
         <li className="myacc_product_li" key={index} onClick={this.openCompanyDetails.bind(this, item)}>
            <div className="myacc_product_imgdiv">
              <img src={item.client_image !== '' ? item.client_image : productoneImg} />
              <div className="inner_img_cotnt">
                <ul className="myacc_product_option">
                {item.availability.map((avail, availIndex)=>(
                  <li>
                    <a>{avail.availability_name}</a>
                  </li>
                  ))}
                </ul>
                <ul className="myacc_product_wish">
                  <li>
                    <a href="#"><img src={heartImg} /></a>
                  </li>
                </ul>
              </div>

              {/*<div className="dash_credit">
                <ul className="myacc_credit">
                  <li>
                    <a href="#">$24.<sup>00</sup></a><span>credit</span>
                  </li>
                </ul>
              </div>*/}

            </div>

            <div className="myacc_product_contntdiv">
              <h5>{item.client_name}</h5>
              <ul>
                <li className="">
                  <img src={produclockImg} />
                  Last visited : { item.activity_customer_updated_on !=='' ? Moment(item.activity_customer_updated_on).format("DD MMM YYYY") : Moment(new Date()).format("DD MMM YYYY")}
                </li>

                <li className="cust_promoli">
                  {/*<p>
                    <img src={producelabrateImg} />
                    Promotions : 3
                  </p>*/}
                   {/*<p className="loyality_para">
                    <img src={productawardImg} />
                    Loyalty : 3
                  </p>*/}
                  
                </li> 
              </ul>
            </div>
         </li>
        
        ));
    }
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.state.customercompanyactivitylist.length / displayPerPage); i++) {
      pageNumbers.push(i);
    }
    var renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
          key={number}
          id={number}
          className={"paginate "+((number==this.state.currentPage) ? "active " : "")}
          onClick={this.handleClick.bind(this)}
        >
          {number}
        </li>
      );
    });


  $("#dvLoading").fadeOut(2000);

        
    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
      
        <section className="myacc_details"></section>
          <div className="container myacc_Dmain customer_reward_main">

       <div className="myacc_main_div">

        <div className="myacc_info customer_Ndashboard">{/* Normal Dashboard start*/}
        <div className="myacc_info_lhs">
          <div className="welcome-title">
             <h1>
                <div><span>Hi ! </span>{reactLocalStorage.get("UserFname") + " " + reactLocalStorage.get("UserLname")} <img src={hiHndImage} /></div>
              </h1>
           </div>
            <p className="myacc_linkdesc">Welcome to ChopChop Rewards, you can check your loyalty and all the ongoing promotions here</p>

            <p className="myacc_linkpara">
                  <a href="/logout" title="SignOut">SignOut</a>
                </p>

          </div>



        <div className="myacc_info_rhs customer_Ndashboard_rsh">
          <div className="myacc_lhs_singletier">
            <div className="welcome-title">
              
                
            </div>
          </div>
          <div className="myacc_rhs_singletier">
            <p>cash back point balance</p>
            <h2>{parseFloat(this.state.reward_ponits || 0)}</h2>
            <div className="destop_acc_dbutton">
              <a className="button" title="Refer Now">view benefits</a>
            </div>
          </div>
        </div>
      </div>


      <div className="myacc_content_main destop_myacc_Dmain">
       <ul>
         <li className="myacc_locat_div">
           <img src={locationImg} />
           <h5>Recent visits</h5>
         </li>
         {/*<li>
           <a title="" className="button">view all</a>
         </li>*/}
       </ul>
      </div>

      <div className="myacc_product_main destop_myacc_Dmain">
      {renderPageNumbers.length > 0 && (

       <ul>
       {display_recentlist}
       </ul>

       )}

      {renderPageNumbers.length === 0 && (
      <ul className="noprodct-ul"> 
       <div className="noprodct-imgdiv">
          <img src={noproductImg} />
         </div>
        <div className="noprodct-content">
          <h5>No campaigns at the moment</h5>
          <p>When you use services. You can track and redeem everything here.</p>
        </div>
      </ul>
      )}
        
       {/* Pagination start*/}
       <ul id="page-numbers">
        {(renderPageNumbers.length > 8 ? renderPageNumbers : "" )}
       </ul>

      </div>
      
    </div>
          </div>

          <div className="container myacc_Dmain mobile_myacc_Dmain">

            <div className="myacc_product_main mobile-noprodct">
            {renderPageNumbers.length === 0 && (
              <ul className="noprodct-ul">
                <div className="noprodct-imgdiv">
                  <img src={noproductImg} />
                </div>
                <div className="noprodct-content">
                  <h5>No campaigns at the moment</h5>
                  <p>When you use services. You can track and redeem everything here.</p>
                </div>
              </ul>
              )}
            </div>


            <div className="myacc_product_main customrt_product_main">
            {renderPageNumbers.length > 0 && (
               <ul>
               {display_recentlist}
               </ul>
            )}


               <ul id="page-numbers">
                {(renderPageNumbers.length > 8 ? renderPageNumbers : "" )}
               </ul>
            </div>
            
          </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
    ///customercompanyactivitylist: state.customercompanyactivity,
    loyaltycustomerdetail: state.loyaltycustomerdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
     getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    }, 
    getCustomerDetail: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    }, 
  };
};

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
