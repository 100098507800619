/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { stripslashes, getTimeFrmDate } from "../Helpers/SettingHelper";

import {
  appId,
  apiUrl,
  apiUrlV2,
  timThumpUrl,
  deliveryId,
  mediaUrl,
  pickupId,
} from "../Helpers/Config";

var Parser = require("html-react-parser");

import update from "immutability-helper";

import { validated } from "react-custom-validation";
import validator from "validator";

import axios from "axios";
import cookie from "react-cookies";

import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import noimage from "../../common/images/no-img-product.png";
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";

class Outlets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
    };
  }

  componentDidMount() {
    this.props.getAllOutlets(pickupId);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        });
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  tConvert(time) {
    if (
      time !== null &&
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      var hr = "";
      var timeArray = time.split(":");
      hr = timeArray[0];
      var ampm = " AM";
      if (timeArray[0] >= 12) {
        hr = Math.round(timeArray[0] - 12);
        ampm = " PM";
      }
      var result = hr + ":" + timeArray[1] + " " + ampm;
      return result;
    }
  }

  gotoContacus(outletId) {
    cookie.save("contusOutletId", outletId, { path: "/" });
    this.props.history.push("/contact-us");
  }

  callPhoneOptn(phoneTxt) {
    var resultTxt = "";
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }

  getOutletData = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item, index) => {
        return (
          <li key={index}>
            <div className="ourrest_row">
              <div className="ourrest_img">
                {item.outlet_image !== "" &&
                typeof item.outlet_image !== undefined &&
                typeof item.outlet_image !== "undefined" ? (
                  <img
                    src={mediaUrl + "outlet/" + item.outlet_image}
                    alt="Outlet Img"
                  />
                ) : (
                  <img className="media-object" src={noimage} />
                )}
              </div>
              {item.outlet_informations !== "" &&
                typeof item.outlet_informations !== undefined &&
                typeof item.outlet_informations !== "undefined" ? Parser(item.outlet_informations) : ""}
            </div>
          </li>
        );
      });
    }
  };

sateValChange = (field, value) => {};

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>Locations</h3>
          </div>
        </div>
        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <ul className="outletul">
                {this.getOutletData(this.state.outlets)}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Outlets.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Outlets));
