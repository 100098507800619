import React, { Component } from "react";
import cookie from "react-cookies";
import { removePromoCkValue, removeVoucherPromoCkValue } from "../Helpers/SettingHelper";

class MerchantLogout extends Component {

  componentWillMount() {

    var merchant =  cookie.load("merchant_login_type") !== "" &&  cookie.load("merchant_login_type") !== undefined &&  cookie.load("merchant_login_type") !== null
      ? cookie.load("merchant_login_type") : ''; 

     var merchant_outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined && cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : ''; 

      if(merchant === "merchant"){

        if(merchant_outlet_slug_name !=="" && merchant_outlet_slug_name !==undefined){

        cookie.remove("CustomerUserId", { path: "/" });
        cookie.remove("CustomerUserFname", { path: "/" });
        cookie.remove("CustomerUserLname", { path: "/" });
        cookie.remove("CustomerUserMobile", { path: "/" });
        cookie.remove("CustomerUserEmail", { path: "/" });
        cookie.remove("merchant_outlet_id", { path: "/" });
        cookie.remove('firstNavigation', { path: "/" });
        cookie.remove('subtotal_value', { path: "/" });
        cookie.remove('cart_subtotal_value', { path: "/" });
        cookie.remove('merchant_loaylty_userId');
        cookie.remove('merchant_userId');
        cookie.remove('merchant_id');
        removePromoCkValue();
        removeVoucherPromoCkValue();
        this.props.history.push("/merchantlogin");
        }else{

        cookie.remove("CustomerUserId", { path: "/" });
        cookie.remove("CustomerUserFname", { path: "/" });
        cookie.remove("CustomerUserLname", { path: "/" });
        cookie.remove("CustomerUserMobile", { path: "/" });
        cookie.remove("CustomerUserEmail", { path: "/" });
        cookie.remove("merchant_outlet_id", { path: "/" });
        cookie.remove('merchant_loaylty_userId');
        cookie.remove('merchant_userId');
        cookie.remove('merchant_app_id');
        cookie.remove('merchant_outlet_name');
        cookie.remove('merchant_id');
        this.props.history.push("/merchantlogin");

        }

      }else{

        cookie.remove("CustomerUserId", { path: "/" });
        cookie.remove("CustomerUserFname", { path: "/" });
        cookie.remove("CustomerUserLname", { path: "/" });
        cookie.remove("CustomerUserMobile", { path: "/" });
        cookie.remove("CustomerUserEmail", { path: "/" });
        cookie.remove("merchant_outlet_id", { path: "/" });
        cookie.remove('merchant_loaylty_userId');
        cookie.remove('merchant_userId');
        cookie.remove('merchant_id');
        this.props.history.push("/merchantlogin");

      }

    }

  
  render() {
    return <h1 className="loading-text">Logging out...</h1>;
  }
}

export default MerchantLogout;
