/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_REWARDEARNED, SET_REWARDEARNED} from '../actions';
import { apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';
import {reactLocalStorage} from 'reactjs-localstorage';

export const watchGetRewardEarned = function* () {
  yield takeEvery(GET_REWARDEARNED, workerGetRewardEarned);
}

function* workerGetRewardEarned({ params }) {
  try {
    const uri = apiUrl+'chopchop/loyalty_points/rewards_earnedlist?status=A&app_id='+ reactLocalStorage.get("app_id") +params;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_REWARDEARNED, value: resultArr });
  } 
  catch {
    console.log('Get reward earned Failed');
  }
} 

