/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import cookie from "react-cookies";
import { createHashHistory } from 'history'
import PhoneInput from "react-phone-input-2";
import "../../../common/css/countryflag.css";

import { appId, apiUrl, baseUrl} from "../../Helpers/Config";
import { GET_BANNER_LIST, GET_LOGINDATA } from "../../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
} from "../../Helpers/SettingHelper";
import bannerimage from "../../../common/banner/merchentbanner.jpg";

var Parser = require("html-react-parser");
var qs = require("qs");

class MerchantBanner extends Component {
  constructor(props) {

    let pathname = props.location.pathname;
    let uriparms_one, uriparms_two = "";
    pathname = pathname.split('/');
    uriparms_one = pathname[1];
    uriparms_two = pathname[2];

    cookie.save("merchant_outlet_slug_name", uriparms_one, {path:'/'});

    var outlet_slug_name = cookie.load("merchant_outlet_slug_name") !== "" && cookie.load("merchant_outlet_slug_name") !== undefined &&  cookie.load("merchant_outlet_slug_name") !== null
      ? cookie.load("merchant_outlet_slug_name") : ''; 

    super(props);
    this.state = {
      customer_phone: "",
      country_code: "",
      error_phonenumber: false,
      logindata: [],
      outlet_slug_name: outlet_slug_name,
      no_customer_true: false,
    }
    
  }

  componentWillMount() {
    
    this.props.getBannerList();
  }

    closepopup(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(PropsDt) {

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }


  }


  /* for login and facebook login*/
  doLogin(fbloginData) {

    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }
      cookie.save("CustomerUserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("CustomerUserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "CustomerUserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "CustomerUserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("CustomerUserMobile", mobileno, { path: "/" });
      cookie.load("merchant_login_type", 'merchant');

      var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';


      var postObjectDetails = {
        app_id: app_id,
        customer_id: fbloginData.result_set.customer_id,
        outlet_slug: this.state.outlet_slug_name,
       }

        axios.post(apiUrl + "chopchop/customer/update_customer_company_activity", qs.stringify(postObjectDetails))
        .then((res) => {
          if (res.data.status === "ok") {
             this.props.history.push("/merchant/dashboard");
             this.setState({ no_customer_true: false});
          } 
        });

    } else {

      showAlert("Error", fbloginData.message);
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });

      this.setState({ no_customer_true: true});

     //
    
    }
  }

  openRegistration(){

    window.open(baseUrl + this.state.outlet_slug_name +"/customer");
  }


  customerNumberSbumt(){

    var customer_phone = this.state.customer_phone.replace(this.state.country, "");

    if(customer_phone !=="" && customer_phone.length >= 8){

      var app_id = cookie.load("merchant_app_id") !== "" &&  cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : ''; 

      var qs = require("qs");
      var postObject = {
        app_id: app_id,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
      };
      this.props.getLoginData(qs.stringify(postObject));
      this.setState({error_phonenumber: false});

    }else {

      this.setState({error_phonenumber: true});

    }

  }

  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      dots: true,
    };

    return (
      <div className="home-banner merchant-banner">
              <div>
                 {/*<img src={bannerimage} alt="Banner" />*/}
                  <div className="container">
                  <div className="homebanner-content">
                  <h1>enjoy</h1>
                  <h3>rewards, promotions & vouchers</h3>
                    <div className="homebanner-content-inner">
                    <div className="form-label">Enter Customer Mobile No</div>
                      <form>
                       <PhoneInput
                        country={"sg"}
                        countryCodeEditable={false}
                        value={this.state.country_code+ this.state.customer_phone}
                        onChange={(customer_phone, country) =>
                          this.setState({
                              customer_phone,
                              country: country.dialCode,
                            })
                        }/>

                         {this.state.error_phonenumber === true && (
                           <span className="error banner_mbl_error">Please enter the fields</span>
                         )}

                         {this.state.no_customer_true === true && (
                          <div className="mobile_creat_link_main">
                          <p className="creat_link" onClick={this.openRegistration.bind(this)}>Create Account</p>
                          </div>
                         )}


                        <div className="banner-btn">
                          <button type="button" className="button delivery_submit_cls" onClick={ () => this.customerNumberSbumt() }>Search</button>
                        </div>

                        </form>

                        {this.state.no_customer_true === true && (
                          <div className="creat_link_main">
                          <p className="creat_link" onClick={this.openRegistration.bind(this)}>Create Account</p>
                          </div>
                        )}

                         {this.state.error_phonenumber === true && (
                          <span className="error home-phone"> Please enter the fields.</span>
                         )}

                      </div>
            

                    </div>
                  </div>
              </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
    logindata: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(MerchantBanner));
