/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_CUSTOMER_DETAIL, SET_CUSTOMER_DETAIL } from '../actions';
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from "reactjs-localstorage";

export const watchGetCustomerDetail = function* () {
  yield takeEvery(GET_CUSTOMER_DETAIL, workerGetCustomerDetail);
}

function* workerGetCustomerDetail({ params, login_type}) {

  try {

    var app_id = "";

    if(login_type === "Merchant"){
      
     app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id")
      : localStorage.getItem("merchant_app_id");

    if(app_id === "" || app_id === null){

      app_id = localStorage.getItem("merchant_app_id") !== "" && localStorage.getItem("merchant_app_id") !== undefined && localStorage.getItem("merchant_app_id") !== null
        ? localStorage.getItem("merchant_app_id")
        : '';
    }

    }else{

      app_id = reactLocalStorage.get("app_id") !== "" &&
        typeof reactLocalStorage.get("app_id") !== undefined &&
        typeof reactLocalStorage.get("app_id") !== "undefined"
          ? reactLocalStorage.get("app_id")
          :  cookie.load("app_id");
    }

  const uri = apiUrl+'chopchop/customer/customerdetail?app_id='+app_id+params;
  const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_CUSTOMER_DETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Customer Failed');
  }
} 

