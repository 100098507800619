/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId, apiUrl } from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";

import { GET_GLOBAL_SETTINGS, GET_ACTIVITYCOUNT, GET_REWARDEARNED, GET_REWARDREDEEM, GET_LOYLATITY_CUSTOMER_DETAIL, GET_CUSTOMER_DETAIL } from "../../actions";

import TierBenifits from "./TierBenifits";

import hiHndImage from "../../common/images/hand_color.png";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import awardImg from "../../common/images/award_red.svg";
import awardorderImg from "../../common/images/award_gray.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import aboutImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";

var Parser = require("html-react-parser");

class CustomerRewards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reward_ponits: 0,
      rewardearned: [],
      rewardredeem:[],
      status: "",
      status1: "",
      pointsEarned: [],
      pointsRedeemed: [],
      customerdetail:[],
      loyaltycustomerdetail:[],
      showearnitems:10,
      showredeemitems:10,
    };

    if (reactLocalStorage.get("LoyaltyUserId") == "" || reactLocalStorage.get("LoyaltyUserId") == undefined) {
      props.history.push("/");
    }

  }


  componentDidMount() {

    this.getActivityCounts();
    var params ="&customer_id=" + reactLocalStorage.get("UserId") + "&limit=" +this.state.showearnitems;
    var params_redeem ="&customer_id=" + reactLocalStorage.get("UserId") + "&limit=" +this.state.showredeemitems;
    this.props.getRewardEarned(params);
    this.props.getRewardRedeem(params_redeem);

    $("html, body").animate({ scrollTop: 0 }, 800);

    const login_type = "customer";
    this.props.getGlobalSettings(login_type);

    var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + reactLocalStorage.get("LoyaltyUserId");
    this.props.getCustomerDetailLoyalty(params);

    var customer_user_id =  reactLocalStorage.get("UserId") !== "" && reactLocalStorage.get("UserId") !== undefined && reactLocalStorage.get("UserId") !== null
    ? reactLocalStorage.get("UserId") : '';

    var customer_params = "&status=A&refrence=" + customer_user_id;
    this.props.getCustomerDetail(customer_params, login_type);
  }

  /* Get Redeem Points Count History Details */
   getActivityCounts() {
    const inputKeys = ["reward"];
    const login_type = "Customer";
    this.props.getActivityCount(JSON.stringify(inputKeys), login_type);
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.customerdetail !== this.props.customerdetail) {
      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(1000);
        if (nextProps.customerdetail[0].result_set !== "" && nextProps.customerdetail[0].result_set !== "undefined") {
          this.setState({customerdetail: nextProps.customerdetail[0].result_set},function(){
            var params ="&customer_id=" + nextProps.customerdetail[0].result_set.customer_id + "&limit=" +this.state.showearnitems;
            var params_redeem ="&customer_id=" + nextProps.customerdetail[0].result_set.customer_id + "&limit=" +this.state.showredeemitems;
            this.props.getRewardEarned(params);
            this.props.getRewardRedeem(params_redeem);  
          });
        }
      }
    }

   /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (nextProps.activitycount[0].status &&nextProps.activitycount[0].result_set) {
          this.setState({reward_ponits: nextProps.activitycount[0].result_set.reward_ponits});
        }
      }
    } else {
      this.setState({ reward_ponits: 0 });
    }
    /*activity count - end*/

    /* reward earned - start */
    if (nextProps.rewardearned !== this.props.rewardearned) {
      $("b").removeClass("gloading_img");
      $("#dvLoading").fadeOut(1000);
      if (nextProps.rewardearned[0].result_set !== "" && nextProps.rewardearned[0].result_set !== "undefined") {
        if (nextProps.rewardearned[0].status === "ok"){
        this.setState({ pointsEarned: nextProps.rewardearned[0].result_set, status: "ok"}, function(){
          this.loadRewardsEarned();
        });

         if(this.state.showearnitems >  nextProps.rewardearned[0].common.total_records ) {
            $(".load_more_data").hide();
          } else {
            $(".load_more_data").show();
          }

        }else {
                $(".load_more_data").hide();
              }

      } else {
        $(".load_more_data").hide();
      }
    }
    /* reward earned - end */

    /* reward redeem - start */
    if (nextProps.rewardredeem !== this.props.rewardredeem) {
      $("b").removeClass("gloading_img");
      $("#dvLoading").fadeOut(1000);
      if ( nextProps.rewardredeem[0].result_set !== "" && nextProps.rewardredeem[0].result_set !== "undefined" ) {
        if (nextProps.rewardredeem[0].status === "ok"){
        this.setState({ pointsRedeemed: nextProps.rewardredeem[0].result_set, status1: "ok" }, function(){
          this.loadRewardsRedeemed();
        });

        if ( this.state.showredeemitems > nextProps.rewardredeem[0].common.total_records) {
            $(".load_more_data1").hide();
          } else {
            $(".load_more_data1").show();
          }

      }
      } else {
        $(".load_more_data1").hide();
      }
    }
    /* reward redeem - end */
  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }


  sateValChange = (field, value) => {};


  /* for load more button -start */

  loadrewardearn() {
    var pageNext = this.state.showearnitems + 10;
    this.setState({ showearnitems: pageNext }, function () {
      this.loadereward();
    });
  }
  loadrewardredeem() {
    var pagepNext = this.state.showredeemitems + 10;
    this.setState({ showredeemitems: pagepNext }, function () {
      this.loadrreward();
    });
  }

  loadereward() {

    var customerDetails = this.state.customerdetail;

    $(".load_more_data").append('<b class="gloading_img"></b>');

      var rewardListEarned = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + customerDetails.customer_id + "&limit=" +this.state.showearnitems;
      axios.get(apiUrl + "chopchop/loyalty_points/rewards_earnedlist?" + rewardListEarned).then((res) => {
        
              if (res.data.status === "ok") {
               $("b").removeClass("gloading_img");
              this.setState({
                pointsEarned: res.data.result_set, status: "ok"}, function(){
                this.loadRewardsRedeemed();

           });

           
            if ( this.state.showearnitems > res.data.common.total_records ) {
                $(".load_more_data").show();
              } else {
                $(".load_more_data").hide();
              }
            } else {
              $(".load_more_data").hide();
            }

       });

  }


  loadrreward() {

    var customerDetails = this.state.customerdetail;

    $(".load_more_data1").append('<b class="gloading_img"></b>');
    
      var rewardListParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + 
                           customerDetails.customer_id + "&limit=" +this.state.showredeemitems;

      axios.get(apiUrl + "chopchop/loyalty_points/rewards_redeemedlist?" + rewardListParam).then((res) => {
              if (res.data.status === "ok") {
                 $("b").removeClass("gloading_img");
              this.setState({
                pointsRedeemed: res.data.result_set, status1: "ok"}, function(){
                this.loadRewardsEarned(); });

           
            if ( this.state.showredeemitems > res.data.common.total_records
              ) {
                $(".load_more_data1").show();
              } else {
                $(".load_more_data1").hide();
              }
            } else {
              $(".load_more_data1").hide();
            }

       });
  }




  loadRewardsEarned(){
   if (this.state.status === "ok") {
    return  this.state.pointsEarned.map((points, index) => (<div className="reward_main">
        <ul>
          <li className="reward_orderdiv">
            <img src={awardorderImg} />
           <div className="reward-mbl-para">
             <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
             <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
              <h5>{points.loyalty_pointorders_orderlocalno}</h5>
            </div>
          </li>

          <li className="reward_datediv">
            <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
            <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
          </li>

          <li className="reward_pointsdiv">
            <p>Points Earned</p>
            <h5>{points.loyalty_pointorders_total_amount}</h5>
          </li>
        </ul>
      </div> ));
   }else{

    return <div className="no-recrds-found">When you use our services. You can track and redeem everything here.</div>;
   }
 
  }


  loadRewardsRedeemed(){
   if (this.state.status1 === "ok") {
    return  this.state.pointsRedeemed.map((points, index) => (<div className="reward_main">
        <ul>
          <li className="reward_orderdiv">
            <img src={awardorderImg} />
           <div className="reward-mbl-para">
            <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
             <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
              <h5>{points.loyalty_pointorders_orderlocalno}</h5>
            </div>
          </li>

          <li className="reward_datediv">
           <p className="rewards_earned_outlet_name">{points.outlet_name}</p>
            <p>{Moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {Moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
          </li>

          <li className="reward_pointsdiv">
            <p>Points Earned</p>
            <h5>{points.loyalty_pointorders_total_amount}</h5>
          </li>
        </ul>
      </div> ));
   }else{

    return <div className="no-recrds-found">When you use our services. You can track and redeem everything here.</div>;
   }

  }

  render() {

  let customer_membership_type_display = "";
    let customer_unique_id = "";
    let calc_kakis_perc = 0;
    let calc_kakis_perc_display = "";
    let client_membership_type = "";
    let customer_membership_type = "";
    let customer_membership_tier_benefits = "";
    let membership_spent_msg = "";


    var settingsArr = this.props.globalsettings;
    var client_logo = "";
    var client_name = "";
    var client_slug  = "";

     if (settingsArr !=="" &&  settingsArr.length > 0 && settingsArr !== "undefined" && settingsArr !==null) {
         if (settingsArr[0].status ==="ok") {
        client_membership_type = settingsArr[0].result_set.client_membership_type;
        client_name = settingsArr[0].result_set.client_name;
        client_logo = settingsArr[0].result_set.client_logo;
        client_slug  = settingsArr[0].result_set.client_slug;
      }
    }


     if (this.state.customerdetail !=="" && this.state.customerdetail !==undefined && Object.keys(this.state.customerdetail).length > 0) {

      customer_membership_type = this.state.customerdetail.customer_membership_type;
      customer_unique_id = this.state.customerdetail.customer_unique_id;
      customer_membership_tier_benefits = this.state.customerdetail.customer_membership_tier_benefits;
      membership_spent_msg = this.state.customerdetail.membership_spent_msg; 

      let membership_max_amount = this.state.customerdetail.membership_max_amount;
      let membership_spent_amount = this.state.customerdetail.membership_spent_amount;
      
     if ( customer_membership_type !== "" && customer_membership_type !== "Normal") {
        customer_membership_type_display = customer_membership_type === "Normal" ? "Bronze" : customer_membership_type;
      }else{
        customer_membership_type_display = "Bronze";
      }


      if (parseInt(membership_max_amount)) {
          calc_kakis_perc = (membership_spent_amount / membership_max_amount) * 100;
      }

      if (customer_membership_type === "Normal" && membership_max_amount) {
        calc_kakis_perc_display = "SPENT $500 more to become Silver Member";
      }else{
        if(customer_membership_type === "Normal"){
          calc_kakis_perc_display = "$500 more to become Silver Member";
        }else if(customer_membership_type === "Silver"){
          calc_kakis_perc_display =  "more to become Gold Member";
        }else if(customer_membership_type === "Gold"){
         calc_kakis_perc_display = "Gold Member";
        }
           
      }

    }


    var settingsMyAcc = {
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 400,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
        <section className="myacc_details">
        </section>
          <div className="container myacc_Dmain">
      <div className="myacc_main_div">
      <div className="myacc_info">
        <div className="myacc_info_lhs">
          <div className="welcome-title">
          <h1><div><span>Hi ! </span>{reactLocalStorage.get('UserFname') !=="" ? reactLocalStorage.get('UserFname'): ""}
                               {reactLocalStorage.get('UserLname') !=="" ? reactLocalStorage.get('UserLname'): ""} 
                               <img src={hiHndImage} /></div></h1>
                               
            <p className="myacc_linkdesc">Welcome to ChopChop Rewards, you can check your loyalty and all the ongoing promotions here</p>
            <p className="myacc_linkpara"><a className="" href="/logout"  title="Refer Now">signout</a></p>
          </div>
        </div>

        <div className="myacc_info_rhs promotions_info_mem">
          <ul>
            <li>
              <h3>{customer_membership_type_display}</h3>
            </li>
            <li>
              <p>cash back point balance</p>
              <h3>{this.state.reward_ponits}</h3>
            </li>
          </ul>
          <div className="member-prog-bar">
            <div className="member-progress-2">
              <div className="member-bar-2 member-bar-width-2" style={{width: calc_kakis_perc + "%"}}></div>
            </div>
            <p className="member-help-block">{membership_spent_msg}</p>
          </div>
          
          <div className="destop_acc_dbutton">
            <TierBenifits memDisplayType={customer_membership_type_display} />
          </div>
        </div>
        <div className="mobile_acc_dbutton">
           <TierBenifits memDisplayType={customer_membership_type_display} />
        </div>
      </div>


      <div className="mobile_mnu_block">
        <Slider {...settingsMyAcc}>
            <div className="single_block active">
              <Link to={"/customer/rewards"}>
                <img className="normal_simg" src={awardorderImg} />
                <img className="hover_simg" src={awardorderhoverImg} />
                <span>my rewards</span>
              </Link>
            </div>

            <div className="single_block">
              <Link to={"/customer/promotions"}>
                <img className="normal_simg" src={celabrateImg} />
                <img className="hover_simg" src={celabratehoverImg} />
                <span>all promo</span>
              </Link>
            </div>

             <div className="single_block">
               <Link to={"/customer/vouchers/"}>
                <img className="normal_simg" src={percentageImg} />
                <img className="hover_simg" src={percentagehoverImg} />
                <span>e vouchers</span>
              </Link>
            </div>

            <div className="single_block">
               <Link to={"/customer/aboutus/"+client_slug}>
                <img className="normal_simg" src={aboutImg} />
                <img className="hover_simg" src={abouthoverImg} />
                <span>about us</span>
              </Link>
            </div>
        </Slider>
      </div>


        <div className="singletier_menumain">
            <ul>

              <li className="active">
               <Link to={"/customer/rewards"}>
                  <img className="normal_simg" src={awardorderImg} />
                  <img className="hover_simg" src={awardorderhoverImg} />
                  <span>my rewards</span>
                </Link>
              </li>

              <li>
                <Link to={"/customer/promotions"}>
                  <img className="normal_simg" src={celabrateImg} />
                  <img className="hover_simg" src={celabratehoverImg} />
                  <span>all promo</span>
                </Link>
              </li>

               <li>
                <Link to={"/customer/vouchers"}>
                  <img className="normal_simg" src={percentageImg} />
                  <img className="hover_simg" src={percentagehoverImg} />
                  <span>e vouchers</span>
                </Link>
              </li>

              <li>
                 <Link to={"/customer/aboutus/"+client_slug}>
                  <img className="normal_simg" src={aboutImg} />
                  <img className="hover_simg" src={abouthoverImg} />
                  <span>about us</span>
                </Link>
              </li>

            </ul>
          </div>

      <div className="myacc_content_main promotion_content_main rward-none">
       <ul>
         <li className="myacc_locat_div">
           <img src={awardImg} />
           <h5>My Rewards</h5>
         </li>
       </ul>
      </div>


        <div className="tab_sec main_tabsec_inner destop-reward-tab">
          <div className="myacc_filter">
            <div className="tab_sec filter_tabsec">
              <ul className="nav nav-tabs text-center destop-reward-ul">
                <li className="active">
                  <a
                    data-toggle="tab"
                    href="#tab-id-inn5"
                    aria-expanded="true"
                  >
                    <span>earned</span>
                  </a>
                </li>
                <li className="">
                  <a
                    data-toggle="tab"
                    href="#tab-id-inn6"
                    aria-expanded="false"
                  >
                    <span>redeemed</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  id="tab-id-inn5"
                  className="tab-pane fade active in"
                >
                  {/*<h4 className="tab_mobtrigger inner_tab_border active">
                  <i></i>
                  </h4>*/}
                  <div className="tab_mobrow filter_tabin">
                    <div className="order-delivery">
                      <div className="ord-body">
                        <div className="cur-order-body reward-list-body">
                          <div className="myacc_order_details">
                            {this.loadRewardsEarned()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="load_more_div">
                    <button
                      onClick={this.loadrewardearn.bind(this)}
                      className="load_more_data"
                      style={{ display: "none" }}
                    >
                      Load More
                    </button>
                  </div>
                </div>
                <div id="tab-id-inn6" className="tab-pane fade">
                {/*<h4 className="tab_mobtrigger inner_tab_border">
                  <i></i>
                  </h4>*/}
                  <div className="tab_mobrow filter_tabin">
                    <div className="cur-order-body reward-list-body">
                      <div className="myacc_order_details">
                        {this.loadRewardsRedeemed()}
                      </div>
                    </div>
                  </div>
                  <div className="load_more_div">
                    <button
                      className="load_more_data1"
                      style={{ display: "none" }}
                      onClick={this.loadrewardredeem.bind(this)}>
                      Load More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
       </div>

        <Footer />
        <div id="dvLoading"></div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {

  return {
    activitycount: state.activitycount,
    rewardearned: state.rewardearned,
    rewardredeem: state.rewardredeem,
    globalsettings: state.settings,
    customerdetail: state.customerdetail,
    loyaltycustomerdetail: state.loyaltycustomerdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   getCustomerDetailLoyalty: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    }, 
   getActivityCount: (getObject, login_type) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject, login_type});
    },
    getRewardEarned: (params) => {
      dispatch({ type: GET_REWARDEARNED, params });
    },
    getRewardRedeem: (params) => {
      dispatch({ type: GET_REWARDREDEEM, params });
    },
    getGlobalSettings: (login_type) => {
     dispatch({ type: GET_GLOBAL_SETTINGS, login_type });
    }, 
    getCustomerDetail: (params, login_type) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params, login_type });
    },     
  };
};

CustomerRewards.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerRewards)
);
