/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PROMOTIONLIST, SET_PROMOTIONLIST} from '../actions';
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';
import {reactLocalStorage} from 'reactjs-localstorage';

export const watchGetPromotionList = function* () {
  yield takeEvery(GET_PROMOTIONLIST, workerGetPromotionList);
}

function* workerGetPromotionList({ customerParam, login_type }) {
  try {

    var app_id = "";

    if(login_type === "Merchant"){
      
     app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id")
      : '';

    }else{

      app_id = reactLocalStorage.get("app_id") !== "" &&
        typeof reactLocalStorage.get("app_id") !== undefined &&
        typeof reactLocalStorage.get("app_id") !== "undefined"
          ? reactLocalStorage.get("app_id")
          : "";
    }

    const uri = apiUrl+'promotion_api_v2/promotionlistWhitoutuniqcode?app_id='+app_id+'&customer_id='+customerParam;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_PROMOTIONLIST, value: resultArr });
  } 
  catch {
    console.log('Get Promotion list Failed');
  }
} 


