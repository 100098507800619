/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_REWARDREDEEM, SET_REWARDREDEEM} from '../actions';
import { apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';
import { reactLocalStorage } from "reactjs-localstorage";

export const watchGetRewardRedeem = function* () {
  yield takeEvery(GET_REWARDREDEEM, workerGetRewardRedeem);
}

function* workerGetRewardRedeem({ params }) {
  try {

  var appId = localStorage.getItem("app_id") !== "" &&  localStorage.getItem("app_id") !== undefined &&  localStorage.getItem("app_id") !== null
        ? localStorage.getItem("app_id")
        : '';
  const uri = apiUrl+'chopchop/loyalty_points/rewards_redeemedlist?status=A&app_id='+appId+params;
  const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_REWARDREDEEM, value: resultArr });
  } 
  catch {
    console.log('Get reward redeem Failed');
  }
} 

