/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import { appId, apiUrl } from "../Helpers/Config";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from 'moment';
import axios from "axios";
import {reactLocalStorage} from 'reactjs-localstorage';

import { GET_PICKUP_OUTLETS, GET_CONTACTDATA, GET_PROMOTIONLIST, GET_LOYLATITY_CUSTOMER_DETAIL, 
  GET_CUSTOMER_DETAIL, GET_GLOBAL_SETTINGS } from "../../actions";

import TierBenifits from "./TierBenifits";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/celabrate_red.png";
import productoneImg from "../../common/images/promofour.png";
import productwoImg from "../../common/images/promotwo.png";
import producthreeImg from "../../common/images/promofour.png";
import productfourImg from "../../common/images/promothree.png";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import awardorderImg from "../../common/images/award_gray.svg";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import aboutImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";
import awardImg from "../../common/images/award_red.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";

var Parser = require("html-react-parser");

class Customerpromotions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlet_loaded: "no",
      outlets_result: [],
      outlets: [],
      promotionlist: [],
      loyaltycustomerdetail: [],
      customerdetail: [],
      img_source:"",
      outlet_id: "",
      fields: {
        name: "",
        mobile: "",
        subject: "",
        email: "",
        type: "",
        message: "",
      },
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
      promoCodeDesc: '',
      customerdetail:[],
      loyaltycustomerdetail:[],    
      client_slug:  this.props.match.params.client_slug !== "" && typeof this.props.match.params.client_slug !== undefined &&
                    typeof this.props.match.params.client_slug !== "undefined" ? this.props.match.params.client_slug : "",

      };

     if (reactLocalStorage.get("LoyaltyUserId") == "" || reactLocalStorage.get("LoyaltyUserId") == undefined) {
      props.history.push("/");
      }

  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleFormSubmit = () => {
    const formPayload = this.state.fields;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.name,
      customer_emailaddress: formPayload.email,
      customer_phonenumber: formPayload.mobile,
      subject: formPayload.type,
      customer_message: formPayload.message,
    };

    this.props.getContactData(qs.stringify(postObject));
  };

  componentDidMount() {
    /*this.props.getPickupOutlets();*/
    $("html, body").animate({ scrollTop: 0 }, 800);

    const login_type = "customer";
    this.props.getGlobalSettings(login_type);
    let customer_user_id = reactLocalStorage.get("UserId");
    var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + reactLocalStorage.get("LoyaltyUserId");
    this.props.getCustomerDetailLoyalty(params);
    this.props.getPromotionList(customer_user_id, login_type);
    var customer_params = "&status=A&refrence=" + reactLocalStorage.get("LoyaltyUserId");
    this.props.getCustomerDetail(customer_params, login_type);
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.loyaltycustomerdetail !== this.props.loyaltycustomerdetail) {
      if(Object.keys(nextProps.loyaltycustomerdetail).length > 0 && nextProps.loyaltycustomerdetail !==undefined){
        if (nextProps.loyaltycustomerdetail[0].status === "ok") {
          $("#dvLoading").fadeOut(1000);
          this.setState({loyaltycustomerdetail: nextProps.loyaltycustomerdetail[0].result_set, },function(){
            this.getCustomerDeatils(); });
        }
      }
    }
    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }

    if (nextProps.promotionlist !== this.props.promotionlist) {
      var promotionlistarr = [],
          promotionlistImage ="";
      if (nextProps.promotionlist[0].status === "ok") {
          promotionlistImage = nextProps.promotionlist[0].common;
        if (
          nextProps.promotionlist[0].status &&
          nextProps.promotionlist[0].result_set
        ) {
          promotionlistarr = nextProps.promotionlist[0].result_set.my_promo;
        }
      }
      this.setState({promotionlist: promotionlistarr, img_source:promotionlistImage});
    }


  }

  sateValChangefn(outlvalue) {
    this.setState({ outlet_id: outlvalue });
  }

  loadRewardsEarned(){
   if (this.state.status === "ok" && this.state.pointsEarned !=="" && Object.keys(this.state.pointsEarned).length > 0) {
    return  this.state.pointsEarned.map((points, index) => (<div className="reward_main">
        <ul>
          <li className="reward_orderdiv">
            <img src={awardorderImg} />
           <div className="reward-mbl-para">
              <h5>{points.loyalty_pointorders_orderlocalno}</h5>
            </div>
          </li>

          <li className="reward_datediv">
            <p>{moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
          </li>

          <li className="reward_pointsdiv">
            <p>Points Earned</p>
            <h5>{points.lh_credit_points}</h5>
          </li>
        </ul>
      </div> ));
   }else{

    return <div className="no-recrds-found">When you use our services. You can track and redeem everything here.</div>;
   }
 
  }



  loadRewardsRedeemed(){
   if (this.state.status1 === "ok" && this.state.pointsRedeemed !=="" && Object.keys(this.state.pointsRedeemed).length > 0) {
    return  this.state.pointsRedeemed.map((points, index) => (<div className="reward_main">
        <ul>
          <li className="reward_orderdiv">
            <img src={awardorderImg} />
           <div className="reward-mbl-para">
              <h5>{points.loyalty_pointorders_orderlocalno}</h5>
            </div>
          </li>

          <li className="reward_datediv">
            <p>{moment(points.loyalty_pointorders_created_on).format("DD/MM/YYYY")}<span> |</span> {moment(points.loyalty_pointorders_created_on).format("h:mm A")}</p>
          </li>

          <li className="reward_pointsdiv">
            <p>Points Earned</p>
            <h5>{points.loyalty_pointorders_total_amount}</h5>
          </li>
        </ul>
      </div> ));
   }else{

    return <div className="no-recrds-found">When you use our services. You can track and redeem everything here.</div>;
   }

  }

  displayDesktopPromo(){  
    var promtoion_list = "";  
    if(Object.keys(this.state.promotionlist).length > 0){  
      return this.state.promotionlist.map((item, index) => (  
        <ul className="promotion-list-ul">  
            <li className="promo_product_li" key={index}> 
          <div className="promo_product_imgdiv">  
            <img src={(item.promotion_image !=''&&item.promotion_image !=null)?this.state.img_source.promo_image_source+item.promotion_image:productwoImg} /> 
            <div className="inner_img_cotnt"> 
              <div className="inner_img_lhs"> 
                <ul>  
                  <li>  
                    valid till {moment(item.promotion_end_date).format("DD/MM/YYYY")} 
                  </li> 
                  <li>  
                    <h5>{item.promotion_title}</h5>   
                  </li> 
                </ul> 
              </div>  
              <div className="inner_img_rhs"> 
                <p><sup>$</sup> {item.promotion_percentage} off</p> 
              </div>  
            </div>  
          </div>  
          <div className="myacc_product_contntdiv"> 
            <div className="promo_button">  
              <a onClick={this.openVoucherDetail.bind(this, item.promo_desc)} className="button" title="Refer Now">view more</a> 
            </div>  
          </div>  
       </li></ul> 
    )); 
    }else{ 
    return <ul>No Promotions Found.</ul>; 
    }  
  } 
  displayMobilePromo(){ 
  if(Object.keys(this.state.promotionlist).length > 0){ 
   return this.state.promotionlist.map((item, index) => ( 
     <ul><li className="promo_product_li" key={index}>  
        <div className="promo_product_imgdiv">  
          <img src={(item.promotion_image !=''&&item.promotion_image !=null)?this.state.img_source.promo_image_source+item.promotion_image:productwoImg} /> 
          <div className="inner_img_cotnt"> 
            <div className="inner_img_lhs"> 
              <ul>  
                <li>  
                  valid till {moment(item.promotion_end_date).format("DD/MM/YYYY")} 
                </li> 
                <li>  
                  <h5>{item.promotion_title}</h5>   
                </li> 
              </ul> 
            </div>  
            <div className="inner_img_rhs"> 
              <p><sup>$</sup> {item.promotion_percentage} off</p> 
            </div>  
          </div>  
        </div>  
        <div className="myacc_product_contntdiv"> 
          <div className="promo_button">  
            <a onClick={this.openVoucherDetail.bind(this,item.promo_desc)} className="button" title="Refer Now">view more</a>  
          </div>  
        </div>  
     </li> </ul>  
       ));  
     }else{ 
      return <ul><li>No Promotions Found.</li></ul>;  
     }  
  }

  getCustomerDeatils(){

      var customerDetails = this.state.loyaltycustomerdetail;

      if(Object.keys(customerDetails).length > 0 &&  customerDetails !==undefined){
        console.log(customerDetails, "customerDetails");

      //reactLocalStorage.set("UserId", customerDetails.customer_loyalty_id);
      var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + reactLocalStorage.get("UserId");

       axios.get(apiUrl + "chopchop/customer/customerdetail?" + params).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                customerdetail:  res.data.result_set,
              });

            }
       });

      const inputKeys = ["reward", "overall_orders"];
      var customerParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + reactLocalStorage.get("UserId") + "&act_arr=" + inputKeys;
      axios.get(apiUrl + "chopchop/reports/activity_counts?" + customerParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                reward_ponits:  res.data.result_set.reward_ponits
              });

            }
       });

      }
  }


  openVoucherDetail(promoDesc){
    this.setState({promoCodeDesc : promoDesc});
   $.magnificPopup.open({
      items: {
        src: ".promo_details_popup",
      },
      type: "inline",
    });
  }

  sateValChange = (field, value) => {};
  render() {

    let f = "";
    let customer_membership_type_display = "";
    let customer_unique_id = "";
    let calc_kakis_perc = 0;
    let calc_kakis_perc_display = "";
    let client_membership_type = "";
    let customer_membership_type = "";
    let customer_membership_tier_benefits = "";
    let membership_spent_msg = "";


    var settingsArr = this.props.globalsettings;
    var client_logo = "";
    var client_name = "";
    var client_slug = "";

     if (settingsArr !=="" &&  settingsArr.length > 0 && settingsArr !== "undefined" && settingsArr !==null) {
         if (settingsArr[0].status ==="ok") {
        client_membership_type = settingsArr[0].result_set.client_membership_type;
        client_name = settingsArr[0].result_set.client_name;
        client_logo = settingsArr[0].result_set.client_logo;
        client_slug = settingsArr[0].result_set.client_slug;
      }
    }

   
     if (this.state.customerdetail !=="" && this.state.customerdetail !==undefined && Object.keys(this.state.customerdetail).length > 0) {

      customer_membership_type = this.state.customerdetail.customer_membership_type;
      customer_unique_id = this.state.customerdetail.customer_unique_id;
      customer_membership_tier_benefits = this.state.customerdetail.customer_membership_tier_benefits;
      membership_spent_msg = this.state.customerdetail.membership_spent_msg; 

      let membership_max_amount = this.state.customerdetail.membership_max_amount;
      let membership_spent_amount = this.state.customerdetail.membership_spent_amount;
      
     if ( customer_membership_type !== "" && customer_membership_type !== "Normal") {
        customer_membership_type_display = customer_membership_type === "Normal" ? "Bronze" : customer_membership_type;
      }else{
        customer_membership_type_display = "Bronze";
      }

      if (parseInt(membership_max_amount)) {
          calc_kakis_perc = (membership_spent_amount / membership_max_amount) * 100;
      }

      if (customer_membership_type === "Normal" && membership_max_amount) {
        calc_kakis_perc_display = "SPENT $500 more to become Silver Member";
      }else{
        if(customer_membership_type === "Normal"){
          calc_kakis_perc_display = "$500 more to become Silver Member";
        }else if(customer_membership_type === "Silver"){
          calc_kakis_perc_display =  "more to become Gold Member";
        }else if(customer_membership_type === "Gold"){
         calc_kakis_perc_display = "Gold Member";
        }
           
      }

    }


    var settingsMyAcc = {
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 2,
      initialSlide: 1,
      speed: 400,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
 

    return (
    <div className="contactusmain-top-div">
      <Header sateValChange={this.sateValChange} />
      <section className="myacc_details"></section>
      <div className="container myacc_Dmain customer_promotion_main">
        <div className="myacc_main_div">
          <div className="myacc_info">
            <div className="myacc_info_lhs customer-welcome">
              <div className="welcome-title">
                <h1>
                  <div><span>Hi ! </span>{reactLocalStorage.get('UserFname') !=="" ? reactLocalStorage.get('UserFname'): ""} 
                {" "}{reactLocalStorage.get('UserLname') !=="" ? reactLocalStorage.get('UserLname'): ""} <img src={hiHndImage} /></div>
                </h1>
                <p className="myacc_linkdesc">Welcome to ChopChop Rewards, you can check your loyalty and all the ongoing promotions here</p>
                <p className="myacc_linkpara">
                  <a href="/logout" title="SignOut">SignOut</a>
                </p>
              </div>
            </div>

            <div className="myacc_info_rhs promotions_info_mem">
              <ul>
                <li>
                  <h3>{customer_membership_type_display}</h3>
                </li>
                <li>
                  <p>cash back point balance</p>
                  <h3>{(this.state.reward_ponits || 0).toFixed(2)}</h3>
                </li>
              </ul>
              <div className="member-prog-bar">
                <div className="member-progress-2">
                  <div className="member-bar-2 member-bar-width-2" style={{width: calc_kakis_perc + "%"}}></div>
                </div>
                <p className="member-help-block">{membership_spent_msg}</p>
              </div>
              
              <div className="destop_acc_dbutton">
                 <TierBenifits memDisplayType={customer_membership_type_display} />
              </div>
            </div>
            
            <div className="mobile_acc_dbutton">
              <TierBenifits memDisplayType={customer_membership_type_display} />
            </div>

          </div>


       
        <div className="singletier_menumain">
            <ul>

              <li>
               <Link to={"/customer/rewards"}>
                  <img className="normal_simg" src={awardorderImg} />
                  <img className="hover_simg" src={awardorderhoverImg} />
                  <span>my rewards</span>
                </Link>
              </li>

              <li className="active">
                <Link to={"/customer/promotions"}>
                  <img className="normal_simg" src={celabrateImg} />
                  <img className="hover_simg" src={celabratehoverImg} />
                  <span>all promo</span>
                </Link>
              </li>

              <li>
                <Link to={"/customer/vouchers"}>
                  <img className="normal_simg" src={percentageImg} />
                  <img className="hover_simg" src={percentagehoverImg} />
                  <span>e vouchers</span>
                </Link>
              </li>

              <li>
                 <Link to={"/customer/aboutus/"+client_slug}>
                  <img className="normal_simg" src={aboutImg} />
                  <img className="hover_simg" src={abouthoverImg} />
                  <span>about us</span>
                </Link>
              </li>

            </ul>
          </div>



      <div className="mobile_mnu_block">
        <Slider {...settingsMyAcc}>
            <div className="single_block">
              <Link to={"/customer/rewards/"}>
                <img className="normal_simg" src={awardorderImg} />
                <img className="hover_simg" src={awardorderhoverImg} />
                <span>my rewards</span>
              </Link>
            </div>

            <div className="single_block active">
              <Link to={"/customer/promotions"}>
                <img className="normal_simg" src={celabrateImg} />
                <img className="hover_simg" src={celabratehoverImg} />
                <span>all promo</span>
              </Link>
            </div>

            <div className="single_block">
               <Link to={"/customer/vouchers/"}>
                <img className="normal_simg" src={percentageImg} />
                <img className="hover_simg" src={percentagehoverImg} />
                <span>e vouchers</span>
              </Link>
            </div>

            <div className="single_block">
               <Link to={"/customer/aboutus/"+client_slug}>
                <img className="normal_simg" src={aboutImg} />
                <img className="hover_simg" src={abouthoverImg} />
                <span>about us</span>
              </Link>
            </div>
        </Slider>
      </div>


          <div className="myacc_content_main promotion_content_main destop_myacc_Dmain">
           <ul className="customer-promo-ul">
            {/*<li className="myacc_locat_div">
               <img src={locationImg} />
               <h5>All Promo</h5>
             </li>*/}
             <li>
                <h6>Promo Codes</h6>
             </li>
            
           </ul>
          </div>

          <div className="myacc_product_main promotion_product_main destop_myacc_Dmain">
            {this.displayDesktopPromo()}

          </div>{/* Destop view End */}

          <div id="receipt-popup" className="white-popup mfp-hide promo_details_popup">
            <div className="promo-popup-inner">
              <div className="promo-popup-image">
              </div>
              <div className="promo-pop-content">
                <h4>Promo Code Description</h4>
                <p>{Parser(this.state.promoCodeDesc)}</p>
              </div>
            </div>
          </div>


          <div className="myacc_product_main promotion_product_main mobile_myacc_Dmain">
            <div className="myacc_content_main promotion_content_main promotion_innert_headind">
           <ul>
             <li>
                <h6>Promo Codes</h6>
             </li>
           </ul>
          </div>
            {this.displayMobilePromo()}

          </div>{/* Destop view End */}
        </div>
        <div id="dvLoading"></div>
      </div>
      
      <Footer />
  </div>
    );
  }
    
  }

const mapStateToProps = (state) => {
  return {
    outlets: state.outlets,
    globalsettings: state.settings,
    contactdata: state.contactdata,
    loyaltycustomerdetail: state.loyaltycustomerdetail,
    promotionlist: state.promotionlist,
    customerdetail: state.customerdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: (login_type) => {
     dispatch({ type: GET_GLOBAL_SETTINGS, login_type });
    }, 
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
    getCustomerDetailLoyalty: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    },
    getCustomerDetail: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    },
    getPromotionList: (customerParam, login_type) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam, login_type });
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Customerpromotions)
);
