/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import { setMinutes, setHours, getDay, format } from "date-fns";
import cookie from "react-cookies";
import Header from "../Layout/MerchantHeader";
import Footer from "../Layout/MerchantFooter";

import {
  stripslashes,
  addressFormat,
  getCalculatedAmount,
  callImage,
  showLoader,
  hideLoader,
  showAlert,
} from "../Helpers/SettingHelper";
import { appId, deliveryId, CountryTxt, apiUrlNotify } from "../Helpers/Config";
import thnkyou_tick_img from "../../common/images/tick_white.svg";
import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import cartMpImg from "../../common/images/cart-map.png";

import { GET_ORDER_DETAIL } from "../../actions";

var Parser = require("html-react-parser");

class Thankyou extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    var chkOrderid =
      typeof cookie.load("ChkOrderid") === "undefined"
        ? ""
        : cookie.load("ChkOrderid");
    if (orderId !== "" && chkOrderid === orderId) {
      this.props.getOrderDetail(orderId);
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      //this.props.history.push("/merchant/dashboard");
    }
  }

  componentDidMount() {

  }


  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];
      return cartItems.map((item, index) => (
        <div key={index}>
         <div class="product-details">
            <div class="col-sm-cls cart_left">
               <div class="cart_info tnx-view">
                  <h4><b> {item.item_qty} X </b><div className="p-name-tnx">{stripslashes(item.item_name)} </div> </h4>
               </div>
            </div>
            <div class="col-sm-cls cart_right text-right tnx-right">
               <div class="cart_price">
                  <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
               </div>
            </div>
         </div>
        </div>
      ));
    }
  }

  logoutClientDetails(){
  
  cookie.remove("CustomerUserId", { path: "/" });
  cookie.remove("CustomerUserFname", { path: "/" });
  cookie.remove("CustomerUserLname", { path: "/" });
  cookie.remove("CustomerUserMobile", { path: "/" });
  cookie.remove("CustomerUserEmail", { path: "/" });

  var merchant_outlet_slug_name =cookie.load("merchant_outlet_slug_name") !== "" &&  cookie.load("merchant_outlet_slug_name") !== undefined &&  cookie.load("merchant_outlet_slug_name") !== null
    ? cookie.load("merchant_outlet_slug_name") : ''; 
  this.props.history.push("/"+merchant_outlet_slug_name+"/merchant");
 }

  sateValChange = (field, value) => {};

  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];
    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <section class="myacc_details"></section>
        <div class="container myacc_Dmain merchant_dash_contain">
        {Object.keys(orderDetail).length > 0 && (
          <div class="myacc_main_div">
          
           <div className="merchent_serving">
              {/*<ul className="merchent_servingul">                        
                <li className="merchent_dropdown">
                  <a className="hordertype_btn" title="Serving">Serving</a>
                    <ul className="serving_submenu-inner">
                     <li> 
                      <Link to={"/merchant/transactionhistory"} className="merchant_menu" title="Transaction History"><span>Transaction History</span></Link>
                    </li>
                    <li> 
                      <a href="/merchantlogout" className="merchant_menu" title="Logout"><span>Logout</span></a>
                    </li>
                   </ul>
                </li>
              </ul>*/}
              <li className="hservingtype hservingnext">
                <a onClick={this.logoutClientDetails.bind(this)} title="Serve Next">Serve Next</a>
              </li>
            </div>

           <div class="myacc_info merchent_dbord merchant_innerheading">
            <div class="merchant_header-tick">
               <img src={thnkyou_tick_img} alt="Thank You" />
               <h2>Thank You</h2>
               <p>Your order has been placed successfully</p>
            </div>
           </div>

            <div class="merchant_tnkyou">
              <div class="thank-order-detaildiv">
                 <div class="tnk-detail text-center">
                    <h2>YOUR ORDER DETAILS</h2>
                    <div class="tnk-order">
                       <h3>Order No - {orderDetail.loyalty_pointorders_orderlocalno}</h3>
                       <p>Order placed at : {Moment(orderDetail.loyalty_pointorders_created_on).format(
                        "DD-MM-YYYY hh:mm A" )}<br />Pay by : Cash </p>
                    </div>
                 </div>

                 <div class="tnk-delivery">

                    <div class="orderitem_body_div">
                       <div class="overall-parent">
                          <div class="order-details-with-clear">
                             <h5>Your Items</h5>
                          </div>
                          {this.loadItems(orderDetail)}
                       </div>
                    </div>

                    <div class="tnk_cart_footer">
                       <div class="cart_row">
                          <p class="text-uppercase">SUBTOTAL</p>
                          <span>${orderDetail.loyalty_pointorders_subtotal_amount}</span>
                       </div>
                       {parseFloat(orderDetail.loyalty_pointorders_redeemed_points) > 0 && (
                       <div class="cart_row">
                          <p class="text-uppercase">Points Discount</p>
                          <span>${parseFloat(orderDetail.loyalty_pointorders_redeemed_points).toFixed(2)}</span>
                       </div>
                      )}
                      {parseFloat(orderDetail.loyalty_pointorders_discount_amount) > 0 && (
                       <div class="cart_row">
                          <p class="text-uppercase">Promotion Discount</p>
                          <span>${parseFloat(orderDetail.loyalty_pointorders_discount_amount).toFixed(2)}</span>
                       </div>
                      )}
                       <div class="cart_row grant-total-cls">
                          <p class="text-uppercase">Total</p>
                          <span><sup>$</sup>{parseFloat(orderDetail.loyalty_pointorders_total_amount).toFixed(2)}</span>
                       </div>
                    </div>
                    <div class="tnk-chk-order">
                      <a class="button" href="/merchant/dashboard">Check Your Reward Status</a>
                    </div>
                 </div>
            </div>
           </div>
      
        {/* container - end */}
          </div>)}

        </div>

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
