import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import MerchantHome from "./components/Home/MerchantHome";
import Pages from "./components/Pages/Pages";
import Faq from "./components/Pages/Faq";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";

import Orders from "./components/Myaccount/Orders";
import Customerpromotions from "./components/Customer/Customerpromotions";
import CustomerRewards from "./components/Customer/CustomerRewards";
import Myvouchers from "./components/Myaccount/Myvouchers";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Customer/Logout";
import MerchantLogout from "./components/Myaccount/MerchantLogout";
import Refpage from "./components/Layout/Refpage";
import Dashboard from "./components/Customer/Dashboard";
import CompanyDashboard from "./components/Customer/CompanyDashboard";
import CompanyAboutus from "./components/Customer/CompanyAboutus";
import Myaccount from "./components/Customer/Myaccount";
import CustomerVoucher from "./components/Customer/Customervoucher";

import MerchantDashboard from "./components/Merchant/Dashboardmulti";
import MerchantRewards from "./components/Merchant/Merchantrewards";
import MerchantVoucher from "./components/Merchant/Merchantvoucher";
import Merchantpromotions from "./components/Merchant/Merchantpromotions";

import MerchantVoucherCheckout from "./components/Merchant/Merchantvouchercheckout";
//import Merchantlogin from "./components/Merchant/Merchantlogin";
import MerchantAdminLogin from "./components/Merchant/Merchantadminlogin";
import Merchanttransactionhistory from "./components/Merchant/Merchanttransactionhistory";
import Merchantclienttransactionhistory from "./components/Merchant/Merchantclienttransactionhistory";
import Thankyou from "./components/Merchant/Thankyou";

import Customerlogin from "./components/Customer/Customerlogin";
import OutletCustomerlogin from "./components/Customer/OutletCustomerlogin";
import Customerregister from "./components/Customer/Register";
import Outletcustomerregister from "./components/Customer/Outletcustomerregister";
import Registersuccess from "./components/Customer/Registersuccess";

import Page404 from "./Page404";

const store = getStore();


render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/:outlet_slug/merchant" component={MerchantHome} />
        <Route path="/news-and-updates" component={Pages} />
        {/*<Route path="/privacy-policy" component={Pages} />*/}
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/about-us" component={Pages} />
        <Route path="/event" component={Pages} />
        <Route path="/press" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/faq" component={Faq} />
        <Route path="/events" component={ContactUs} />
        <Route path="/locations" component={Outlets} />
        <Route path="/customer/rewards" component={CustomerRewards} />
        <Route path="/customer/promotions" component={Customerpromotions} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myvouchers" component={Myvouchers} />
        <Route path="/customer/myaccount" component={Myaccount} />
        <Route path="/customer/vouchers" component={CustomerVoucher} />

        {/*<Route path="/:outltet_slug/customernumber" component={Customerlogin} />*/}

        <Route path="/customernumber" component={Customerlogin} />
        <Route path="/customernumber:/:customer_number" component={Customerlogin} />
        <Route exact path="/:outltet_slug/customer" component={OutletCustomerlogin} />
        <Route path="/customerregister" component={Customerregister} />
        <Route path="/outletcustomerregister" component={Outletcustomerregister} />
        <Route path="/registersuccess" component={Registersuccess} />
        <Route path="/customer/dashboard" component={Dashboard} />
        <Route path="/customer/profile/:client_slug" component={CompanyDashboard} />
        <Route path="/customer/aboutus/:client_slug" component={CompanyAboutus} />

        {/*<Route exact path="/merchantlogin" component={Merchantlogin} />
        <Route exact path="/:outlet_slug/merchantlogin" component={Merchantlogin} />*/}

        <Route exact path="/merchantlogin" component={MerchantAdminLogin} />
        <Route path="/merchant/dashboard" component={MerchantDashboard} />
        {/* <Route path="/merchant/rewards" component={MerchantRewards} />*/}

        <Route path="/merchant/checkout" component={MerchantRewards} />
        <Route path="/merchant/vouchercheckout" component={MerchantVoucherCheckout} />
        <Route path="/merchant/vouchers" component={MerchantVoucher} />
        <Route path="/merchant/promotions" component={Merchantpromotions} />
        <Route path="/merchant/transactionhistory" component={Merchanttransactionhistory} />
        <Route path="/merchant/company/transactionhistory" component={Merchantclienttransactionhistory} />
        <Route path="/merchant/thankyou/:orderId" component={Thankyou} />

        <Route path="/account/resetpassword/:resetKey" component={Resetpassword} />
        <Route path="/logout" component={Logout} />
        <Route path="/merchantlogout" component={MerchantLogout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
        
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
