/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { stripslashes, showAlert, getCalculatedAmount_rewards, getPromoCkValue, shorten, showPriceValue, showLoader, hideLoader, showCustomAlert, removePromoCkValue, removeVoucherPromoCkValue } from "../Helpers/SettingHelper";
import { appId, apiUrl, apiUrlV2 } from "../Helpers/Config";
import Slider from "react-slick";
import update from "immutability-helper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";
import moment from 'moment';
import axios from "axios";

import { connect } from "react-redux";
import {reactLocalStorage} from 'reactjs-localstorage';
import TierBenifits from "./TierBenifits";

import { GET_GLOBAL_SETTINGS, GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL, GET_LOYLATITY_CUSTOMER_DETAIL, GET_CART_DETAIL } from "../../actions";

import hiHndImage from "../../common/images/hand_color.png";
import awardorderImg from "../../common/images/award_gray.svg";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import productoneImg from "../../common/images/promofour.png";
import heartImg from "../../common/images/heart-black.svg";
import phoneImg from "../../common/images/phone.svg";
import awardImg from "../../common/images/award_white.svg";
import aboutImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";
import proVouchar from "../../common/images/9701a661bbc0f9d08661f28b2d670f9b.jpg";
import cashVouchar from "../../common/images/db18a309338006fe86a38aa8dc7147c5.png";
import BigproVouchar from "../../common/images/big-pro-vouchar.png";
import BigcashVouchar from "../../common/images/big-cash-vouchar.png";

var Parser = require("html-react-parser");
var qs = require("qs");

class Merchantvoucher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loyalty_amount_value : '',
      subtotal_value: 0,
      total_amount_value: '',
      reward_points_amount: '',
      customercompanyactivitylist: [],
      recentVisitlistData: [],
      vistCount: 0,
      last_vist_date: "",
      omisePayNowResponse: [],
      globalSettings: [],
      paymentmodevalue: 'payCash',
      reward_ponits: 0,
      qty: 0,
      calc_kakis_perc_display: '',
      customer_membership_type: '',
      voucherProductDetail: [],
      voucher_product_qty: 0,
      voucherIndexFlag: 0,
      productDetailList: [],
      promodetails: [],
      promoused: [],
      myVouchers: [],
      usedVouchers: [],
      productimagePath: '', 
      currentDate: '',
      customerdetail:[],
      loyaltycustomerdetail:[],    
      client_slug:  this.props.match.params.client_slug !== "" && typeof this.props.match.params.client_slug !== undefined &&
                    typeof this.props.match.params.client_slug !== "undefined" ? this.props.match.params.client_slug : "",

    };

    if (reactLocalStorage.get("LoyaltyUserId") == "" || reactLocalStorage.get("LoyaltyUserId") == undefined) {
      props.history.push("/");
    }

    var app_id = reactLocalStorage.get("app_id") !== "" && reactLocalStorage.get("app_id") !== undefined && reactLocalStorage.get("app_id") !== null
    ? reactLocalStorage.get("app_id") : '';
    var customer_user_id =  reactLocalStorage.get("UserId") !== "" && reactLocalStorage.get("UserId") !== undefined && reactLocalStorage.get("UserId") !== null
    ? reactLocalStorage.get("UserId") : '';
    var login_type = "customer";
    this.props.getGlobalSettings(login_type);
    var params = "app_id="+ app_id + "&status=A&refrence=" + reactLocalStorage.get("LoyaltyUserId");
    this.props.getCustomerDetailLoyalty(params);
    var customer_params = "&status=A&refrence=" + customer_user_id;
    this.props.getCustomerDetail(customer_params, login_type);

  }

  componentDidMount() {

    $("#dvLoading").fadeIn();

    $("html, body").animate({ scrollTop: 0 }, 800);

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    this.setState({
      currentDate: today,
    });
  
  }

  getVoucherList(app_id,customer_user_id){
    axios
      .get(
        apiUrl +
          "promotion_api_v2/vouchers?app_id=" +
          app_id +
          "&customer_id=" +customer_user_id
      )
      .then((vouchers) => {
        this.setState({ voucherStatus: "Ready" });
        /* set promotion content */
        if (vouchers.data.status === "ok") {
          this.setState({
            myVouchers: vouchers.data.result_set.voucher_list,
            usedVouchers: vouchers.data.result_set.used_vouchers,
            productimagePath: vouchers.data.common.image_source,
            status: "ok",
          });
        }
      });
  }

  getCustomerDeatils(){

      var customerDetails = this.state.loyaltycustomerdetail;

      if(Object.keys(customerDetails).length > 0 &&  customerDetails !==undefined){

      reactLocalStorage.set("UserId", customerDetails.customer_loyalty_id);

      var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + customerDetails.customer_loyalty_id;

       axios.get(apiUrl + "chopchop/customer/customerdetail?" + params).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                customerdetail:  res.data.result_set,
              });

            }
       });

      const inputKeys = ["reward", "overall_orders"];
      var customerParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&act_arr=" + inputKeys;
      axios.get(apiUrl + "chopchop/reports/activity_counts?" + customerParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                reward_ponits:  res.data.result_set.reward_ponits
              });

            }
       });

      }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

    let selectedNavigation = nextProps.selectedNavigation;
    let slugType = nextProps.selectedSlugType;

    if (nextProps.loyaltycustomerdetail !== this.props.loyaltycustomerdetail) {
      if(Object.keys(nextProps.loyaltycustomerdetail).length > 0 && nextProps.loyaltycustomerdetail !==undefined){
        if (nextProps.loyaltycustomerdetail[0].status === "ok") {
          $("#dvLoading").fadeOut(1000);
 
          var app_id = reactLocalStorage.get("app_id") !== "" && reactLocalStorage.get("app_id") !== undefined && reactLocalStorage.get("app_id") !== null
          ? reactLocalStorage.get("app_id") : '';
          this.setState({loyaltycustomerdetail: nextProps.loyaltycustomerdetail[0].result_set, },function(){
            this.getCustomerDeatils(); 
            var customer_user_id =  nextProps.loyaltycustomerdetail[0].result_set.customer_loyalty_id;
            this.getVoucherList(app_id,customer_user_id);
        });
      }
     }
    }
    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        setTimeout(function () {
          window.location.reload();
        }, 3000);
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }

    /* for receipt promotion */
    if (nextProps.promotionreceipt !== this.props.promotionreceipt) {
      if (nextProps.promotionreceipt[0].status === "ok") {
        this.setState({
          promodetails: nextProps.promotionreceipt[0].result_set,
        });

        $("#dvLoading").fadeOut(2000);

        $.magnificPopup.open({
          items: {
            src: ".receipt_popup",
          },
          type: "inline",
        });
      } else {
        this.setState({ promodetails: [] });
      }
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {

      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(4000);
        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",

             },
        });


      }
    }

   }

  openVoucherDetail(productDetail){
   this.setState({productDetailList : productDetail});
   $.magnificPopup.open({
      items: {
        src: "#voucher-popup",
      },
      type: "inline",
    });

  }

  loadProductDate(date) {
    var product_date = new Date(date).toString().split(" ");
    return product_date[2] + "-" + product_date[1] + "-" + product_date[3];
  }

  loadVouchers(list, pro_cate_slug, pro_subcate_slug){
    var imageSource = this.props.productCommon.product_image_source;
    var tagimagesource = this.props.productCommon.tag_image_source;
    var productgalleryimagesource =
      this.props.productCommon.product_gallery_image_source;
    var totalProduct = 0;
    const listProduct = list.map((productDetail, index) => {

    return(<div className="myacc_product_main promotion_product_main" key={index}>
      {productDetail.product_voucher == 'f' &&
      <>
      <h6>
        Product Vouchers
      </h6>
      <ul>
         <li className="promo_product_li">
            <div className="promo_product_imgdiv">
              <img src={productDetail.product_thumbnail !== "" ? (imageSource + "/" + productDetail.product_thumbnail) : productoneImg} />
              <div className="inner_img_cotnt">
                <div className="inner_img_lhs merchent_lhs">
                  <ul>
                    <li>
                      valid till {moment(productDetail.product_voucher_expiry_date).format("DD/MM/YYYY")}
                    </li>

                    <li>
                      <h5>
                        {productDetail.product_alias !== "" ? stripslashes(productDetail.product_alias):stripslashes(productDetail.product_name)}
                      </h5> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="myacc_product_contntdiv">
              <div className="promo_button">
                <a onClick={this.openVoucherDetail.bind(this,productDetail)} className="button" title="Refer Now">view more</a>
              </div>
            </div>
         </li>

       </ul></>}

        <div className="promotion_voacher_main">
        {productDetail.product_voucher == 'c' &&
          <>
          <div className="voacher_heading">
            <h5>Cash vouchers</h5>
          </div>
          <ul>
             <li className="promo_product_li">
                <div className="promo_product_imgdiv">
                  <img src={productDetail.product_thumbnail !== "" ? (imageSource + "/" + productDetail.product_thumbnail) : productoneImg} />
                  <div className="inner_img_cotnt">
                    <div className="inner_img_lhs merchent_lhs">
                      <ul>
                        <li>
                          valid till {moment(productDetail.product_voucher_expiry_date).format("DD/MM/YYYY")}
                        </li>

                        <li>
                          <h5>
                            {productDetail.product_alias !== "" ? stripslashes(productDetail.product_alias):stripslashes(productDetail.product_name)}
                          </h5> 
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="myacc_product_contntdiv">
                  <div className="promo_button">
                    <a onClick={this.openVoucherDetail.bind(this,productDetail)} className="button" title="Refer Now">view more</a>
                  </div>
                </div>
             </li>

           </ul></>}
        </div>
      </div>);
    });
    return listProduct;
  }

  loadMyVouchers() {
    var myVouchers =
      this.state.myVouchers !== undefined ? this.state.myVouchers : Array();
      
    if (Object.keys(myVouchers).length > 0) {
      return this.state.myVouchers.map((vouch, index) => (
        <li className="promo-li" key={index}>
            <div className="promo-image">
              {vouch.product_thumbnail !== "" &&
              vouch.product_thumbnail !== null ? (
                <img
                  src={this.state.productimagePath + vouch.product_thumbnail}
                />
              ) : (
                <img
                  src={vouch.product_voucher == "c" ? cashVouchar : proVouchar}
                />
              )}
            </div>
            <div className="promo-info-div">
              <div className="promation_btn" id={"promo-codediv-" + index}>
                {/* <p>Available: {vouch.order_item_voucher_balance_qty}</p> */}
                {this.state.currentDate > vouch.expiry_date ? (
                  <a
                    className="button promation_btn-one voacher_btn-one"
                    href="javascript:void(0);"
                    onClick={this.promoPopup.bind(this, vouch)}
                  >
                    Expired{" "}
                  </a>
                ) : (
                  <a
                    className="button promation_btn-one voacher_btn-one"
                    onClick={this.promoPopup.bind(this, vouch)}
                  >
                    {vouch.product_voucher == "c" ? "View & Redeem" : "View More"}
                  </a>
                )}
              </div>
            </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  /* Vouchers used list*/
  loadMyUsedVouchers() {
    var myVouchers =
      this.state.usedVouchers !== undefined ? this.state.usedVouchers : Array();

    if (Object.keys(myVouchers).length > 0) {
      return this.state.usedVouchers.map((vouch, index) => (
        <li className="promo-li" key={index}>
            <div className="promo-image">
              {vouch.product_thumbnail !== "" &&
              vouch.product_thumbnail !== null ? (
                <img
                  src={this.state.productimagePath + vouch.product_thumbnail}
                />
              ) : (
                <img
                  src={vouch.product_voucher == "c" ? cashVouchar : proVouchar}
                />
              )}

              <div class="inner_img_cotnt">
                <div class="inner_img_lhs">
                  <ul>
                    <li><span>Expiry {this.loadProductDate(vouch.expiry_date)}</span></li>
                    <li><h4 className="promo-heading"><span> {vouch.item_name}</span></h4></li>
                  </ul>
                </div>
                {/* <div class="inner_img_rhs">
                  <p className="vouchar-txt">{Parser(vouch.product_short_description)}</p>
                </div> */}
              </div>

            </div>

            <div className="promo-earned-info">
              {/* <div className="promo-earned-top">
                <h4 className="promo-earned-code">
                  <span> {vouch.item_name}</span>
                </h4>
                <span>Expiry {this.loadProductDate(vouch.expiry_date)}</span>
                <p className="vouchar-txt">{Parser(vouch.product_short_description)}</p>
              </div> */}
              <div className="promation_btn voucher_expiry" id={"promo-codediv-" + index}>
                <a
                  className="button promation_btn-one"
                >
                  Expired{" "}
                </a>
              </div>
            </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  promoPopup(promoData) {
    var promoImage =
      promoData.product_thumbnail !== "" && promoData.product_thumbnail !== null
        ? promoData.product_thumbnail
        : proVouchar;
        
    if(promoData.product_voucher === 'c' || promoData.product_voucher === 'f'){
    this.setState(
      { promodetails: promoData, promoImage: promoImage },
      function () {
        this.trgPromoPopup();
      }.bind(this)
    );
    }else{
      return false;
    }
  }

  trgPromoPopup() {
    $.magnificPopup.open({
      items: {
        src: ".receipt_popup",
      },
      type: "inline",
    });
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $(".proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $(".proqty_input").val(proqtyInput);
  }

  addToCart(voucher) {

    if (voucher !== "") {
      if (voucher.product_voucher == "f") {
        var modifier = [];
        var combo = [];
        var postObject = {};
        var price = parseFloat("0.00");
        var ImagePath = voucher.product_thumbnail;
        if (ImagePath !== "") {
          var postImagePath =
            this.state.productimagePath + voucher.product_thumbnail;
        } else {
          postImagePath = "";
        }

        var qty_voucher = $(".proqty_input").val();
        var login_type = "customer";
        var postObject = {
          app_id: reactLocalStorage.get('app_id'),
          product_id: voucher.item_product_id,
          product_name: voucher.item_name,
          product_sku: voucher.item_sku,
          product_image: postImagePath,
          product_unit_price: price,
          product_qty: qty_voucher,
          product_total_price: price,
          modifiers: modifier,
          menu_set_component: combo,
          customer_id: reactLocalStorage.get("UserId"),
          order_item_id: voucher.order_item_id,
          order_outlet_id: voucher.order_outlet_id,
        };

        axios
          .post(apiUrl + "cart/is_voucher_insert", qs.stringify(postObject))
          .then((res) => {
            this.setState({ status: "ok" });
            if (res.data.status === "ok") {
              this.setState({ redirectToCart: true, cartTriggerFlg: "yes" });
              $(".mfp-close").trigger("click");
              // this.sateValChange("cartflg", "yes");
              showCustomAlert(
                "success",
                "Great choice! Voucher added to your cart."
              );
              /*showCartLst();*/
              this.handleShowAlertFunct(
                "success",
                "Great choice! Voucher added to your cart."
              );
              this.props.getCartDetail(login_type);
            } else if (res.data.status === "error") {
              $(".mfp-close").trigger("click");
              this.handleShowAlertFunct("Error", res.data.message);
            }
          });
      }

      if (voucher.product_voucher == "c") {
        var modifier = [];
        var combo = [];
        var postObject = {};
        var price = parseFloat("0.00");
        var qty_voucher = $(".proqty_input").val();
        postObject = {
          app_id: reactLocalStorage.get('app_id'),
          product_qty: qty_voucher,
          product_voucher_points: voucher.product_voucher_points,
          customer_id: reactLocalStorage.get("UserId"),
          order_item_id: voucher.order_item_id,
        };

        axios
          .post(
            apiUrl + "chopchop/loyalty_points/add_voucher_redeem",
            qs.stringify(postObject)
          )
          .then((res) => {
            this.setState({ status: "ok" });
            if (res.data.status === "ok") {
              //push("/rewards");
              showCustomAlert("success", "Cash Voucher Points Credited.");
              /*showCartLst();*/
              this.handleShowAlertFunct(
                "success",
                "Cash Voucher Points Credited."
              );
              this.getVoucherList(reactLocalStorage.get('app_id'),reactLocalStorage.get("UserId"));
              
            } else if (res.data.status === "error") {
              this.handleShowAlertFunct("Error", res.data.message);
            }
          });
      }
    }
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  stripHTML(myString) {
    let repStr = myString || '';
    return repStr.replace( /(<([^>]+)>)/ig, '');
  }

  sateValChange = (field, value) => { };
  
  render() {

   let f = "";
    let customer_membership_type_display = "";
    let customer_unique_id = "";
    let calc_kakis_perc = 0;
    let calc_kakis_perc_display = "";
    let client_membership_type = "";
    let customer_membership_type = "";
    let customer_membership_tier_benefits = "";
    let membership_spent_msg = "";


    var settingsArr = this.props.globalsettings;
    var client_logo = "";
    var client_name = "";
    var client_slug = "";

     if (settingsArr !=="" &&  settingsArr.length > 0 && settingsArr !== "undefined" && settingsArr !==null) {
         if (settingsArr[0].status ==="ok") {
        client_membership_type = settingsArr[0].result_set.client_membership_type;
        client_name = settingsArr[0].result_set.client_name;
        client_logo = settingsArr[0].result_set.client_logo;
        client_slug = settingsArr[0].result_set.client_slug;
      }
    }

   
     if (this.state.customerdetail !=="" && this.state.customerdetail !==undefined && Object.keys(this.state.customerdetail).length > 0) {

      customer_membership_type = this.state.customerdetail.customer_membership_type;
      customer_unique_id = this.state.customerdetail.customer_unique_id;
      customer_membership_tier_benefits = this.state.customerdetail.customer_membership_tier_benefits;
      membership_spent_msg = this.state.customerdetail.membership_spent_msg; 

      let membership_max_amount = this.state.customerdetail.membership_max_amount;
      let membership_spent_amount = this.state.customerdetail.membership_spent_amount;
      
     if ( customer_membership_type !== "" && customer_membership_type !== "Normal") {
        customer_membership_type_display = customer_membership_type === "Normal" ? "Bronze" : customer_membership_type;
      }else{
        customer_membership_type_display = "Bronze";
      }

      if (parseInt(membership_max_amount)) {
          calc_kakis_perc = (membership_spent_amount / membership_max_amount) * 100;
      }

      if (customer_membership_type === "Normal" && membership_max_amount) {
        calc_kakis_perc_display = "SPENT $500 more to become Silver Member";
      }else{
        if(customer_membership_type === "Normal"){
          calc_kakis_perc_display = "$500 more to become Silver Member";
        }else if(customer_membership_type === "Silver"){
          calc_kakis_perc_display =  "more to become Gold Member";
        }else if(customer_membership_type === "Gold"){
         calc_kakis_perc_display = "Gold Member";
        }
           
      }

    }


    var settingsMyAcc = {
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 2,
      initialSlide: 2,
      speed: 400,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />
        <section className="myacc_details">
        </section>
        <div className="container myacc_Dmain">
          <div className="myacc_main_div">
            <div className="myacc_info">
              <div className="myacc_info_lhs">
                <div className="welcome-title">
                <h1>
                  <div><span>Hi ! </span>{reactLocalStorage.get('UserFname') !=="" ? reactLocalStorage.get('UserFname'): ""} 
                {" "}{reactLocalStorage.get('UserLname') !=="" ? reactLocalStorage.get('UserLname'): ""} <img src={hiHndImage} /></div>
                </h1>
                <p className="myacc_linkdesc">Welcome to ChopChop Rewards, you can check your loyalty and all the ongoing promotions here</p>
                <p className="myacc_linkpara">
                  <a href="/logout" title="SignOut">SignOut</a>
                </p>
              </div>
              </div>

              <div className="myacc_info_rhs promotions_info_mem">
                <ul>
                  <li>
                    <h3>{customer_membership_type_display}</h3>
                  </li>
                  <li>
                    <p>cash back point balance</p>
                    <h3>{(this.state.reward_ponits || 0).toFixed(2)}</h3>
                  </li>
                </ul>
                <div className="member-prog-bar">
                  <div className="member-progress-2">
                    <div className="member-bar-2 member-bar-width-2" style={{width: calc_kakis_perc + "%"}}></div>
                  </div>
                  <p className="member-help-block">{membership_spent_msg}</p>
                </div>
                
                <div className="destop_acc_dbutton">
                  <TierBenifits memDisplayType={customer_membership_type_display} />
                </div>
              </div>
              <div className="mobile_acc_dbutton">
                <TierBenifits memDisplayType={customer_membership_type_display} />
              </div>
            </div>

            <div className="mobile_mnu_block">
              <Slider {...settingsMyAcc}>
                  <div className="single_block">
                    <Link to={"/customer/rewards"}>
                      <img className="normal_simg" src={awardorderImg} />
                      <img className="hover_simg" src={awardorderhoverImg} />
                      <span>my rewards</span>
                    </Link>
                  </div>

                  <div className="single_block">
                    <Link to={"/customer/promotions"}>
                      <img className="normal_simg" src={celabrateImg} />
                      <img className="hover_simg" src={celabratehoverImg} />
                      <span>all promo</span>
                    </Link>
                  </div>

                  <div className="single_block active">
                     <Link to={"/customer/vouchers/"}>
                      <img className="normal_simg" src={percentageImg} />
                      <img className="hover_simg" src={percentagehoverImg} />
                      <span>e vouchers</span>
                    </Link>
                  </div>

                  <div className="single_block">
                     <Link to={"/customer/aboutus/"+client_slug}>
                      <img className="normal_simg" src={aboutImg} />
                      <img className="hover_simg" src={abouthoverImg} />
                      <span>about us</span>
                    </Link>
                  </div>
              </Slider>
            </div>

            <div className="singletier_menumain">
              <ul>
                <li>
                 <Link to={"/customer/rewards"}>
                    <img className="normal_simg" src={awardorderImg} />
                    <img className="hover_simg" src={awardorderhoverImg} />
                    <span>my rewards</span>
                  </Link>
                </li>

                <li>
                  <Link to={"/customer/promotions"}>
                    <img className="normal_simg" src={celabrateImg} />
                    <img className="hover_simg" src={celabratehoverImg} />
                    <span>all promo</span>
                  </Link>
                </li>

                <li  className="active">
                  <Link to={"/customer/vouchers"}>
                    <img className="normal_simg" src={percentageImg} />
                    <img className="hover_simg" src={percentagehoverImg} />
                    <span>e vouchers</span>
                  </Link>
                </li>

                <li>
                   <Link to={"/customer/aboutus/"+client_slug}>
                    <img className="normal_simg" src={aboutImg} />
                    <img className="hover_simg" src={abouthoverImg} />
                    <span>about us</span>
                  </Link>
                </li>

              </ul>
            </div>
            <div className="myacc_content_main mobile_tab_div merchant-voucher-tab">
              <ul className="mer-mobileul">
                {/*<li className="active">
                  <a data-toggle="tab"
                      href="#tab-id-inn5"
                      aria-expanded="true">
                      <span>All</span>
                  </a>
                </li>*/}
                <li className="active">
                  <a data-toggle="tab"
                      href="#tab-id-inn3"
                      aria-expanded="true">
                      <span>My E vouchers</span>
                  </a>
                </li>
                <li className="">
                  <a data-toggle="tab"
                      href="#tab-id-inn4"
                      aria-expanded="true">
                      <span>Used E vouchers</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                  <div
                    id="tab-id-inn3"
                    className="tab-pane fade active in"
                  >
                    <h4 className="tab_mobtrigger inner_tab_border active">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="order-delivery">
                        <div className="ord-body">
                          <div className="cur-order-body reward-list-body voucher-maindiv">
                            <h6>My E Vouchers</h6>
                            <ul className="voucher_ul">
                              {this.loadMyVouchers(this,"vouchers")}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data"
                        style={{ display: "none" }}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                  <div id="tab-id-inn4" className="tab-pane fade">
                    <h4 className="tab_mobtrigger inner_tab_border">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="cur-order-body reward-list-body">
                        <div className="myacc_product_main voucher-maindiv mobile_voucher_uddiv">
                        <h6>My Used E Vouchers</h6>
                        <ul className="voucher_ul">
                          {this.loadMyUsedVouchers(this)}
                        </ul>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data1"
                        style={{ display: "none" }}
                  
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
            </div>

            {/*<div className="merchant-voacher-mobile">
              <div className="cur-order-body reward-list-body">
                <ul className="voucher_ul">
                  {this.state.productDetails}
                </ul>
              </div>
            </div>*/}

            <div className="merchant-voacher-destop">
              <div className="tab_sec filter_tabsec">
                <ul className="nav nav-tabs text-center">
                  <li className="active">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn5"
                      aria-expanded="true"
                    >
                      <span>My E Vouchers</span>
                    </a>
                  </li>
                  <li className="">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn6"
                      aria-expanded="false"
                    >
                      <span>Used E vouchers</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    id="tab-id-inn5"
                    className="tab-pane fade active in"
                  >
                    <h4 className="tab_mobtrigger inner_tab_border active">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="ord-body">
                        <div className="cur-order-body reward-list-body">
                          <div className="myacc_product_main voucher-maindiv">
                            <h6>My E Vouchers</h6>
                            <ul className="voucher_ul">
                              {this.loadMyVouchers(this,"vouchers")}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data"
                        style={{ display: "none" }}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                  <div id="tab-id-inn6" className="tab-pane fade">
                    <h4 className="tab_mobtrigger inner_tab_border">
                    <i></i>
                    </h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="cur-order-body reward-list-body used_voucher">
                        <div className="myacc_product_main voucher-maindiv">
                        <h6>My Used E Vouchers</h6>
                        <ul className="voucher_ul">
                          {this.loadMyUsedVouchers(this)}
                        </ul>
                        </div>
                      </div>
                    </div>
                    <div className="load_more_div">
                      <button
                        className="load_more_data1"
                        style={{ display: "none" }}
                  
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="voucher-popup" className="white-popup mfp-hide receiptcart_popup voucher_details_popup">
          <div className="voucher-popup">
            <div className="voucher-popup-image">
              <img src={this.state.productDetailList.product_thumbnail !== "" ? (this.props.productCommon.product_image_source + "/" + this.state.productDetailList.product_thumbnail) : productoneImg} />
            </div>
            <div className="voucher-popup-box">
              <div className="voucher-popup-content">
                <h5>Valid till {moment(this.state.productDetailList.product_voucher_expiry_date).format("DD/MM/YYYY")}</h5>
                <h4>
                {this.state.productDetailList.product_alias !== "" ? stripslashes(this.state.productDetailList.product_alias) : stripslashes(this.state.productDetailList.product_name)}
                </h4>
                <p>{this.state.productDetailList.product_short_description !== "" ? this.stripHTML(this.state.productDetailList.product_short_description) : ''}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup redeem_popsec vouchar-popup cust-vouchar-popup"
        >
          <div className="redeem_bansec">
            <div className="promo-popup-image">
              {this.state.promodetails.product_thumbnail !== "" &&
              this.state.promodetails.product_thumbnail !== null ? (
                <img
                  src={
                    this.state.productimagePath +
                    this.state.promodetails.product_thumbnail
                  }
                />
              ) : (
                <img
                  src={
                    this.state.promodetails.product_voucher == "c"
                      ? BigcashVouchar
                      : BigproVouchar
                  }
                />
              )}
            </div>
            <div className="promo-earned-content">
              <div className="promo-earned-inner">
                <h3 className="">{this.state.promodetails.item_name}</h3>
                <span className="expiry-date">
                  {this.loadProductDate(this.state.promodetails.expiry_date)}
                </span>

                {this.state.currentDate <= this.state.promodetails.expiry_date && (<h4>
                  Available :{" "}
                  {this.state.promodetails.order_item_voucher_balance_qty}
                </h4>)}
                {this.state.promodetails.product_short_description !== "" &&
                  this.state.promodetails.product_short_description !== null && (
                    <p>
                      {Parser(
                        stripslashes(
                          this.state.promodetails.product_short_description + ""
                        )
                      )}
                    </p>
                  )}
                  </div>
                  {this.state.currentDate <= this.state.promodetails.expiry_date && this.state.promodetails.product_voucher === "c" && (<div className="V-productpopup">
                <p className="sel-quality">Select Quantity</p>
                <div className="V-productamount">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.promodetails.promotion_id,
                        "decr",
                        this.state.promodetails.order_item_voucher_balance_qty
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.promodetails.promotion_id,
                        "incr",
                        this.state.promodetails.order_item_voucher_balance_qty
                      )}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>)}
              <div className="voucher-popup-button">

              {this.state.currentDate > this.state.promodetails.expiry_date ? (
                <a className="button">
                  Expired
                </a> ) : (this.state.promodetails.product_voucher === "c" ? <a
                  className="button"
                  onClick={this.addToCart.bind(this, this.state.promodetails)}
                >
                  Redeem Now
                </a> :<a
                  className="button"
                >
                  Purchase In Store
                </a> )}
              </div>
            </div>
          </div>
        </div>

        <div id="dvLoading"></div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var tempArr = Array();
  var tempProdArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;

    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }
    
  if (Object.keys(state.product).length > 0) {
    var tempProdArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;

    if (Object.keys(tempProdArr).length > 0) {
      if (tempProdArr[0].status === "ok") {
        navigateRst = tempProdArr[0].result_set;
        navigateCmn = tempProdArr[0].common;
        if (tempProdArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempProdArr[0].result_set[0].category_name;
          menu_slug = tempProdArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempProdArr[0].result_set[0].subcategory_name;
          menu_slug = tempProdArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  return {
    customerdetail: state.customerdetail,
    loyaltycustomerdetail: state.loyaltycustomerdetail,
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    promotionreceipt: state.promotionreceipt,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: (login_type) => {
      dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
    },
    getCustomerDetail: (params, login_type) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params, login_type });
    },
    getPromotionReceipt: (params) => {
      dispatch({ type: GET_PROMOTIONRECEIPT, params });
    },
    getCustomerDetailLoyalty: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    },
    getCartDetail: (login_type) => {
      dispatch({ type: GET_CART_DETAIL, login_type });
    },
  };
};

Merchantvoucher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchantvoucher)
);
