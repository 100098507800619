import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_MEMBERSHIP_BENIFITS, SET_MEMBERSHIP_BENIFITS} from '../actions';
import { apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from "react-cookies";
import {reactLocalStorage} from 'reactjs-localstorage';


export const watchGetTierBenifits = function* () {
  yield takeEvery(GET_MEMBERSHIP_BENIFITS, workerGetTierBenifits);
}

function* workerGetTierBenifits({login_type}) {
  try {

    var app_id = "";

    if(login_type === "Merchant"){
      app_id  =  cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
        ? cookie.load("merchant_app_id") : "";
    }else{
      app_id = reactLocalStorage.get("app_id") !== "" && reactLocalStorage.get("app_id") !== undefined && reactLocalStorage.get("app_id") !== null
        ? reactLocalStorage.get("app_id") : "";
    }

    const uri = apiUrl+'chopchop/customer/membrshpBenifits?app_id='+app_id;
    const result = yield call(Axios.get, uri);
  	var resultArr = [];
  	resultArr.push(result.data);
      yield put({ type: SET_MEMBERSHIP_BENIFITS, value: resultArr });
    } 
    catch {
      console.log('Get Tier Benifits Failed');
    }
}