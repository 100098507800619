/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { companyname } from "../Helpers/Config";
import { appId, apiUrl, appName } from "../Helpers/Config";
import mainLogo from "../../common/images/logo.png";

import footerLogo from "../../common/images/logo.svg";

import footerfacebook from "../../common/images/facebook.svg";
import footerinstagram from "../../common/images/instagram.svg";
import footeryoutube from "../../common/images/youtube.svg";


import { GET_STATIC_BLOCK } from "../../actions";
var Parser = require("html-react-parser");
import axios from "axios";
var qs = require("qs");
import {
  showAlert,
} from "../Helpers/SettingHelper";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "",newsLetterEmail: "",isChecked: false, };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
  checkEmail(){
      if (this.state.newsLetterEmail !== "" && this.state.isChecked == true) {
        var postObject = {
          app_id: appId,
          email: this.state.newsLetterEmail,
        };
        axios
          .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "ok") {
              var magnfPopup = $.magnificPopup.instance;
              showAlert("Success", "Newsletter Subscribed", magnfPopup);
              setTimeout(function () {
                window.location.reload();
              }, 3000);
            }else{
              showAlert("Error", "Enter a valid email", magnfPopup);
            }
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          });

      }
  }


  handleChangeemail(event){
    this.setState({newsLetterEmail : event.target.value});
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
     

      <footer className="footer-main">
   <div className="container-full">
      <div className="footer-top">

         <div className="footer-newletter">
            <span>Sign up for our newsletter</span>
            <h4>Be the First to Get the Scoop on Our Freshest Deals, Launches Recipes!</h4>
             {/* <div className="f-newletter-form">
              <input type="text" onChange={this.handleChangeemail.bind(this)} placeholder="Email Address" value={this.state.newsLetterEmail} />
            <button type="submit" className="button" onClick={this.checkEmail.bind(this)}>Subscribe</button>
            </div>
            <div className="f-newletter-bottom">
            <input type="checkbox" onChange={this.toggleChange} className="subscribe_accept" />
            <label> By signing up, i agree to receive communications about Ninja Enterprise’s services store events and matters 
            via email and i have also acknowledge agreed to Ninja Enterprise’s Privacy Policy and Terms Conditions
            </label >
          </div>*/}

         </div>

         <div className="footer-logo">
          <div className="footer-logo_div">
            <a  title=""> <img src={footerLogo} /> </a>
          </div>
         
          <div className="social-media">
            <h6>Chop Chop Rewards</h6>
            <div className="social-media-links">
              <a href="https://www.facebook.com/" target="_blank"> 
                <img src={footerfacebook} alt="Facebook"/>
              </a> 
              <a href="https://www.instagram.com/" target="_blank"> 
                <img src={footerinstagram} alt="Instagram"/> 
              </a>
              <a href="#" target="_blank"> 
                <img src={footeryoutube} alt="Youtube"/> 
              </a>
            </div>
          </div>
        </div>
         <div className="footer-nav">
            <div className="footer-nav-inner">
               <h5>
                  About Us
               </h5>
               <ul>
                  <li>
                     <a href="/page/our-story">Our Story</a>
                  </li>
               </ul>
            </div>
            <div className="footer-nav-inner">
               <h5>
                  Helpful Links
               </h5>
               <ul>

                  <li>
                     <a href="/page/faq">FAQ</a>
                  </li>
                  <li>
                     <a href="/page/terms-and-conditions">Terms Conditions</a>
                  </li>
                  <li>
                     <a href="/page/privacy-policy">Privacy & Policy</a>
                  </li>
               </ul>
            </div>
         </div>
         <div className="mobile-footer-nav">
            <Accordion>
              <AccordionItem title="About Us">
                <ul>
                  <li>
                    <a href="/page/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/page/locations">Visit Us</a>
                  </li>
                </ul>
              </AccordionItem>
              <AccordionItem title="Helpful Links">
                <ul>
                  <li>
                    <Link to={"/page/faq"}>FAQ</Link>
                  </li>
                  <li>
                    <Link to={"/page/privacy-policy"}>Privacy Policy</Link>
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
        </div>
      </div>
   </div>
   <div className="copyright-section">
      <p>Copyright {yearSp} Loyalty System.</p>
   </div>
   <div className="scrolltop show" id="scrollbutton">
    <a href="/" className="disbl_href_action"><span><i className="fa fa-angle-double-up ars"></i><i className="fa fa-angle-up ars1"></i><i className="fa fa-angle-up ars2"></i></span></a></div>
</footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
