import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import cookie from "react-cookies";

import { apiUrl } from "../Helpers/Config";
import axios from "axios";

import { GET_GLOBAL_SETTINGS, GET_CUSTOMER_COMPANY_ACTIVITY, GET_ACTIVITYCOUNT, GET_CUSTOMER_DETAIL, GET_MENU_NAVIGATION, GET_PROMOTIONLIST, GET_PRODUCT_LIST } from "../../actions";

import phoneImg from "../../common/images/phone.svg";
import awardImg from "../../common/images/award_white.svg";
import celabrateImg from "../../common/images/celabrate_white.svg";
import percentagewhiteImg from "../../common/images/percentage.svg";

var login_type = "Merchant";

class Merchantdtopheader extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      promocode_count: 0,
      reward_ponits: 0,
      voucherCount: 0,
      customercompanyactivitylist: [],
      recentVisitlistData: [],
      calc_kakis_perc_display: '',
      customer_membership_type: '',
      navigateMenu: [],
      selectedNavigation: "",
      selectedSlugType: "",
      catSlug: '',
      subcatSlug: '',
      productSubcatlist: [],
      productDetailList: []
    }
  }

  componentDidMount() {

    const inputKeys = ["reward", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys), login_type);

    const script = document.createElement("script");
    script.src = "https://cdn.omise.co/omise.js";
    script.async = true;
    document.body.appendChild(script);

   var app_id = cookie.load("merchant_app_id") !== "" && cookie.load("merchant_app_id") !== undefined && cookie.load("merchant_app_id") !== null
      ? cookie.load("merchant_app_id") : '';

    var customer_user_id = cookie.load("CustomerUserId");
    var customerParam = "app_id="+ app_id + "&customer_id=" + customer_user_id + "&login_type="+login_type;
    this.props.getCustomerCompanyActivity(customerParam);

    this.checkOutAuthentication();

    var params = "&status=A&refrence=" + customer_user_id;
    var outltIdTxt = cookie.load("merchant_outlet_id");
    this.props.getCustomerDetail(params, login_type);
    this.props.getPromotionList(customer_user_id, login_type);
    this.props.getMenuNavigationList(outltIdTxt);
    this.getVoucherList(app_id,customer_user_id);
  }

  componentWillReceiveProps(nextProps) {

    let selectedNavigation = nextProps.selectedNavigation;
    let slugType = nextProps.selectedSlugType;
  
    if (this.state.globalSettings !== nextProps.settingsArr) {
      var tampStArr = nextProps.settingsArr;
      if (Object.keys(tampStArr).length > 0) {
        this.setState({
          globalSettings: nextProps.settingsArr,
        });
      }

    }

    if (selectedNavigation !== this.state.selectedNavigation) {

      var catSlug = "";
      var subcatSlug = "";
      if (selectedNavigation !== "") {
        catSlug = selectedNavigation;
        subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        catSlug = (catSlug !=='')?catSlug:subcatSlug;
        subcatSlug = (subcatSlug !=='')?subcatSlug:catSlug;
        this.props.getProductList(catSlug, subcatSlug, login_type);
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedSlugType: slugType,
        catSlug: catSlug,
        subcatSlug: subcatSlug,
      });
    }

    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          //$("#dvLoading").fadeOut(500);
          this.setState({reward_ponits: nextProps.activitycount[0].result_set.reward_ponits});
        }
      }
    } else {
      this.setState({ reward_ponits: 0 });
    }

   if (nextProps.customercompanyactivitylist !== this.props.customercompanyactivitylist) {
    if(Object.keys(nextProps.customercompanyactivitylist).length > 0 && nextProps.customercompanyactivitylist !==undefined){
      if (nextProps.customercompanyactivitylist[0].status === "ok") {

       this.setState({customercompanyactivitylist: nextProps.customercompanyactivitylist[0].result_set,
        vistCount:nextProps.customercompanyactivitylist[0].total_vist, last_vist_date: nextProps.customercompanyactivitylist[0].last_vist_date},function(){

      });

     }

    }
    
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {

      if (nextProps.customerdetail[0].status === "ok") {
        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",

             },
        });


      }
    }

    if (nextProps.promotionlist !== this.props.promotionlist) {

      if (nextProps.promotionlist[0].status === "ok") {
        if (
          nextProps.promotionlist[0].status &&
          nextProps.promotionlist[0].result_set
        ) {
          this.setState({promocode_count: Object.keys(nextProps.promotionlist[0].result_set.my_promo).length});
        }
      }
    }
    
    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          //this.productsubcatlist();
        }
      );
    }

    var customer_membership_type = "",
        calc_kakis_perc_display = "",
        membership_message_amount = "";
        
    if (nextProps.customerdetail !=="" && nextProps.customerdetail !==undefined && Object.keys(nextProps.customerdetail).length > 0 && nextProps.customerdetail[0].status !=='error') {

      customer_membership_type = nextProps.customerdetail[0].result_set
        .customer_membership_displayname;

      let membership_max_amount = nextProps.customerdetail[0].result_set
        .membership_max_amount;
      let membership_spent_amount = nextProps.customerdetail[0].result_set
        .membership_spent_amount;

        membership_message_amount = membership_max_amount - membership_spent_amount;
    
      if(customer_membership_type === "Normal" || customer_membership_type === "Bronze"){
        calc_kakis_perc_display = <p>{parseFloat(membership_message_amount).toFixed(2)} more points to become Silver Member</p>
      }else if(customer_membership_type === "Silver"){
        calc_kakis_perc_display = <p>{parseFloat(membership_message_amount).toFixed(2)} more points to become Gold Member</p>
      }else if(customer_membership_type === "Gold"){
        calc_kakis_perc_display = <p>Gold Member</p>
      }

    }
    this.setState({ customer_membership_type : customer_membership_type, calc_kakis_perc_display: calc_kakis_perc_display });
  }


  getVoucherList(app_id,customer_user_id){
    axios.get( apiUrl + "promotion_api_v2/vouchers?app_id=" + app_id + "&customer_id=" +customer_user_id).then((vouchers) => {
        this.setState({ voucherStatus: "Ready" });
        if (vouchers.data.status === "ok") {
          var myVouchersLength = vouchers.data.result_set.voucher_list.length;
          this.setState({
            myVouchers: vouchers.data.result_set.voucher_list,
            voucherCount: myVouchersLength,
            status: "ok",
          });
        }
      });
  }



 checkOutAuthentication(){ }

  // productsubcatlist() {
  //   var subcatlistArr = this.state.productSubcatlist;
  //   var subcatlistCount = 0;
  //   if (Object.keys(subcatlistArr).length > 0) {
  //     var productDetails = subcatlistArr.map( categories => (
  //       subcatlistCount = Object.keys(categories.products).length
  //     ));
  //    /// this.setState({voucherCount: subcatlistCount});
  //     return productDetails;
  //   } else {
  //     return " ";
  //   }
  // }
  
  render(){


  return(
    <div className="merchant-allmain">
      <div className="container">
        <div className="merchant-allinner">
          <ul className="merchant-allul">
            <li className="merchant-all-lhs">
              <p>{cookie.load('CustomerUserFname') !=="" ? cookie.load('CustomerUserFname'): ""} 
              {" "}{cookie.load('CustomerUserLname') !=="" ? cookie.load('CustomerUserLname'): ""}</p>
              <div className="merchant-all-phoe">
                <img src={phoneImg} alt="" />{cookie.load('CustomerUserMobile') !=="" ? cookie.load('CustomerUserMobile'): ""}
              </div>
            </li>
            <li className="merchant-all-rhs">
              <h3>{this.state.customer_membership_type}</h3>
              {this.state.calc_kakis_perc_display}
            </li>
          </ul>
        </div>
        <div className="merchant-membermain">
          <ul className="merchant-memberinner">
            <Link to="/merchant/checkout">
              <li className="merchant-member-left">
                <div className="merchant-left-imgdiv">
                  <img src={awardImg} alt="" />
                </div>
                <div className="merchant-left-contentdiv">
                  <h5>{parseFloat(this.state.reward_ponits || 0)} <span> Reward Points</span> </h5>
                  <p>Click here to redeem</p>
                </div>
              </li>
            </Link>
            <Link to="/merchant/vouchers">
              <li className="merchant-member-middle">
                <div className="merchant-left-imgdiv merchant-middle-img">
                  <img src={percentagewhiteImg} alt="" />
                </div>
                <div className="merchant-left-contentdiv">
                  <h5>{this.state.voucherCount} <span>E Vouchers</span></h5>
                  <p>Click here to redeem</p>
                </div>
              </li>
            </Link>
            <Link to="/merchant/promotions">
              <li className="merchant-member-right">
                <div className="merchant-left-imgdiv merchant-right-img">
                  <img src={celabrateImg} alt="" />
                </div>
                <div className="merchant-left-contentdiv">
                  <h5>{this.state.promocode_count} <span>Promo Codes</span></h5>
                  <p>Click here to redeem</p>
                </div>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}
}

const mapStateToProps = (state) => {
 
  var globalSettings = [];
  var tempArr = [];
  var tempProdArr = [];
  var productSubcat = [];
  var productCmn = [];
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = [];
  var navigateCmn = [];
  var selectedCatry = "";

  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  if (Object.keys(state.product).length > 0) {
      tempArr = !("productlist" in state.product[0])
      ? []
      : state.product[0].productlist;

    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }
    
  if (Object.keys(state.product).length > 0) {
      tempProdArr = !("menuNavigation" in state.product[0])
      ? []
      : state.product[0].menuNavigation;

    if (Object.keys(tempProdArr).length > 0) {
      if (tempProdArr[0].status === "ok") {
        navigateRst = tempProdArr[0].result_set;
        navigateCmn = tempProdArr[0].common;
        if (tempProdArr[0].result_set[0].menu_type === "main") {
          selectedCatry = tempProdArr[0].result_set[0].category_name;
          menu_slug = tempProdArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempProdArr[0].result_set[0].subcategory_name;
          menu_slug = tempProdArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }


  return {
    customercompanyactivitylist: state.customercompanyactivity,
    settingsArr: globalSettings,
    activitycount: state.activitycount,
    customerdetail: state.customerdetail,
    promotionlist: state.promotionlist,
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  
      getGlobalSettings: (login_type) => {
       dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
      },
      getActivityCount: (getObject, login_type) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject, login_type});
      }, 
      getCustomerCompanyActivity: (customerParam) => {
      dispatch({ type: GET_CUSTOMER_COMPANY_ACTIVITY, customerParam });
      },
      getCustomerDetail: (params, login_type) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params, login_type });
      },
      getPromotionList: (customerParam, login_type) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam, login_type });
      },
      getMenuNavigationList: (outletID) => {
        dispatch({ type: GET_MENU_NAVIGATION, outletID });
      },
      getProductList: (catslug, subcatslug, login_type, outletid, sortByField, sortByValue) => {
        dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, login_type, outletid, sortByField, sortByValue });
      },
  };
};


Merchantdtopheader.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Merchantdtopheader)
);