/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import Moment from "moment";
import {reactLocalStorage} from 'reactjs-localstorage';


import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";
import { apiUrl} from "../Helpers/Config";
import TierBenifits from "./TierBenifits";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import clockImg from "../../common/images/clock-round-black.svg";
import shoopingbagImg from "../../common/images/shopping-bag-dark.svg";
import locationtagImg from "../../common/images/location_block.svg";
import phoneImg from "../../common/images/phone.svg";
import celabratewhiteImg from "../../common/images/celabrate_white.svg";
import awardabtImg from "../../common/images/award_white.svg";
import cartImg from "../../common/images/buy-cart_white.svg";
import aboutImg from "../../common/images/noimg-400x400.jpg";
import awardImg from "../../common/images/award_red.svg";
import heartredImg from "../../common/images/heart-bg-red.svg";

import logoImg from "../../common/images/logo.png";
import awardorderImg from "../../common/images/award_gray.svg";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";
import aboutsingleImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";

var Parser = require("html-react-parser");

import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import { GET_GLOBAL_SETTINGS, GET_ALL_OUTLETS, GET_LOYLATITY_CUSTOMER_DETAIL} from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class CompanyAboutus extends Component {
  constructor(props) {
    super(props);
    //  console.log(this.props);
    this.state = { outlets: [], globalsettings: [], reward_ponits: 0, customerdetail: [],};
    const login_type = "customer";
    this.props.getSettings(login_type);
   // this.props.getPickupOutlets();
    this.props.getAllOutlets();


    if(reactLocalStorage.get("LoyaltyUserId") == "" || reactLocalStorage.get("LoyaltyUserId") == undefined) {
      props.history.push("/");
    }

  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);

    var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + reactLocalStorage.get("LoyaltyUserId");
    this.props.getCustomerDetailLoyalty(params);
  }

  componentWillReceiveProps(nextProps) {
 
       if (nextProps.loyaltycustomerdetail !== this.props.loyaltycustomerdetail) {
       if(Object.keys(nextProps.loyaltycustomerdetail).length > 0 && nextProps.loyaltycustomerdetail !==undefined){
          if (nextProps.loyaltycustomerdetail[0].status === "ok") {
           this.setState({loyaltycustomerdetail: nextProps.loyaltycustomerdetail[0].result_set, },function(){
                        this.getCustomerDeatils(); });
             }
           }
        }


  }

   getCustomerDeatils(){

    var customerDetails = this.state.loyaltycustomerdetail;

      if(Object.keys(customerDetails).length > 0 &&  customerDetails !==undefined){

      reactLocalStorage.get("UserId", customerDetails.customer_loyalty_id);

      var params = "app_id="+ reactLocalStorage.get("app_id") + "&status=A&refrence=" + customerDetails.customer_loyalty_id;

       axios.get(apiUrl + "chopchop/customer/customerdetail?" + params).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                customerdetail:  res.data.result_set,
              });

            }
       });

      const inputKeys = ["reward", "overall_orders"];
      var customerParam = "app_id="+ reactLocalStorage.get("app_id") +"&status=A&customer_id=" + customerDetails.customer_loyalty_id + "&act_arr=" + inputKeys;
      axios.get(apiUrl + "chopchop/reports/activity_counts?" + customerParam).then((res) => {
              if (res.data.status === "ok") {
              this.setState({
                reward_ponits:  res.data.result_set.reward_ponits
              });

            }
       });

      }
  }


  sateValChange = (field, value) => {};


  tConvert(time) {
    if (
      time !== null &&
      time !== "" &&
      typeof time !== undefined &&
      typeof time !== "undefined"
    ) {
      var hr = "";
      var timeArray = time.split(":");
      hr = timeArray[0];
      var ampm = " AM";
      if (timeArray[0] >= 12) {
        hr = Math.round(timeArray[0] - 12);
        ampm = " PM";
      }
      var result = hr + ":" + timeArray[1] + " " + ampm;
      return result;
    }
  }

  loadOutletList(){

  var outLetList = this.props.outlets;
 
  if (outLetList !== "" && outLetList !== undefined && Object.keys(outLetList).length > 0) {
  
       var listhtml = outLetList.map((item, index) => {

        return(

            <div className="outlet_list_inner">
                      <h5>{item.outlet_name}</h5>
                      <ul>
                          <li>
                            <img src={clockImg} />Earlist Delivery : {this.tConvert(item.da_monday_start_time)}
                          </li>
                          
                          {/*<li>
                            <img src={shoopingbagImg} />Min Order : $50
                          </li>*/}

                          {/*<li>
                            <img src={locationtagImg} />Direction : <span>13.3KM Away </span>
                          </li>*/}

                        </ul>
                        <p className="outlet_address">
                          {item.outlet_address_line1 + ", Singapore " + item.outlet_postal_code}
                        </p>
                    </div>)


          });


     return listhtml;

  } else {
    return "";
  }

  }
  render() {

    let customer_membership_type = "";
    let customer_membership_type_display = "";
    let customer_unique_id = "";
    let calc_kakis_perc = 0;
    let calc_kakis_perc_display = "";
    let client_membership_type = "";
    let customer_membership_tier_benefits = "";
    let membership_spent_msg = "";

    var settingsArr = this.props.globalsettings;
    var client_logo = "";
    var client_name = "";
    var client_company_phone = "";
    var client_about_us = "";
    var client_slug = "";


     if (settingsArr !=="" &&  settingsArr.length > 0 && settingsArr !== "undefined" && settingsArr !==null) {
         if (settingsArr[0].status ==="ok") {
        client_name = settingsArr[0].result_set.client_name;
        client_logo = settingsArr[0].result_set.client_logo;
        client_company_phone = settingsArr[0].client_company_phone;
        client_about_us = settingsArr[0].result_set.about_us;
        client_slug = settingsArr[0].result_set.client_slug;
      }
    }

     if (this.state.customerdetail !=="" && this.state.customerdetail !==undefined && Object.keys(this.state.customerdetail).length > 0) {
   
      customer_membership_type = this.state.customerdetail.customer_membership_type;
      customer_unique_id = this.state.customerdetail.customer_unique_id;
      customer_membership_tier_benefits = this.state.customerdetail.customer_membership_tier_benefits;

      let membership_max_amount = this.state.customerdetail.membership_max_amount;
      let membership_spent_amount = this.state.customerdetail.membership_spent_amount;

      membership_spent_msg = this.state.customerdetail.membership_spent_msg;

     if ( customer_membership_type !== "" && customer_membership_type !== "Normal") {
        customer_membership_type_display = customer_membership_type === "Normal" ? "Bronze" : customer_membership_type;
      }else{
        customer_membership_type_display = "Bronze";
      }

      if (parseInt(membership_max_amount)) {
          calc_kakis_perc = (membership_spent_amount / membership_max_amount) * 100;
      }

      if (customer_membership_type === "Normal" && membership_max_amount) {
        calc_kakis_perc_display = "SPENT $500 more to become Silver Member";
      }else{
        if(customer_membership_type === "Normal"){
          calc_kakis_perc_display = "SPENT $500 more points to become Silver Member";
        }else if(customer_membership_type === "Silver"){
          calc_kakis_perc_display = "SPENT $1000 more to become Gold Member";
        }else if(customer_membership_type === "Gold"){
         calc_kakis_perc_display = "Gold Member";
        }
           
      }

    }

    var settingsMyAcc = {
      infinite: false,
      dots: true,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 2,
      speed: 400,
      initialSlide: 2,
      responsive: [
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <section className="myacc_details"></section>


        <div className="container myacc_Dmain">
              <div className="myacc_main_div">
              <div className="abt-backbtn-destop">
                <a className="button abt-backbtn" href="/customer/dashboard" title="back">back</a>
              </div>
                <div className="about_info">
                  <div className="myacc_info_lhs about_info_lhs">
                     <div className="myacc_product_imgdiv destop_lhsimage">
                       <div className="destop_lhsimage-div">
                        <img src={client_logo !=="" ? client_logo :  aboutImg} />
                        </div>

                        {/*<div className="inner_img_cotnt">
                          <ul className="myacc_product_wish">
                            <li>
                              <a className="heart_normal" href="#"><img src={heartImg} /></a>
                              <a className="heart_hover" href="#"><img src={heartredImg} /></a>
                            </li>
                          </ul>
                        </div>*/}

                      </div>
                  </div>

                  <div className="about_info_rhs">
                    <div className="about_tag">
                      <ul>
                        <li><a href="#">delivery</a></li>
                        <li><a href="#">pickup</a></li>
                      </ul>
                    </div>

                    <div className="about_desscribtion">
                      <h4>{client_name}</h4>
                      <ul>
                        <li>
                          <img src={clockImg} />Earlist Delivery : 9.00AM
                        </li>

                        {/*<li>
                          <img src={shoopingbagImg} />Min Order : $50
                        </li>*/}

                        {/*<li>
                          <img src={locationtagImg} />Direction : <span>13.3KM Away </span>
                        </li>*/}

                         {client_company_phone !=="" &&  client_company_phone !== undefined &&(
                          <li>
                          <img src={phoneImg} /><a href={"tel:"+client_company_phone}>{"+65"+ client_company_phone}</a>
                          </li>
                          )}
                        
                      </ul>
                      <p>
                        {client_about_us}
                      </p>
                    </div>

                    <div className="about-button">
                      <ul>
                        <li>
                          <a title="" class="button"><img src={awardabtImg} />cash back</a>
                        </li>

                        <li>
                          <a  title="" class="button"><img src={celabratewhiteImg} />view promo</a>
                        </li>

                        <li>
                          <a  title="" class="button"><img src={cartImg} />order now</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>

               <div className="myacc_info multitier myacc_info multitier aboutbk_multitier">{/* multitier start*/}
                <div className="myacc_info_lhs">
                  <div className="welcome-title">
                    <img src={client_logo !=="" ? client_logo :  aboutImg} />
                  </div>
                  <p className="myacc_linkdesc">Welcome to ChopChop Rewards, you can check your loyalty and all the ongoing promotions here</p>
                </div>

                <div className="myacc_info_rhs promotions_info_mem myacc_info_rhs_multi">
                  <ul>
                    <li className="multi__rhs_li-left">
                      <div className="myacc_lhs_multitier">
                        <div className="welcome-title">
                          <h1><div><span>Hi ! </span>{reactLocalStorage.get('UserFname') !=="" ? reactLocalStorage.get('UserFname'): ""}
                               {reactLocalStorage.get('UserLname') !=="" ? reactLocalStorage.get('UserLname'): ""} 
                               <img src={hiHndImage} /></div></h1>
                        </div>
                      </div>
                      <div class="multitier_member_div">
                        <h3>{customer_membership_type_display}</h3>
                        <p className="member-help-block">{membership_spent_msg}</p>
                      </div>
                    </li>
                    <li className="multi__rhs_li-right">
                      <p>cash back point balance</p>
                      <h3>{parseFloat(this.state.reward_ponits || 0).toFixed(2)}</h3>
                    </li>
                  </ul>
                  <div className="member-prog-bar">
                    <div className="member-progress-2">
                      <div className="member-bar-2 member-bar-width-2" style={{width: calc_kakis_perc + "%"}}></div>
                    </div>
                    <p className="member-mobile-block">{membership_spent_msg}</p>
                  </div>
                  <div className="multitier_button_rsh">
                    <div className="myacc_linkpara">
                      <a href="/logout" title="Sign Out">Sign Out</a>
                    </div>
                <div className="destop_acc_dbutton">
                    <TierBenifits memDisplayType={customer_membership_type_display} />
                  </div>
                  </div>
                </div>
                <div className="mobile_acc_dbutton">
                 <TierBenifits memDisplayType={customer_membership_type_display} />
               </div>
              </div>{/* multitier End*/}

              <div className="mobile_mnu_block">
                <Slider {...settingsMyAcc}>

                    <div className="single_block">
                      <Link to={"/customer/rewards"}>
                        <img className="normal_simg" src={awardorderImg} />
                        <img className="hover_simg" src={awardorderhoverImg} />
                        <span>my rewards</span>
                      </Link>
                    </div>

                    <div className="single_block">
                      <Link to={"/customer/promotions"}>
                        <img className="normal_simg" src={celabrateImg} />
                        <img className="hover_simg" src={celabratehoverImg} />
                        <span>all promo</span>
                      </Link>
                    </div>

                     <div className="single_block">
                       <Link to={"/customer/vouchers/"}>
                        <img className="normal_simg" src={percentageImg} />
                        <img className="hover_simg" src={percentagehoverImg} />
                        <span>e vouchers</span>
                      </Link>
                    </div>

                    <div className="single_block active">
                       <Link to={"/customer/aboutus/"+client_slug}>
                        <img className="normal_simg" src={aboutImg} />
                        <img className="hover_simg" src={abouthoverImg} />
                        <span>about us</span>
                      </Link>
                    </div>
                </Slider>
              </div>

          <div className="singletier_menumain">
              <ul>

                <li>
                 <Link to={"/customer/rewards"}>
                    <img className="normal_simg" src={awardorderImg} />
                    <img className="hover_simg" src={awardorderhoverImg} />
                    <span>my rewards</span>
                  </Link>
                </li>

                <li >
                  <Link to={"/customer/promotions"}>
                    <img className="normal_simg" src={celabrateImg} />
                    <img className="hover_simg" src={celabratehoverImg} />
                    <span>all promo</span>
                  </Link>
                </li>

                <li>
                  <Link to={"/customer/vouchers"}>
                    <img className="normal_simg" src={percentageImg} />
                    <img className="hover_simg" src={percentagehoverImg} />
                    <span>e vouchers</span>
                  </Link>
                </li>

                <li className="active">
                   <Link to={"/customer/aboutus/"+client_slug}>
                    <img className="normal_simg" src={aboutImg} />
                    <img className="hover_simg" src={abouthoverImg} />
                    <span>about us</span>
                  </Link>
                </li>

              </ul>
            </div>

              <div className="abt-backbtn-mobile">
                <a className="button abt-backbtn" href="/customer/dashboard" title="back">back</a>
              </div>

                 <div className="about_info about_mobile_infomain">
                  <div className="myacc_info_lhs about_info_lhs">
                     <div className="myacc_product_imgdiv destop_lhsimage">
                       <div className="destop_lhsimage-div">
                       <img src={client_logo !=="" ? client_logo :  aboutImg} />
                       </div>
                        <div className="inner_img_cotnt">
                          <ul className="myacc_product_wish">
                            <li>
                              <a className="heart_normal" href="#"><img src={heartImg} /></a>
                              <a className="heart_hover" href="#"><img src={heartredImg} /></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                  </div>

                  <div className="about_info_rhs ">
                    <div className="about_tag">
                      <ul>
                        <li><a href="#">delivery</a></li>
                        <li><a href="#">pickup</a></li>
                      </ul>
                    </div>

                    <div className="about_desscribtion">
                      <h4>{client_name}</h4>
                      <ul>
                        <li>
                          <img src={clockImg} />Earlist Delivery : 9.00AM
                        </li>
                        <li>
                          <img src={shoopingbagImg} />Min Order : $50
                        </li>

                        <li>
                          <img src={locationtagImg} />Direction : <span>13.3KM Away </span>
                        </li>

                         {client_company_phone !=="" &&  client_company_phone !== undefined &&(

                          <li>
                          <img src={phoneImg} /><a href={"tel:"+client_company_phone}>{"+65"+ client_company_phone}</a>
                         </li>

                         )}

                      </ul>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>

                    <div className="about-button">
                      <ul>
                        <li>
                          <a href="/" title="" class="button"><img src={awardabtImg} />cash back</a>
                        </li>

                        <li>
                          <a href="/" title="" class="button"><img src={celabratewhiteImg} />view promo</a>
                        </li>

                        <li>
                          <a href="/" title="" class="button"><img src={cartImg} />order now</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


                <div className="myacc_content_main outlet_main">
                 <ul>
                   <li className="myacc_locat_div">
                     <img src={awardImg} />
                     <h4>Available Oulets</h4>
                   </li>
                 </ul>

                 <div className="outlet_list">
                 
                  {this.loadOutletList()}

                  </div>

                  <div className="about_info_div">
                  {client_about_us !== "" && (
                    <ul>
                     <li className="myacc_locat_div">
                       <h4>About Us</h4>
                     </li>
                    </ul> 

                   )}
                    <p>{client_about_us}</p>
                  </div>

                </div>
              </div>
            </div>

        <Footer />
 
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    outlets: alloutletsArr,
    loyaltycustomerdetail: state.loyaltycustomerdetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: (login_type) => {
      dispatch({ type: GET_GLOBAL_SETTINGS, login_type});
    },
    getAllOutlets: () => {
      dispatch({ type: GET_ALL_OUTLETS });
    },
     getCustomerDetailLoyalty: (params) => {
      dispatch({ type: GET_LOYLATITY_CUSTOMER_DETAIL, params });
    }, 
  };
};

CompanyAboutus.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyAboutus));
