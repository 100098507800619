/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import clockImg from "../../common/images/clock-round-black.svg";
import shoopingbagImg from "../../common/images/shopping-bag-dark.svg";
import locationtagImg from "../../common/images/location_block.svg";
import phoneImg from "../../common/images/phone.svg";
import celabratewhiteImg from "../../common/images/celabrate_white.svg";
import awardabtImg from "../../common/images/award_white.svg";
import cartImg from "../../common/images/buy-cart_white.svg";
import aboutImg from "../../common/images/about.jpg";
import awardImg from "../../common/images/award_red.svg";
import heartredImg from "../../common/images/heart-bg-red.svg";


import logoImg from "../../common/images/logo.png";
import awardorderImg from "../../common/images/award_gray.svg";
import awardorderhoverImg from "../../common/images/award_blue.svg";
import celabrateImg from "../../common/images/celabrate_gray.svg";
import celabratehoverImg from "../../common/images/celabrate_blue.svg";
import percentageImg from "../../common/images/percentage_gray.svg";
import percentagehoverImg from "../../common/images/percentage_blue.svg";
import aboutsingleImg from "../../common/images/shopimg.svg";
import abouthoverImg from "../../common/images/shopimg_blue.svg";


var Parser = require("html-react-parser");

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/*import {
  requestPageData,
} from '../modules/ui/actions'; */

import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class Pages extends Component {
  constructor(props) {
    super(props);
    //  console.log(this.props);
    this.state = { pagedata: [], pagedetail: "", pagetitle_txt: "" };

    if (
      this.props.match.params !== "" &&
      this.props.match.params.page_slug !== undefined
    ) {
      var page_slug = this.props.match.params.page_slug;
    } else {
      var page_slug = this.props.match.url.replace(/\\|\//g, "");
    }
    //  console.log(page_slug);
    //const { page_slug } = this.props.match.params;
    this.props.getSettings();
    this.props.getRequestpage(page_slug);
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps.match.path !== this.props.match.path) {
      if ($(".trigger_menu").length > 0) {
        $(".trigger_menu").toggleClass("active");
        if ($(".hmenu_list").hasClass("open")) {
          $(".mega_menu").slideUp();
        }
        $(".hmenu_list").toggleClass("open");
      }
      var pageslug = nextProps.match.path.replace(/\\|\//g, "");
      this.props.getRequestpage(pageslug);
    }

    if (nextProps.pagedata !== this.state.pagedata) {
      $(".dvLoadrCls").fadeOut(500);
      var pageDetails = "";
      var pageTitleTxt = "";
      if (Object.keys(nextProps.pagedata).length > 0) {
        var pagedataTxt = nextProps.pagedata[0].cmspage_description;
        pageTitleTxt = nextProps.pagedata[0].cmspage_title;
        pageDetails = pagedataTxt !== "" ? Parser(pagedataTxt) : "";

       this.setState({
        pagedata: nextProps.pagedata,
        pagedetail: pageDetails,
        pagetitle_txt: pageTitleTxt,
      });
      }else{

       this.setState({
        pagedetail: "Comming Soon.",
      });

      }


    }
  }
  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <section className="myacc_details">
        </section>

        <div className="container myacc_Dmain">
              <div className="myacc_main_div">
                <div className="about_info">
                  <div className="myacc_info_lhs about_info_lhs">
                  {this.state.pagedetail}
                  </div>
                </div>
                </div>
            </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    pagedata: pagedataRst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
