/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import update from "immutability-helper";
import QrReader from "react-qr-reader";
import {reactLocalStorage} from 'reactjs-localstorage';
import PhoneInput from "react-phone-input-2";
import { setMinutes, setHours, getDay, format } from "date-fns";
var dateFormat = require("dateformat");
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  baseUrl,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FBLOGINDATA,
  GET_GOOGLELOGINDATA,
  GET_FORGET_PASSWORD,
  GET_CHANGEPASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
  GET_CUSTOMER_LOGINDATA
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  getAliasName,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  validateEmailFun
} from "../Helpers/SettingHelper";

/* import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob"; */
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";

import {
  Login,
  Forgotpassword,
  Signup,
  Changepassword,
} from "../../components/Customer/Index";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* import images */
import mainLogo from "../../common/images/logo.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import warningImg from "../../common/images/warning.svg";
import leftarrowImg from "../../common/images/arrow-left.svg";
import avicon from "../../common/images/av-icon.png";
import loupe from "../../common/images/search-one.png";
import loginav from "../../common/banner/login.jpg";
import Loginsccess from "../../common/images/sucess.png";

//import loginav from "../../common/images/login.jpg";

import deliveryImg from "../../common/images/delivery.svg";
import takeawayImg from "../../common/images/takeaway.svg";
import PhoneCall from "../../common/images/phone-call.png";
import largeLogo from "../../common/images/ex-logo.png";
import dineImg from "../../common/images/dish.svg";
import wishlishImg from "../../common/images/wishlist.png";
import navarrowdown from "../../common/images/navarrow-down.png";
import ReservationImg from "../../common/images/reservation-black.png";
import ReservationWhite from "../../common/images/reservation-white.png";
import shoppingBag from "../../common/images/shopping-cart.png";
import footerLogo from "../../common/images/logo.svg";

var Parser = require("html-react-parser");
var qs = require("qs");
var temp_app_id = "99F42644-8372-42EA-8BF0-215DF11369E1";
class CustomerHeader extends Component {
  constructor(props) {
 
    let pathname = props.location.pathname;
    let uriparms_one, uriparms_two = "";
    pathname = pathname.split('/');
    uriparms_one = pathname[1];
    uriparms_two = pathname[2];

    const outlet_slug_name = reactLocalStorage.get("outlet_slug_name") !== "" &&
    typeof reactLocalStorage.get("outlet_slug_name") !== undefined &&
    typeof reactLocalStorage.get("outlet_slug_name") !== "undefined"
      ? reactLocalStorage.get("outlet_slug_name")
      : "";
    
    super(props);

    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear());
    this.state = {
      seletedAvilablityId: "",
      defaultAvilablityId:
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
          ? cookie.load("defaultAvilablityId")
          : "",
      seletedOutletId: "",
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      searchProResult: [],
      selectedProResult: [],
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      logindata: [],
      customerlogindata: [],
      deliveryInfo: [],
      pickupInfo: [],
      /* For Advanced Slot Start */
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */
      fields: {
        mobile: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
          check_email: '',
          check_firstname: '',
          check_phone: '',
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        pin: "",
        rePin: "",
        mobile: "",
        pdpa_consent: "",
        terms: "",
      },
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
      menuData: [],
      googlelogin: "No",
      trigerGlogin: false,
      qrcoderesult: "No",
      enableQrCode: false,
      fbstatus: "ok",
      staticblacks: [],
      topinfoBlks: "",
      homeorderInfo: "",
      homesubscribe: "",
      single_outlet_select: '',
      uriparms_one : uriparms_one,
      uriparms_two :uriparms_two,
      time: {}, 
      seconds: 59,
      customer_phone: "",
      country_code: "",
      error_phonenumber: null,
      phonenumbersuccessPopup: true,
      customer_password: "",
      customer_password_error: false,
      customer_invalid_password: "",
      passwordEnterInputPopup:false,
      customermobile_number: '',
      country : "",
      customermobile_number_error: false,
      outlet_slug_name: outlet_slug_name, 
      customer_otp: "", 
      invalid_customer_error: "",
      openOtpinput:false,
      code_digit1: "",
      code_digit2: "",
      code_digit3: "",
      code_digit4: "",
      code_digit5: "",
      code_digit6: "",
      invalid_otp_error: "",
      country_code: "",

      customermobile_register_number: (cookie.load("register_customer_mobile_number") !="" && 
                                          cookie.load("register_customer_mobile_number") !==undefined ? 
                                          cookie.load("register_customer_mobile_number") :""),
      register_country: "65",
      error_customer_phone: false, 
      customer_email: "", 
      error_customer_email: false,
      error_invalid_email: "",
      error_exist_email: "",
      customer_firstname: "", 
      error_customer_firstname: false,
      customer_lastname: "",
      error_customer_lastname: false,
      customer_dob: false,
      customer_dob_error: false,
      customer_register_password: "",
      customer_register_password_error: "",
      customer_confpassword: "",
      customer_confpassword_error: "", 
      customer_confpassword_match: '', 
      Maxdate: Maxdate,
      birthdate: "",
      error_customer_birthaday_error: "",
      isChecked: false,
      terms_error:false,
      client_site_url: "https://loyalty.chopchoprewards.com/"
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    //this.props.getGlobalSettings();
    //this.props.getAllOutlets(deliveryId);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  handleTextChange(event) {

  var value = event.target.value;

  this.setState({ phoneNumber: value });

 }

 handlePwdTextChange(event) {

  var value = event.target.value;

   if (event.target.name === "customer_password") {
    if (value !== "") {
      this.setState({ customer_password_error: false });
    } else {
      this.setState({ customer_password_error: true });
    }
    this.setState({ customer_password: value });
  } 

 }

 secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  startTimer() {
    //console.log(this.timer,"this.timer");
    //console.log(this.state.seconds,"this.state.seconds");
    if (this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    
    // Check if we're at zero.
    if (seconds == 0) { 
      clearInterval(this.timer);
    }
  }

  sendSMS() {
    var customer_phone = this.state.customer_phone.replace(this.state.country, "");

    if (this.state.customer_phone !== "" && customer_phone.length >= 8 ) {
      /*axios
        .post(apiUrl + "otp/sendSMS", qs.stringify(customer_phone))
        .then((res) => {
          $.magnificPopup.open({
            items: {
              src: "#otp-popup",
            },
            type: "inline",
          });
          this.startTimer();
          if (res.data.status === "ok") {
            this.setState({checkOTP : res.data.message});
          }
        });*/
      $.magnificPopup.open({
        items: {
          src: "#otp-popup",
        },
        type: "inline",
      });
      this.startTimer();
    } else {
      this.setState(() => ({ error_phonenumber: "Please enter this field" }));
    }
  }

  resendSMS(){
    this.setState({ seconds : 60 },
      function () {
        this.startTimer();
      }.bind(this)
    );
  }

  handleTextChange_getStarted(event) {
    var value = event.target.value;

     if (event.target.name === "customermobile_register_number") {
      if (value !== "") {
        this.setState({ error_customer_phone: false });
      } else {
        this.setState({ error_customer_phone: true });
      }
      this.setState({ customermobile_register_number: value });
    } else if (event.target.name === "customer_email") {
      if (value !== "") {
        this.setState({ error_customer_email: false });
        this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
      } else {
        this.setState({ error_customer_email: true });
      }
      this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
    }else if (event.target.name === "customer_firstname") {
      if (value !== "") {
        this.setState({ error_customer_firstname: false });
      } else {
        this.setState({ error_customer_firstname: true });
      }
      this.setState({ customer_firstname: value });
    }else if (event.target.name === "customer_lastname") {
      if (value !== "") {
        this.setState({ error_customer_lastname: false });
      } else {
        this.setState({ error_customer_lastname: true });
      }
      this.setState({ customer_lastname: value });
    }else if (event.target.name === "customer_register_password") {
      if (value !== "") {
        this.setState({ customer_register_password_error: false });
      } else {
        this.setState({ customer_register_password_error: true });
      }
      this.setState({ customer_register_password: value });
    }else if (event.target.name === "customer_confpassword") {
      if (value !== "") {
        this.setState({ customer_confpassword_error: false });
      } else {
        this.setState({ customer_confpassword_error: true });
      }
      this.setState({ customer_confpassword: value });
    }

  }

  checkAlreadyExit(flg) {
    var postObject = {
      type: flg,
      customer_email: this.state.customer_email,
     // app_id: appId,
      customer_phone: this.state.customermobile_number,
    };
    axios
      .post(apiUrl + "chopchop/customer/loyality_checkexisting", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "success") {
          if (flg === "email") {
            this.setState({ error_exist_email: "", error_customer_email:false, error_invalid_email: false });
            
          }
        } else {
          if (flg === "email") {
            this.setState({ error_exist_email: res.data.message});
          }
        }
      });
  }

RegistrationSubmit(){

  var errorFlg = "n";

  if (this.state.customermobile_number === "") {
    errorFlg = "y";
    this.setState({ error_customer_phone: true });
    return false;
  }else{
    this.setState({ error_customer_phone: false });
  }

  if (this.state.customer_email === "") {
    errorFlg = "y";
    this.setState({ error_customer_email: true, error_invalid_email: false });
    return false;
   }else if(this.state.error_exist_email !== ''){
    errorFlg = "y";
    this.setState({ error_customer_email: true,  error_invalid_email: false});
    this.setState({ });
   }else {
      var validate_Email_Fun = validateEmailFun(this.state.customer_email);

      if (validate_Email_Fun === true) {
        this.setState({ error_customer_email: false });
        this.setState({ error_invalid_email: false });
      } else {
        errorFlg = "n";
        this.setState({ error_invalid_email: true});
        return false;
      }
  }

  if (this.state.customer_firstname === "") {
    errorFlg = "y";
    this.setState({ error_customer_firstname: true });
    return false;
  }else{
    this.setState({ error_customer_firstname: false });
  }

  if (this.state.customer_lastname === "") {
    errorFlg = "y";
    this.setState({ error_customer_lastname: true });
    return false;
  }else{
    this.setState({ error_customer_lastname: false });
  }

  if (this.state.birthdate === "") {
    errorFlg = "y";
    this.setState({ error_customer_birthaday_error: true });
    return false;
  }else{
    this.setState({ error_customer_birthaday_error: false });
  }

  if (this.state.customer_register_password=== "") {
    errorFlg = "y";
    this.setState({ customer_register_password_error: true });
    return false;
  }else{
    this.setState({ customer_register_password_error: false });
  }

  if (this.state.customer_confpassword_error === "") {
    errorFlg = "y";
    this.setState({ customer_confpassword_error: true });
    return false;
  }else{

    this.setState({ customer_confpassword_error: false });
  }

   if (this.state.customer_register_password !== this.state.customer_confpassword) {
    errorFlg = "y";
    this.setState({ customer_confpassword_match: true });
    return false;
  }else{
    this.setState({ customer_confpassword_match: false });
  }

 if (this.state.isChecked === false) {
    errorFlg = "y";
    this.setState({ terms_error : true });
    return false;
  }else{
    this.setState({ terms_error: false });
  }

  if (errorFlg === "n") {

    var customermobile_number  = this.state.customermobile_number .slice(2);

      var postObject = {
        cust_firstname: this.state.customer_firstname,
        cust_lastname: this.state.customer_lastname,
        cust_phone: customermobile_number,
        cust_email: this.state.customer_email,
        cust_dob: this.state.birthdate,
        customer_verification: "verified", 
        customer_password: this.state.customer_confpassword, 
      };

      axios.post(apiUrl + "chopchop/customer/customer_home_registration", qs.stringify(postObject)).then((res) => {
        
              if (res.data.status === "ok") {

                reactLocalStorage.set("login_type", "customer");
                reactLocalStorage.set("LoyaltyUserId", res.data.result_set.customer_id);
                reactLocalStorage.set("UserMobile", res.data.result_set.customer_phone);
                reactLocalStorage.set("UserEmail", res.data.result_set.customer_email);
                reactLocalStorage.set("UserFname", res.data.result_set.customer_first_name);
                reactLocalStorage.set("UserLname", res.data.result_set.customer_last_name);

                var cust_birthdate = "";

                if (typeof res.data.result_set.customer_birthdate !== "undefined" &&
                    res.data.result_set.customer_birthdate !== "null" &&
                   res.data.result_set.customer_birthdate !== null &&
                    res.data.result_set.customer_birthdate !== "" &&
                    res.data.result_set.customer_birthdate !== "0000-00-00"
                  ) {
                    cust_birthdate = res.data.result_set.customer_birthdate;
                  }

                cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
                cookie.remove("register_customer_mobile_number",{ path: "/" });

                reactLocalStorage.remove("UserBirthdate");
                reactLocalStorage.remove("register_customer_mobile_number");

                $.magnificPopup.open({
                  items: {
                    src: "#register-success-popup",
                  },
                  type: "inline",
                });
                
              } else {
                showCustomAlert("error", res.data.message);
              }
            });

  }

}

  openDashboard(event){
    event.preventDefault();
    $.magnificPopup.close();
    this.props.history.push("/customer/dashboard");
  }

 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    reactLocalStorage.set("birthdate", dateval);
    this.handleChange("birthdate", datevalue);
    this.setState({ birthdate: datevalue });
  }

  handleChange = (item, value) => {
    this.setState({ ...this.state, [item]: value });
    var des = document.getElementsByClassName("birthdate");
  };

toggleChange = (e) => {
 
  this.setState({
    [e.target.name]: e.target.value,
  });

}

  customerNumberPopupSubmit(){

    var customer_phone = this.state.customer_phone.replace(this.state.country, "");

    if(customer_phone !=="" && customer_phone.length >= 8){

      var postObject = {
        //app_id: appId,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
      };
      //this.props.getCustomerLoginData(qs.stringify(postObject));
      axios.post(apiUrl + "chopchop/customer/customer_home_login", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {
          reactLocalStorage.set("UserFname", res.data.result_set.customer_first_name !== ""
              ? res.data.result_set.customer_first_name
              : "");
          reactLocalStorage.set("UserLname", res.data.result_set.customer_last_name !== ""
              ? res.data.result_set.customer_last_name
              : "");

          this.setState({error_phonenumber: false, passwordEnterInputPopup: true, customermobile_number: '65'+customer_phone});
          this.doUsernamePopup(res.data);
        }else{

          $.magnificPopup.open({
            items: {
              src: "#otp-popup",
            },
            type: "inline",
          });
          this.setState({ invalid_customer_number: res.data.message, customermobile_number: '65'+customer_phone });
        }
      });

    }else {

      this.setState({error_phonenumber: true});

    }

  }

  forgot_password() {
    $.magnificPopup.open({
      items: {
        src: "#forgot-password-popup",
      },
      type: "inline",
    });
  }

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      type: "web",
      email_address: formPayload.email,
      site_url: this.state.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      logintype: "mobile",
      cust_phone: formPayload.mobile,
    };
    showLoader("login_submit", "class");
    //this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var pdpaConsent = formPayload.pdpa_consent === true ? "yes" : "no";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      registertype: "mobile",
      passwordtype: "pin",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_pin: formPayload.pin,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/


  /* Change Password */
  fieldChangepassword = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };


 changepassword = () => {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("LoyaltyUserId"),
    };
    showLoader("change-pass-sbmt", "class");
    var qs = require("qs");
    this.props.getChangePassword(qs.stringify(postObject));
  }

  componentWillReceiveProps(PropsDt) {

    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.logindata !== this.props.logindata) {
      if(PropsDt.logindata[0] !== "" && PropsDt.logindata[0] !== undefined){
      //this.doPageHidePopup(PropsDt.logindata[0]);
      }
    }


    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {

      var deliveryOutletId = this.state.delivery_outlet_id;     
      var singleOutletSelect = 'no';  

       if(Object.keys(PropsDt.alloutletslist).length === 1) {
        deliveryOutletId = PropsDt.alloutletslist[0].outlet_id;
        singleOutletSelect = 'yes';
       }
          
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
        delivery_outlet_id: deliveryOutletId, 
        single_outlet_select: singleOutletSelect
      });

    }



    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (PropsDt.registration !== undefined) {
        this.setState({ regstatus: "ok" });
        this.showMessage(PropsDt.registration[0]);
      }
    }


    if (PropsDt.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (PropsDt.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (PropsDt.changepassword[0].form_error) {
          var string_replace_password = PropsDt.changepassword[0].form_error;
          showAlert("Error", string_replace_password.replaceAll("PIN", "Password"));
        } else {
          var string_replace_message = PropsDt.changepassword[0].message;
          showAlert("Error", string_replace_message.replaceAll("PIN", "Password"));
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
      
  }

  doUsernamePopup(fbloginData){

   if (fbloginData.status === "ok") {
      $.magnificPopup.open({
        items: {
          src: "#password-popup",
        },
        type: "inline",
      });
    }

  }

  /*doPageHidePopup(fbloginData){

    if (fbloginData.status === "ok" ) {

      if(this.state.phonenumbersuccessPopup === true){

        this.setState({passwordEnterInputPopup: true, phonenumbersuccessPopup:false});

        this.doUsernamePopup(fbloginData);
        console.log(fbloginData,"doUsernamePopup");
      }else if(this.state.passwordEnterInputPopup === true && this.state.passwordEnterInputPopup === true){
        this.doLoginPopup(fbloginData);
        console.log(fbloginData,"doLoginPopup");
      }

    }else if(fbloginData.status === "error"){

     //showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: "#otp-popup",
        },
        type: "inline",
      });


     if(this.state.phonenumbersuccessPopup === true){

       $.magnificPopup.open({
        items: {
          src: "#otp-popup",
        },
        type: "inline",
      });
    }

   }

  }*/

  mobileNumberPopup(type){

    var customermobile_number = this.state.customermobile_number;
    customermobile_number =  customermobile_number.slice(2);
    var customer_otp = this.state.customer_otp;

    if(customermobile_number !== "" && customermobile_number !== null && type == "otp"){

      this.setState({customermobile_number_error:false});

      reactLocalStorage.set("register_customer_mobile_number", this.state.customermobile_number);

      var postObject = {
        verify_type: "register_loyality",
        customer_phone: customermobile_number, 
      }

       axios.post(apiUrl + "chopchop/customer/send_customer_otp", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {
     
            this.setState({ customermobile_number_error:false, openOtpinput: true, seconds:59});
            this.startTimer();
          
        } else {

          this.setState({ invalid_customer_error: res.data.message, openOtpinput: false});
        }

      });
    }else{

      this.setState({customermobile_number_error:true});
    }


     if(type == "verification" && customermobile_number !==""){

       if (
        this.state.code_digit1 !== "" ||
        this.state.code_digit2 !== "" ||
        this.state.code_digit3 !== "" ||
        this.state.code_digit4 !== "" ||
        this.state.code_digit5 !== "" ||
        this.state.code_digit6 !== ""
      ) {

     var enterOTP =
          this.state.code_digit1 +
          this.state.code_digit2 +
          this.state.code_digit3 +
          this.state.code_digit4 +
          this.state.code_digit5 +
          this.state.code_digit6;


      var postObject = {
        verify_type: "register_loyality",
        //app_id: appId,
        customer_phone: customermobile_number,
        customer_otp_val: enterOTP,
      };

       axios.post(apiUrl + "chopchop/customer/customer_otp_verification", qs.stringify(postObject)).then((res) => {

        if(res.data.status === "ok") {

            reactLocalStorage.set("register_customer_mobile_number", this.state.customermobile_number);
     
            this.setState({ invalid_otp_error:''});

            //this.props.history.push("/customerregister");
            
            $.magnificPopup.open({
              items: {
                src: "#register-popup",
              },
              type: "inline",
            });
          
        } else {

          this.setState({ invalid_otp_error: res.data.message});
        }
    });

    }

   }

  }

  handleVerificationCode(first, last) {
    var name = last.target.name;
    var value = last.target.value;
    var element_number = parseInt(first) + 1;

    this.setState({ [name]: last.target.value}, function () {
    
    if(element_number <= 6){
    var element_id = "code_digit" + element_number;
    document.getElementById(element_id).focus();
     }
   });
  }

 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/page/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a

            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        
        var url_name = "";
        if(cookie.load("login_type") === "customer"){
          url_name = "/customer";
        }else{
          url_name = "/merchant";
        }

        return (
          <Link
            to={url_name+ menu.nav_pages} 
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
            onClick={this.openCategoryNav.bind(this, menu.nav_pages)}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
     }
    }

  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function (menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/page/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return <a href="/" className="submenu-arow disbl_href_action"></a>;
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (nav_pages === "home" || nav_pages === "#") {
      returnClsTx =
        this.props.match.path === "/home" || this.props.match.path === "/"
          ? "active"
          : "";
    } else if (nav_pages === "products") {
      returnClsTx =
        this.props.match.path === "/products" ||
        this.props.match.path === "/products/:slugType/:slugValue" ||
        this.props.match.path === "/products/:slugType/:slugValue/:proValue" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou/:orderId"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : "";
    }
    return returnClsTx;
  }
  openCategoryNav(pageLink, event) {

    if (pageLink === "products") {
      if (
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
      ) {
      }
    }

  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        return (
          <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
            {(cookie.load("UserId") !=='' && cookie.load("UserId") !==undefined ? this.createLink(menu) : "") }
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */
  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Error", response.form_error);
      } else {
        showAlert("Error", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability && typeof cookie.load("defaultAvilablityId") != undefined ) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
      this.props.history.push('/products');
      return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      ///popupIdtxt = "#delevery-redirect-popup";
      //popupIdtxt = "#delevery-postcode-popup";
      popupIdtxt = (this.state.single_outlet_select === 'yes') ? '#delevery-postcode-popup' : '#delevery-popup';
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  chooseAvailabilityMbl() {
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "Order Now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Takeaway";
    }
    return showtext;
  }

  /* facebook login */
  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];
      var birthday = response.birthday;
      var qs = require("qs");
      var photo = response.picture.data.url;
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }
      /* update DOB */
      var dob = "";
      if (typeof birthday !== "undefined" && birthday !== "") {
        dob = dateFormat(response.birthday, "yyyy-mm-dd");
      }

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: response.first_name,
        login_lastname: response.last_name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
        customer_photo: photo,
        customer_dob: dob,
      };
      this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  /* for popup login*/
  doLoginPopup(fbloginData) {
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }

      reactLocalStorage.set("app_id", fbloginData.result_set.customer_app_id);
      reactLocalStorage.set("login_type", 'customer');
      reactLocalStorage.set("UserBirthdate", cust_birthdate);
      reactLocalStorage.set("UserMobile", mobileno);
      reactLocalStorage.set("UserLname", fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "");
      reactLocalStorage.set("UserFname", fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "");

      reactLocalStorage.set("UserEmail", fbloginData.result_set.customer_email);
      reactLocalStorage.set("LoyaltyUserId", fbloginData.result_set.customer_id);

      this.props.history.push("/customer/dashboard");

    } else {

      showAlert("Error", fbloginData.message);
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }

      reactLocalStorage.set("app_id", fbloginData.result_set.customer_app_id);
      reactLocalStorage.set("login_type", 'customer');
      reactLocalStorage.set("UserBirthdate", cust_birthdate);
      reactLocalStorage.set("UserMobile", mobileno);
      reactLocalStorage.set("UserLname", fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "");
      reactLocalStorage.set("UserFname", fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "");

      reactLocalStorage.set("UserEmail", fbloginData.result_set.customer_email);
      reactLocalStorage.set("UserDefaultAddress", fbloginData.result_set.customer_address_name);
      reactLocalStorage.set("UserDefaultUnitOne", fbloginData.result_set.customer_address_line1);
      reactLocalStorage.set("UserDefaultUnitTwo", fbloginData.result_set.customer_address_line2);
      reactLocalStorage.set("UserDefaultPostalCode", fbloginData.result_set.customer_postal_code);
      reactLocalStorage.set("UserId", fbloginData.result_set.customer_id);

      const { history } = this.props;

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        reference_id: getReferenceID(),
        customer_id: fbloginData.result_set.customer_id,
        availability_id: cookie.load("defaultAvilablityId"),
      };

      var postObjectDetails = {
        app_id: appId,
        customer_id: fbloginData.result_set.customer_id,

      }

      axios
        .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
        .then((res) => {
          showAlert("Success", "Logged in Successfully!");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          if (res.data.status === "ok") {
            if (cookie.load("loginpopupTrigger") === "fromcheckout") {
              cookie.remove("loginpopupTrigger", { path: "/" });
              history.push("/checkout");
            }else if (cookie.load("redirectReservatin") === "Yes") {
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
            } else {
              history.push("dashboard");
            }
          } else {
              if (cookie.load("redirectReservatin") === "Yes") {
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
              } else {
                history.push("dashboard");
              }
          }
        });

        axios
        .post(apiUrl + "chopchop/customer/update_customer_company_activity", qs.stringify(postObjectDetails))
        .then((res) => {
          if (res.data.status === "ok") {
          } 
        });

    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      /*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/
      showAlert("Error", fbloginData.message);
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }


/*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
      this.setState(update(this.state, { fieldscheckout: { [field]: { $set: value } } }))
  }

  handleCheckout = () => {
        const formPayload = this.state.fieldscheckout;
        if (document.getElementById("spn-email-error").innerHTML === '<span class="error">This email already exists</span>') {
            return false;
        }
        if (document.getElementById("spn-mobile-error").innerHTML === '<span class="error">This mobile number already exists</span>') {
            return false;
        }
    
    showLoader('login_in_guest','class');
    
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.check_firstname,
            "customer_email": formPayload.check_email,
            "customer_phone": formPayload.check_phone,
            "site_url": this.props.globalsettings[0].result_set.client_site_url,
        };
        
        axios.post(apiUrl + "guestaccount/create", qs.stringify(postObject))
            .then(response => {
                hideLoader('login_in_guest','class');
                if (response.data.status === "ok") {
                    window.scrollTo(0, 0);
                    $.magnificPopup.close();
                   
                    /* set User cookie values - Start */
                    cookie.save("UserId", response.data.result_set.customer_id, { path: "/" });
                     cookie.save("UserFname",response.data.result_set.customer_first_name !== ""
                          ? response.data.result_set.customer_first_name
                          : "",
                        { path: "/" }
                      );
                    cookie.save(
                      "UserLname",
                      response.data.result_set.customer_last_name !== ""
                        ? response.data.result_set.customer_last_name
                        : "",
                      { path: "/" }
                    );
                    cookie.save("UserMobile", response.data.result_set.customer_phone, { path: "/" });
                    cookie.save("UserEmail", response.data.result_set.customer_email, { path: "/", });                  
                    cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name, { path: "/" });
                    cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1, { path: "/" });
                    cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2, { path: "/" });
                    cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code, { path: "/" });
         
           const{history} = this.props;
          
          if(cookie.load('cateringPay') === 'start') {
            showAlert('Success', 'Logged in Successfully!');
            $.magnificPopup.open({
              items: {
                src: '.alert_popup'
              },
              type: 'inline'
            });
            history.push("/payment/"+cookie.load('orderIdTxt')+"/"+cookie.load('paymentIdTxt'));
            } else {

                    var qs = require('qs');
                    var postObject = {
                        "app_id": appId,
                        "reference_id": getReferenceID(),
                        "customer_id": response.data.result_set.customer_id,
                        "availability_id": cookie.load('defaultAvilablityId')
                    };

               axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject)).then(res => {
             showAlert('Success', 'Logged in Successfully!');
             $.magnificPopup.open({
              items: {
                src: '.alert_popup'
              },
              type: 'inline'
             });
               if (res.data.status === "ok") {
              if(cookie.load('loginpopupTrigger')==='fromcheckout') {
                cookie.remove('loginpopupTrigger');
                history.push("/checkout");
              } else {
                history.push("/dashboard");
              }
              
              } else {
            
                history.push("/dashboard");
              
               }

             });

          }
                  
            } else {
          
          if (response.data.form_error) {
           $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.form_error+'</div>');

                } else {

              cookie.remove("loginpopupTrigger");
              showAlert("Error", response.data.message);
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });

              $('.guest-chk-error').html('<div class="alert alert_danger" style="display:block">'+response.data.message+'</div>');

                }
            }
            return false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }


  /* Google Login */
  prepareLoginButton = () => {
    if (this.state.trigerGlogin == false) {
      this.setState({ trigerGlogin: true }, function () {
        setTimeout(function () {
          $(".loginBtn--google").trigger("click");
        }, 1000);
      });
    }
    var current = this;
    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        var resultRes = {
          name: profile.getName(),
          email: profile.getEmail(),
          picture: profile.getImageUrl(),
          id: profile.getId(),
        };
        //console.log(resultRes, "resultRes");
        current.loginGoogle(resultRes);
      },
      (error) => {
        //console.log(error, "googleerror");
        //alert(JSON.stringify(error, undefined, 2));
      }
    );
  };

  googleSDK() {
    //console.log(googleAppId, "googleAppId");
    window["googleSDKLoaded"] = () => {
      window["gapi"].load("auth2", () => {
        this.auth2 = window["gapi"].auth2.init({
          client_id: googleAppId,
          cookiepolicy: "single_host_origin",
          scope: "profile email",
        });

        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  }

  loginGoogle(response) {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];

      var qs = require("qs");
      var photo = response.picture;
      /* update gender field */

      var postObject = {
        app_id: appId,
        type: "web",
        login_firstname: firstname,
        login_lastname: lastname,
        login_username: response.email,
        customer_google_id: response.id,
        customer_photo: photo,
      };
      this.setState({ googlestatus: "loading" });
      this.props.getGoogleLoginData(qs.stringify(postObject));
    }
  }

  closepopup(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#delevery-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#takeaway-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
    });

    /* For Advanced Slot Start */
    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      slotType: tempVl,
      seleted_ord_slot: tempVl,
      seleted_ord_slotTxt: tempVl,
      seleted_ord_slot_str: tempVl,
      seleted_ord_slot_end: tempVl,
    });
    /* For Advanced Slot End */

    this.destroyCart("Yes");

    var popupIdtxt = "";
    if (this.state.nextavail === deliveryId) {
     // popupIdtxt = "#delevery-redirect-popup";
     //popupIdtxt = "#delevery-postcode-popup";
     popupIdtxt = (this.state.single_outlet_select === 'yes') ? '#delevery-postcode-popup' : '#delevery-popup';
    } else if (this.state.nextavail === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else {
      $.magnificPopup.close();
      popupIdtxt = "#order-popup";
      this.props.history.push("/");
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
      cookie.remove("outletchosen", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();

  //  console.log("ckvremove");

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    /* For Advanced Slot */
    cookie.remove("isAdvanced", { path: "/" });
    cookie.remove("slotType", { path: "/" });
    cookie.remove("orderSlotVal", { path: "/" });
    cookie.remove("orderSlotTxt", { path: "/" });
    cookie.remove("orderSlotStrTime", { path: "/" });
    cookie.remove("orderSlotEndTime", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

     var outletIdTxt = this.state.delivery_outlet_id;

    if (outletIdTxt === "") {
      $(".postal_error").html(
        '<span class="error">Go Back and Select your delivery outlet.</span>'
      );
      return false;
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&skip_timing=Yes&availability_id=" +
            availability +
            "&postal_code=" +
            postalcode +
           // "&postalcode_basedoutlet=yes"+
            "&outlet_id="+outletIdTxt
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          var deliveryInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("outletchosen", availability, { path: "/" });

            var additionalTatTime =
              res.data.result_set.zone_additional_tat_time !== ""
                ? res.data.result_set.zone_additional_tat_time
                : 0;
            var outletDeliveryTiming =
              res.data.result_set.outlet_delivery_timing !== ""
                ? res.data.result_set.outlet_delivery_timing
                : 0;
            var outletDeliveryTaT =
              parseInt(outletDeliveryTiming) + parseInt(additionalTatTime);

            var orderDeliveryAddress =
              res.data.result_set.postal_code_information.zip_buno +
              " " +
              res.data.result_set.postal_code_information.zip_sname;
            var orderHandled =
              stripslashes(res.data.result_set.outlet_name) +
              ", Crew will be seeing you in " +
              outletDeliveryTaT +
              " Minutes";

            deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
            deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
            deliveryInfo["orderOutletName"] = stripslashes(
              res.data.result_set.outlet_name
            );
            deliveryInfo["orderPostalCode"] =
              res.data.result_set.postal_code_information.zip_code;
            deliveryInfo["orderTAT"] = outletDeliveryTaT;
            deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
            deliveryInfo["orderHandled"] = orderHandled;
            deliveryInfo["defaultAvilablityId"] = availability;

            var unitNum = this.showUnitNum(
              res.data.result_set.outlet_unit_number1,
              res.data.result_set.outlet_unit_number2
            );

            var orderHandledText =
              res.data.result_set.outlet_address_line1 +
              " " +
              res.data.result_set.outlet_address_line2 +
              ", " +
              unitNum +
              " Singapore " +
              res.data.result_set.outlet_postal_code;

              deliveryInfo["orderHandledByText"] = orderHandledText;

            this.setState(
              {
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: outletDeliveryTaT,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  res.data.result_set.postal_code_information.zip_code,
              },
              function () {
                axios
                  .get(
                    apiUrlV2 +
                      "settings/chkTimeslotIsAvaiable?app_id=" +
                      appId +
                      "&availability_id=" +
                      availability +
                      "&outletId=" +
                      res.data.result_set.outlet_id
                  )
                  .then((timeslt) => {
                    hideLoader("delivery_submit_cls", "class");
                    $.magnificPopup.close();
                    /* Success time slot response */
                    if (timeslt.data.status === "success") {
                      /* For Advanced Slot */
                      var isAdvanced = timeslt.data.isAdvanced,
                        slotType = "0";
                      if (isAdvanced === "yes") {
                        slotType = timeslt.data.slotType;
                      }
                      this.setState({
                        getDateTimeFlg: "yes",
                        isAdvanced: isAdvanced,
                        slotType: slotType,
                      });
                      removeOrderDateTime();
                      removePromoCkValue();

                      $.magnificPopup.open({
                        items: {
                          src: "#awesome-popup",
                        },
                        type: "inline",
                      });
                    } else {
                      $.magnificPopup.open({
                        items: {
                          src: "#outlet-error-popup",
                        },
                        type: "inline",
                      });
                    }
                  });
              }
            );
          }

          /* Error response */
          if (res.data.status === "error") {
            this.setState({ deliveryInfo: deliveryInfo });
            hideLoader("delivery_submit_cls", "class");
            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: "#error-postal-popup",
              },
              type: "inline",
            });

            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Please enter valid postal code.";
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }
  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          $.magnificPopup.close();
          cookie.save("outletchosen", availability, { path: "/" });
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";
          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress +
              " Singapore " +
              res.data.result_set.postal_code_information.zip_code,
          });
          this.setState({ orderHandled: orderHandled });
          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          cookie.save(
            "orderPostalCode",
            res.data.result_set.postal_code_information.zip_code,
            { path: "/" }
          );
          cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
            path: "/",
          });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", Singapore " +
            postalcode;
           cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
        }

        /* Error response */
        if (res.data.status === "error") {
          $.magnificPopup.close();
          $.magnificPopup.open({
            items: {
              src: "#error-postal-popup",
            },
            type: "inline",
          });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      cookie.save("outletchosen", cookie.load("defaultAvilablityId"), {
        path: "/",
      });
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        this.props.history.push("/products");
      }
    }
  }

  selectDatetm() {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) {
      showLoader("takeaway-btn-act", "class");

      axios
        .get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            pickupId +
            "&outletId=" +
            seletedOutletId
        )
        .then((res) => {
          hideLoader("takeaway-btn-act", "class");

          /* Success response */
          if (res.data.status === "success") {
            /* For Advanced Slot */
            var isAdvanced = res.data.isAdvanced,
              slotType = "0";
            if (isAdvanced === "yes") {
              slotType = res.data.slotType;
            }
            this.setState({
              getDateTimeFlg: "yes",
              isAdvanced: isAdvanced,
              slotType: slotType,
            });

            removeOrderDateTime();
            removePromoCkValue();

            $.magnificPopup.open({
              items: {
                src: "#awesome-popup",
              },
              type: "inline",
            });
          } else {
            $.magnificPopup.open({
              items: {
                src: "#outlet-error-popup",
              },
              type: "inline",
            });
          }
        });
    } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.pickOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo + "," : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    event.preventDefault();
    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidMount() {
    if (
      cookie.load("openLogin") !== undefined &&
      typeof cookie.load("openLogin") !== undefined &&
      typeof cookie.load("openLogin") !== "undefined"
    ) {
      cookie.remove("openLogin", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });

    $("html, body").animate(
      {
        scrollTop: $("body").offset().top,
      },
      100
    );

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    var login_type = "customer";
    this.props.getMenuData("header-menu-fllvp9k2qcwtbmmnacy51qxsz", login_type);
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    $(".hcategory_trigger").click(function () {
      $(this).toggleClass("active");
      $(".hcategory_menu").toggleClass("open");
    });

    $(document).click(function (e) {
      if (!$(e.target).is(".hcategory_trigger")) {
        if ($(".hcategory_menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".hcategory_trigger").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });

    /*this.getOptions.bind(this,"mill");*/
  }

  componentDidUpdate() {}


  searchProKeyPress = (event) => {
    $(".search_result").show();

    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        return item.label.substring(0, value.length).toLowerCase() === value;
      });

    $("#clearSearch").show();
    if (value === "") {
      $("#clearSearch").hide();
    }

    this.setState({ selectedProResult: matches });
  };

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/products/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      return <li className="no-pro-found">No Product found</li>;
    }
  }

  clearSearchFun() {
    var emtytxt = "";
    $(".productsearch").val(emtytxt);
    this.setState({ selectedProResult: this.state.searchProResult });
  }

  getOptions = (input) => {
    var searchProResult = this.state.searchProResult;
    var searchResult = [];
    /*if (Object.keys(searchProResult).length > 0) {*/
    /*searchProResult.map((loadData) =>
        searchResult.push({ value: loadData.value, label: loadData.label })
       );*/
    searchResult.push({ value: "wqewrr", label: "fish cury" });
    searchResult.push({ value: "werew3", label: "fish cury2" });
    // console.log("wlll");
    // console.log(searchResult);
    // console.log("input");
    // console.log(input);
    return { options: searchResult };
    /*}*/
  };

  ViewProducts(event) {
    var productSlug = event.value;
    this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkAblBtn() {
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var actText = "Order Now";
    var extraclass = "";
    
    if (
      availability === deliveryId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Delivery";
      extraclass = "home-btn";
    } else if (
      availability === pickupId &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      actText = "Takeaway";
      extraclass = "home-btn";
    }
    return (
      <a
        href="/"
        onClick={this.closepopup.bind(this)}
        className={"hordertype_btn " + extraclass}
        title={actText}
      >
        {actText}
         {((cookie.load("defaultAvilablityId") == deliveryId || cookie.load("defaultAvilablityId") == pickupId )) && cookie.load("orderOutletId") !=="" && cookie.load("orderOutletId") !== undefined ? <span className="header-arrow header-mbl-arrow"><img src={navarrowdown} /></span> : "" }
      </a>
    );
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  checkActiveDivMbl(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } 
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "dashboard";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <a
            href={baseUrl + "dashboard"}
            className="myacunt_act"
            title="Dashboard"
          >
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode1").val(pstVl);
    }
  }

  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  customerPasswordPopupSubmit(){
     var customer_phone = this.state.customer_phone.replace(this.state.country, "");
     var customer_password = this.state.customer_password;

    if(customer_password !==""){

      var qs = require("qs");
      var postObject = {
        //app_id: appId,
        type: "web",
        logintype: "customer",
        cust_phone: this.state.customer_phone.replace(this.state.country, ""),
        cust_password: this.state.customer_password,
      };
      axios.post(apiUrl + "chopchop/customer/customer_home_login", qs.stringify(postObject)).then((res) => {
        if (res.data.status === "ok") {

          cookie.save(
            "UserFname",
            res.data.result_set.customer_first_name !== ""
              ? res.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            res.data.result_set.customer_last_name !== ""
              ? res.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );

          reactLocalStorage.set("UserFname", res.data.result_set.customer_first_name !== ""
              ? res.data.result_set.customer_first_name
              : "");
          reactLocalStorage.set("UserFname", res.data.result_set.customer_last_name !== ""
              ? res.data.result_set.customer_last_name
              : "");

          this.setState({customer_password_error: false, customer_invalid_password: false, customermobile_number: '65'+res.data.result_set.customer_phone});
          this.doLoginPopup(res.data);
        }else{
          showAlert("Error",res.data.message);
          window.$.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.setState({ customermobile_number: '65'+customer_phone });
        }
      });
      this.setState({customer_password_error: false, customer_invalid_password:false});

    }else {

      this.setState({customer_password_error: true});

    }

  }
  
  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        /* For Advanced Slot */
        var isAdvanced = this.state.isAdvanced;
        var slotType = this.state.slotType;
        var orderSlotVal = "",
          orderSlotTxt = "",
          orderSlotStrTime = "",
          orderSlotEndTime = "";
        if (slotType === "2") {
          orderSlotVal = this.state.seleted_ord_slot;
          orderSlotTxt = this.state.seleted_ord_slotTxt;
          orderSlotStrTime = this.state.seleted_ord_slot_str;
          orderSlotEndTime = this.state.seleted_ord_slot_end;
        }
        cookie.save("isAdvanced", isAdvanced, { path: "/" });
        cookie.save("slotType", slotType, { path: "/" });
        cookie.save("orderSlotVal", orderSlotVal, { path: "/" });
        cookie.save("orderSlotTxt", orderSlotTxt, { path: "/" });
        cookie.save("orderSlotStrTime", orderSlotStrTime, { path: "/" });
        cookie.save("orderSlotEndTime", orderSlotEndTime, { path: "/" });
        /* For Advanced Slot End */

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });
        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });
        cookie.save("outletchosen", orderInfoData["defaultAvilablityId"], {
          path: "/",
        });

        $.magnificPopup.close();
        if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" });
          this.props.history.push("/");
        } else {
          if (
            this.props.match.path === "/products" ||
            this.props.match.path ===
              "/products/:slugType/:slugValue/:proValue" ||
            this.props.match.path === "/products/:slugType/:slugValue"
          ) {
            location.reload();
          } else {
            this.props.history.push("/products");
          }
        }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  searBlkFun(typeTxt) {
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var liTopCls = "htico_search";
    var scearDivTopCls = "hsearch_sec";
    var triggerActCls = "hsearch_trigger";
    var searchResultCls = "search_result";
    var searchIconcls = "search_i_icon";
    if (typeTxt === "mbl") {
      liTopCls = "htico_search_mbl";
      scearDivTopCls = "hsearch_sec_mbl";
      triggerActCls = "hsearch_trigger_mbl";
      searchResultCls = "search_result_mbl";
      searchIconcls = "fa fa-search";
    }

    return (
      <>
      <li className={liTopCls}>
        {cookie.load("orderOutletId") !== undefined && (
          <a href="/" className={triggerActCls} title="Search">
            <img src={loupe} alt="" />
          </a>
        )}

        {cookie.load("orderOutletId") === undefined && (
          <a
            data-effect="mfp-zoom-in"
            className={triggerActCls}
            title="Search"
          >
            <img src={loupe} alt="" />
          </a>
        )}

        <div className={scearDivTopCls}>
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              className="productsearch"
              id={"productsearch_" + typeTxt}
              placeholder="Search..."
              onKeyUp={this.searchProKeyPress}
            />

            <a
              href="/"
              id="clearSearch"
              onClick={this.clearSearchFun.bind(this)}
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>

          <ul className={searchResultCls} id={searchResultCls}>
            {this.loadProSearchList()}
          </ul>
        </div>
      </li>


     <li className="htico_wishlish">
          <a className="wishlish" title="Wishlist">
            <img src={wishlishImg} alt="" />
          </a>
      </li> 

      </>
    );
  }

  handleScan = (data) => {
   /// console.log(data, "data");
    if (data) {
      this.setState({
        qrcoderesult: data,
      });
    }
  };
  handleError = (err) => {
    console.error(err);
  };

  enableQrCode(e) {
    e.preventDefault();
    this.setState({ enableQrCode: true });
  }

  submitTableNumber(e) {
    e.preventDefault();
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      tm_table_number: "TB01",
    };

    axios
      .post(apiUrl + "tablemanagement/find_table", qs.stringify(postObject))
      .then((res) => {

        if (res.data.status === "ok") {
        } else {
        }
      });
  }


  openDeliveryRedirect(availability, event) {
    event.preventDefault();
     if (availability === deliveryId) {
      $.magnificPopup.open({
        items: {
          src: "#delevery-redirect-popup",
        },
        type: "inline",
      });
    }
  }


openCheckout(){

  this.props.history.push("/merchant/checkout");
  

}
  /* ViewProducts */

  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";
    var urlpath = this.props.location.pathname;


    /* For Advanced Slot Start */
    var defaultAvilTyId = cookie.load("defaultAvilablityId");
    var settingsArr = this.props.globalsettings;
    var advancedTimeslotEnable = "0";
    var client_name = "";

    var header_page_url = "customer/dashboard";
    var birthdate = new Date();
    if (this.state.birthdate !== "") {
      birthdate = this.state.birthdate;
    }

    return (
      <> 
        <header className="customer-header">
        
        <div className="header-top-cls">
          <div className="container">
            <div className="logo-main-section">
              <div className="menu_icon trigger_menunav_act">
                <span className="icon-bar icon-bar1"></span>
                <span className="icon-bar icon-bar2"></span>
                <span className="icon-bar icon-bar3"></span>
              </div>

              <div className="mobile-login">
                <a href="#" className="controller-nav">
                  <img src={avicon} alt="avatar" />
                </a>

                <ul className="mobile-login-list">
                  {!reactLocalStorage.get("UserId") && (
                    <li>
                      <a
                        href="#login-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link htico_sign"
                        title="Login"
                      >
                        <span>Login</span>
                      </a>
                      
                      <a
                        href="#signup-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link"
                        title="Sign up"
                      >
                        <span>Sign Up</span>
                      </a>
                    </li>
                  )}

                  {reactLocalStorage.get("UserId") && (
                    <li className="" key="2">
                      <a href={baseUrl + header_page_url} title="My Account">
                        <span>Dashboard</span>
                      </a>
                      <a
                        href="#change-password-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link"
                      >
                        <i className="setting_menu_list_icon password_icon"></i>
                        Change Password
                      </a>
                      <Link to="/logout" title="Logout">
                        <span>Logout</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>

              <div className="mobile-menu">
                <div className="mobile-menu-header">
                  <div className="mobile-menu-close">
                    <span id="close_mobile_menu"></span>
                  </div>
                </div>
                <div className="mobile-menu-body">
                  <ul className="menu">
                    {this.state.menudata && this.listMainNavigation()}
                    {reactLocalStorage.get("LoyaltyUserId") && (
                      <>
                      <li> 
                      <Link to={"/customer/dashboard"} className={"merchant_menu "+ (activeUrl == '/customer/dashboard')?"active":""} title="Dashboard"><span>Dashboard</span></Link>
                      </li>
                      <li> 
                      <Link to={"/customer/myaccount"} className={"merchant_menu "+ (activeUrl == '/customer/dashboard')?"active":""} title="My Account"><span>My Account</span></Link>
                      </li>
                      </>)}
                     <li> 
                      <a target="_blank" href="https://support.ninjaos.com/" className="merchant_menu" title="Contact Support"><span>Contact Support</span></a>
                    </li>
                    {reactLocalStorage.get("LoyaltyUserId") && (
                    <li> 
                      <a href="/logout" className="merchant_menu" title="Contact Support"><span>Logout</span></a>
                    </li>                  
                    )}
                  </ul>
                </div>
              </div>

              

              <div className="logo">
                <Link to={"/" +header_page_url} title="Loyalty System">
                  <img src={footerLogo} alt="Logo" />
                </Link>
              </div>

              <div className="hmenu-login-section hdropdown-list">

               {(urlpath === '/') && (
                <ul className="desktop-login-list">
                  {!reactLocalStorage.get("LoyaltyUserId") && (
                    <li className="hordertype_sec">
                      <a
                        href="#login-popup"
                        data-effect="mfp-zoom-in"
                        className="hordertype_btn open-popup-link htico_sign"
                        title="Login"
                      >
                        <span>Login</span>
                      </a>
                    </li>
                  )}

                  {/*{reactLocalStorage.get("UserId") && (
                    <li className="" key="2">
                      <a href={baseUrl + header_page_url} title="My Account">
                        <span>Dashboard</span>
                      </a>
                      <a
                        href="#change-password-popup"
                        data-effect="mfp-zoom-in"
                        className="open-popup-link"
                      >
                        <i className="setting_menu_list_icon password_icon"></i>
                        Change Password
                      </a>
                      <Link to="/logout" title="Logout">
                        <span>Logout</span>
                      </Link>
                    </li>
                  )}*/}
                </ul>)}

              {reactLocalStorage.get("LoyaltyUserId") && (
                <ul className="hmenu-login-act">
                    <li className="hsign_sec customer_dp_down" key="2">
                      <a
                        className="htico_sign"
                        //href={baseUrl + header_page_url}
                      >
                        <i /> <span>{reactLocalStorage.get("UserFname") + " " + reactLocalStorage.get("UserLname")}</span>
                      </a>

                       <a href="/"  className="submenu-arow disbl_href_action"></a>
                        <ul className="submenu_list">

                         <li> 
                          <a className="htico_sign" title="My Account" href={"/customer/myaccount"}><span>My Account</span></a>
                        </li>

                         <li> 
                          <a target="_blank" href="https://support.ninjaos.com/" className="htico_sign" title="Contactsupport"><span>Contact support</span></a>
                        </li>

                        <li> 
                          <a className="htico_sign" title="Logout" href="/logout"><span>Logout</span></a>
                        </li>


                      </ul>

                    </li>
                  
                </ul>
                )}


              </div>

             {((cookie.load("login_type") === "merchant") && (cookie.load("merchant_id") !== "" && cookie.load("merchant_id") !== undefined )) &&  (
                
               <div className="hmenu-login-section">
               <a className="hcartdd_trigger" onClick={this.openCheckout.bind(this)}    
                title="Cart">
                <img src={shoppingBag} alt="" />
                </a>
              </div>
              )}

            </div>
          </div>
        </div>

        {/* mobile_accountdel_body - start */}
        <div className="mobile-order-nowbtn">{this.checkAblBtn()}</div>
        {/* mobile_accountdel_body - end */}



        <div
          className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">
            Nice! Products added to your cart
          </p>{" "}
        </div>

        <div
          className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-error-msg">Something went wrong</p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
          id="jquery-common-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-success-msg">
            Nice! Products added to your cart
          </p>{" "}
        </div>

        <div
          className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
          id="jquery-common-error-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-common-error-msg">Something went wrong</p>{" "}
        </div>

        {/* login popup */}

        <div id="login-popup" className="white-popup mfp-hide popup_sec login-popup">
          <div className="login-innermain">
            <div className="login-lhs">
              <div className="login-lhs-inner">
                {/*<img src={loginav} alt="Login" />*/}
                <h3>Everything within one platform</h3>
                <p>The best deal you could get in town!</p>
              </div>
            </div>

            <div className="login-rhs">
              <div className="login-rhs-header">
                <h4>Welcome to ChopChop Rewards</h4>
                <p>Login and register an account to find all your best deals here.</p>
              </div>

              <div className="login-rhs-body">
              {this.state.phonenumbersuccessPopup === true && (
                <form>
                       <PhoneInput
                        country={"sg"}
                        value={this.state.country_code+ this.state.customer_phone}
                        countryCodeEditable={false}
                        onChange={(customer_phone, country) =>
                          this.setState({
                              customer_phone,
                              country: country.dialCode,
                            }) }/>

                        {this.state.error_phonenumber === true && (
                           <span className="error">Please enter the fields</span>
                         )}
                <div className="login-button">
                  <button type="button" className="button delivery_submit_cls" onClick={this.customerNumberPopupSubmit.bind(this)}>NEXT</button>
                </div>
                </form>)}
              </div>
            </div>
          </div>
        </div>

      {/* password popup */}

        <div id="password-popup" className="white-popup mfp-hide popup_sec login-popup">
          <div className="login-innermain">
            <div className="login-lhs">
              <div className="login-lhs-inner">
                {/*<img src={loginav} alt="Login" />*/}
                <h3>Everything within one platform</h3>
                <p>The best deal you could get in town!</p>
              </div>
            </div>

            <div className="login-rhs">
              <div className="login-rhs-header">
                <h4>Welcome back, {reactLocalStorage.get("UserFname") + " " + reactLocalStorage.get("UserLname")}</h4>
              </div>

                <div className="login-rhs-body forgot-div">

                {this.state.passwordEnterInputPopup === true && (

                 <form>
                  
                 <div className="custm_password">
                  <input type="password" className="form-control" id="customer_password" name="customer_password" placeholder="Enter your password" value={this.state.customer_password} onChange={this.handlePwdTextChange.bind(this)}/>
                
                 {this.state.customer_password_error === true && (
                    <span className="error">Please fill in your password.</span>
                  )}    
                
                  {this.state.customer_invalid_password === true && (
                    <span className="error">PSorry, that password isn’t right.</span>
                  )}    
                </div>
                
                <div className="banner-btn">
                  <button type="button" className="button continue_btn" onClick={this.customerPasswordPopupSubmit.bind(this)}>Continue</button>
                </div> 
                <a onClick={this.forgot_password.bind(this)} className="forgt_link">Forgot Password?</a>
                </form> 
                )}
                {/*<p>We will send a verification code to <br />this number via SMS</p>*/}
              </div>
            </div>
          </div>
        </div>


      {/* Login OTP popup */}


      <div id="otp-popup" className="white-popup mfp-hide popup_sec login-popup">
          <div className="login-innermain">
            <div className="login-lhs">
              <div className="login-lhs-inner">
                {/*<img src={loginav} alt="Login" />*/}
                <h3>Everything within one platform</h3>
                <p>The best deal you could get in town!</p>
              </div>
            </div>

             <div className="login-rhs">
              <div className="login-rhs-header">
                <h4>Welcome to ChopChop Rewards</h4>
                <p>Login and register an account to find all your best deals here.</p>
              </div>

              <div className="login-rhs-body">
                <form>

               {/*{this.state.openOtpinput === true && (

                <label>Phone number verification</label>

                )}*/}

                  <PhoneInput
                        country={"sg"}
                        value={this.state.customermobile_number}
                        countryCodeEditable={false}
                        onChange={(customermobile_number, country) =>
                        this.setState({
                            customermobile_number,
                        }) }
                        disabled
                        />



                {/*<label class="control-label"> Your Mobile Number</label>
                <input type="tel" name="customermobile_number" placeholder="Enter your mobile number" maxLength="8" value={this.state.customermobile_number} onChange={this.handleTextChange_getStarted.bind(this)} onKeyPress={(e) => this.validateIntval(e)} />*/}

                {this.state.invalid_customer_number !== '' && (
                  <span className="error">{this.state.invalid_customer_number}</span>
                )}

                {this.state.customermobile_number_error === true && (
                  <span className="error">This field is required</span>
                )}


                  {this.state.openOtpinput === true && (

                  <div className="verification-code-div">
                  <div className="verification-code">
                    <div className="verification-prompt">
                      <p>Please enter verification code <br/>we sent to your mobile no.</p>
                    </div>
                    <div className="prompt-box verification-code-row">
                      <input type="text" className="input-box-small" id= "code_digit1" name="code_digit1" maxLength="1" onChange={this.handleVerificationCode.bind(this, '1')} 
                      value={this.state.code_digit1} onKeyPress={(e) => this.validateIntval(e)} />
                      <input type="text" className="input-box-small" id= "code_digit2" name="code_digit2" maxLength="1" onChange={this.handleVerificationCode.bind(this, '2')}
                        value={this.state.code_digit2} onKeyPress={(e) => this.validateIntval(e)}/>
                      <input type="text" className="input-box-small" id= "code_digit3" name="code_digit3" maxLength="1" onChange={this.handleVerificationCode.bind(this, '3')}
                      value={this.state.code_digit3} onKeyPress={(e) => this.validateIntval(e)} />
                      <input type="text" className="input-box-small" id= "code_digit4" name="code_digit4" maxLength="1" onChange={this.handleVerificationCode.bind(this, '4')}
                      value={this.state.code_digit4} onKeyPress={(e) => this.validateIntval(e)} />
                      <input type="text" className="input-box-small" id= "code_digit5" name="code_digit5" maxLength="1" onChange={this.handleVerificationCode.bind(this, '5')}
                      value={this.state.code_digit5} onKeyPress={(e) => this.validateIntval(e)} />
                      <input type="text" className="input-box-small" id= "code_digit6" name="code_digit6" maxLength="1" onChange={this.handleVerificationCode.bind(this, '6')}
                      value={this.state.code_digit6} onKeyPress={(e) => this.validateIntval(e)} />
                    </div>
                  </div>
                </div>
                )}

               {this.state.invalid_otp_error !== '' && (
                  <span className="error">{this.state.invalid_otp_error}</span>
               )}

              </form>

            {this.state.openOtpinput === false && (
            <>
            <p>We will send a verification code to <br />this number via SMS</p>
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.mobileNumberPopup.bind(this, 'otp')}>NEXT</button>
            </div></>
            )}

              {this.state.openOtpinput === true && (
                <>
                  <div className="time-infodiv">
                    <p>If you do not receive the code in 60 seconds tap below to re-send it</p> 
                  </div>
                  {this.state.seconds > 0 ?
                  <><div className="countdown"><span>00:{this.state.time.s}</span> </div>
                  <div className="login-button">
                    <button type="button" className="button" onClick={this.mobileNumberPopup.bind(this, "verification")}>NEXT</button>
                  </div></>:
                  <><div className="login-btn">
                    <button type="button" class="button delivery_submit_cls" onClick={this.mobileNumberPopup.bind(this, 'otp')}>Resend code</button>
                  </div></>}
                </>
            )}

          </div>


          </div>

        </div>
      </div>

      {/* Register Popup */}

      <div id="register-popup" className="white-popup mfp-hide popup_sec login-popup">
      <div className="login-innermain">
        <div className="login-lhs">
        <div className="login-lhs-inner">
          {/*<img src={loginav} alt="Login" />*/}
          <h3>Everything within one platform</h3>
          <p>The best deal you could get in town!</p>
        </div>
      </div>
      
      <div className="login_form register_div login-rhs register-popdiv">
        <div className="register_heading_main">
          <div className="back-package">
            <a className="back-button"><img src={leftarrowImg} /></a>
          </div>
          <div className="register_heading">
            <h4>Complete signing up</h4>
          </div>
        </div>

        <div className="rpopup-body-main">
        <form>
            <PhoneInput
              country={"sg"}
              value={this.state.customermobile_number}
              countryCodeEditable={false}
              onChange={(customermobile_number, register_country) =>
              this.setState({
                  customermobile_number,
              }) }
              disabled
              />

           {/*<input type="tel" disabled placeholder="Enter your mobile number" name="customermobile_number" 
           placeholder="123-45-678" maxLength="8" value={this.state.country+this.state.customermobile_number} 
           onChange={this.handleTextChange_getStarted.bind(this)} onKeyPress={(e) => this.validateIntval(e)} />*/}
          

           {this.state.error_customer_phone === true && (
              <span className="error">This field is required.</span>
           )}    
             
          <div className="control-group">
            <input type="email" id="customer_email" name="customer_email" placeholder="Email Address" value={this.state.customer_email} onChange={this.handleTextChange_getStarted.bind(this)} />
            <label className="control-label">Email Address</label>

            {this.state.error_customer_email === true && (
              <span className="error">This field is required.</span>
            )}    
            
            {this.state.error_invalid_email === true && (
              <span className="error">This is not a valid email.</span>
            )}    
  
            
             {this.state.error_exist_email !== "" && (
              <span className="error">
                {this.state.error_exist_email}
              </span>
            )}
          </div>

          <div className="control-group">
            <input type="text" id="customer_firstname" name="customer_firstname" placeholder="First Name" value={this.state.customer_firstname} onChange={this.handleTextChange_getStarted.bind(this)}/>
            <label className="control-label">First Name</label>
          
           {this.state.error_customer_firstname === true && (
              <span className="error">This field is required.</span>
            )}    

          </div>

          <div className="control-group">
            <input type="text" id="customer_last_name" name="customer_lastname" placeholder="Last Name" value={this.state.customer_lastname} onChange={this.handleTextChange_getStarted.bind(this)}/>
            <label className="control-label">Last Name</label>
           {this.state.error_customer_lastname === true && (
              <span className="error">This field is required.</span>
           )}                  
          </div>
          <div className="control-group">
           <DatePicker
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className="form-control"
            selected={birthdate}
            maxDate={this.state.Maxdate}
            dateFormat="dd/MM/yyyy"
            onChange={this.handleChangeDate}
          />
          <label className="control-label">DOB</label>

         {this.state.error_customer_birthaday_error === true && (
            <span className="error">This field is required.</span>
         )}    

        </div>

          <div className="control-group">
            <input type="password"  name="customer_register_password" value={this.state.customer_register_password} placeholder="Enter your password"  onChange={this.handleTextChange_getStarted.bind(this)} />
            <label className="control-label">Password</label>
          </div> 

           {this.state.customer_register_password_error === true && (
              <span className="error">This field is required.</span>
           )}

          <div className="control-group">
            <input type="password" name="customer_confpassword"  value={this.state.customer_confpassword} placeholder="Enter your confirm password"  onChange={this.handleTextChange_getStarted.bind(this)}/>
            <label className="control-label">Confirm Password</label>
          </div>

          {this.state.customer_confpassword_error === true && (
              <span className="error">This field is required.</span>
           )}

           {this.state.customer_confpassword_match === true && (
              <span className="error">Password doesn't match.</span>
           )}

          <div className="terms-chk">
             <input type="checkbox" id="terms" name="isChecked" value={this.state.isChecked}  onChange={(e) => {
            this.toggleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked,
                },
              });
              }}  />
             <label className="inline">I agree with Ninja OS's  <a href="/terms-and-conditions" target="_blank">Terms &amp; Conditions</a> 
             </label>
           </div>
           {this.state.terms_error === true && (
              <span className="error">This field is required.</span>
           )}    

        </form>
        <div className="login-btn">
          <button type="button" className="button delivery_submit_cls" onClick={this.RegistrationSubmit.bind(this)}>REGISTER</button>
        </div>
      </div>
        </div>
        </div>
        </div>
      {/* Forgot Password Popup */}

        <div id="forgot-password-popup" className="white-popup mfp-hide popup_sec login-popup forgot-password-popup">

           <div className="login-innermain">
            <div className="login-lhs">
              <div className="login-lhs-inner">
                {/*<img src={loginav} alt="Login" />*/}
                <h3>Everything within one platform</h3>
                <p>The best deal you could get in town!</p>
              </div>
            </div>

            <div className="login-rhs">
              <div className="login-rhs-header">
                <h4>Forgot your Password?</h4>
                <p>You can reset your Password here.</p>
              </div>
              <div className="login-rhs-body forgot-div">
                <Forgotpassword
                  fields={this.state.fieldsfgtpassword}
                  onChange={this.fieldforgot}
                  onValid={this.forgotpassword}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
          </div>
        </div>
      </div>

      {/* Register Success Popup */}

      <div id="register-success-popup" className="white-popup mfp-hide popup_sec login-popup">
          <div className="login-innermain">
            <div className="login-lhs">
              <div className="login-lhs-inner">
                {/*<img src={loginav} alt="Login" />*/}
                <h3>Everything within one platform</h3>
                <p>The best deal you could get in town!</p>
              </div>
            </div>

            <div className="login-rhs">
              <div className="logsucess-in">
                <div className="logsucess">
                  <div className="sucess_imagediv">
                    <img src={Loginsccess} />
                  </div>
                  <h3>congratz!</h3>
                  <h6>You're ready to go!</h6>
                </div>

                <div className="login-btn">
                  <button type="button" className="button" onClick={this.openDashboard.bind(this)}>CONTINUE</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      
        
        {/* Signup popup */}
        <div
          id="signup-popup"
          className="white-popup mfp-hide popup_sec signup-popup"
        >
          <div className="pop-whole">
            <div className="pop-whole-lhs">
              <div className="pop-whole-lhs-inner">
                <img src={loginav} alt="Register" />
                <h3>Sign Up</h3>
                <p>Create Your Account with us.</p>
              </div>
            </div>


            <div className="inside-popup-rhs">

               <span className="display_block guest_checkout_text text-center">Continue As</span>
               <a href="#guest-popup" className="btn guest_btn guest_checkout open-popup-link "> <i className="fa fa-user-o" aria-hidden="true"></i> Guest Checkout </a>

               <div className="or-seperator">
                <span>Or</span>
              </div>

              <Signup
                fields={this.state.fieldssignup}
                onChange={this.fieldChangeSignup}
                onValid={this.handleSignup}
                onInvalid={() => console.log("Form invalid!")}
              />
              {/*<div className="or-seperator">
                <span>Or Sign in With</span>
              </div>*/}
             {/* <div className="form-group">
                <div className="login_pop_sub">
                  <button
                    className="btn btn_black btn_minwid login_submit_lign_gray loginBtn loginBtn--google"
                    onClick={this.googleSDK.bind(this)}
                    ref="googleLoginBtn"
                  >
                    <i className="fa fa-google" aria-hidden="true"></i>
                    <span className="span_line"></span> Sign in with Google
                  </button>
                </div>
              </div>*/}
            </div>
          </div>
        </div>

        

          {/* Change Password */}
           <div
            id="change-password-popup"
            className="white-popup mfp-hide popup_sec changepw_popup"
          >
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">Change Password</h3>
              <Changepassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldChangepassword}
                onValid={this.changepassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>      

        {/* Order popup - start */}
        <div
          id="order-popup"
          className="white-popup mfp-hide popup_sec order_popup"
        >
          <div className="order-body">
            <h2>Order Now</h2>
            <p>Select your order type</p>
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a
                      href="/"
                      //onClick={this.openDeliveryRedirect.bind(this, deliveryId)}
                      onClick={this.chooseAvailabilityFun.bind(this, deliveryId)}
                      className={this.checkActiveDivHd(deliveryId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={deliveryImg} />
                        <h3>Delivery</h3>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/"
                      onClick={this.chooseAvailabilityFun.bind(this, pickupId)}
                      className={this.checkActiveDivHd(pickupId)}
                    >
                      <div className="header-ordernow-single-img">
                        <img className="order_type_img" src={takeawayImg} />
                        <h3>Takeaway</h3>
                      </div>
                    </a>
                  </li>
          



                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Order popup - end */}



        {/* Order popup - start */}
        <div
          id="delevery-redirect-popup"
          className="white-popup mfp-hide popup_sec delivery_redirect_popup"
        >
            <div className="order_delivery_row">
              <div className="order_delivery_col">
                <ul className="order_delivery_item">
                  <li>
                    <a className="redirect_button" href="https://www.bungkus.sg/en/store/Mr-Biryani-Chander-Rd/1176754" target="_blank">Chander Road (Real Time Orders)</a>
                  </li>

                    <li>
                   <a className="redirect_button" href="https://www.bungkus.sg/en/store/Mr-Biryani-Norris-Rd/653556" target="_blank" >Norris Road (Real Time Orders)</a>
             
                  </li>

                </ul>
              </div>
          </div>
        </div>
        {/* Order popup - end */}

        {/* Delivery Popup - Start */}
        <div
          id="delevery-popup"
          className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img className="outlet-scooter-img" src={deliveryImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>Please Select</h2>
                <p>Your Delivery Outlet</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPressDly}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadDeliveryOutletsList()}
                    </ul>
                    <a
                      className="button disbl_href_action"
                      id="delivery-continue-link"
                      href="/"
                      onClick={this.selectDlyOutlet.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delivery Popup - end */}





        {/*  Delivery Postal code Popup - start */}
        <div
          id="delevery-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img className="pop-scooter-img" src={deliveryImg} />
                  <h2 className="text-uppercase">Let us know</h2>
                  <small>Your Delivery Location</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {reactLocalStorage.get("UserId") && (
                  <div className="address-list-cls address-list-main">
                    {this.userAddressList(1)}
                  </div>
                )}

                <div className="form-group">
                  <div
                    className={
                      this.state.secondaryaddresslist.length > 0
                        ? "focus-out focused"
                        : "focus-out"
                    }
                  >
                    <label>Enter your postal code</label>
                    <input
                      type="text"
                      id="postalcode"
                      onKeyPress={(e) => this.validateIntval(e)}
                      pattern="\d*"
                      maxLength="6"
                      className="form-control input-focus"
                    />
                    <div className="postal_error"></div>
                  </div>
                </div>
                <div className="btn_sec">
                  <div className="two-button-row">
                    <div className="go_second">
                      {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                      <a
                        href="/"
                        onClick={this.gobckOutletpopup.bind(this)}
                        className="button button-left disbl_href_action"
                        title="Go Back"
                      >
                        Go Back
                      </a>
                    </div>
                    <div className="con_first delivery_submit_cls">
                      {/*<input type="button" onClick={this.selectOutlet.bind(this, 1, deliveryId)} className="button button-right delivery_submit" value="Continue" />*/}
                      <input
                        type="button"
                        onClick={this.findOutletBasedZone.bind(
                          this,
                          1,
                          deliveryId
                        )}
                        className="button button-right delivery_submit"
                        value="Continue"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup - end */}

        {/* Delevery Popup error - start */}
        <div
          id="error-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconUnhappy} />
                  <h2 className="text-uppercase">Sorry </h2>
                  <small>We cant find your postal code</small>
                </div>
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                {/*<h4>Enter your postal code</h4>*/}

                {reactLocalStorage.get("UserId") && (
                  <div className="address-list-cls address-list-error">
                    {this.userAddressList(2)}
                  </div>
                )}

                <form className="form_sec">
                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Enter your postal code</label>
                      <input
                        type="text"
                        id="postalcode1"
                        pattern="\d*"
                        maxLength="6"
                        onKeyPress={(e) => this.validateIntval(e)}
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>
                  <div className="btn_sec delivery_submit_cls delivery_submit_div">
                    {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        0,
                        deliveryId
                      )}
                      className="button delivery_submit"
                      value="Continue"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="awesome-popup"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title">
                  <img className="pop-scooter-img" src={iconWin} />
                  <h2 className="text-uppercase">Awesome</h2>
                  {this.state.seletedAvilablityId === deliveryId && (
                    <small>We can Deliver !</small>
                  )}
                </div>
                {this.state.seletedAvilablityId === deliveryId ? (
                  <div className="awesome_del">
                    <h5>Your Delivery Address :</h5>
                    <h2>{this.state.orderDeliveryAddress}</h2>
                  </div>
                ) : (
                  <div className="awesome_del">
                    <h5>Your Pickup location Is :</h5>
                    <h2>{this.state.orderHandled}</h2>
                  </div>
                )}
              </div>
            </div>
            <div className="popup_right">
              <div className="innervmid_in">
                <div className="datetime_selt_sec">
                  <div className="datetime_selt_lbl">
                    {this.state.seletedAvilablityId === deliveryId
                      ? "Select Your Delivery Date & Time"
                      : "Select Your Pickup Date & Time"}
                  </div>

                  {!currenturl.includes(isCheckout) && (
                    <div>
                      {advancedTimeslotEnable === "1" ? (
                        <OrderAdvancedDatetimeSlot
                          {...this.props}
                          hdrState={this.state}
                          setdateTimeFlg={this.setdateTimeFlg}
                        />
                      ) : (
                        <OrderdatetimeSlot
                          {...this.props}
                          hdrState={this.state}
                          setdateTimeFlg={this.setdateTimeFlg}
                        />
                      )}
                    </div>
                  )}

                  <div className="ordrdatetime_error"></div>
                </div>

                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* success popup - Start */}
        <div
          id="awesome-popup-old"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="popup_equalrw">
            <div className="popup_ttsec">
              <div className="innervmid_in">
                <div className="pop_title poptt_icontop text-center">
                  <img src={iconWin} />
                  <h2 className="text-uppercase">Awesome</h2>
                  <small>We can Deliver !</small>
                </div>
                <div className="awesome_del">
                  <h5>Your Delivery Address :</h5>
                  <h2>{this.state.orderDeliveryAddress}</h2>
                </div>
                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.gotoProducts.bind(this)}
                    className="button"
                    value="Continue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/* error Popup - start */}
        <div
          id="outlet-error-popup"
          className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <h2 className="text-uppercase">Sorry</h2>
                  <p>{"We can`t Deliver At the Moment!"}</p>
                  <p>Please come back again.</p>
                  <div className="alt_btns">
                    {this.state.seletedAvilablityId === pickupId ? (
                      <a
                        href="/"
                        onClick={this.gobckPkupOutletpopup.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        change outlet
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.gobckOutletpopup.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        change address
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* error Popup - end */}

        {/* Takeaway Popup - Start */}
        <div
          id="takeaway-popup"
          className="white-popup mfp-hide popup_sec self_popup"
        >
          <div className="order-body">
            <div className="self_popup_hea_row">
              <div className="self_popup_hea_col_left">
                <img src={takeawayImg} />
              </div>
              <div className="self_popup_hea_col">
                <h2>Please Select</h2>
                <p>Your Self Collection Outlet</p>
              </div>
            </div>

            <div className="self_pop_row">
              <div className="self_pop_col self_pop_col_right">
                <div className="self_pop_item">
                  <div className="self_pop_locbx">
                    {/*<h4>Search Cedele Outlet.</h4>*/}
                    <div className="form-group">
                      <div className="focus-out">
                        <label>Search Outlet</label>
                        <input
                          type="input"
                          className="form-control input-focus"
                          onKeyUp={this.handleKeyPress}
                        />
                        <div className="outlet_error"></div>
                      </div>
                    </div>
                  </div>

                  <div className="self_outlet">
                    <h2>Nearby Outlets</h2>
                    <ul className="self_outlet_inner">
                      {this.loadOutletsList()}
                    </ul>
                    <a
                      className="button takeaway-btn-act disbl_href_action"
                      id="takeaway-continue-link"
                      href="/"
                      onClick={this.selectDatetm.bind(this)}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Takeaway Popup - end */}

        {/*  Coming Soon Popup - start */}
        <div
          id="comingsoon-popup"
          className="white-popup mfp-hide popup_sec comingsoon_popup"
        >
          <div className="order-body">
            <div className="comingsoon_popup_hea_row">
              <div className="comingsoon_popup_hea_col">
                <h2>COMING SOON.</h2>
              </div>
            </div>
            <div className="comingsoon_pop_row">
              <p> can you select another availability.</p>
              <a
                href="/"
                onClick={this.closepopup.bind(this)}
                className="button disbl_href_action"
                title="Go Back"
              >
                Go Back
              </a>
            </div>
          </div>
        </div>
        {/* Coming Soon Popup - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      No
                    </a>
                    <a
                      href="/"
                      onClick={this.changeAvailability.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}

        <div className="mbl-menu-overly"></div>
      </header>
      </>
    );
  }
}

const mapStateTopProps = (state) => {

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }


  return {
    globalsettings: state.settings,
    alloutletslist: alloutletsArr,
    forgetpassword: state.forgetpassword,
    changepassword: state.changepassword,
    registration: state.registration,
    menudata: state.menudata,
    logindata: state.customerlogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
    getMenuData: (menuslug, login_type) => {
      dispatch({ type: GET_MENUDATA, menuslug, login_type});
    },
    getCustomerLoginData: (formPayload) => {
      dispatch({ type: GET_CUSTOMER_LOGINDATA, formPayload });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(CustomerHeader));
