/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cookie from "react-cookies";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import PhoneInput from "react-phone-input-2";
import "../../common/css/countryflag.css";
import DatePicker from "react-datepicker";
import { setMinutes, setHours, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";


import { appId, apiUrl } from "../Helpers/Config";
import { stripslashes, hideLoader,validateEmailFun, showCustomAlert, showAlert} from "../Helpers/SettingHelper";
import Header from "../Layout/CustomerHeader";
import Footer from "../Layout/Footer";

import hiHndImage from "../../common/images/hand_color.png";
import locationImg from "../../common/images/location-pin.svg";
import productoneImg from "../../common/images/mgalleryone.jpg";
import productwoImg from "../../common/images/mgallerytwo.jpg";
import producthreeImg from "../../common/images/mgallerythree.jpg";
import productfourImg from "../../common/images/mgalleryfour.jpg";
import heartImg from "../../common/images/heart-black.svg";
import produclockImg from "../../common/images/clock-round-black.svg";
import productawardImg from "../../common/images/award_black.svg";
import producelabrateImg from "../../common/images/celabrate.png";
import leftarrowImg from "../../common/images/arrow-left.svg";
import footerLogo from "../../common/images/logo.svg";

import logoImg from "../../common/images/logo.png";


var Parser = require("html-react-parser");

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_GLOBAL_SETTINGS } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

var qs = require("qs");

class Pages extends Component {
  constructor(props) {
    super(props);
    
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear());



    const outlet_slug_name = reactLocalStorage.get("outlet_slug_name") !== "" &&
    typeof reactLocalStorage.get("outlet_slug_name") !== undefined &&
    typeof reactLocalStorage.get("outlet_slug_name") !== "undefined"
      ? reactLocalStorage.get("outlet_slug_name")
      : "";


    this.state = {customermobile_number: (reactLocalStorage.get("register_customer_mobile_number") !="" && 
                                          reactLocalStorage.get("register_customer_mobile_number") !==undefined ? 
                                          reactLocalStorage.get("register_customer_mobile_number") :""),
                  country: "65",
                  error_customer_phone: false, 
                  customer_email: "", 
                  error_customer_email: false,
                  error_invalid_email: "",
                  error_exist_email: "",
                  customer_firstname: "", 
                  error_customer_firstname: false,
                  customer_lastname: "",
                  error_customer_lastname: false,
                  customer_dob: false,
                  customer_dob_error: false,
                  outlet_slug_name: outlet_slug_name, 
                  customer_password: "",
                  customer_password_error: "",
                  customer_confpassword: "",
                  customer_confpassword_error: "", 
                  customer_confpassword_match: '', 
                  Maxdate: Maxdate,
                  birthdate: "",
                  error_customer_birthaday_error: "",
                  isChecked: false,
                  terms_error:false,

                 };

    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.props.getSettings();

  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    

  }

   handleTextChange_getStarted(event) {
    var value = event.target.value;

     if (event.target.name === "customermobile_number") {
      if (value !== "") {
        this.setState({ error_customer_phone: false });
      } else {
        this.setState({ error_customer_phone: true });
      }
      this.setState({ customermobile_number: value });
    } else if (event.target.name === "customer_email") {
      if (value !== "") {
        this.setState({ error_customer_email: false });
        this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
      } else {
        this.setState({ error_customer_email: true, error_invalid_email: '', error_exist_email: '' });
      }
      this.setState(
        { customer_email: value },
        function () {
          var validate_Email_Fun = validateEmailFun(this.state.customer_email);
          if (validate_Email_Fun === true) {
            this.checkAlreadyExit("email");
          }
        }.bind(this)
      );
    }else if (event.target.name === "customer_firstname") {
      if (value !== "") {
        this.setState({ error_customer_firstname: false });
      } else {
        this.setState({ error_customer_firstname: true });
      }
      this.setState({ customer_firstname: value });
    }else if (event.target.name === "customer_lastname") {
      if (value !== "") {
        this.setState({ error_customer_lastname: false });
      } else {
        this.setState({ error_customer_lastname: true });
      }
      this.setState({ customer_lastname: value });
    }else if (event.target.name === "customer_password") {
      if (value !== "") {
        this.setState({ customer_password_error: false });
      } else {
        this.setState({ customer_password_error: true });
      }
      this.setState({ customer_password: value });
    }else if (event.target.name === "customer_confpassword") {
      if (value !== "") {
        this.setState({ customer_confpassword_error: false });
      } else {
        this.setState({ customer_confpassword_error: true });
      }
      this.setState({ customer_confpassword: value });
    }

  }

  checkAlreadyExit(flg) {
    var postObject = {
      type: flg,
      customer_email: this.state.customer_email,
     // app_id: appId,
      customer_phone: this.state.customermobile_number,
    };
    axios
      .post(apiUrl + "chopchop/customer/loyality_checkexisting", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "success") {
          if (flg === "email") {
            this.setState({ error_exist_email: "", error_customer_email:false, error_invalid_email: false });
            
          }
        } else {
          if (flg === "email") {
            this.setState({ error_exist_email: res.data.message, error_customer_email: false});
          }
        }
      });
  }

 validateIntval(e) {  
    const re = /[0-9]+/g; 
    if (!re.test(e.key)) {  
      e.preventDefault(); 
    } 
  }

RegistrationSubmit(){

  var errorFlg = "n";

  if (this.state.customermobile_number === "") {
    errorFlg = "y";
    this.setState({ error_customer_phone: true });
    return false;
  }else{
    this.setState({ error_customer_phone: false });
  }

  if (this.state.customer_email === "") {
    errorFlg = "y";
    this.setState({ error_customer_email: true, error_invalid_email: false });
    return false;
   }else if(this.state.error_exist_email !== ''){
    errorFlg = "y";
    this.setState({ error_customer_email: true,  error_invalid_email: false});
    this.setState({ });
   }else {
      var validate_Email_Fun = validateEmailFun(this.state.customer_email);

      if (validate_Email_Fun === true) {
        this.setState({ error_customer_email: false });
        this.setState({ error_invalid_email: false });
      } else {
        errorFlg = "n";
        this.setState({ error_invalid_email: true});
        return false;
      }
  }

  if (this.state.customer_firstname === "") {
    errorFlg = "y";
    this.setState({ error_customer_firstname: true });
    return false;
  }else{
    this.setState({ error_customer_firstname: false });
  }

  if (this.state.customer_lastname === "") {
    errorFlg = "y";
    this.setState({ error_customer_lastname: true });
    return false;
  }else{
    this.setState({ error_customer_lastname: false });
  }

  if (this.state.birthdate === "") {
    errorFlg = "y";
    this.setState({ error_customer_birthaday_error: true });
    return false;
  }else{
    this.setState({ error_customer_birthaday_error: false });
  }

  if (this.state.customer_password=== "") {
    errorFlg = "y";
    this.setState({ customer_password_error: true });
    return false;
  }else{
    this.setState({ customer_password_error: false });
  }

  if (this.state.customer_confpassword_error === "") {
    errorFlg = "y";
    this.setState({ customer_confpassword_error: true });
    return false;
  }else{

    this.setState({ customer_confpassword_error: false });
  }

   if (this.state.customer_password !== this.state.customer_confpassword) {
    errorFlg = "y";
    this.setState({ customer_confpassword_match: true });
    return false;
  }else{
    this.setState({ customer_confpassword_match: false });
  }

 if (this.state.isChecked === false) {
    errorFlg = "y";
    this.setState({ terms_error : true });
    return false;
  }else{
    this.setState({ terms_error: false });
  }

  if (errorFlg === "n") {

    var customermobile_number = this.state.customermobile_number.slice(2);

      var postObject = {
        cust_firstname: this.state.customer_firstname,
        cust_lastname: this.state.customer_lastname,
        cust_phone: customermobile_number,
        cust_email: this.state.customer_email,
        cust_dob: this.state.birthdate,
        customer_verification: "verified", 
        customer_password: this.state.customer_confpassword, 
      };




      axios.post(apiUrl + "chopchop/customer/customer_home_registration", qs.stringify(postObject)).then((res) => {
        
              if (res.data.status === "ok") {

                reactLocalStorage.set("login_type", "customer");
                reactLocalStorage.set("LoyaltyUserId", res.data.result_set.customer_id);
                reactLocalStorage.set("UserMobile", res.data.result_set.customer_phone);
                reactLocalStorage.set("UserEmail", res.data.result_set.customer_email);
                reactLocalStorage.set("UserFname", res.data.result_set.customer_first_name);
                reactLocalStorage.set("UserLname", res.data.result_set.customer_last_name);


                var cust_birthdate = "";

                if (typeof res.data.result_set.customer_birthdate !== "undefined" &&
                    res.data.result_set.customer_birthdate !== "null" &&
                   res.data.result_set.customer_birthdate !== null &&
                    res.data.result_set.customer_birthdate !== "" &&
                    res.data.result_set.customer_birthdate !== "0000-00-00"
                  ) {
                    cust_birthdate = res.data.result_set.customer_birthdate;
                  }

                reactLocalStorage.set("UserBirthdate", cust_birthdate);

                cookie.remove("register_customer_mobile_number",{ path: "/" });

                reactLocalStorage.remove("register_customer_mobile_number");
                
                showCustomAlert("success", res.data.message);

                this.handleShowAlertFun("success", res.data.message);

                this.props.history.push("/registersuccess");
              } else {
                showCustomAlert("error", res.data.message);
              }
            });

  }

}

 handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }


  handleChangeDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    cookie.save("birthdate", dateval, { path: "/" });
    
    this.handleChange("birthdate", datevalue);
    this.setState({ birthdate: datevalue });
  }

  handleChange = (item, value) => {
    this.setState({ ...this.state, [item]: value });
    var des = document.getElementsByClassName("birthdate");
  };

toggleChange = (e) => {
 
  this.setState({
    [e.target.name]: e.target.value,
  });

}

  sateValChange = (field, value) => {};
  render() {

    var birthdate = '';
    if (this.state.birthdate !== "") {
      birthdate = this.state.birthdate;
    }

    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}
        <div className="customer_Mainregister">
        <div className="container">
          <div className="login_main_div">
            <div className="login_form register_div">
            <div className="register-logodiv">
                  <a>
                  <img src={footerLogo} />
                  </a>
                </div>

              <div className="register_heading_main">
                <div className="back-package">
                  <a className="back-button"><img src={leftarrowImg} /></a>
                </div>
                 <div className="register_heading">
                <h4>Complete signing up</h4>
                </div>
              </div>

              <form>

                <label class="control-label"> Enter Your Mobile Number</label>
           
                      <PhoneInput
                        country={"sg"}
                        value={this.state.customermobile_number}
                        countryCodeEditable={false}
                        onChange={(customermobile_number, country) =>
                        this.setState({
                            customermobile_number,
                        }) }
                        disabled
                        />
    
                 {/*<input type="tel" disabled placeholder="Enter your mobile number" name="customermobile_number" 
                 placeholder="123-45-678" maxLength="8" value={this.state.country+this.state.customermobile_number} 
                 onChange={this.handleTextChange_getStarted.bind(this)} onKeyPress={(e) => this.validateIntval(e)} />*/}
                

                 {this.state.error_customer_phone === true && (
                    <span className="error">This field is required.</span>
                 )}    
                   
                <div className="control-group">
                  <input type="email" id="customer_email" name="customer_email" placeholder="Email Address" value={this.state.customer_email} onChange={this.handleTextChange_getStarted.bind(this)} />
                  <label class="control-label">Email Address</label>

                  {this.state.error_customer_email === true && (
                    <span className="error">This field is required.</span>
                  )}    
                  
                  {this.state.error_invalid_email === true && (
                    <span className="error">This is not a valid email.</span>
                  )}    
        
                  
                   {this.state.error_exist_email !== "" && (
                    <span className="error">
                      {this.state.error_exist_email}
                    </span>
                  )}
                </div>

                <div className="control-group">
                  <input type="text" id="customer_firstname" name="customer_firstname" placeholder="First Name" value={this.state.customer_firstname} onChange={this.handleTextChange_getStarted.bind(this)}/>
                  <label class="control-label">First Name</label>
                
                 {this.state.error_customer_firstname === true && (
                    <span className="error">This field is required.</span>
                  )}    

                </div>


                <div className="control-group">
                  <input type="text" id="customer_last_name" name="customer_lastname" placeholder="Last Name" value={this.state.customer_lastname} onChange={this.handleTextChange_getStarted.bind(this)}/>
                  <label class="control-label">Last Name</label>
                
                 {this.state.error_customer_lastname === true && (
                    <span className="error">This field is required.</span>
                 )}    
                                    
                </div>

                <div className="control-group">
                     <DatePicker
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control input-focus"
                      selected={birthdate}
                      maxDate={this.state.Maxdate}
                      dateFormat="dd/MM/yyyy"
                      onChange={this.handleChangeDate}
                      placeholderText={'Date Of Birth'}
                    />

                  <label class="control-label">DOB</label>


                 {this.state.error_customer_birthaday_error === true && (
                    <span className="error">This field is required.</span>
                 )}    

                </div>

            

                <div className="control-group">
                  <input type="password"  name="customer_password" value={this.state.customer_password} placeholder="Enter your password"  onChange={this.handleTextChange_getStarted.bind(this)} />
                  <label class="control-label">Password</label>
                </div> 

                 {this.state.customer_password_error === true && (
                    <span className="error">This field is required.</span>
                 )}

                <div className="control-group">
                  <input type="password" name="customer_confpassword"  value={this.state.customer_confpassword} placeholder="Enter your confirm password"  onChange={this.handleTextChange_getStarted.bind(this)}/>
                  <label class="control-label">Confirm Password</label>
                </div>

                {this.state.customer_confpassword_error === true && (
                    <span className="error">This field is required.</span>
                 )}

                 {this.state.customer_confpassword_match === true && (
                    <span className="error">Password doesn't match.</span>
                 )}

                <div className="terms-chk">
                   <input type="checkbox" id="terms" name="isChecked" value={this.state.isChecked}  onChange={(e) => {
                  this.toggleChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                    }}  />
                   <label className="inline">I agree with NinjaOS  <a href="/terms-and-conditions" target="_blank">Terms &amp; Conditions</a> 
                   </label>
                 </div>
                 {this.state.terms_error === true && (
                    <span className="error">This field is required.</span>
                 )}    

              </form>
              </div>
            <div className="login-btn">
              <button type="button" className="button delivery_submit_cls" onClick={this.RegistrationSubmit.bind(this)}>REGISTER</button>
            </div>
          </div>
        </div>
        </div>
        <Footer />

      </div>
    );
  }
}

const mapStateToProps = (state) => {


  return {
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },

  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
